import React from 'react';
import styled, { css } from 'styled-components';

import { Box, Flex } from '@rover/kibble/core';
import { DSTokenMap, MQ } from '@rover/kibble/styles';
import CalloutBadge from '@rover/react-lib/src/components/callouts/CalloutBadge';
import type { ProfileStatusBadge, SitterBadge } from '@rover/types';

import { SEARCH_RESULT_CARD_Z_INDEX } from '../SearchResultCard.constants';
import SitterBadges from '../SitterBadges';

export type Props = {
  badgeData: SitterBadge[];
  displayExpanded: boolean;
  name: string;
  handleBadgeChange: (arg0: boolean) => void;
  index?: number;
  isBadgeOpen: boolean;
  profileStatusBadgeData?: ProfileStatusBadge;
  rank?: number | null;
};

const expandedFontStyles = css`
  font-size: ${DSTokenMap.HEADING_400_FONT_SIZE.toString()};
  font-weight: ${DSTokenMap.HEADING_300_FONT_WEIGHT.toString()};
  color: ${DSTokenMap.INTERACTIVE_TEXT_COLOR_LINK_SECONDARY.toString()};
  :hover {
    color: ${DSTokenMap.INTERACTIVE_TEXT_COLOR_LINK_SECONDARY.toString()};
  }
`;

const reducedFontStyles = css`
  font-size: ${DSTokenMap.HEADING_200_FONT_SIZE};
  font-weight: ${DSTokenMap.HEADING_200_FONT_WEIGHT};
`;

type NameLinkProps = {
  displayExpanded: boolean;
};

const StyledSitterNameHeading = styled.h3<NameLinkProps>`
  ${expandedFontStyles}

  ${MQ.LG.toString()} {
    ${(props) => (props.displayExpanded ? '' : reducedFontStyles)}
  }

  margin-top: 0;
  margin-bottom: 0;
  margin-right: ${DSTokenMap.SPACE_1X};
  overflow-wrap: break-word;

  ${MQ.XS_DOWN.toString()} {
    ${reducedFontStyles}
  }
`;

const NameRow: React.FC<Props> = ({
  badgeData,
  displayExpanded,
  name,
  handleBadgeChange,
  index,
  isBadgeOpen,
  profileStatusBadgeData,
  rank,
}) => {
  const showRank = rank != null;

  return (
    // @ts-expect-error
    <Flex flexWrap="wrap" mt="-4px" mb="1x" alignItems="center">
      <Flex mt="1x">
        <StyledSitterNameHeading displayExpanded={displayExpanded} aria-label={name}>
          {showRank && `${rank}. `}
          <span itemProp="name">{name}</span>
        </StyledSitterNameHeading>
      </Flex>
      <Flex
        mt="1x"
        ml="4x"
        zIndex={
          isBadgeOpen
            ? SEARCH_RESULT_CARD_Z_INDEX.BADGE_OPEN.toString()
            : SEARCH_RESULT_CARD_Z_INDEX.BADGE_CLOSED.toString()
        }
      >
        <SitterBadges
          badgeData={badgeData}
          displayExpanded={displayExpanded}
          handleBadgeChange={handleBadgeChange}
          index={index}
          badgeProps={{ itemProp: 'hasCredential' }}
        />
      </Flex>
      {profileStatusBadgeData && profileStatusBadgeData.slug === 'star-sitter' && (
        <Box mt="1x">
          <CalloutBadge
            value={profileStatusBadgeData.text}
            calloutBadgeStyle={CalloutBadge.Styles.STAR_SITTER}
            itemProp="award"
          />
        </Box>
      )}
      {profileStatusBadgeData && profileStatusBadgeData.slug === 'daycare-center' && (
        <Box mt="1x">
          <CalloutBadge
            value={profileStatusBadgeData.text}
            calloutBadgeStyle={CalloutBadge.Styles.DAYCARE_CENTER}
          />
        </Box>
      )}
    </Flex>
  );
};

export default NameRow;
