import React, { useId } from 'react';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import { A11yHidden } from '@rover/kibble/styles';
import type { Option } from '@rover/react-lib/src/components/formFields/ButtonGroup';
import ButtonGroup from '@rover/react-lib/src/components/formFields/ButtonGroup';
import type { AvailabilityBuckets } from '@rover/types/src/AvailabilityBuckets';
import { AvailabilitySearchFilters } from '@rover/types/src/AvailabilitySearchFilters';
import FilterLabel from '@rover/shared/js/search/components/FilterLabel';

import Props from './DayTimeAvailabilityProps';

const StyledLabel = styled(FilterLabel)<{ hideLabel: boolean }>`
  ${(props) => (props.hideLabel ? A11yHidden : '')};
`;

const DayTimeAvailability = (props: Props): JSX.Element => {
  const dayTimeAvailabilityId = useId();
  const { availabilityBuckets, label } = props;
  const labelIsVisible = !!props.labelIsVisible;

  const getDayTimeFiltersFromProps = () => {
    return {
      morningAvailability: props.searchFilters.morningAvailability,
      middayAvailability: props.searchFilters.middayAvailability,
      eveningAvailability: props.searchFilters.eveningAvailability,
    } as AvailabilitySearchFilters;
  };

  const handleOnChange = (option: Option) => {
    const updatedAvailabilityFilters = { ...getDayTimeFiltersFromProps() };
    updatedAvailabilityFilters[option.key] = !option.value;
    props.onChange(updatedAvailabilityFilters);
  };

  const createOptions = (buckets: AvailabilityBuckets): Option[] => {
    const dayTimeFilters = getDayTimeFiltersFromProps();
    return Object.keys(buckets).map((key) => {
      const optionKey = `${key}Availability`;
      const searchFiltersForKeyEnabled = dayTimeFilters[optionKey];
      const optionValue = searchFiltersForKeyEnabled;
      const optionLabel = buckets[key];
      const ariaLabel = buckets[key].toUpperCase().split('-').join(' - '); // helps screenreader read as time range
      return {
        key: optionKey,
        label: optionLabel,
        'aria-label': ariaLabel,
        value: optionValue,
      };
    });
  };

  const options: Option[] = createOptions(availabilityBuckets);

  return (
    <>
      <StyledLabel id={dayTimeAvailabilityId} hideLabel={!labelIsVisible}>
        <Trans id={label} />
      </StyledLabel>
      <ButtonGroup
        options={options}
        aria-labelledby={dayTimeAvailabilityId}
        onToggle={(option) => handleOnChange(option)}
        name="daytime-availability"
      />
    </>
  );
};

export default DayTimeAvailability;
