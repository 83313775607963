import React from 'react';
import { plural } from '@lingui/macro';

import { Flex, Text } from '@rover/kibble/core';
import StarRating from '@rover/kibble/patterns/StarRating';
import { ThemeFontWeight } from '@rover/kibble/styles';
import { useI18n } from '@rover/rsdk/src/modules/I18n';

type Props = {
  ratingsAverage: string;
  reviewsCount: number;
  fontWeight?: ThemeFontWeight;
};

export default function RatingsReview({
  ratingsAverage,
  reviewsCount,
  fontWeight,
}: Props): React.ReactElement {
  const { i18n } = useI18n();
  const rating = parseFloat(ratingsAverage) || 0;

  const hasRating = rating > 0;

  const separator = hasRating ? '• ' : '';

  return (
    <Flex gap="2x" data-testid="ratings-review-row">
      {hasRating && (
        <StarRating
          textSize="100"
          fontWeight={fontWeight ?? 'regular'}
          rating={rating}
          variant="compact"
        />
      )}
      <Text size="100" fontWeight={fontWeight ?? 'regular'}>
        {separator}
        {i18n._(plural({ value: reviewsCount, one: '# review', other: '# reviews' }))}
      </Text>
    </Flex>
  );
}
