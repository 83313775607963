import React from 'react';
import keyBy from 'lodash-es/keyBy';

import { Box, Text } from '@rover/kibble/core';
import Join from '@rover/react-lib/src/components/utils/Join';
import SERVICES from '@rover/react-lib/src/constants/services.constants';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import type { ServiceTypeSlug } from '@rover/types';

import { schema } from '../../../microdata';

type KeyBy<T extends any[], K extends string> = Record<T[number][K], T[number]>;

const SERVICES_BY_TYPE: KeyBy<typeof SERVICES, 'type'> = keyBy(SERVICES, 'type');

const OfferedServices: React.FC<{ slugs: ServiceTypeSlug[] }> = ({ slugs }) => {
  const { i18n } = useI18n();

  const offeredServices = slugs
    .map((slug) => SERVICES_BY_TYPE[slug]?.title)
    .filter(Boolean)
    .map((serviceName) => (
      <span key={serviceName.id} itemProp="itemListElement" itemScope itemType={schema`Service`}>
        <span itemProp="name">{i18n._(serviceName)}</span>
      </span>
    ));

  return (
    <Box mb="2x" itemProp="hasOfferCatalog" itemScope itemType={schema`OfferCatalog`}>
      <meta itemProp="numberOfItems" content={`${offeredServices.length}`} />
      <Text size="100" fontWeight="semibold">
        <Join separator=", ">{offeredServices}</Join>
      </Text>
    </Box>
  );
};

export default OfferedServices;
