/* eslint-disable max-classes-per-file */
import { CONCEPTS } from '@rover/rsdk/src/concepts';
import type { ConsentPurpose } from '@rover/rsdk/src/modules/Analytics';
import { AnalyticsEvent } from '@rover/types/src/Analytics';

/**
 * &nbsp;
 *
 * # Description
 *
 * Fires when SearchSittersBanner is shown with alert banner.
 *
 * &nbsp;
 */
export class SearchSittersBannerShownWithAlert extends AnalyticsEvent {
  eventName = 'searchpage-no-dates-header-shown';

  consentPurpose: ConsentPurpose = 'functional';

  concept = CONCEPTS.recommendations.browse_search;
}

/**
 * &nbsp;
 *
 * # Description
 *
 * Fires when SearchSittersBanner is shown with alert banner.
 *
 * &nbsp;
 */
export class SearchSittersBannerShown extends AnalyticsEvent {
  eventName = 'searchpage-yes-dates-header-shown';

  consentPurpose: ConsentPurpose = 'functional';

  concept = CONCEPTS.recommendations.browse_search;
}
