import React from 'react';
import { plural } from '@lingui/macro';

import { Rebook, Trophy } from '@rover/icons';
import { Box, Flex, Text } from '@rover/kibble/core';
import { FavoriteButton } from '@rover/react-lib/src/components';
import { useI18n } from '@rover/rsdk/src/modules/I18n';

import RatingsReview from './InfoColumn/RatingsReview';
import { SEARCH_RESULT_CARD_Z_INDEX } from './SearchResultCard.constants';

type Props = {
  ratingsAverage: string;
  repeatClientCount: number;
  reviewsCount: number;
  yearsOfExperience: string;
  isAuthenticated: boolean | null | undefined;
  isFavorite: boolean | undefined;
  onFavoriteToggled: () => void;
  hideFavorite: boolean | null | undefined;
  isFacility?: boolean;
};

const ICON_SIZE = 16;

export default function PillsRow({
  ratingsAverage,
  repeatClientCount,
  reviewsCount,
  yearsOfExperience,
  isAuthenticated,
  isFavorite,
  onFavoriteToggled,
  hideFavorite,
  isFacility = false,
}: Props): React.ReactElement {
  const { i18n } = useI18n();

  const shouldShowRatingsReviews = reviewsCount > 0;
  const shouldShowRepeatCount = repeatClientCount > 0;
  const shouldShowYearsOfExperience = isFacility
    ? !shouldShowRepeatCount && yearsOfExperience !== ''
    : !shouldShowRatingsReviews && !shouldShowRepeatCount && yearsOfExperience !== '';

  return (
    <Flex width="100%" mt="3x" mb="2x" alignItems="flex-start">
      <Flex
        flex="1"
        flexDirection="row"
        gap="2x"
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
      >
        {shouldShowRatingsReviews && (
          <Box minWidth="30%">
            <RatingsReview ratingsAverage={ratingsAverage} reviewsCount={reviewsCount} />
          </Box>
        )}
        {shouldShowRepeatCount && (
          <Flex flex="1" gap="1x" flexBasis="auto">
            <Box as={Rebook} height={ICON_SIZE} width={ICON_SIZE} flexShrink="0" />
            <Text size="100">
              {i18n._(
                plural({
                  value: repeatClientCount,
                  one: '# repeat client',
                  other: '# repeat clients',
                })
              )}
            </Text>
          </Flex>
        )}

        {shouldShowYearsOfExperience && (
          <Flex flex="1" gap="1x">
            <Trophy width={ICON_SIZE} height={ICON_SIZE} />
            <Text size="100">{yearsOfExperience}</Text>
          </Flex>
        )}
        {!hideFavorite && (
          <Box zIndex={`${SEARCH_RESULT_CARD_Z_INDEX.INTERACTIVE}`}>
            <FavoriteButton
              variant="flat"
              on={!!isFavorite}
              onClick={(ev) => {
                ev.stopPropagation();

                if (isAuthenticated) {
                  onFavoriteToggled();
                } else {
                  // @ts-expect-error
                  // eslint-disable-next-line rover/no-platform-specific-globals-or-imports
                  window.location = `/account/continue/?action=sign_in&next=${window.location.pathname}`;
                }
              }}
            />
          </Box>
        )}
      </Flex>
    </Flex>
  );
}
