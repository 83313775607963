import type { RefObject } from 'react';
import React, { Component } from 'react';
import styled, { css } from 'styled-components';

import { DSTokenMap, TABLET_MAX, TABLET_MIN } from '@rover/kibble/styles';
import RoverLoading from '@rover/react-lib/src/components/utils/RoverLoading';
import SearchTrustSafetyBanner from '@rover/react-lib/src/pages/search/SearchPage/components/SearchResults/SearchTrustSafetyBanner';
import type { SearchResultsObservation } from '@rover/react-lib/src/pages/search/SearchPage/SearchPage.types';
import { LAYOUT, SEARCH_PAGE_Z_INDEX } from '@rover/shared/js/constants/searchPage.constants';
import { SearchFilters, ServiceType } from '@rover/types';

import SearchResultsContainer from './SearchResultsContainer';
import { SearchResultsTitle } from './SearchResultsTitle';

export type Props = {
  isMapExpanded: boolean;
  isSearchLoading: boolean;
  onObserve: (observation: SearchResultsObservation) => void;
  scrollToResults: boolean;
  searchCardRefs?: (instance: HTMLDivElement, index: number) => void;
  resultsRef: RefObject<any>;
  isSeoPage: boolean;
  userInEurope: boolean;
  showWizardFilters: boolean;
  isRollout1: boolean;
  inVariantSimplifiedMobileOwnerSearchFormExperiment: boolean;
  searchFilters: SearchFilters;
  serviceTypeOptions: ServiceType[];
  hasSelectedProvider: boolean;
};
const StyledRoverLoading = styled(RoverLoading)`
  flex: 1;
  background-color: ${DSTokenMap.BACKGROUND_COLOR_SECONDARY.toString()};

  &:after {
    top: 40px !important;
  }
`;

const DESKTOP = `@media(min-width:${TABLET_MAX + 1}px)`;
const TABLET = `@media (min-width: ${TABLET_MIN}px and max-width: ${TABLET_MAX}px)`;

const Results = styled.div<{
  isMapExpanded: boolean;
}>`
  flex: 1;
  background-color: ${DSTokenMap.BACKGROUND_COLOR_PRIMARY.toString()};
  align-self: stretch;
  will-change: transform;
  z-index: ${SEARCH_PAGE_Z_INDEX.SEARCH_RESULTS_WRAPPER};

  ${DESKTOP} {
    border-radius: ${DSTokenMap.BORDER_RADIUS_SECONDARY} 0 0 ${DSTokenMap.BORDER_RADIUS_SECONDARY};
    flex: 0 0;
    width: 0;
  }

  ${({ isMapExpanded }) => {
    const WIDTH_PERCENT = isMapExpanded ? LAYOUT.NARROW_WIDTH_PERCENT : LAYOUT.WIDE_WIDTH_PERCENT;
    return css`
      ${TABLET} {
        flex-basis: calc(${LAYOUT.AVAILABLE_WIDTH} * ${LAYOUT.WIDE_WIDTH_PERCENT});
      }
      ${DESKTOP} {
        flex-basis: calc(${LAYOUT.AVAILABLE_WIDTH} * ${WIDTH_PERCENT});
      }
    `;
  }};
`;
type ObserverProps = {
  bound: 'top' | 'bottom';
  rootMargin: string;
};
export default class SearchResultsWrapper extends Component<Props> {
  viewportIsBelowTop: boolean;

  viewportIsAboveBottom: boolean;

  observers!: {
    top: IntersectionObserver;
    bottom: IntersectionObserver;
  };

  constructor(props: Props) {
    super(props);
    this.viewportIsBelowTop = false;
    this.viewportIsAboveBottom = false;
  }

  componentDidMount(): void {
    const areaAboveResults = '10000% 0px -100% 0px';
    const areaBelowResults = '-100% 0px 10000% 0px';
    this.observers = {
      top: this.createObserver({
        bound: 'top',
        rootMargin: areaAboveResults,
      }),
      bottom: this.createObserver({
        bound: 'bottom',
        rootMargin: areaBelowResults,
      }),
    };
    const { resultsRef } = this.props;
    this.observers.top.observe(resultsRef.current);
    this.observers.bottom.observe(resultsRef.current);
  }

  componentDidUpdate(): void {
    const { resultsRef } = this.props;

    // does not run on initial render
    if (this.props.isSearchLoading && this.props.scrollToResults) {
      if (resultsRef && resultsRef.current) {
        resultsRef.current.scrollIntoView();
      }
    }
  }

  componentWillUnmount(): void {
    if (this.observers.top) {
      this.observers.top.disconnect();
    }

    if (this.observers.bottom) {
      this.observers.bottom.disconnect();
    }
  }

  onObserveIntersection = (): void => {
    const { onObserve } = this.props;

    if (onObserve) {
      onObserve({
        viewportIsBelowTop: this.viewportIsBelowTop,
        viewportIsAboveBottom: this.viewportIsAboveBottom,
      });
    }
  };

  createObserver = ({ bound, rootMargin }: ObserverProps): IntersectionObserver => {
    const onThresholdCross = (entries): void => {
      entries.forEach((entry) => {
        if (bound === 'top') {
          this.viewportIsBelowTop = entry.isIntersecting;
        }

        if (bound === 'bottom') {
          this.viewportIsAboveBottom = entry.isIntersecting;
        }

        this.onObserveIntersection();
      });
    };

    return new IntersectionObserver(onThresholdCross, {
      rootMargin,
    });
  };

  render(): JSX.Element {
    const {
      isMapExpanded,
      isSearchLoading,
      resultsRef,
      searchCardRefs,
      isSeoPage,
      userInEurope,
      showWizardFilters,
      isRollout1,
      inVariantSimplifiedMobileOwnerSearchFormExperiment,
      searchFilters,
      serviceTypeOptions,
      hasSelectedProvider,
    } = this.props;

    return (
      <Results
        ref={resultsRef}
        data-qa-id="search-results-wrapper"
        data-testid="search-results-wrapper"
        isMapExpanded={isMapExpanded}
      >
        <StyledRoverLoading isLoading={isSearchLoading}>
          {isRollout1 &&
            inVariantSimplifiedMobileOwnerSearchFormExperiment &&
            showWizardFilters && <SearchTrustSafetyBanner userInEurope={userInEurope} />}
          {inVariantSimplifiedMobileOwnerSearchFormExperiment &&
            isRollout1 &&
            !hasSelectedProvider && (
              <SearchResultsTitle
                searchFilters={searchFilters}
                serviceTypeOptions={serviceTypeOptions}
                shouldAddMargin={showWizardFilters}
              />
            )}
          <SearchResultsContainer searchCardRefs={searchCardRefs} isSeoPage={isSeoPage} />
        </StyledRoverLoading>
      </Results>
    );
  }
}
