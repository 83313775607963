import type { ReactElement } from 'react';
import React, { useId } from 'react';
import styled from 'styled-components';

import { AlertHelp } from '@rover/icons';
import { DSTokenMap } from '@rover/kibble/styles';

import Tooltip, { Props as TooltipProps } from '../Tooltip';

type Props = {
  icon?: string;
  text: string;
} & TooltipProps;

type IconProps = {
  role: string;
  tabIndex: string;
  'aria-describedby': string;
};

export const IconWrapper = styled.span<Record<string, unknown>>`
  svg {
    fill: ${DSTokenMap.TEXT_COLOR_PRIMARY.toString()};
    height: 16px;
    vertical-align: middle;
    width: 16px;
  }
`;

const renderIconComponent = (IconComponent: string, iconProps: IconProps): ReactElement | null => {
  if (!IconComponent) {
    return null;
  }

  return (
    <IconWrapper>
      <IconComponent {...iconProps} data-testid="something" />
    </IconWrapper>
  );
};

const IconTooltip = ({ icon = AlertHelp, text, ...other }: Props): ReactElement => {
  const tooltipId = useId();
  const iconProps: IconProps = {
    role: 'image',
    tabIndex: '0',
    'aria-describedby': tooltipId,
  };

  return (
    <Tooltip id={tooltipId} text={text} toggleOnFocusOrBlur {...other} data-testid="hello2">
      {renderIconComponent(icon, iconProps)}
    </Tooltip>
  );
};

export default IconTooltip;
