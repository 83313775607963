import { A11yHiddenBox } from '@rover/kibble/styles';
import { SeoLocationSchema as SeoLocation } from '@rover/rsdk/src/apiClient/latest';

import MicrodataHTMLAttributes from './MicrodataHTMLAttributes';
import PostalAddress from './PostalAddress';
import schema from './schema';

type GeoCoordinatesProps = {
  latitude: string;
  longitude: string;
} & MicrodataHTMLAttributes;

const GeoCoordinates: React.FC<GeoCoordinatesProps> = ({
  latitude,
  longitude,
  ...props
}: GeoCoordinatesProps) => (
  <A11yHiddenBox {...props} itemScope itemType={schema`GeoCoordinates`}>
    <meta itemProp="latitude" content={latitude} />
    <meta itemProp="longitude" content={longitude} />
  </A11yHiddenBox>
);

type Props = Pick<SeoLocation, 'localityType' | 'details'> & MicrodataHTMLAttributes;

const CityOrPlace: React.FC<Props> = ({ localityType, details, ...props }: Props) => {
  const { locality } = details;
  return (
    <A11yHiddenBox
      {...props}
      itemScope
      itemType={localityType === 'city' ? schema`City` : schema`Place`}
    >
      <meta itemProp="name" content={locality.seoDisplayName} />
      <GeoCoordinates itemProp="geo" {...locality} />
    </A11yHiddenBox>
  );
};

export default CityOrPlace;
