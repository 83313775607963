import React, { FC, useCallback, useMemo, useRef } from 'react';
import { t } from '@lingui/macro';

import { Cat as CatIcon, Dog as DogIcon, Puppy as PuppyIcon } from '@rover/icons';
import { Text } from '@rover/kibble/core';
import Row from '@rover/kibble/patterns/Row';
import { DSTokenMap } from '@rover/kibble/styles';
import { StyledStepper } from '@rover/react-lib/src/components/forms/AddPetForm/AddPetFormFlow/styles';
import RowList from '@rover/react-lib/src/components/RowList';
import { emitAnalyticsEvent } from '@rover/rsdk/src/modules/Analytics';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import { MAX_ALLOWED_PETS } from '@rover/shared/js/constants/searchPage.constants';
import { PetType } from '@rover/types/src/Pet';

import type { TriggeredFrom } from './analyticsEvents';
import { PetCountAddedEvent, PetCountRemovedEvent } from './analyticsEvents';

type PetTypeDetails = {
  icon: React.JSX.Element;
  label: string;
  singularNoun: string;
  value: number;
  maxValue: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
  sublabel?: string;
};

type PetTypeDetailsMap = {
  [petType in PetType]: PetTypeDetails;
};

export type PetCounterBodyProps = {
  dogs: number;
  cats: number;
  puppies: number;
  availablePets: string[]; // Change to PetType[] in DEV-106783
  triggeredFrom?: TriggeredFrom;
  setDogs: React.Dispatch<React.SetStateAction<number>>;
  setPuppies: React.Dispatch<React.SetStateAction<number>>;
  setCats: React.Dispatch<React.SetStateAction<number>>;
};

const PetCounterBody: FC<PetCounterBodyProps> = ({
  dogs,
  cats,
  puppies,
  availablePets,
  triggeredFrom,
  setDogs,
  setPuppies,
  setCats,
}) => {
  const { i18n } = useI18n();
  const increaseButtonRef = useRef<Record<string, HTMLButtonElement>>(null);
  const petTypeMap: PetTypeDetailsMap = useMemo(
    () => ({
      [PetType.DOG]: {
        icon: <DogIcon width={24} height={24} fill={DSTokenMap.TEXT_COLOR_SECONDARY.toString()} />,
        label: i18n._(t`Dogs`),
        singularNoun: i18n._(t`dog`),
        value: dogs,
        maxValue: MAX_ALLOWED_PETS - cats - puppies,
        setValue: setDogs,
      },
      [PetType.PUPPY]: {
        icon: (
          <PuppyIcon width={24} height={24} fill={DSTokenMap.TEXT_COLOR_SECONDARY.toString()} />
        ),
        label: i18n._(t`Puppies`),
        singularNoun: i18n._(t`puppy`),
        value: puppies,
        maxValue: MAX_ALLOWED_PETS - dogs - cats,
        setValue: setPuppies,
        sublabel: i18n._(t`Younger than 1 year old`),
      },
      [PetType.CAT]: {
        icon: <CatIcon width={24} height={24} fill={DSTokenMap.TEXT_COLOR_SECONDARY.toString()} />,
        label: i18n._(t`Cats`),
        singularNoun: i18n._(t`cat`),
        value: cats,
        maxValue: MAX_ALLOWED_PETS - dogs - puppies,
        setValue: setCats,
      },
    }),
    [dogs, cats, puppies, setDogs, setCats, setPuppies, i18n]
  );

  const handleCounterIncrement = useCallback(
    (pet: string): void => {
      emitAnalyticsEvent(new PetCountAddedEvent({ triggeredFrom }));
      petTypeMap[pet].setValue((prev: number) => prev + 1);
    },
    [petTypeMap, triggeredFrom]
  );

  const handleCounterDecrement = useCallback(
    (pet: string): void => {
      emitAnalyticsEvent(new PetCountRemovedEvent({ triggeredFrom }));
      if (petTypeMap[pet].value === 1) {
        increaseButtonRef[pet].focus();
      }
      petTypeMap[pet].setValue((prev: number) => prev - 1);
    },
    [petTypeMap, triggeredFrom]
  );

  return (
    <div data-testid="pet-counter-body">
      <RowList>
        {availablePets?.map((pet) => (
          <Row
            key={pet}
            label={
              <Text size="200" fontWeight="semibold">
                {petTypeMap[pet].label}
              </Text>
            }
            leftItem={petTypeMap[pet].icon}
            {...(petTypeMap[pet].sublabel ? { sublabel: petTypeMap[pet].sublabel } : {})}
            rightItem={
              <StyledStepper
                value={petTypeMap[pet].value}
                onIncrement={() => handleCounterIncrement(pet)}
                onDecrement={() => handleCounterDecrement(pet)}
                minValue={0}
                maxValue={petTypeMap[pet].maxValue}
                focusableRef={(ref) => {
                  increaseButtonRef[pet] = ref;
                }}
                incrementProps={{
                  'aria-label': i18n._(
                    /* i18n: This is an aria-label on a plus button where users indicate how many dogs they have. The label announces currently selected value, e.g. "Add one dog, current value 3" */
                    t`Add one ${petTypeMap[pet].singularNoun}, current value ${petTypeMap[pet].value}`
                  ),
                }}
                decrementProps={{
                  'aria-label': i18n._(
                    /* i18n: This is an aria-label on a minus button where users indicate how many dogs they have. The label announces currently selected value, e.g. "Remove one dog, current value 3" */
                    t`Remove one ${petTypeMap[pet].singularNoun}, current value ${petTypeMap[pet].value}`
                  ),
                }}
                inputProps={{
                  'aria-roledescription': i18n._(petTypeMap[pet].label),
                  'aria-disabled': true,
                }}
              />
            }
          />
        ))}
      </RowList>
    </div>
  );
};
export default PetCounterBody;
