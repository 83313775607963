import React from 'react';

import { TABLET_MAX } from '@rover/kibble/styles';

import { STATIC_MAPS_DIV_ID } from './StaticGoogleMap.constants';

export type Props = {
  mapUrlStub: string;
};

function renderTemplate(mapUrlStub: string): string {
  // self invoking function pattern gives us a closure and avoids polluting global namespace
  return `((function(){
  const map = document.getElementById('${STATIC_MAPS_DIV_ID}');
  const width = map.offsetWidth;
  const height = map.offsetHeight;
  const url = ("${mapUrlStub}" + '&size=' + width + 'x' + height).replace(/\\s/g, '');
  const preload = document.createElement('link');
  preload.rel = 'preload';
  preload.as = 'image';
  preload.href = url;
  preload.media = "(min-width:${TABLET_MAX + 1}px)";
  document.querySelector('head').append(preload);
  map.style.backgroundImage = "url('" + url + "')";
})())`;
}

const SetStaticMapSrcOnLoadScript = ({ mapUrlStub }: Props): JSX.Element => (
  <script
    type="text/javascript"
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{
      __html: renderTemplate(mapUrlStub),
    }}
  />
);

export default SetStaticMapSrcOnLoadScript;
