import React, { FC, useEffect } from 'react';
import { Trans } from '@lingui/react';

import { Paragraph } from '@rover/kibble/core';
import Modal from '@rover/kibble/official-patterns/Modal';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import { isMobileBrowser } from '@rover/rsdk/src/modules/Network/userAgent';
import emitMetric from '@rover/utilities/emitMetric';

import {
  EXPLANATION_MODAL_BUTTON_TEXT,
  EXPLANATION_MODAL_HEADER,
  EXPLANATION_MODAL_INITIAL_PARAGRAPH,
} from './constants';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

/**
 * A similar modal is found in
 * reactNativeApp/src/pages/search/components/sheets/BestMatchExplanationSheet.tsx.
 *
 * Please ensure any changes to this file are reflected there.
 */

const BestMatchExplanationModal: FC<Props> = ({ isOpen, onClose }) => {
  const { i18n } = useI18n();
  const source = isMobileBrowser() ? 'mobile_web' : 'web';

  useEffect(() => {
    if (isOpen) {
      emitMetric('search_best_match_explanation_modal_opened', { source });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      headerText={i18n._(EXPLANATION_MODAL_HEADER)}
      submitButtonText={i18n._(EXPLANATION_MODAL_BUTTON_TEXT)}
      onClickSubmit={onClose}
      showCloseIcon
    >
      <Paragraph>{i18n._(EXPLANATION_MODAL_INITIAL_PARAGRAPH)}</Paragraph>
      <Paragraph mb="0x">
        <Trans>
          All pet care providers who sign up on Rover pass{' '}
          <a href="/background-checks" target="_blank">
            a background check
          </a>
          .
        </Trans>
      </Paragraph>
    </Modal>
  );
};

export default BestMatchExplanationModal;
