import React, { FC, useId } from 'react';
import { MessageDescriptor } from '@lingui/core';
import { Trans } from '@lingui/react';
import styled from 'styled-components';

import { Box, Text } from '@rover/kibble/core';
import { SpacingSystemType } from '@rover/kibble/core/styledSystemPropTypes';
import {
  A11yHidden,
  DSTokenMap,
  FontSize,
  FontWeight,
  ThemeFontWeight,
  ThemeTextSizes,
} from '@rover/kibble/styles';
import { Props as CheckboxProps } from '@rover/react-lib/src/components/formFields/FancyCheckbox/FancyCheckbox.types';
import { isTranslatable } from '@rover/utilities/i18n';

import checkIcon from './check.svg?url';
import disabledCheckIcon from './disabledCheck.svg?url';

const CHECKBOX_SIZE = '24px';

type StyledFancyCheckboxLabelProps = {
  disabled?: boolean;
  alignItems?: string;
};

export type Props = CheckboxProps &
  StyledFancyCheckboxLabelProps & {
    onChange?: (value: boolean) => void;
    labelIsProvidedViaHtmlFor?: boolean;
    label?: JSX.Element | string | MessageDescriptor | null;
    hideLabel?: boolean;
    actualId?: string;
    id?: string;
    'data-qa-id'?: string;
    autoComplete?: string;
    fontSize?: ThemeTextSizes;
    fontWeight?: ThemeFontWeight;
    labelMarginLeft?: SpacingSystemType;
    secondaryLabel?: string | MessageDescriptor | null;
  };

export const StyledFancyCheckboxFigure = styled.div`
  position: relative;
  user-select: none;
  width: ${CHECKBOX_SIZE};
  height: ${CHECKBOX_SIZE};
  background-color: ${DSTokenMap.BACKGROUND_COLOR_PRIMARY.toString()};
  border: 2px solid transparent;
  border-radius: ${DSTokenMap.BORDER_RADIUS_SECONDARY};
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  cursor: pointer;
  outline-offset: 4px;
`;

export const StyledHiddenCheckbox = styled.input`
  ${A11yHidden};
  margin: 0 !important;
  flex: 0;
  width: ${CHECKBOX_SIZE};
  height: ${CHECKBOX_SIZE};

  /* States: */
  &:not([disabled]) {
    & + ${StyledFancyCheckboxFigure} {
      /* State: Default */
      border-color: ${DSTokenMap.INTERACTIVE_BORDER_COLOR_PRIMARY.toString()};
    }

    /* state: default--hover */
    &:hover,
    &:focus {
      & + ${StyledFancyCheckboxFigure} {
        border-color: ${DSTokenMap.INTERACTIVE_BORDER_COLOR_PRIMARY_PRESSED.toString()};
      }
    }

    /* state: default--focus */
    &:focus {
      & + ${StyledFancyCheckboxFigure} {
        /*
            Simulates default browser focus outline
            taken from /src/frontend/app/css/vendor/bootstrap/scss/mixins/_tab-focus.scss
          */
        /* Default */
        outline-width: 2px;
        outline-style: solid;
        outline-color: Highlight;
        /* Webkit */
        outline: thin dotted;
        outline: 5px auto -webkit-focus-ring-color;
      }
    }

    &:checked {
      & + ${StyledFancyCheckboxFigure} {
        /* State: Active */
        border-color: ${DSTokenMap.INTERACTIVE_BORDER_COLOR_PRIMARY_ACTIVE.toString()};
        background-image: url(${checkIcon});
        background-position: -2px -2px;
        background-color: ${DSTokenMap.INTERACTIVE_BACKGROUND_COLOR_PRIMARY_ACTIVE.toString()};

        /* State: Active--hover */
        &:hover,
        &:focus {
          border-color: ${DSTokenMap.INTERACTIVE_BORDER_COLOR_PRIMARY_ACTIVE_PRESSED.toString()};
          background-color: ${DSTokenMap.INTERACTIVE_BACKGROUND_COLOR_PRIMARY_ACTIVE_PRESSED.toString()};
        }
      }
    }
  }

  /* State: Conflict */
  &[disabled] {
    & + ${StyledFancyCheckboxFigure} {
      border-color: ${DSTokenMap.INTERACTIVE_BORDER_COLOR_DISABLED.toString()};
      cursor: not-allowed;
    }

    &:checked {
      & + ${StyledFancyCheckboxFigure} {
        background-image: url(${disabledCheckIcon});
        background-position: -2px -2px;
        background-color: ${DSTokenMap.INTERACTIVE_BACKGROUND_COLOR_DISABLED.toString()};
      }
    }
  }
`;

export const StyledFancyCheckboxLabel = styled.label<StyledFancyCheckboxLabelProps>`
  display: flex;
  align-items: ${({ alignItems }) => alignItems || 'center'};
  margin-bottom: ${DSTokenMap.SPACE_3X};
  font-size: ${FontSize.L.toString()};
  font-weight: ${FontWeight.NORMAL.toString()};
  color: ${DSTokenMap.TEXT_COLOR_SECONDARY.toString()};
  user-select: none;
  line-height: normal;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const FancyCheckbox: FC<Props> = ({
  checked = false,
  disabled = false,
  labelIsProvidedViaHtmlFor = false,
  label = '',
  hideLabel = false,
  actualId = undefined,
  id = undefined,
  autoComplete = undefined,
  fontSize = DSTokenMap.TEXT_200_FONT_SIZE as ThemeTextSizes,
  fontWeight,
  labelMarginLeft = '4x',
  alignItems = 'center',
  onChange,
  secondaryLabel,
  ...other
}) => {
  const defaultId = useId();

  // Append the autogenerated ID to the passed-in ID in case the same component is included twice
  // (e.g., once for wide and once for narrow screens).
  const prefix = id || '';
  const elementId = actualId || `${prefix}-${defaultId}`;

  const checkbox = (
    <>
      <StyledHiddenCheckbox
        data-testid="styled-hidden-checkbox"
        tabIndex={0}
        type="checkbox"
        disabled={disabled}
        id={elementId}
        name={elementId}
        checked={checked}
        aria-checked={checked}
        // When autoComplete="off" it will disable the default behavior in some browsers (mostly chrome)
        // that maintains session state for form elements, which can cause
        // weird issues where react & the DOM get out of sync.
        // https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion
        // https://github.com/facebook/react/issues/1159
        autoComplete={autoComplete}
        {...(onChange
          ? {
              onChange: (event) => {
                event.stopPropagation();
                onChange(!checked);
              },
            }
          : {})}
      />
      <StyledFancyCheckboxFigure />
    </>
  );

  return !labelIsProvidedViaHtmlFor ? (
    <StyledFancyCheckboxLabel
      disabled={disabled}
      htmlFor={elementId}
      onClick={(event) => {
        event.stopPropagation();
      }}
      alignItems={alignItems}
      {...other}
    >
      {checkbox}
      <Box
        display={secondaryLabel ? 'flex' : 'inline-block'}
        flexDirection={secondaryLabel ? 'column' : ''}
        marginLeft={labelMarginLeft}
        sx={hideLabel ? A11yHidden : undefined}
      >
        <Text size={fontSize} fontWeight={fontWeight}>
          {isTranslatable(label) ? <Trans id={label} /> : label}
        </Text>
        {secondaryLabel && (
          <Text size={['50', '100']} color="secondry">
            <Trans id={secondaryLabel} />
          </Text>
        )}
      </Box>
    </StyledFancyCheckboxLabel>
  ) : (
    checkbox
  );
};

export default FancyCheckbox;
