import React from 'react';

import { Flex, Text } from '@rover/kibble/core';
import { DSTokenMap } from '@rover/kibble/styles';

export type Props = {
  holidayConfirmation?: {
    description: string;
    isGreen: boolean;
  };
};

const HolidayConfirmation: React.FC<Props> = ({ holidayConfirmation }) => {
  if (!holidayConfirmation) {
    return null;
  }

  const { description, isGreen } = holidayConfirmation;

  return (
    <Flex
      backgroundColor={
        isGreen
          ? DSTokenMap.BACKGROUND_COLOR_SUCCESS.toString()
          : DSTokenMap.BACKGROUND_COLOR_INFO.toString()
      }
      borderRadius="24px"
      px="2x"
      py="1x"
    >
      <Text size="100">{description}</Text>
    </Flex>
  );
};

export default HolidayConfirmation;
