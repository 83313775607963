import styled from 'styled-components';

import { DSTokenMap, MQ } from '@rover/kibble/styles';

const FilterWrapper = styled.div`
  margin-bottom: ${DSTokenMap.SPACE_6X};

  ${MQ.XS_DOWN.toString()} {
    margin-bottom: ${DSTokenMap.SPACE_4X};
  }
`;

export default FilterWrapper;
