import React, { Component } from 'react';
import { t } from '@lingui/macro';

import CollapsingCheckBoxCollection from '@rover/react-lib/src/components/formFields/CollapsingCheckBoxCollection';
import type { Checkbox } from '@rover/react-lib/src/components/formFields/CollapsingCheckBoxCollection/CheckBoxCategory';
import { I18nType, useI18n } from '@rover/rsdk/src/modules/I18n';
import type { SearchFilters, SearchFiltersOptions } from '@rover/types';

const allAdvancedFilterNames = [
  'acceptsOnlyOneClient',
  'apse',
  'bathingGrooming',
  'catCare',
  'dogsAllowedOnBed',
  'dogsAllowedOnFurniture',
  'hasFencedYard',
  'hasHouse',
  'hasNoChildren',
  'isPremier',
  'knowsFirstAid',
  'noCagedPets',
  'noCats',
  'noChildren05',
  'noChildren612',
  'nonSmoking',
  'personDoesNotHaveDogs',
  'petsitusa',
  'puppy',
];

const staffOnlyFilterNames = [
  'searchScoreDebug',
  'injectedMedication',
  'searchCurrentProvider',
  'specialNeeds',
  'oralMedication',
  'moreThanOneClient',
  'uncratedDogs',
  'unspayedFemales',
  'nonNeuteredMales',
  'femalesInHeat',
  'premierMatching',
  'premierOrRoverMatch',
  'isMemberOfSitterToSitter',
  'isMemberOfSitterToSitterPlus',
];

const staffOnlyMultiValueFilterNames = ['providerGroup', 'agency'];

const allFilters = allAdvancedFilterNames.concat(
  staffOnlyFilterNames,
  staffOnlyMultiValueFilterNames
);

const removeUnusedAndSortCheckboxes = (checkboxes: Array<Record<string, any>>): Checkbox[] => {
  if (!checkboxes) {
    return [];
  }

  return checkboxes
    .filter(Boolean)
    .sort((a, b) => a.rank - b.rank)
    .map(({ attribute, label, tooltip, value }) => ({ attribute, label, tooltip, value }));
};

type Props = {
  i18n: I18nType;
  advancedFiltersCount: number;
  onChange: (arg0: Record<string, any>) => void;
  searchFilters: Partial<SearchFilters>;
  searchFiltersOptions: SearchFiltersOptions;
  onCategoryExpandAndCollapse?: () => void;
  isPriceTransparency?: boolean;
};

export class Advanced extends Component<Props> {
  resetAll(): void {
    const { onChange, searchFilters } = this.props;
    const resetFilters = allFilters.reduce(
      (acc, filterName) => ({
        ...acc,
        [filterName]: Array.isArray(searchFilters[filterName]) ? [] : false,
      }),
      {}
    );

    onChange(resetFilters);
  }

  updateFilter(updatedCheckboxFilter: Record<string, any>, checkboxValue?: any | null): void {
    const { onChange, searchFilters } = this.props;
    const filterName = Object.keys(updatedCheckboxFilter)[0];
    const isChecked = updatedCheckboxFilter[filterName];
    const currentValue = searchFilters[filterName];

    if (Array.isArray(currentValue)) {
      const newList = currentValue.includes(checkboxValue)
        ? currentValue
            .slice(0, currentValue.indexOf(checkboxValue))
            .concat(currentValue.slice(currentValue.indexOf(checkboxValue) + 1))
        : currentValue;

      if (isChecked) {
        onChange({ [filterName]: newList.concat([checkboxValue]) });
      } else {
        onChange({ [filterName]: newList });
      }
    } else {
      onChange({ [filterName]: isChecked });
    }
  }

  render(): JSX.Element {
    const {
      i18n,
      searchFiltersOptions,
      advancedFiltersCount,
      searchFilters,
      onCategoryExpandAndCollapse,
      isPriceTransparency,
    } = this.props;

    // Do not show puppy and cat care if price transparency is enabled
    const advancedFilterNames = isPriceTransparency
      ? allAdvancedFilterNames.filter(
          (filterName) => filterName !== 'puppy' && filterName !== 'catCare'
        )
      : allAdvancedFilterNames;

    const categorizedCheckboxes = Object.keys(searchFiltersOptions).reduce(
      (accum: Record<string, any>, fieldName: string) => {
        if (advancedFilterNames.includes(fieldName) || staffOnlyFilterNames.includes(fieldName)) {
          const searchFilterOption = searchFiltersOptions[fieldName];
          const checkBox = {
            attribute: fieldName,
            ...searchFilterOption,
          };

          if (accum[searchFilterOption.category]) {
            accum[searchFilterOption.category].push(checkBox);
          } else {
            // eslint-disable-next-line no-param-reassign
            accum[searchFilterOption.category] = [checkBox];
          }
        }

        if (staffOnlyMultiValueFilterNames.includes(fieldName)) {
          const searchFilterOption = searchFiltersOptions[fieldName];
          // eslint-disable-next-line no-param-reassign
          accum[fieldName] = searchFilterOption.values.map(({ value, displayName }) => ({
            attribute: fieldName,
            tooltip: '',
            label: displayName,
            value,
          }));
        }

        return accum;
      },
      {}
    );

    const categories = [
      {
        category: t`Housing conditions`,
        rowsVisibleWhenCollapsed: 2,
        checkboxes: removeUnusedAndSortCheckboxes(categorizedCheckboxes.housing_conditions),
      },
      {
        category: t`Pets in the home`,
        rowsVisibleWhenCollapsed: 3,
        checkboxes: removeUnusedAndSortCheckboxes(categorizedCheckboxes.pets_in_the_home),
      },
      {
        category: t`Children in the home`,
        rowsVisibleWhenCollapsed: 1,
        checkboxes: removeUnusedAndSortCheckboxes(categorizedCheckboxes.children_in_the_home),
      },
      {
        category: t`Additional services`,
        rowsVisibleWhenCollapsed: 4,
        checkboxes: removeUnusedAndSortCheckboxes(categorizedCheckboxes.advanced_services),
      },
      {
        category: t`Sitter memberships`,
        rowsVisibleWhenCollapsed: 1,
        checkboxes: removeUnusedAndSortCheckboxes(categorizedCheckboxes.organizations),
      },
      {
        category: t`STAFF ONLY`,
        rowsVisibleWhenCollapsed: 4,
        checkboxes: removeUnusedAndSortCheckboxes(categorizedCheckboxes.staff_only),
      },
      {
        category: t`Agencies`,
        rowsVisibleWhenCollapsed: removeUnusedAndSortCheckboxes(categorizedCheckboxes.agency)
          .length,
        checkboxes: removeUnusedAndSortCheckboxes(categorizedCheckboxes.agency),
      },
      {
        category: t`Provider Groups`,
        rowsVisibleWhenCollapsed: 4,
        checkboxes: removeUnusedAndSortCheckboxes(categorizedCheckboxes.providerGroup),
      },
    ].filter((category) => category.checkboxes.length);

    return (
      <CollapsingCheckBoxCollection
        filtersCount={advancedFiltersCount}
        categories={categories}
        resetAllLinkText={i18n._('Reset all')}
        resetAll={() => this.resetAll()}
        onChange={(updatedCheckboxFilter, checkboxValue) =>
          this.updateFilter(updatedCheckboxFilter, checkboxValue)
        }
        isChecked={(filterName: string, value?: string | null) => {
          return Array.isArray(searchFilters[filterName])
            ? searchFilters[filterName].includes(value)
            : searchFilters[filterName];
        }}
        onCategoryExpandAndCollapse={onCategoryExpandAndCollapse}
      />
    );
  }
}

const AdvancedWithI18n = (props: Omit<Props, 'i18n'>): JSX.Element => {
  const { i18n } = useI18n();
  return <Advanced {...props} i18n={i18n} />;
};

export default AdvancedWithI18n;
