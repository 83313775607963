import { FC, useCallback } from 'react';
import { t } from '@lingui/macro';

import { CarouselRightSmall } from '@rover/icons';
import { Button, Flex } from '@rover/kibble/core';
import ServiceTypeFilter from '@rover/react-lib/src/components/formFields/ServiceTypeFilter';
import {
  DayTimeAvailability,
  DaytimeServiceScheduler,
  FilterWrapper,
  Location,
  OvernightDateRange,
} from '@rover/react-lib/src/pages/search/SearchPage/components/Filters';
import { useSearchWizard } from '@rover/react-lib/src/pages/search/SearchPage/components/Modals/SearchWizardModal/context';
import getMaxRequestDate from '@rover/react-lib/src/utils/getMaxRequestDate';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import { DROP_OFF_PICK_UP_SUPPORTED_SERVICES } from '@rover/shared/js/constants/searchPage.constants';
import { SearchFilters } from '@rover/types';
import type { DateRange } from '@rover/types/src/datetime/DateRange';

export const ServiceDetails: FC = () => {
  const {
    searchFilters,
    serviceTypeFilterOptions,
    serviceTypeOptions,
    showControls: { showDateRange, showDaytimeServiceScheduler, showDaytimeAvailability },
    serviceType,
    isLastStep,
    updateFilters,
    handleSubmit,
  } = useSearchWizard();
  const { locale, i18n } = useI18n();
  const maxDate = getMaxRequestDate();
  const { morningAvailability, middayAvailability, eveningAvailability } = serviceTypeFilterOptions;

  const startPlaceholder = DROP_OFF_PICK_UP_SUPPORTED_SERVICES.has(searchFilters.serviceType)
    ? t`Drop off`
    : t`Start`;
  const endPlaceholder = DROP_OFF_PICK_UP_SUPPORTED_SERVICES.has(searchFilters.serviceType)
    ? t`Pick up`
    : t`End`;

  const handleChangeDateRange = useCallback(
    (dateRange: Partial<DateRange>): void => {
      if (dateRange) {
        updateFilters({
          startDate: dateRange.startDate || undefined,
          endDate: dateRange.endDate || undefined,
        });
      }
    },
    [updateFilters]
  );
  const handlePropChange = useCallback(
    (key: keyof SearchFilters) => (value: SearchFilters[keyof SearchFilters]) => {
      updateFilters({ [key]: value });
    },
    [updateFilters]
  );

  return (
    <>
      <FilterWrapper>
        <ServiceTypeFilter
          displayMode="horizontal"
          serviceTypeOptions={serviceTypeOptions}
          label={i18n._(t`Which service do you need?`)}
          searchFilters={searchFilters}
          onChange={updateFilters}
        />
      </FilterWrapper>
      <FilterWrapper>
        <Location
          locationInputId="location-input-wizard"
          label={i18n._(t`What's your address or cross-streets?`)}
          location={searchFilters.location}
          onChange={updateFilters}
        />
      </FilterWrapper>
      {showDateRange && (
        <FilterWrapper>
          <OvernightDateRange
            endPlaceholder={endPlaceholder}
            maxDate={maxDate}
            label={i18n._(t`Which dates do you need?`)}
            language={locale}
            onChange={handleChangeDateRange}
            searchFilters={searchFilters}
            startPlaceholder={startPlaceholder}
          />
        </FilterWrapper>
      )}
      {showDaytimeServiceScheduler && (
        <FilterWrapper>
          <DaytimeServiceScheduler
            displayAs="compact"
            endPlaceholder={endPlaceholder}
            language={locale}
            maxDate={maxDate}
            onChange={handlePropChange('frequency')}
            onDateRangeChange={handleChangeDateRange}
            oneTimeLabel={i18n._(t`Which dates do you need?`)}
            onSelectedDaysChange={handlePropChange('selectedDays')}
            onStartDateChange={handleChangeDateRange}
            repeatStartDateLabel={startPlaceholder}
            searchFilters={searchFilters}
            serviceType={serviceType}
            showArrow={false}
            showLabel
            startPlaceholder={startPlaceholder}
          />
        </FilterWrapper>
      )}
      {showDaytimeAvailability && (
        <FilterWrapper>
          <DayTimeAvailability
            availabilityBuckets={{
              morning: morningAvailability?.label || null,
              midday: middayAvailability?.label || null,
              evening: eveningAvailability?.label || null,
            }}
            label={i18n._(t`Which time(s) do you need?`)}
            labelIsVisible
            onChange={updateFilters}
            searchFilters={searchFilters}
          />
        </FilterWrapper>
      )}
      <Flex justifyContent="flex-end">
        <Button
          variant="primary"
          onClick={handleSubmit}
          icon={CarouselRightSmall}
          iconAlignment="right"
        >
          {isLastStep ? i18n._(t`Search Now`) : i18n._(t`Next`)}
        </Button>
      </Flex>
    </>
  );
};
