import React, { FC } from 'react';
import { Trans } from '@lingui/react';

import { Flex, Heading, Text } from '@rover/kibble/core';
import { formatCurrency } from '@rover/utilities/currency';

export type Props = {
  currencyCode: string;
  isPriceTransparency?: boolean;
  language: string;
  price: string;
  unitAsSentence: string;
  isTrainingDesign?: boolean;
};

const PriceColumn: FC<Props> = ({
  currencyCode,
  isPriceTransparency,
  language,
  price,
  unitAsSentence,
  isTrainingDesign = false,
}) => {
  const getCurrencyLabel = (value: string): string =>
    formatCurrency(value, currencyCode, language, {
      includeDecimal: false,
    });

  return (
    <Flex
      flexDirection="column"
      alignItems="flex-end"
      alignSelf="stretch"
      justifyContent="flex-start"
      width="auto"
      maxWidth="100%"
    >
      <Flex flexDirection="column" alignItems="flex-end">
        {!isPriceTransparency && !isTrainingDesign && (
          <Text size="100" textColor="tertiary">
            <Trans>from</Trans>
          </Text>
        )}
        <Flex flexDirection="row" alignItems="flex-end">
          <Heading size="400" responsive={false} mb="0x" textColor="success" as="div">
            {getCurrencyLabel(price)}
          </Heading>
        </Flex>
        <Text size="100" textColor="tertiary" sx={{ whiteSpace: 'nowrap' }}>
          {isPriceTransparency ? <Trans>total {unitAsSentence}</Trans> : unitAsSentence}
        </Text>
      </Flex>
    </Flex>
  );
};

export default PriceColumn;
