import React, { useId } from 'react';
import unescapeHtmlEntities from 'lodash-es/unescape';
import styled from 'styled-components';

import { DSTokenMap, FontWeight, Spacing } from '@rover/kibble/styles';
import Popover from '@rover/react-lib/src/components/callouts/Popover';
import { PopoverPlacement } from '@rover/react-lib/src/components/callouts/Popover/popover.constants';
import {
  EducationalOccupationalCredential,
  MicrodataHTMLAttributes,
} from '@rover/react-lib/src/components/microdata';
import fireDataLayerEvent from '@rover/utilities/fireDataLayerEvent';

type Props = {
  className?: string;
  content: string;
  imageUrl: string;
  onChange?: (arg0: boolean) => void;
  popoverPlacement?: PopoverPlacement;
  title: string;
  slug: string;
  width?: number;
} & MicrodataHTMLAttributes;

const StyledPopover = styled(Popover)`
  padding: 0 !important;
  width: 276px !important;
`;

const PopoverContentWrapper = styled.div``;

const PopoverContentTitle = styled.div`
  background-color: ${DSTokenMap.BACKGROUND_COLOR_SECONDARY.toString()};
  border-bottom: 1px solid #ececec;
  font-weight: ${FontWeight.BOLD.toString()};
  padding: ${Spacing.S.toString()} 14px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`;

const PopoverContentBody = styled.div`
  padding: ${Spacing.S.toString()} 14px;
`;

const StyledImage = styled.img`
  margin-right: 5px;
`;

type PopoverContentProps = {
  title: string;
  content: string;
};

const PopoverContent: React.FC<PopoverContentProps> = ({ title, content }) => (
  <PopoverContentWrapper>
    {/* Badge content has HTML which is defined by us and cannot be modified by user */}
    <PopoverContentTitle
      dangerouslySetInnerHTML={{
        __html: unescapeHtmlEntities(title),
      }}
    />
    <PopoverContentBody
      dangerouslySetInnerHTML={{
        __html: unescapeHtmlEntities(content),
      }}
    />
  </PopoverContentWrapper>
);

// HTML isn't valid as a property of an element,
// so we strip the html and keep just the text content
function extractTextContent(html: string): string {
  if (process.env.JS_ENV_CLIENT) {
    const div = document.createElement('div');
    div.innerHTML = html;
    return (div.textContent ?? div.innerText).trim();
  }
  return html.replace(/<[^<]+>/g, '').trim();
}

// `PopoverContent` is only in the DOM when the trigger element is being hovered,
// so we need to render the content separately so it can be scraped
const PopoverContentAsMicrodata: React.FC<PopoverContentProps & MicrodataHTMLAttributes> = ({
  title,
  content,
  ...props
}) => (
  <EducationalOccupationalCredential
    /* Badge content has HTML which is defined by us and cannot be modified by user */
    name={extractTextContent(unescapeHtmlEntities(title))}
    description={extractTextContent(unescapeHtmlEntities(content))}
    {...props}
  />
);

const badgeHover = (slug): void => {
  fireDataLayerEvent({
    event: 'search-badge-hover',
    badgeSlug: slug,
  });
};

const SitterBadge: React.FC<Props> = ({
  className,
  content,
  imageUrl,
  onChange,
  slug,
  popoverPlacement = Popover.Placement.TOP,
  title,
  width = 16,
  ...props
}) => {
  const tooltipId = useId();
  return (
    /* We prefer not to disable jsx-a11y callouts, but this is just stopping click events propagating */
    /* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
    <span onClick={(e) => e.stopPropagation()} className={className}>
      <PopoverContentAsMicrodata title={title} content={content} {...props} />
      {/* @ts-expect-error fixing the odd TS errors from styled-components on Popover is tricky and not worth fixing because we need to update the Popover lib anyway */}
      <StyledPopover
        id={tooltipId}
        role="tooltip"
        content={<PopoverContent title={title} content={content} />}
        placement={popoverPlacement}
        onChange={onChange}
        toggleOnFocusOrBlur
        data-testid="styled-popover"
      >
        <StyledImage
          src={imageUrl}
          alt={title}
          width={width}
          tabIndex={0}
          onMouseEnter={() => badgeHover(slug)}
          aria-describedby={tooltipId}
          data-testid="styled-image"
        />
      </StyledPopover>
    </span>
  );
};

export default SitterBadge;
