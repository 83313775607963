import React, { Component } from 'react';

import { WizardPage } from '@rover/react-lib/src/pages/search/SearchPage/components/Modals';
import { WizardPageType } from '@rover/react-lib/src/pages/search/SearchPage/WizardPageType';
import type { SearchFilters, SearchFiltersOptions, ServiceTypeOption } from '@rover/types';

import { PageOneWizardCollection, PageTwoWizardCollection } from './Wizard';

type Props = {
  isCatOnly: boolean;
  searchFilters: SearchFilters;
  serviceTypeFilterOptions: SearchFiltersOptions;
  serviceTypeOptions: ServiceTypeOption[];
  updateFilters: (arg0: Partial<SearchFilters>) => void;
  visiblePage: WizardPage;
  locationInputId?: string;
};

export default class PaginatedWizardCollection extends Component<Props> {
  handleChange = (filters: Partial<SearchFilters>): void => {
    this.props.updateFilters(filters);
  };

  render(): JSX.Element {
    const {
      isCatOnly,
      searchFilters,
      serviceTypeFilterOptions,
      serviceTypeOptions,
      visiblePage,
      locationInputId,
    } = this.props;

    switch (visiblePage) {
      case WizardPage.ONE:
        return (
          <PageOneWizardCollection
            isCatOnly={isCatOnly}
            locationInputId={locationInputId}
            onChange={this.handleChange}
            pageType={WizardPageType.MODAL}
            searchFilters={searchFilters}
            serviceTypeFilterOptions={serviceTypeFilterOptions}
            serviceTypeOptions={serviceTypeOptions}
          />
        );
      case WizardPage.TWO:
        return (
          <PageTwoWizardCollection
            isCatOnly={isCatOnly}
            onChange={this.handleChange}
            pageType={WizardPageType.MODAL}
            searchFilters={searchFilters}
            serviceTypeFilterOptions={serviceTypeFilterOptions}
          />
        );
      default:
        return (
          <PageOneWizardCollection
            isCatOnly={isCatOnly}
            locationInputId={locationInputId}
            onChange={this.handleChange}
            pageType={WizardPageType.MODAL}
            searchFilters={searchFilters}
            serviceTypeFilterOptions={serviceTypeFilterOptions}
            serviceTypeOptions={serviceTypeOptions}
          />
        );
    }
  }
}
