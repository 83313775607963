import { ROVER_HOSTS } from '../constants/hosts';

function safeParseUrl(maybeUrl: string): URL | null {
  try {
    return new URL(maybeUrl);
  } catch (_) {
    return null;
  }
}

export function getReferrer(): URL | null {
  return safeParseUrl(document.referrer) ?? null;
}

export function referrerIsRover(): boolean {
  const url = getReferrer();

  return ROVER_HOSTS.test(url?.hostname ?? '');
}
