import React, { Component } from 'react';
import { Trans } from '@lingui/macro';

import { Box, Button, Heading } from '@rover/kibble/core';
import events from '@rover/react-lib/src/pages/search/SearchPage/analytics';
import { getWizardTitle } from '@rover/react-lib/src/pages/search/SearchPage/utilities';
import { WizardPageType } from '@rover/react-lib/src/pages/search/SearchPage/WizardPageType';
import { I18nType, useI18n } from '@rover/rsdk/src/modules/I18n';
import type {
  DataLayerEvent,
  SearchFilters,
  SearchFiltersOptions,
  ServiceTypeOption,
} from '@rover/types';

import { PageOneWizardCollection, PageTwoWizardCollection } from './Wizard';

export type Props = {
  i18n: I18nType;
  fireAnalyticsEvent: (
    arg0: DataLayerEvent | ((filters: Partial<SearchFilters>) => DataLayerEvent)
  ) => void;
  fireSearch: (arg0?: { searchInitiatedByMap: boolean }) => void;
  isCatOnly: boolean;
  onSubmit: () => void;
  searchFilters: SearchFilters;
  serviceTypeFilterOptions: SearchFiltersOptions;
  serviceTypeOptions: ServiceTypeOption[];
  updateFilters: (searchFilters: Partial<SearchFilters>) => void;
  fireSearchPageSearchCTAEvent: () => void;
};

export class WizardFiltersCollection extends Component<Props> {
  shouldComponentUpdate({
    searchFilters: nextSearchFilters,
    serviceTypeFilterOptions: nextServiceTypeFilterOptions,
    serviceTypeOptions: nextServiceTypeOptions,
  }: Props): boolean {
    const { searchFilters, serviceTypeFilterOptions, serviceTypeOptions } = this.props;

    return (
      JSON.stringify(searchFilters) !== JSON.stringify(nextSearchFilters) ||
      JSON.stringify(serviceTypeFilterOptions) !== JSON.stringify(nextServiceTypeFilterOptions) ||
      JSON.stringify(serviceTypeOptions) !== JSON.stringify(nextServiceTypeOptions)
    );
  }

  render(): JSX.Element {
    const {
      i18n,
      fireAnalyticsEvent,
      fireSearch,
      isCatOnly,
      onSubmit,
      searchFilters,
      serviceTypeFilterOptions,
      serviceTypeOptions,
      updateFilters,
      fireSearchPageSearchCTAEvent,
    } = this.props;

    const handleChange = (filters, shouldFireSearch = true): void => {
      updateFilters(filters);

      if (shouldFireSearch) {
        fireSearch();
      }
    };

    const handleCloseWizard = (): void => {
      fireAnalyticsEvent(events.SearchOwnerPreferencesCloseEvent);
      fireSearchPageSearchCTAEvent();
      onSubmit();
    };

    return (
      <Box p="4x" pt="0x" borderBottom="1px solid" borderColor="border.primary">
        <Heading size="300" as="h4" pt="4x">
          {i18n._(getWizardTitle(isCatOnly))}
        </Heading>

        <PageOneWizardCollection
          isCatOnly={isCatOnly}
          onChange={handleChange}
          pageType={WizardPageType.INLINE}
          searchFilters={searchFilters}
          serviceTypeFilterOptions={serviceTypeFilterOptions}
          serviceTypeOptions={serviceTypeOptions}
        />

        <PageTwoWizardCollection
          isCatOnly={isCatOnly}
          onChange={handleChange}
          pageType={WizardPageType.INLINE}
          searchFilters={searchFilters}
          serviceTypeFilterOptions={serviceTypeFilterOptions}
        />

        <Button
          data-qa-id="wizard-search-collection-save-button"
          variant="primary"
          fullWidth
          onClick={handleCloseWizard}
        >
          <Trans>Save search information</Trans>
        </Button>
      </Box>
    );
  }
}

const WizardFiltersCollectionWithI18n = (props: Omit<Props, 'i18n'>): JSX.Element => {
  const { i18n } = useI18n();
  return <WizardFiltersCollection {...props} i18n={i18n} />;
};

export default WizardFiltersCollectionWithI18n;
