import { Children, isValidElement, ReactNode } from 'react';

import { SpacingSystemType } from '@rover/kibble/core/styledSystemPropTypes';
import type { ThemeTextColor, ThemeTextSizes } from '@rover/kibble/styles';

export type Props = {
  children: ReactNode;
  helperText?: string;
  inputLabel: string;
  inputLabelSize?: ThemeTextSizes;
  inputLabelColor?: ThemeTextColor;
  inputLabelMarginBottom?: SpacingSystemType;
  isError?: boolean;
  onClick: () => void;
  icon?: React.ReactNode;
  hideIcon?: boolean;
  pointerCursor?: boolean;
};

// Function to get text from given element
const getNodeText = (elem: ReactNode): string => {
  if (!elem) return '';
  if (typeof elem === 'string') return elem;
  if (Children.count(elem) > 1) return Children.toArray(elem).map(getNodeText).join(''); // If many children concatenate element texts
  if (isValidElement(elem)) return getNodeText(elem.props.children); // If the children has children get the text from inner elements recursively
  return getNodeText(elem);
};

export const getAriaLabel = (inputLabel: string, children: ReactNode): string => {
  const nodeText = getNodeText(children);
  return `${inputLabel} ${nodeText}`;
};
