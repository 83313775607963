import React, { memo } from 'react';
import styled from 'styled-components';

import { DSTokenMap, MQ } from '@rover/kibble/styles';
import { Pagination } from '@rover/react-lib/src/components';
import { Alignment, HorizontalLayout } from '@rover/react-lib/src/components/layouts';
import { PaginationWrapper } from '@rover/react-lib/src/components/navigation/Pagination/Pagination';
import type { Pagination as PaginationType } from '@rover/types';

export type Props = {
  pagination: PaginationType;
  onPageChange: (arg0: number) => void;
};
const SearchPaginationWrapper = styled(HorizontalLayout)`
  ${MQ.SM_UP.toString()} {
    background-color: ${DSTokenMap.BACKGROUND_COLOR_SECONDARY.toString()};
  }
  ${PaginationWrapper} > ul {
    margin-bottom: ${DSTokenMap.SPACE_6X};
    margin-top: ${DSTokenMap.SPACE_6X};
  }
  ${PaginationWrapper} > ul > li {
    background-color: ${DSTokenMap.BACKGROUND_COLOR_PRIMARY.toString()};
  }
  ${PaginationWrapper} > ul > li.break {
    cursor: auto;
  }
`;

const SearchPagination = ({
  pagination: { page, pageCount },
  onPageChange,
}: Props): JSX.Element => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>
    {!!(pageCount && pageCount > 1) && (
      <SearchPaginationWrapper hAlign={Alignment.CENTER}>
        {/* @ts-expect-error react-pagination "requires" the pageRangeDisplayed prop but this has been working fine without it so :shrug: */}
        <Pagination
          disableInitialCallback
          pageCount={pageCount}
          forcePage={page && page - 1}
          onPageChange={(p) => onPageChange(p.selected + 1)}
        />
      </SearchPaginationWrapper>
    )}
  </>
);

export default memo(SearchPagination);
