import React from 'react';
import { t } from '@lingui/macro';

import {
  FilterWrapper,
  SpacesRequired,
} from '@rover/react-lib/src/pages/search/SearchPage/components/Filters';
import { DayTimeAvailability } from '@rover/react-lib/src/pages/search/SearchPage/components/Filters/Dates';
import {
  CatPreferences,
  DogPreferences,
  PuppyPreferences,
} from '@rover/react-lib/src/pages/search/SearchPage/components/Filters/Preferences';
import { shouldShowControls } from '@rover/react-lib/src/pages/search/SearchPage/utilities';
import { WizardPageType } from '@rover/react-lib/src/pages/search/SearchPage/WizardPageType';
import { ApiFrontendConfigurationRetrieve200SettingsDogSizeBuckets } from '@rover/rsdk/src/apiClient/latest';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import PetSize from '@rover/shared/js/search/components/PetSize';
import type { SearchFilters, SearchFiltersOptions, ServiceType } from '@rover/types';

export type Props = {
  getServiceTypeBySlug: (arg0: string) => ServiceType | null | undefined;
  hasPets: boolean;
  isCatOnly: boolean;
  onChange: (arg0: Record<string, unknown>, arg1?: boolean) => void;
  pageType: WizardPageType;
  petSizeBuckets: ApiFrontendConfigurationRetrieve200SettingsDogSizeBuckets;
  searchFilters: SearchFilters;
  serviceTypeFilterOptions: SearchFiltersOptions;
  weightUnit: string;
  rolloutPriceTransparency?: boolean;
  isInPetCountersExperiment?: boolean;
};

const PageTwoWizardCollection = ({
  getServiceTypeBySlug,
  hasPets,
  isCatOnly,
  onChange,
  pageType,
  petSizeBuckets,
  searchFilters,
  serviceTypeFilterOptions,
  weightUnit,
  rolloutPriceTransparency,
  isInPetCountersExperiment,
}: Props): JSX.Element => {
  const { i18n } = useI18n();
  const serviceType = getServiceTypeBySlug(searchFilters.serviceType);
  const { morningAvailability, middayAvailability, eveningAvailability } = serviceTypeFilterOptions;

  const {
    showPetSize,
    showSpacesRequired,
    showPuppyPreferences,
    showCatPreferences,
    showDogPreferences,
    showDaytimeAvailability,
  } = shouldShowControls({
    hasPets,
    isCatOnly,
    pageType,
    serviceOptions: serviceTypeFilterOptions,
    serviceType: serviceType || undefined,
    serviceTypeSlug: searchFilters.serviceType,
  });
  const shouldShowPetCounter = rolloutPriceTransparency || isInPetCountersExperiment;

  return (
    <>
      {showSpacesRequired && !shouldShowPetCounter && (
        <FilterWrapper>
          <SpacesRequired
            searchFilters={searchFilters}
            onChange={(spacesRequired) => onChange({ spacesRequired })}
          />
        </FilterWrapper>
      )}

      {pageType === WizardPageType.INLINE && showDaytimeAvailability && (
        <FilterWrapper>
          <DayTimeAvailability
            availabilityBuckets={{
              morning: morningAvailability ? morningAvailability.label : null,
              midday: middayAvailability ? middayAvailability.label : null,
              evening: eveningAvailability ? eveningAvailability.label : null,
            }}
            label={i18n._('Which time(s) do you need?')}
            labelIsVisible
            onChange={(availability) => onChange(availability)}
            searchFilters={searchFilters}
          />
        </FilterWrapper>
      )}

      {showPetSize && (
        <FilterWrapper>
          <PetSize
            displayAsIconButtons={pageType === WizardPageType.MODAL}
            label={i18n._(t`What size are your dogs (${weightUnit})?`)}
            searchFilters={searchFilters}
            weightUnit={weightUnit}
            onChange={(sizeKey) => onChange({ [sizeKey]: !searchFilters[sizeKey] })}
            petSizeBuckets={petSizeBuckets}
          />
        </FilterWrapper>
      )}

      {showPuppyPreferences && !shouldShowPetCounter && (
        <FilterWrapper>
          <PuppyPreferences
            displayAsToggle={pageType !== WizardPageType.MODAL}
            onChange={(puppy) => onChange({ puppy })}
            value={searchFilters.puppy}
          />
        </FilterWrapper>
      )}

      {showCatPreferences && (
        <FilterWrapper>
          <CatPreferences
            displayAsToggle={pageType !== WizardPageType.MODAL}
            onChange={(noCats) => onChange({ noCats })}
            value={searchFilters.noCats}
          />
        </FilterWrapper>
      )}

      {showDogPreferences && (
        <FilterWrapper>
          <DogPreferences
            displayAsToggle={pageType !== WizardPageType.MODAL}
            onChange={(personDoesNotHaveDogs) => onChange({ personDoesNotHaveDogs })}
            value={searchFilters.personDoesNotHaveDogs}
          />
        </FilterWrapper>
      )}
    </>
  );
};

export default PageTwoWizardCollection;
