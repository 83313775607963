import React from 'react';
import { Trans } from '@lingui/macro';

import { Filters } from '@rover/icons';
import { Box, Button } from '@rover/kibble/core';

export type Props = {
  selectedFilterCount: number;
  variant?: React.ComponentProps<typeof Button>['variant'];
  onClick: () => void;
};

const AdvancedFiltersButton = ({
  selectedFilterCount,
  variant = 'default',
  onClick,
}: Props): JSX.Element => (
  <Box
    sx={{
      transform: 'translate3d(0, 0, 0)',
    }}
  >
    <Button variant={variant} icon={Filters} onClick={onClick}>
      <Trans>Filters</Trans>
      {selectedFilterCount > 0 && <span>&nbsp;({selectedFilterCount})</span>}
    </Button>
  </Box>
);

export default AdvancedFiltersButton;
