import React from 'react';

import { Connect } from '@rover/react-lib/src/components/utils/Redux';
import { selectors as searchBoxSelectors } from '@rover/react-lib/src/pages/index/HomePage/components/SearchBox/SearchBox.duck';
import { WizardPage } from '@rover/react-lib/src/pages/search/SearchPage/components/Modals';
import { effects as searchFiltersEffects } from '@rover/react-lib/src/pages/search/SearchPage/SearchFilters.duck';
import {
  effects as searchModalEffects,
  selectors as searchModalSelectors,
} from '@rover/react-lib/src/pages/search/SearchPage/SearchModal.duck';

import PaginatedWizardFiltersCollection from './PaginatedWizardFiltersCollection';

type Props = {
  visiblePage: WizardPage;
  locationInputId?: string;
};

const effects = {
  updateFilters: searchModalEffects.updateFilters,
  updateSearchFilters: searchFiltersEffects.updateFilters,
};

const selector = (state) => ({
  isCatOnly: searchModalSelectors.getIsCatOnly(state),
  searchFilters: searchModalSelectors.getFilters(state),
  serviceTypeFilterOptions: searchModalSelectors.getServiceTypeFilterOptions(state),
  serviceTypeOptions: searchModalSelectors.getPetTypeBrowsableServiceTypeOptions(state),
  isDaycareFilterEarlierExperiment: searchBoxSelectors.getIsDaycareFilterEarlierInExperiment(state),
});

const PaginatedWizardFiltersCollectionContainer = (props: Props): JSX.Element => (
  <Connect effects={effects} selector={selector}>
    {(connectedProps) => <PaginatedWizardFiltersCollection {...connectedProps} {...props} />}
  </Connect>
);

export default PaginatedWizardFiltersCollectionContainer;
