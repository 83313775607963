import React, { useId } from 'react';
import { t, Trans } from '@lingui/macro';
import styled from 'styled-components';

import { Calendar, Recurring } from '@rover/icons';
import { DSTokenMap } from '@rover/kibble/styles';
import Fieldset from '@rover/react-lib/src/components/formFields/Fieldset';
import SingleSelectButtonGroup, {
  Props as SingleSelectButtonGroupProps,
  StyledSelectButton,
} from '@rover/react-lib/src/components/formFields/SingleSelectButtonGroup';
import { ServiceFrequencyEnum } from '@rover/types/src/ServiceFrequency';
import FilterLabel from '@rover/shared/js/search/components/FilterLabel';

export const StyledButtonGroup = styled(
  SingleSelectButtonGroup as React.ComponentType<SingleSelectButtonGroupProps<ServiceFrequencyEnum>>
)`
  hyphens: auto;
  margin-bottom: ${DSTokenMap.SPACE_3X};
  overflow-wrap: break-word;
  word-wrap: break-word;

  ${StyledSelectButton} {
    background-color: ${DSTokenMap.BACKGROUND_COLOR_PRIMARY.toString()};
    padding: ${DSTokenMap.SPACE_3X};
    word-break: break-word;
  }
`;

type Props = {
  displayAs?: 'compact' | 'expanded' | null;
  frequency?: ServiceFrequencyEnum;
  label: string;
  onChange: (serviceFrequency?: ServiceFrequencyEnum) => void;
};

const OneTimeRepeatToggleButtons = ({
  displayAs,
  frequency,
  label,
  onChange,
  ...other
}: Props): JSX.Element => {
  const frequencyFilterId = useId();

  return (
    <Fieldset aria-labelledby={frequencyFilterId} {...other}>
      <FilterLabel id={frequencyFilterId}>
        <Trans id={label} />
      </FilterLabel>
      <StyledButtonGroup
        buttons={[
          {
            title: t`One Time`,
            value: ServiceFrequencyEnum.ONE_TIME,
            icon: Calendar,
            displayAs,
          },
          {
            title: t`Repeat Weekly`,
            value: ServiceFrequencyEnum.REPEAT,
            icon: Recurring,
            displayAs,
          },
        ]}
        value={frequency}
        onChange={(v?: ServiceFrequencyEnum) => onChange(v)}
      />
    </Fieldset>
  );
};

export default OneTimeRepeatToggleButtons;
