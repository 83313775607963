import React, { useCallback, useId, useState } from 'react';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';

import Fieldset from '@rover/react-lib/src/components/formFields/Fieldset';
import SingleSelectButtonGroup from '@rover/react-lib/src/components/formFields/SingleSelectButtonGroup';
import { SelectedValue } from '@rover/react-lib/src/pages/search/SearchPage/components/Filters/Preferences/CatPreferences';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import FilterLabel from '@rover/shared/js/search/components/FilterLabel';

import { TogglePreference } from '..';

type Props = {
  displayAsToggle?: boolean;
  onChange: (arg0: boolean) => void;
  value?: boolean;
};

const DOG_PREFERENCES_OPTIONS = [
  {
    value: 'yes',
    submitValue: false,
    title: t`Yes`,
  },
  {
    value: 'no',
    submitValue: true,
    title: t`No`,
  },
  {
    value: 'unsure',
    submitValue: false,
    title: t`Unsure`,
  },
];

const DogPreferences = ({ displayAsToggle, onChange, value = true }: Props): JSX.Element => {
  const { i18n } = useI18n();
  const id = useId();
  const [singleSelectValue, setSingleSelectValue] = useState<SelectedValue>(value ? 'no' : 'yes');

  const handleSingleSelectChange = useCallback(
    (selectedValue: SelectedValue): void => {
      const clickedOption = DOG_PREFERENCES_OPTIONS.find(
        (option) => option.value === selectedValue
      );
      onChange(clickedOption ? clickedOption.submitValue : false);
      setSingleSelectValue(selectedValue);
    },
    [onChange]
  );

  return displayAsToggle ? (
    <TogglePreference
      label={i18n._('My dogs don’t like other dogs')}
      onChange={onChange}
      value={value}
    />
  ) : (
    <Fieldset aria-labelledby={id}>
      <FilterLabel id={id}>
        <Trans>Do your dogs get along with dogs?</Trans>
      </FilterLabel>
      <SingleSelectButtonGroup
        buttons={DOG_PREFERENCES_OPTIONS}
        id={id}
        onChange={handleSingleSelectChange}
        value={singleSelectValue as SelectedValue}
      />
    </Fieldset>
  );
};

export default DogPreferences;
