import React from 'react';
import styled from 'styled-components';

import { AlertInfo } from '@rover/icons';
import { Icon } from '@rover/icons/utils';
import { Box, Button, Flex, Paragraph } from '@rover/kibble/core';
import Alert from '@rover/kibble/patterns/Alert';
import IconBlock from '@rover/kibble/patterns/IconBlock';
import { Color, DSTokenMap } from '@rover/kibble/styles';
import { AlertSeverity } from '@rover/react-lib/src/components/notifications/Alert/Alert';

type SearchSectionHeaderProps = {
  headerIcon: Icon;
  headerText: string;
  infoIconOnClick?: () => void;
  primarySubheaderText?: string | JSX.Element | React.ReactNode;
  secondarySubheaderText?: string | JSX.Element | React.ReactNode;
  alertText?: string | JSX.Element | React.ReactNode;
  alertSeverity?: AlertSeverity;
};

const StyledInfoIcon = styled(AlertInfo)`
  fill: ${DSTokenMap.INTERACTIVE_LINK_COLOR_PRIMARY.toString()};
`;

const SearchSectionHeader = ({
  headerIcon,
  headerText,
  infoIconOnClick,
  primarySubheaderText,
  secondarySubheaderText,
  alertText,
  alertSeverity,
}: SearchSectionHeaderProps): JSX.Element => {
  return (
    <Box
      pt="6x"
      px="4x"
      pb="6x"
      sx={{
        backgroundColor: DSTokenMap.BACKGROUND_COLOR_PRIMARY.toString(),
        borderBottom: `1px solid ${Color.NEUTRAL_400.toString()}`,
      }}
    >
      <Flex flexDirection="row" alignItems="center" justifyContent="space-between">
        <IconBlock title={headerText} icon={headerIcon} iconSize="23px" iconMargin="2x" />
        {infoIconOnClick && (
          <Button
            onClick={infoIconOnClick}
            variant="flat"
            size="small"
            padding="2x"
            sx={{ '&:hover': { backgroundColor: 'transparent' } }}
            aria-label="Info button"
            icon={StyledInfoIcon}
          />
        )}
      </Flex>
      {primarySubheaderText && (
        <Paragraph size="100" textColor="tertiary" pt="2x" mb="0x">
          {primarySubheaderText}
        </Paragraph>
      )}
      {secondarySubheaderText && (
        <Paragraph size="100" textColor="tertiary" pt="2x" mb="0x">
          {secondarySubheaderText}
        </Paragraph>
      )}
      {alertText && alertSeverity && (
        <Alert
          severity={alertSeverity}
          mt="2x"
          mb="0x"
          borderStyle="none"
          borderRadius="secondary"
          data-testid="banner-alert"
        >
          {alertText}
        </Alert>
      )}
    </Box>
  );
};

export default SearchSectionHeader;
