import React, { useContext, useId } from 'react';
import { MessageDescriptor } from '@lingui/core/i18n';

import { Box, Flex } from '@rover/kibble/core';
import { SPACE_2X, SPACE_6X, SPACE_12X } from '@rover/kibble/tokens/build/es6/DSToken';
import DatePicker from '@rover/react-lib/src/components/datetime/DatePicker';
import DayOfWeekPicker from '@rover/react-lib/src/components/datetime/DayOfWeekPicker';
import GenericCalendar, {
  CalendarType,
} from '@rover/react-lib/src/components/datetime/GenericCalendar';
import { SearchPageContext } from '@rover/react-lib/src/pages/search/SearchPage/utilities/searchPageContext';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import { REPEAT_SERVICE_TYPE_LABELS } from '@rover/shared/js/constants/service';
import FilterLabel from '@rover/shared/js/search/components/FilterLabel';
import type { Day } from '@rover/types';

type Props = {
  dayOfWeekLabel?: string | MessageDescriptor;
  direction?: 'column' | 'row';
  language: string;
  maxDate?: Date;
  onSelectedDaysChange: (selectedDays: Day[]) => void;
  onStartDateChange: (startDate?: Date | null) => void;
  repeatStartDateLabel?: string | MessageDescriptor;
  selectedDays: Day[];
  serviceTypeSlug?: string | null;
  showLabel: boolean;
  startDate?: Date | null;
  startPlaceholder?: string | MessageDescriptor;
  newCalendarInteraction?: boolean;
  serviceName?: string;
  serviceSubtitle?: string;
  disableValidation?: boolean;
  isMobileBrowser?: boolean;
  onClickFromSearch?: () => void;
  onInputBlur?: (e?: React.FocusEvent<HTMLDivElement, Element>) => void;
  validationError?: string;
};

const RepeatServiceScheduler = ({
  dayOfWeekLabel,
  direction = 'column',
  language,
  maxDate,
  onSelectedDaysChange,
  onStartDateChange,
  repeatStartDateLabel,
  selectedDays,
  serviceTypeSlug,
  showLabel,
  startDate,
  startPlaceholder,
  newCalendarInteraction = false,
  serviceName,
  serviceSubtitle,
  disableValidation = false,
  isMobileBrowser = false,
  onClickFromSearch,
  validationError,
  onInputBlur,
}: Props): JSX.Element => {
  const { i18n } = useI18n();
  const dowPickerId = useId();
  const startDatePickerId = useId();
  const {
    isRollout1SimplifiedMobileOwnerSearchFormExperiment,
    inVariantSimplifiedMobileOwnerSearchFormExperiment,
  } = useContext(SearchPageContext);

  return (
    <Flex
      flexDirection={direction}
      flexGrow="1"
      alignItems={direction === 'column' ? 'flex-end' : 'flex-start'}
    >
      <Flex
        flexDirection="column"
        flexGrow="3"
        pr={direction === 'column' ? '0x' : '3x'}
        width={direction === 'column' ? '100%' : 'auto'}
      >
        <Box mb="3x">
          {serviceTypeSlug && showLabel && (
            <FilterLabel id={dowPickerId}>
              {
                // @ts-expect-error - i18n._() can be called with string or MessageDescriptor
                i18n._(dayOfWeekLabel || REPEAT_SERVICE_TYPE_LABELS[serviceTypeSlug])
              }
            </FilterLabel>
          )}
          <DayOfWeekPicker
            aria-labelledby={dowPickerId}
            language={language}
            selectedDays={selectedDays}
            onChange={onSelectedDaysChange}
          />
        </Box>
      </Flex>
      <Flex
        flexDirection="column"
        flexGrow="2"
        width={direction === 'column' ? '100%' : 'auto'}
        // @ts-expect-error this usage of pixels is needed to align these two fields
        pb="3px"
      >
        <FilterLabel htmlFor={startDatePickerId}>
          {repeatStartDateLabel // @ts-expect-error - i18n._() can be called with string or MessageDescriptor
            ? i18n._(repeatStartDateLabel)
            : ''}
        </FilterLabel>
        {isMobileBrowser ? (
          <GenericCalendar
            calendarType={CalendarType.Single}
            date={startDate}
            id={startDatePickerId}
            language={language}
            maxDate={maxDate}
            onChange={onStartDateChange}
            placeholder={
              startPlaceholder && // @ts-expect-error - i18n._() can be called with string or MessageDescriptor
              i18n._(startPlaceholder)
            }
            newCalendarInteraction={newCalendarInteraction}
            serviceName={serviceName}
            serviceSubtitle={serviceSubtitle}
            disableValidation={disableValidation}
            validationType="inline"
            isMobileBrowser={isMobileBrowser}
            {...(isRollout1SimplifiedMobileOwnerSearchFormExperiment &&
              inVariantSimplifiedMobileOwnerSearchFormExperiment && {
                inputStyle: { minHeight: SPACE_12X },
                iconStyle: {
                  height: SPACE_6X,
                  width: SPACE_6X,
                  right: SPACE_2X,
                },
              })}
            onClickFromSearch={onClickFromSearch}
            isDismissible={false}
          />
        ) : (
          <DatePicker
            date={startDate}
            id={startDatePickerId}
            language={language}
            maxDate={maxDate}
            onChange={onStartDateChange}
            placeholder={
              startPlaceholder && // @ts-expect-error - i18n._() can be called with string or MessageDescriptor
              i18n._(startPlaceholder)
            }
            newCalendarInteraction={newCalendarInteraction}
            serviceName={serviceName}
            serviceSubtitle={serviceSubtitle}
            disableValidation={disableValidation || false}
            validationType="inline"
            isMobileBrowser={isMobileBrowser}
            {...(isRollout1SimplifiedMobileOwnerSearchFormExperiment &&
              inVariantSimplifiedMobileOwnerSearchFormExperiment && {
                inputStyle: { minHeight: SPACE_12X },
                iconStyle: {
                  height: SPACE_6X,
                  width: SPACE_6X,
                  right: SPACE_2X,
                },
              })}
            onClickFromSearch={onClickFromSearch}
            onInputBlur={onInputBlur}
            validationError={validationError}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default RepeatServiceScheduler;
