import React from 'react';

import { Connect } from '@rover/react-lib/src/components/utils/Redux';
import { selectors as searchSelectors } from '@rover/react-lib/src/redux/ducks/search.duck';

import SearchPagination from './SearchPagination';

export type Props = {
  onPageChange: (page: number) => void;
};

const selector = (state) => ({
  pagination: searchSelectors.getPagination(state),
});

export default ({ onPageChange }: Props): JSX.Element => (
  <Connect selector={selector}>
    {(connectedProps) => <SearchPagination {...connectedProps} onPageChange={onPageChange} />}
  </Connect>
);
