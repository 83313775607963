import React, { Component } from 'react';
import styled from 'styled-components';

import ErrorBoundary from '@rover/react-lib/src/components/errorHandling/ErrorBoundary';
import StaticGoogleMap from '@rover/react-lib/src/components/maps/StaticGoogleMap';
import RoverLoading from '@rover/react-lib/src/components/utils/RoverLoading/RoverLoading';
import { ApiFrontendConfigurationRetrieve200 } from '@rover/rsdk/src/apiClient/latest';
import type { SearchMapData, SearchResult } from '@rover/types';
import type { GoogleMapsConfigurationType } from '@rover/types/src/GoogleMapsConfiguration';
import type { LatLng } from '@rover/types/src/LatLng';

import { latRad } from '../../utilities';
import type { MapMetadata } from '../GoogleMap';
import GoogleMap from '../GoogleMap';
import Marker from '../Marker';

type Props = {
  center:
    | {
        lat: string;
        lng: string;
      }
    | null
    | undefined;
  zoomLevel: number | null | undefined;
  mapData: SearchMapData;
  searchResults: SearchResult[];
  bestMatchResults?: SearchResult[];
  onMapMoved: (arg0: MapMetadata) => void;
  onMouseOver: (index: number) => void;
  onMouseOut: () => void;
  searchInitiatedByMap: boolean;
  onMarkerClick: (index: number) => void;
  onZoomChanged: (arg0: MapMetadata) => void;
  settings: Pick<ApiFrontendConfigurationRetrieve200['settings'], 'staticGoogleMapsApiKey'>;
  showStaticMap: boolean;
  showDynamicMap: boolean;
  mapStyle: GoogleMapsConfigurationType[];
  rolloutSignStaticMap: boolean;
  options?: Record<string, string | boolean>;
  hoveringResultIndex?: number | null;
  zIndex: (index: number) => number;
};

type State = {
  loading: boolean;
  staticMapIsVisible: boolean;
};

const StyledRoverLoading = styled(RoverLoading)`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
`;

const WORLD_DIMENSIONS = {
  height: 256,
  width: 256,
};
const ZOOM_LEVEL_MAX = 21;

function zoom(mapPx, worldPx, fraction): number {
  return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
}

// calculates the zoom level based on the map bounds and dimensions
const getBoundsZoomLevel = (bounds, mapDimensions): number => {
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();
  const latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;
  const lngDiff = ne.lng() - sw.lng();
  const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;
  const latZoom = zoom(mapDimensions.height, WORLD_DIMENSIONS.height, latFraction);
  const lngZoom = zoom(mapDimensions.width, WORLD_DIMENSIONS.width, lngFraction);
  return Math.min(latZoom, lngZoom, ZOOM_LEVEL_MAX);
};

const getMarkerTitle = ({ shortName, city, state, neighborhood, zip }: SearchResult): string => {
  let location = `${city}, ${state}`;
  if (neighborhood) location = `${neighborhood}, ${location}`;
  else location = `${location}, ${zip}`;
  return `${shortName}: ${location}`;
};

// determines if the new map position is different enough from the previous
// position, so we can decide to fire a search
export const passesThreshold = (currentMetadata: MapMetadata, nextMetadata: MapMetadata): boolean =>
  Object.keys(currentMetadata).some((key) => {
    const currentMetadataValue = currentMetadata[key].toPrecision(5);
    const nextMetadataValue = nextMetadata[key].toPrecision(5);
    return currentMetadataValue !== nextMetadataValue;
  });

class SearchMap extends Component<Props, State> {
  mapDiv: HTMLDivElement | null | undefined;

  currentCenter = this.props.center;

  zoomLevel = this.props.zoomLevel;

  searchMapMetadata: MapMetadata | null | undefined;

  updating = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      // only start with loader if not showing static map
      loading: !props.showStaticMap,
      // we duplicate this into state so we can show the static map until the dynamic map loads
      staticMapIsVisible: props.showStaticMap,
    };
  }

  componentDidUpdate(prevProps: Props): void {
    const { mapData } = this.props;

    if (mapData !== prevProps.mapData) {
      // retry is 10/sec so this gives the google maps lib 30 seconds
      //  to load before we give up and let GAPI show its error UI
      this.onSearchResultsChanged({
        retry: 300,
      });
    }
  }

  onSearchResultsChanged = (
    {
      initialRender,
      retry,
    }: {
      initialRender?: boolean;
      retry: number;
    } = {
      retry: -1,
      // negative numbers turn off retry, positive is how many times to retry
      initialRender: false,
    }
  ): void => {
    const { mapData, searchInitiatedByMap } = this.props;

    // Don't update the map's location if we did a map-based search
    if (
      (searchInitiatedByMap || this.state.loading || this.props.mapData.zoomMethod !== 'dass') &&
      !initialRender
    ) {
      return;
    }

    // retry until google.maps is loaded
    // eslint-disable-next-line rover/no-platform-specific-globals-or-imports
    if (retry > 0 && !(window.google && window.google.maps)) {
      setTimeout(
        () =>
          this.onSearchResultsChanged({
            initialRender,
            retry: retry - 1,
          }),
        100
      );
      return;
    }

    // when out of retries, just stop. GAPI component handles error messaging
    // eslint-disable-next-line rover/no-platform-specific-globals-or-imports
    if (retry === 0 && !(window.google && window.google.maps)) {
      return;
    }

    const bounds = new google.maps.LatLngBounds();

    if (mapData.viewport) {
      const { minlat, maxlat, minlng, maxlng } = mapData.viewport;
      bounds.extend(new google.maps.LatLng(minlat, minlng));
      bounds.extend(new google.maps.LatLng(maxlat, maxlng));
    }

    this.fitBounds(bounds);
  };

  fitBounds = (bounds: google.maps.LatLngBounds): void => {
    if (!this.mapDiv) {
      return;
    }

    const mapDimensions = {
      height: this.mapDiv.offsetHeight,
      width: this.mapDiv.offsetWidth,
    };
    const zoomLevel = getBoundsZoomLevel(bounds, mapDimensions);
    const center = bounds.getCenter();
    // mark the map as updating so not to trigger searches via
    // the idle and zoom listeners
    this.updating = true;
    this.zoomLevel = zoomLevel || this.zoomLevel;
    this.currentCenter = {
      lat: center.lat().toString(),
      lng: center.lng().toString(),
    };
    // need to force update here because we can't treat
    // the google map like a controlled component, we store
    // the center/zoom value in this.currentCenter and this.zoomLevel
    // rather than state since in most cases we're just tracking the
    // center/zoom while in this case we want to trigger a re-render
    this.forceUpdate();
  };

  handleLoad = (): void => {
    this.onSearchResultsChanged({
      initialRender: true,
      retry: -1,
    });
    this.setState({
      loading: false,
    });
    // eslint-disable-next-line rover/no-platform-specific-globals-or-imports
    document.querySelector('[aria-label="Map"]')?.setAttribute('aria-label', 'Sitter Location Map');
  };

  handleError = (): void => {
    // remove the static map to show GAPI's error UI
    this.setState(() => ({
      staticMapIsVisible: false,
    }));
  };

  // this event occurs on load and when the map has moved
  // and is no longer moving, we then check to see if
  // the map has moved sufficiently to fire a search
  handleIdle = (updatedMapMetadata: MapMetadata): void => {
    if (!this.searchMapMetadata) {
      this.searchMapMetadata = updatedMapMetadata;
      return;
    }

    if (!this.updating && passesThreshold(this.searchMapMetadata, updatedMapMetadata)) {
      this.props.onMapMoved(updatedMapMetadata);
    }

    this.updating = false;
  };

  handleTilesLoaded = (): void => {
    // only remove the static map if props.showStaticMap allows it,
    // and the dynamic map is done loading tiles
    if (!this.props.showStaticMap && this.state.staticMapIsVisible) {
      this.setState(() => ({
        staticMapIsVisible: false,
      }));
    }
  };

  handleOnZoomChanged = (updatedMapMetadata: MapMetadata): void => {
    const { onZoomChanged } = this.props;
    this.zoomLevel = updatedMapMetadata.zoomlevel;

    if (!this.updating) {
      onZoomChanged(updatedMapMetadata);
    }
  };

  // since we can't treat the google map as a controlled component
  // (ie the map location updates independently from state)
  // we should still track the location here, so that when we re-render
  // the map doesn't jump around to it's previous center
  handleCenterChanged = (center: { lat: number; lng: number }): void => {
    this.currentCenter = {
      lat: center.lat.toString(),
      lng: center.lng.toString(),
    };
  };

  get center():
    | {
        lat: string;
        lng: string;
      }
    | null
    | undefined {
    if (!this.currentCenter) {
      return undefined;
    }

    const { lat, lng } = this.currentCenter;
    return {
      lat: parseFloat(lat).toFixed(5),
      lng: parseFloat(lng).toFixed(5),
    };
  }

  renderStaticMap = (props: Props): JSX.Element | null => {
    if (!this.state.staticMapIsVisible) return null;
    const {
      center,
      searchResults,
      bestMatchResults = [],
      settings,
      zoomLevel,
      rolloutSignStaticMap,
    } = props;
    const locations = [...bestMatchResults, ...searchResults].reduce(
      (acc: LatLng[], { latitude, longitude }) =>
        acc.concat({
          lat: parseFloat(latitude),
          lng: parseFloat(longitude),
        }),
      []
    );

    const markers = [{ locations }];

    const staticMapProps = {
      apiKey: settings.staticGoogleMapsApiKey,
      center:
        (center && {
          lat: parseFloat(center.lat),
          lng: parseFloat(center.lng),
        }) ||
        undefined,
      markers,
      zoom: zoomLevel,
      mapStyle: this.props.mapStyle,
      rolloutSignStaticMap,
    };
    return <StaticGoogleMap {...staticMapProps} />;
  };

  renderDynamicMap = ({
    searchResults,
    bestMatchResults = [],
    onMarkerClick,
    showDynamicMap,
    showStaticMap,
    onMouseOver,
    onMouseOut,
    zIndex,
    hoveringResultIndex,
  }: Props): JSX.Element | null => {
    // when not showing the static map always show the dynamic map,
    // otherwise respond to props.showDynamicMap
    if (showStaticMap || this.state.staticMapIsVisible) {
      if (!showDynamicMap) return null;
    }

    return (
      <GoogleMap
        zoom={this.zoomLevel}
        center={this.center}
        onLoad={this.handleLoad}
        onError={this.handleError}
        onIdle={this.handleIdle}
        onTilesLoaded={this.handleTilesLoaded}
        onCenterChanged={this.handleCenterChanged}
        onZoomChanged={this.handleOnZoomChanged}
        ref={(ref): void => {
          this.mapDiv = ref;
        }}
        styles={this.props.mapStyle}
        {...(this.props.options || {})}
      >
        {[...bestMatchResults, ...searchResults].map((result, index) => {
          const { latitude, longitude, rank } = result;
          return (
            // @ts-expect-error: map & google props added by GoogleMap component
            <Marker
              hovering={index === hoveringResultIndex}
              onClick={onMarkerClick}
              key={result.isFacility ? result.facilityOpk : result.personOpk}
              position={{
                lat: latitude,
                lng: longitude,
              }}
              rank={rank}
              onMouseOver={onMouseOver}
              onMouseOut={onMouseOut}
              index={index}
              zIndex={zIndex(index)}
              title={getMarkerTitle(result)}
            />
          );
        })}
      </GoogleMap>
    );
  };

  render(): JSX.Element {
    const { loading } = this.state;
    return (
      <ErrorBoundary location="SearchMap">
        <StyledRoverLoading isLoading={loading}>
          {this.renderDynamicMap(this.props)}
          {this.renderStaticMap(this.props)}
        </StyledRoverLoading>
      </ErrorBoundary>
    );
  }
}

export default SearchMap;
