import React from 'react';
import { t } from '@lingui/macro';
import styled from 'styled-components';

import { DSTokenMap } from '@rover/kibble/styles';
import { CalendarDirection } from '@rover/react-lib/src/components/datetime/DatePicker';
import ServiceTypeFilter, {
  ServiceFilterType,
} from '@rover/react-lib/src/components/formFields/ServiceTypeFilter';
import { Alignment, HorizontalLayout } from '@rover/react-lib/src/components/layouts';
import { AdvancedFiltersButton } from '@rover/react-lib/src/pages/search/SearchPage/components/AdvancedFiltersButton';
import {
  DayTimeAvailability,
  DaytimeServiceScheduler,
  FulltimeAvailability,
  OvernightDateRange,
} from '@rover/react-lib/src/pages/search/SearchPage/components/Filters/Dates';
import Location from '@rover/react-lib/src/pages/search/SearchPage/components/Filters/Location';
import { shouldShowControls } from '@rover/react-lib/src/pages/search/SearchPage/utilities';
import { useLocationInputTexts } from '@rover/react-lib/src/pages/search/SearchPage/utilities/useLocationInputTexts';
import getMaxRequestDate from '@rover/react-lib/src/utils/getMaxRequestDate';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import { DROP_OFF_PICK_UP_SUPPORTED_SERVICES } from '@rover/shared/js/constants/searchPage.constants';
import type {
  SearchFilters,
  SearchFiltersOptions,
  ServiceType,
  ServiceTypeOption,
} from '@rover/types';

import { CollectionWrapper, FilterWrapper } from '../common';

const BackgroundWrapper = styled.div`
  background-color: ${DSTokenMap.BACKGROUND_COLOR_SECONDARY.toString()};
`;
const StyledFilterWrapper = styled(FilterWrapper)`
  margin-bottom: ${DSTokenMap.SPACE_3X};
`;

const TopRowLeft = styled.div`
  width: 55%;
`;

const TopRowRight = styled.div`
  width: 40%;
`;

export type Props = {
  advancedFiltersCount: number;
  getServiceTypeBySlug: (arg0: string) => ServiceType | null | undefined;
  isCatOnly: boolean;
  language: string;
  priceFilterMaxValue: number;
  priceFilterMinValue: number;
  searchFilters: SearchFilters;
  serviceTypeFilterOptions: SearchFiltersOptions;
  serviceTypeOptions: ServiceTypeOption[];
  showAdvancedFiltersModal: () => void;
  updateFiltersAndFireSearchOnChange: (searchFilters: Partial<SearchFilters>) => void;
};

const CondensedFiltersCollection = ({
  advancedFiltersCount,
  getServiceTypeBySlug,
  isCatOnly,
  language,
  priceFilterMaxValue,
  priceFilterMinValue,
  searchFilters,
  serviceTypeFilterOptions,
  serviceTypeOptions,
  showAdvancedFiltersModal,
  updateFiltersAndFireSearchOnChange,
}: Props): JSX.Element => {
  const { i18n } = useI18n();
  const serviceType = getServiceTypeBySlug(searchFilters.serviceType);

  const { morningAvailability, middayAvailability, eveningAvailability } = serviceTypeFilterOptions;

  // Abstract this into a selector in https://roverdotcom.atlassian.net/browse/DEV-42261
  const startPlaceholder = DROP_OFF_PICK_UP_SUPPORTED_SERVICES.has(searchFilters.serviceType)
    ? t`Drop off`
    : t`Start`;
  const endPlaceholder = DROP_OFF_PICK_UP_SUPPORTED_SERVICES.has(searchFilters.serviceType)
    ? t`Pick up`
    : t`End`;

  const handleChange = (filters: Partial<SearchFilters>): void => {
    updateFiltersAndFireSearchOnChange(filters);
  };

  const handleChangeDateRange = (dateRange): void => {
    if (dateRange) {
      handleChange({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      });
    }
  };

  const {
    showDateRange,
    showDaytimeAvailability,
    showFulltimeAvailability,
    showDaytimeServiceScheduler,
  } = shouldShowControls({
    serviceOptions: serviceTypeFilterOptions,
    isCatOnly,
    serviceType: serviceType || undefined,
    serviceTypeSlug: searchFilters.serviceType,
  });

  const maxDate = getMaxRequestDate();

  const { getPlaceholderText, getTitleText } = useLocationInputTexts({
    titleTextControl: i18n._('Near'),
  });

  return (
    <BackgroundWrapper data-qa-id="search-condensed-filters-wrapper">
      <CollectionWrapper>
        <StyledFilterWrapper>
          <HorizontalLayout hAlign={Alignment.SPACE_BETWEEN} vAlign={Alignment.END}>
            <TopRowLeft>
              <ServiceTypeFilter
                filterType={ServiceFilterType.DROPDOWN}
                serviceTypeOptions={serviceTypeOptions}
                label={i18n._('Show me sitters for')}
                searchFilters={searchFilters}
                onChange={handleChange}
                priceFilterMaxValue={priceFilterMaxValue}
                priceFilterMinValue={priceFilterMinValue}
              />
            </TopRowLeft>
            <TopRowRight>
              <Location
                locationInputId="location-input-condensed"
                label={getTitleText()}
                placeholder={getPlaceholderText()}
                location={searchFilters.location}
                onChange={handleChange}
                suggestionsMenuAlignment="right"
              />
            </TopRowRight>
          </HorizontalLayout>
        </StyledFilterWrapper>
        {showDateRange && (
          <StyledFilterWrapper>
            <OvernightDateRange
              calendarDirection={CalendarDirection.DOWN}
              endPlaceholder={endPlaceholder}
              maxDate={maxDate}
              label=""
              language={language}
              onChange={handleChangeDateRange}
              searchFilters={searchFilters}
              startPlaceholder={startPlaceholder}
            />
          </StyledFilterWrapper>
        )}
        {showDaytimeServiceScheduler && (
          <StyledFilterWrapper>
            <DaytimeServiceScheduler
              oneTimeLabel=""
              repeatStartDateLabel=""
              calendarDirection={CalendarDirection.DOWN}
              onChange={(frequency) => handleChange({ frequency })}
              endPlaceholder={endPlaceholder}
              language={language}
              maxDate={maxDate}
              onDateRangeChange={handleChangeDateRange}
              onSelectedDaysChange={(selectedDays) => handleChange({ selectedDays })}
              onStartDateChange={handleChangeDateRange}
              searchFilters={searchFilters}
              serviceType={serviceType}
              showLabel={false}
              startPlaceholder={startPlaceholder}
              showArrow
            />
          </StyledFilterWrapper>
        )}
        {showFulltimeAvailability && (
          <StyledFilterWrapper>
            <FulltimeAvailability
              label={i18n._('Sitter is home full-time')}
              searchFilters={searchFilters}
              onChange={(fulltimeAvailability) => handleChange({ fulltimeAvailability })}
            />
          </StyledFilterWrapper>
        )}
        {showDaytimeAvailability && (
          <StyledFilterWrapper>
            <DayTimeAvailability
              availabilityBuckets={{
                morning: morningAvailability ? morningAvailability.label : null,
                midday: middayAvailability ? middayAvailability.label : null,
                evening: eveningAvailability ? eveningAvailability.label : null,
              }}
              label={i18n._('Which time(s) do you need?')}
              searchFilters={searchFilters}
              onChange={(availability) => handleChange(availability)}
            />
          </StyledFilterWrapper>
        )}
        <HorizontalLayout hAlign={Alignment.CENTER}>
          <AdvancedFiltersButton
            selectedFilterCount={advancedFiltersCount}
            onClick={showAdvancedFiltersModal}
          />
        </HorizontalLayout>
      </CollectionWrapper>
    </BackgroundWrapper>
  );
};

export default CondensedFiltersCollection;
