import React from 'react';

import { Box, Image } from '@rover/kibble/core';
import { Color } from '@rover/kibble/styles';
import { PopoverPlacement } from '@rover/react-lib/src/components/callouts/Popover/popover.constants';
import Badge from '@rover/react-lib/src/pages/search/shared/components/SitterBadge';
import type { SitterBadge } from '@rover/types';

import {
  AVATAR_BADGE_SIZES,
  AVATAR_SIZES,
  SEARCH_RESULT_CARD_Z_INDEX,
} from '../SearchResultCard.constants';

type Props = {
  imageUrl: string;
  lazy: boolean;
  badge?: SitterBadge;
  displayExpanded: boolean;
  description: string;
  isFacility?: boolean;
};

export default function DesktopAvatar({
  imageUrl,
  lazy,
  badge,
  displayExpanded,
  description,
  isFacility = false,
}: Props): React.ReactElement {
  return (
    <Box
      position="relative"
      height={displayExpanded ? AVATAR_SIZES.EXPANDED : AVATAR_SIZES.REDUCED}
      width={displayExpanded ? AVATAR_SIZES.EXPANDED : AVATAR_SIZES.REDUCED}
      border={isFacility ? `2px solid ${Color.GREEN_600.toString()}` : undefined}
      borderRadius="round"
    >
      <Image
        src={imageUrl}
        description={description}
        height="100%"
        width="100%"
        eager={!lazy}
        borderRadius="round"
      />
      {badge && (
        <Box
          position="absolute"
          bottom="0"
          right="0"
          width={displayExpanded ? AVATAR_BADGE_SIZES.EXPANDED : AVATAR_BADGE_SIZES.REDUCED}
          height={displayExpanded ? AVATAR_BADGE_SIZES.EXPANDED : AVATAR_BADGE_SIZES.REDUCED}
          zIndex={`${SEARCH_RESULT_CARD_Z_INDEX.INTERACTIVE}`}
        >
          <Badge
            title={badge.title}
            content={badge.content}
            imageUrl={badge.imageUrl}
            key={badge.imageUrl}
            slug={badge.slug}
            width={displayExpanded ? AVATAR_BADGE_SIZES.EXPANDED : AVATAR_BADGE_SIZES.REDUCED}
            popoverPlacement={PopoverPlacement.RIGHT}
          />
        </Box>
      )}
    </Box>
  );
}
