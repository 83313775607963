import { AlertSeverity } from '@rover/react-lib/src/components/notifications/Alert/Alert';
import { ServiceType } from '@rover/shared/js/constants/service';

import {
  AlertContentEnum,
  getAlertContent,
  getSubHeader,
  HEADER_TITLES,
  HeaderIconEnum,
  HeaderTitlesEnum,
  SubHeadersEnum,
} from './constants';
import { DateData, PrebuiltHeaderProps } from './types';

type Props = {
  dateData: DateData;
  individualPetTypeCountDescription: string;
  isPriceTransparency?: boolean;
  serviceType: ServiceType;
};

const buildBestMatchesHeader = ({
  dateData,
  individualPetTypeCountDescription,
  isPriceTransparency,
  serviceType,
}: Props): PrebuiltHeaderProps | object => {
  const title = HEADER_TITLES[HeaderTitlesEnum.BEST_MATCHES];

  let primarySubheader;
  let secondarySubheader;
  let alertText;

  if (!isPriceTransparency) {
    primarySubheader = getSubHeader(SubHeadersEnum.SEEING_PROVIDERS_DATES, {
      serviceType,
      dateData,
    });
    secondarySubheader = getSubHeader(SubHeadersEnum.BEST_MATCH_LIST_SHORT_LEAD_TIME);
  } else {
    primarySubheader = getSubHeader(SubHeadersEnum.BEST_MATCH_LIST_SHORT_LEAD_TIME);
    alertText = getAlertContent(AlertContentEnum.PRICE_TRANSPARENCY_PRICES_FOR_UNIT, {
      serviceType,
      individualPetTypeCountDescription,
      dateData,
    });
  }

  return {
    icon: HeaderIconEnum.BOLT,
    title,
    primarySubheader,
    secondarySubheader,
    alertText,
    alertSeverity: AlertSeverity.INFO,
    isBestMatchHeader: true,
  };
};

export default buildBestMatchesHeader;
