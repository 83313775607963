import React from 'react';
import { plural, t } from '@lingui/macro';
import get from 'lodash-es/get';
import styled from 'styled-components';

import { Box, Text } from '@rover/kibble/core';
import { DSTokenMap, MQ, Spacing } from '@rover/kibble/styles';
import Advanced from '@rover/react-lib/src/pages/search/SearchPage/components/Filters/Advanced';
import PriceFilter from '@rover/react-lib/src/pages/search/SearchPage/components/Filters/PriceFilter';
import SpacesRequired from '@rover/react-lib/src/pages/search/SearchPage/components/Filters/SpacesRequired';
import StarSitterFilter from '@rover/react-lib/src/pages/search/SearchPage/components/Filters/StarSitterFilter';
import PetCounter from '@rover/react-lib/src/pages/search/SearchPage/components/PetCounter';
import {
  advancedFiltersCounter,
  getServiceType,
  shouldShowControls,
} from '@rover/react-lib/src/pages/search/SearchPage/utilities';
import { ApiFrontendConfigurationRetrieve200SettingsDogSizeBuckets } from '@rover/rsdk/src/apiClient/latest';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import { SHOW_SPACES_REQUIRED_WITH_PETS_SERVICES } from '@rover/shared/js/constants/searchPage.constants';
import Pets from '@rover/shared/js/search/components/Pets';
import PetSize from '@rover/shared/js/search/components/PetSize';
import PetTypeFilter from '@rover/shared/js/search/components/PetTypeFilter';
import getIsCatOnlyPetType from '@rover/shared/js/search/utilities/getIsCatOnlyPetType';
import type { Pet, SearchFilters, SearchFiltersOptions, ServiceType } from '@rover/types';
import type { SearchDerivedData } from '@rover/types/src/apiResponses/FetchSearchResponse';
import { PetType } from '@rover/types/src/Pet';
import { ServiceTypeSlug } from '@rover/types/src/ServiceType';

import { FilterWrapper } from '../common';
import DaycareTypeFilter from '../DaycareTypeFilter';

export type Props = {
  getServiceTypeBySlug: (arg0: string) => ServiceType | null | undefined;
  currencyCode: string;
  formData: Partial<SearchFilters>;
  getPriceUnit: (arg0: string) => string;
  hasPets: boolean;
  language: string;
  onChange: (arg0: Partial<SearchFilters>) => void;
  petOptions: Pet[];
  petSizeBuckets: ApiFrontendConfigurationRetrieve200SettingsDogSizeBuckets;
  searchFilters: SearchFilters;
  serviceTypeFilterOptions: SearchFiltersOptions;
  weightUnit: string;
  priceFilterMaxValue: number;
  priceFilterMinValue: number;
  queryDerivedData: SearchDerivedData;
  isInGingrFacilitiesInSearchExperiment: boolean;
  isDaycareFilterOutsideMarketsInExperiment: boolean;
  rolloutPriceTransparency?: boolean;
  isInPetCountersExperiment?: boolean;
};

const AdvancedWrapper = styled(FilterWrapper)`
  margin-left: -${Spacing.M.toString()};
  margin-right: -${Spacing.M.toString()};
  margin-bottom: 0;
  padding: ${Spacing.M.toString()};
  padding-bottom: ${Spacing.XXL.toString()};
  background-color: ${DSTokenMap.BACKGROUND_COLOR_SECONDARY.toString()};

  ${MQ.XS_DOWN.toString()} {
    margin-bottom: 0;
  }
`;

const AdvancedFiltersCollection = ({
  currencyCode,
  formData,
  getPriceUnit,
  hasPets,
  language,
  onChange,
  petOptions,
  petSizeBuckets,
  searchFilters,
  serviceTypeFilterOptions,
  weightUnit,
  priceFilterMaxValue,
  priceFilterMinValue,
  getServiceTypeBySlug,
  queryDerivedData,
  isInGingrFacilitiesInSearchExperiment,
  isDaycareFilterOutsideMarketsInExperiment,
  rolloutPriceTransparency,
  isInPetCountersExperiment,
}: Props): JSX.Element => {
  const { i18n } = useI18n();
  const serviceType = getServiceTypeBySlug(searchFilters.serviceType);
  const { showStarSitterFilter } = queryDerivedData;
  const { starSitterLink } = queryDerivedData;
  const isIncludingFees = serviceTypeFilterOptions.minprice?.fullprice === true;

  const {
    showDaycareType,
    showPetSize,
    showPets,
    showPrice,
    showPetType,
    showSpacesRequired,
    showStarSitterOnlyFilter,
  } = shouldShowControls({
    hasPets,
    isCatOnly: getIsCatOnlyPetType(formData.petType || []),
    serviceOptions: serviceTypeFilterOptions,
    serviceTypeSlug: searchFilters.serviceType,
    isInGingrFacilitiesInSearchExperiment,
    isDaycareFilterOutsideMarketsInExperiment,
    showStarSitterFilter,
    isIncludingFees,
    rolloutPriceTransparency,
  });
  const shouldShowPetCounter = rolloutPriceTransparency || isInPetCountersExperiment;

  return (
    <>
      {showPetType && !shouldShowPetCounter && (
        <FilterWrapper>
          <PetTypeFilter
            label={i18n._(t`I'm looking for service for my:`)}
            searchFilters={{ petType: formData.petType || [], ...formData }}
            onChange={(petType) => {
              const localIsCatOnly = getIsCatOnlyPetType(petType);
              const updatedServiceType = getServiceType(
                localIsCatOnly,
                searchFilters.serviceType as ServiceTypeSlug,
                get(serviceType, 'suggestedPetType')
              );
              const args = {
                petType,
                catCare: localIsCatOnly,
                serviceType: updatedServiceType,
              };
              return onChange(args);
            }}
          />
        </FilterWrapper>
      )}
      {showPetType && shouldShowPetCounter && (
        <FilterWrapper>
          <PetCounter
            inputLabel={i18n._(t`I'm looking for service for my:`)}
            inputLabelMarginBottom="2x"
            inputLabelColor="secondary"
            dogCount={formData.dogCount}
            catCount={formData.catCount}
            puppyCount={formData.puppyCount}
            availablePets={[PetType.DOG, PetType.PUPPY, PetType.CAT]}
            triggeredFrom="Search"
            onChange={({ petType, dogCount, catCount, puppyCount }) => {
              const localIsCatOnly = getIsCatOnlyPetType(petType);
              const updatedServiceType = getServiceType(
                localIsCatOnly,
                searchFilters.serviceType as ServiceTypeSlug,
                get(serviceType, 'suggestedPetType')
              );
              const args = {
                petType,
                catCare: localIsCatOnly,
                serviceType: updatedServiceType,
                dogCount,
                catCount,
                puppyCount,
              };
              return onChange(args);
            }}
            hideHelperText
          />
        </FilterWrapper>
      )}
      {showPets && (
        <FilterWrapper>
          <Pets
            label={i18n._(
              plural({
                value: serviceTypeFilterOptions.pets
                  ? serviceTypeFilterOptions.pets.choices.length
                  : 0,
                one: 'Your pet:',
                other: 'Your pets:',
              })
            )}
            petOptions={petOptions}
            searchFilters={formData}
            onChange={({ pet, petType }) => {
              const localIsCatOnly = getIsCatOnlyPetType(petType as []);
              const newServiceType = getServiceType(
                localIsCatOnly,
                searchFilters.serviceType as ServiceTypeSlug,
                get(serviceType, 'suggestedPetType')
              );
              return onChange({
                pet,
                petType,
                catCare: localIsCatOnly,
                serviceType: newServiceType,
              });
            }}
          />
          {(rolloutPriceTransparency || isInPetCountersExperiment) && !formData?.pet?.length && (
            <Text size="100" textColor="tertiary" data-testid="helper-text">
              {i18n._(t`Select at least one pet to ensure a more accurate search`)}
            </Text>
          )}
        </FilterWrapper>
      )}
      {showPetSize && (
        <FilterWrapper>
          <PetSize
            label={i18n._(t`What size are your dogs (${weightUnit})?`)}
            onChange={(sizeKey) => {
              onChange({ [sizeKey]: !formData[sizeKey] });
            }}
            petSizeBuckets={petSizeBuckets}
            searchFilters={formData}
            weightUnit={weightUnit}
          />
        </FilterWrapper>
      )}
      {showSpacesRequired &&
        (!shouldShowPetCounter ||
          SHOW_SPACES_REQUIRED_WITH_PETS_SERVICES.has(searchFilters.serviceType)) && (
          <FilterWrapper>
            <SpacesRequired
              searchFilters={formData}
              onChange={(spacesRequired) => onChange({ spacesRequired })}
            />
          </FilterWrapper>
        )}
      {!!(showPrice && formData.serviceType && formData.minprice) && (
        <FilterWrapper>
          <PriceFilter
            currencyCode={currencyCode}
            label={i18n._(
              /* i18n: Rate per {priceUnit} e.g. day, night, walk */
              t`Rate per ${getPriceUnit(formData.serviceType)}`
            )}
            language={language}
            onChange={({ min, max }) => {
              onChange({ minprice: min, maxprice: max });
            }}
            selectedRangeMin={formData.minprice}
            selectedRangeMax={formData.maxprice || priceFilterMaxValue}
            sliderMax={priceFilterMaxValue}
            sliderMin={priceFilterMinValue}
            isIncludingFees={isIncludingFees}
          />
        </FilterWrapper>
      )}
      {showDaycareType && (
        <FilterWrapper>
          <DaycareTypeFilter
            onChange={(inSittersHome, atDaycareFacility) => {
              onChange({ inSittersHome, atDaycareFacility });
            }}
            inSittersHome={searchFilters.inSittersHome}
            atDaycareFacility={searchFilters.atDaycareFacility}
            placement="search-sidebar"
          />
        </FilterWrapper>
      )}
      {showStarSitterOnlyFilter && (
        <Box mb="6x" mt={!showDaycareType ? '6x' : '0x'}>
          <StarSitterFilter
            label={i18n._(t`Only show star sitters`)}
            onChange={(starSitter) => onChange({ starSitter })}
            value={formData.starSitter || false}
            starSitterLink={starSitterLink}
          />
        </Box>
      )}
      <AdvancedWrapper>
        <Advanced
          advancedFiltersCount={advancedFiltersCounter(formData, serviceTypeFilterOptions)}
          onChange={(filter) => onChange(filter)}
          searchFiltersOptions={serviceTypeFilterOptions}
          searchFilters={formData}
          isPriceTransparency={!!shouldShowPetCounter}
        />
      </AdvancedWrapper>
    </>
  );
};

export default AdvancedFiltersCollection;
