import React from 'react';
import styled from 'styled-components';

import { getStaticMapStyles } from '@rover/react-lib/src/constants/googleMap.constants';
import type { GoogleMapsConfigurationType } from '@rover/types/src/GoogleMapsConfiguration';
import type { LatLng } from '@rover/types/src/LatLng';

import markersToParams from './markersToParams';
import SetStaticMapSrcOnLoadScript from './SetStaticMapSrcOnLoadScript';
import { STATIC_MAPS_DIV_ID } from './StaticGoogleMap.constants';
import type { StaticMapMarker } from './StaticMapMarker';

const DEFAULT_CENTER: LatLng = {
  lat: 47.6062,
  lng: -122.3321,
}; // Seattle

const DEFAULT_ZOOM = 13;
export type Props = {
  apiKey?: string;
  center?: LatLng;
  markers: StaticMapMarker[];
  zoom?: number | null | undefined;
  mapStyle: GoogleMapsConfigurationType[];
  rolloutSignStaticMap: boolean;
};
const StyledStaticMapDiv = styled.div`
  &:hover {
    cursor: wait;
  }
  position: absolute;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

function getMapsApiRoot(rolloutSignStaticMap: boolean): string {
  if (rolloutSignStaticMap) {
    return '/static-map-signature/';
  }

  return 'https://maps.googleapis.com/maps/api/staticmap';
}

export function constructUrlWithoutSizeParam({
  apiKey,
  center: { lat, lng } = DEFAULT_CENTER,
  markers,
  zoom,
  mapStyle,
  rolloutSignStaticMap,
}: Props): string {
  const params: string[] = [];
  const mapsRoot = getMapsApiRoot(rolloutSignStaticMap);

  params.push(`center=${lat},${lng}`);
  params.push(`zoom=${zoom}`);
  params.push('maptype=roadmap');

  // Only include key when not signing maps
  // Soon this will key will be removed if the rollout is a success
  if (rolloutSignStaticMap === false) {
    params.push(`key=${apiKey}`);
  }

  params.push(`format=jpg`);
  params.push(markersToParams(markers));
  params.push(getStaticMapStyles(mapStyle));
  return `${mapsRoot}?${params.join('&')}`;
}

const StaticGoogleMap = ({
  apiKey = '',
  center = DEFAULT_CENTER,
  markers,
  zoom = DEFAULT_ZOOM,
  mapStyle,
  rolloutSignStaticMap,
}: Props): JSX.Element => {
  return (
    <>
      <StyledStaticMapDiv id={STATIC_MAPS_DIV_ID} />
      <SetStaticMapSrcOnLoadScript
        mapUrlStub={constructUrlWithoutSizeParam({
          apiKey,
          center,
          markers,
          zoom,
          mapStyle,
          rolloutSignStaticMap,
        })}
      />
    </>
  );
};

export default StaticGoogleMap;
