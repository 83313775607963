import React from 'react';
import { t } from '@lingui/macro';
import styled from 'styled-components';

import { Box } from '@rover/kibble/core';
import { DSTokenMap, MQ, Spacing } from '@rover/kibble/styles';
import Advanced from '@rover/react-lib/src/pages/search/SearchPage/components/Filters/Advanced';
import PriceFilter from '@rover/react-lib/src/pages/search/SearchPage/components/Filters/PriceFilter';
import SpacesRequired from '@rover/react-lib/src/pages/search/SearchPage/components/Filters/SpacesRequired';
import StarSitterFilter from '@rover/react-lib/src/pages/search/SearchPage/components/Filters/StarSitterFilter';
import {
  advancedFiltersCounter,
  shouldShowControls,
} from '@rover/react-lib/src/pages/search/SearchPage/utilities';
import { ApiFrontendConfigurationRetrieve200SettingsDogSizeBuckets } from '@rover/rsdk/src/apiClient/latest';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import PetSize from '@rover/shared/js/search/components/PetSize';
import getIsCatOnlyPetType from '@rover/shared/js/search/utilities/getIsCatOnlyPetType';
import type { SearchFilters, SearchFiltersOptions, ServiceType } from '@rover/types';
import type { SearchDerivedData } from '@rover/types/src/apiResponses/FetchSearchResponse';
import { ServiceTypeSlugs } from '@rover/types/src/Service';

import { FilterWrapper } from '../common';
import DaycareTypeFilter from '../DaycareTypeFilter';
import { CatPreferences, DogPreferences } from '../Preferences';
import PuppyPreferences from '../Preferences/PuppyPreferences';

export type Props = {
  getServiceTypeBySlug: (arg0: string) => ServiceType | null | undefined;
  currencyCode: string;
  formData: Partial<SearchFilters>;
  getPriceUnit: (arg0: string) => string;
  hasPets: boolean;
  language: string;
  onChange: (arg0: Partial<SearchFilters>) => void;
  petSizeBuckets: ApiFrontendConfigurationRetrieve200SettingsDogSizeBuckets;
  searchFilters: SearchFilters;
  serviceTypeFilterOptions: SearchFiltersOptions;
  weightUnit: string;
  priceFilterMaxValue: number;
  priceFilterMinValue: number;
  queryDerivedData: SearchDerivedData;
  isInGingrFacilitiesInSearchExperiment: boolean;
  isDaycareFilterOutsideMarketsInExperiment: boolean;
};

const AdvancedWrapper = styled(FilterWrapper)`
  margin-left: -${Spacing.M.toString()};
  margin-right: -${Spacing.M.toString()};
  margin-bottom: 0;
  padding: ${Spacing.M.toString()};
  padding-bottom: ${Spacing.XXL.toString()};
  background-color: ${DSTokenMap.BACKGROUND_COLOR_SECONDARY.toString()};

  ${MQ.XS_DOWN.toString()} {
    margin-bottom: 0;
  }
`;

const AdvancedFiltersCollectionV2 = ({
  currencyCode,
  formData,
  getPriceUnit,
  hasPets,
  language,
  onChange,
  petSizeBuckets,
  searchFilters,
  serviceTypeFilterOptions,
  weightUnit,
  priceFilterMaxValue,
  priceFilterMinValue,
  getServiceTypeBySlug,
  isInGingrFacilitiesInSearchExperiment,
  isDaycareFilterOutsideMarketsInExperiment,
  queryDerivedData,
}: Props): JSX.Element => {
  const { i18n } = useI18n();
  const serviceType = getServiceTypeBySlug(searchFilters.serviceType);
  const { starSitterLink } = queryDerivedData;
  const {
    showCatPreferences,
    showDaycareType,
    showDogPreferences,
    showPetSize,
    showPrice,
    showPuppyPreferences,
  } = shouldShowControls({
    hasPets,
    isCatOnly: getIsCatOnlyPetType(formData.petType || []),
    serviceOptions: serviceTypeFilterOptions,
    serviceTypeSlug: searchFilters.serviceType,
    isInGingrFacilitiesInSearchExperiment,
    isDaycareFilterOutsideMarketsInExperiment,
  });

  /* The spaces_required query parameter depends on how many walks/visits OR how many pets you have. For the new design
   * we will handle number of pets separately but still need to check number of walks and visits. */
  const showSpacesRequired =
    serviceType &&
    (serviceType.slug === ServiceTypeSlugs.dogWalking ||
      serviceType.slug === ServiceTypeSlugs.dropIn);

  return (
    <>
      {showPetSize && (
        <FilterWrapper>
          <PetSize
            label={i18n._(t`What size are your dogs (${weightUnit})?`)}
            onChange={(sizeKey) => {
              onChange({ [sizeKey]: !formData[sizeKey] });
            }}
            petSizeBuckets={petSizeBuckets}
            searchFilters={formData}
            weightUnit={weightUnit}
          />
        </FilterWrapper>
      )}
      {showSpacesRequired && (
        <FilterWrapper>
          <SpacesRequired
            searchFilters={formData}
            onChange={(spacesRequired) => onChange({ spacesRequired })}
          />
        </FilterWrapper>
      )}
      {showPuppyPreferences && (
        <FilterWrapper>
          <PuppyPreferences
            displayAsToggle
            onChange={(puppy) => onChange({ puppy })}
            value={formData.puppy}
          />
        </FilterWrapper>
      )}

      {showCatPreferences && (
        <FilterWrapper>
          <CatPreferences
            displayAsToggle
            onChange={(noCats) => onChange({ noCats })}
            value={formData.noCats}
          />
        </FilterWrapper>
      )}

      {showDogPreferences && (
        <FilterWrapper>
          <DogPreferences
            displayAsToggle
            onChange={(personDoesNotHaveDogs) => onChange({ personDoesNotHaveDogs })}
            value={formData.personDoesNotHaveDogs}
          />
        </FilterWrapper>
      )}

      {!!(showPrice && formData.serviceType && formData.minprice) && (
        <FilterWrapper>
          <PriceFilter
            currencyCode={currencyCode}
            label={i18n._(
              /* i18n: Rate per {priceUnit} e.g. day, night, walk */
              t`Rate per ${getPriceUnit(formData.serviceType)}`
            )}
            language={language}
            onChange={({ min, max }) => {
              onChange({ minprice: min, maxprice: max });
            }}
            selectedRangeMin={formData.minprice}
            selectedRangeMax={formData.maxprice || priceFilterMaxValue}
            sliderMax={priceFilterMaxValue}
            sliderMin={priceFilterMinValue}
          />
        </FilterWrapper>
      )}
      {showDaycareType && (
        <FilterWrapper>
          <DaycareTypeFilter
            onChange={(inSittersHome, atDaycareFacility) => {
              onChange({ inSittersHome, atDaycareFacility });
            }}
            inSittersHome={searchFilters.inSittersHome}
            atDaycareFacility={searchFilters.atDaycareFacility}
            placement="search-sidebar"
          />
        </FilterWrapper>
      )}
      <Box>
        <FilterWrapper>
          <StarSitterFilter
            label={i18n._('Only show star sitters')}
            onChange={(starSitter) => onChange({ starSitter })}
            value={formData.starSitter || false}
            starSitterLink={starSitterLink}
          />
        </FilterWrapper>
      </Box>
      <AdvancedWrapper>
        <Advanced
          advancedFiltersCount={advancedFiltersCounter(formData, serviceTypeFilterOptions)}
          onChange={(filter) => onChange(filter)}
          searchFiltersOptions={serviceTypeFilterOptions}
          searchFilters={formData}
        />
      </AdvancedWrapper>
    </>
  );
};

export default AdvancedFiltersCollectionV2;
