import type { ReactElement, ReactNode } from 'react';
import React from 'react';
import styled from 'styled-components';

import Popover, { PopoverPlacement } from '../Popover';

export type Props = {
  id?: string;
  text: string;
  children?: ReactNode;
  placement?: PopoverPlacement;
  display?: 'inline' | 'inline-block' | 'block';
  toggleOnFocusOrBlur?: boolean;
};
export const StyledPopover = styled(Popover)<Record<string, unknown>>`
  max-width: 276px;
`;

const Tooltip = ({ id, text, children, placement, ...other }: Props): ReactElement => (
  <StyledPopover id={id} role="tooltip" placement={placement} content={text} {...other}>
    {children}
  </StyledPopover>
);

Tooltip.defaultProps = {
  id: null,
  placement: Popover.Placement.TOP,
  display: undefined,
};
export default Tooltip;
