import React from 'react';

import { useI18n } from '@rover/rsdk/src/modules/I18n';

import ErrorMessage from './ErrorMessage';

const SearchError = (): JSX.Element => {
  const { i18n } = useI18n();

  return (
    <ErrorMessage
      title={i18n._("Whoops! We couldn't perform your search.")}
      subtitle={i18n._('Make sure the information you entered is correct and try again.')}
    />
  );
};

export default SearchError;
