import React from 'react';

import { Connect } from '@rover/react-lib/src/components/utils/Redux';
import { selectors as searchSelectors } from '@rover/react-lib/src/redux/ducks/search.duck';

import StickySidebar from './StickySidebar';

type Props = {
  onScrollableFilterChange: () => void;
};

const selector = (state) => ({
  searchResults: searchSelectors.getSearchResults(state),
});

const StickySidebarContainer = (props: Props): JSX.Element => (
  <Connect selector={selector}>
    {(connectedProps) => <StickySidebar {...connectedProps} {...props} />}
  </Connect>
);

export default StickySidebarContainer;
