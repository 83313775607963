import React from 'react';

import SearchResultCard, {
  Props as SearchResultCardProps,
} from '@rover/react-lib/src/components/cards/SearchResultCard/SearchResultCard';
import { emitAnalyticsEvent } from '@rover/rsdk/src/modules/Analytics';

import { SelectedProviderSearchResultClick } from './analyticsEvents';

export type Props = SearchResultCardProps & {
  startDate?: Date;
  endDate?: Date;
  dates?: string;
  serviceType: string;
};

const SelectedProvider = ({
  searchResult,
  language,
  isPriceTransparency,
  ...searchResultCardProps
}: Props): JSX.Element => {
  const { isBestMatch, maybeBestMatch } = searchResult;

  const onClick = (entityOpk, isFacility): void => {
    if (!isPriceTransparency) {
      emitAnalyticsEvent(
        new SelectedProviderSearchResultClick({
          providerOpk: entityOpk,
          isFacility,
        })
      );
    }

    searchResultCardProps.onClick?.(
      entityOpk,
      isFacility,
      !!isBestMatch,
      !!maybeBestMatch,
      isPriceTransparency
    );
  };

  return (
    <SearchResultCard
      searchResult={searchResult}
      language={language}
      {...searchResultCardProps}
      onClick={onClick}
      isPriceTransparency={isPriceTransparency}
      isSelectedProviderResult
    />
  );
};

export default SelectedProvider;
