import React from 'react';
import styled from 'styled-components';

import { Heading, Paragraph } from '@rover/kibble/core';
import { MQ, Spacing } from '@rover/kibble/styles';
import {
  Alignment,
  HorizontalLayout,
  VerticalLayout,
} from '@rover/react-lib/src/components/layouts';
import AnimatedSpinner from '@rover/react-lib/src/components/typography/AnimatedSpinner';

const StyledLayout = styled(VerticalLayout)`
  min-height: 336px;
  padding: 20px;
  width: 100%;
  justify-content: center;
  text-align: center;

  ${MQ.XS_DOWN.toString()} {
    min-height: 200px;
  }
`;
const StyledAnimatedSpinner = styled(AnimatedSpinner)`
  margin-right: ${Spacing.S.toString()};
`;
export type Props = {
  title: string;
  subtitle?: string;
  showSpinner?: boolean;
};

const ErrorMessage = ({ title, subtitle, showSpinner = false }: Props): JSX.Element => (
  <StyledLayout hAlign={Alignment.CENTER}>
    <HorizontalLayout hAlign={Alignment.CENTER} vAlign={Alignment.CENTER}>
      {showSpinner && <StyledAnimatedSpinner diameter={20} />}
      <Heading size="300" as="div">
        {title}
      </Heading>
    </HorizontalLayout>
    {subtitle && (
      <Paragraph textAlign="center" mt="0x" textColor="secondary">
        {subtitle}
      </Paragraph>
    )}
  </StyledLayout>
);

export default ErrorMessage;
