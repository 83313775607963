import React from 'react';
import { t } from '@lingui/macro';

import { Search } from '@rover/icons';
import SearchResultCard from '@rover/react-lib/src/components/cards/SearchResultCard';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import type { SearchResult } from '@rover/types';

import SearchSectionHeader from '../SearchSectionHeader';

type Props = {
  displayExpanded: boolean;
  isAuthenticated: boolean | null | undefined;
  language: string;
  memberProfileLinkParams: Record<string, string | undefined>;
  onFavoriteToggled: (personOpk: string) => void;
  searchResults: SearchResult[];
  serviceName: string;
  serviceDescription: string;
  isSeoPage: boolean;
  preferCatReviews: boolean;
  isPriceTransparency?: boolean;
};

export default ({
  displayExpanded,
  isAuthenticated,
  language,
  memberProfileLinkParams,
  onFavoriteToggled,
  searchResults,
  serviceName,
  serviceDescription,
  isSeoPage,
  preferCatReviews,
  isPriceTransparency,
}: Props): JSX.Element => {
  const { i18n } = useI18n();

  return (
    <>
      <SearchSectionHeader
        headerIcon={Search}
        headerText={i18n._(
          /* i18n: {serviceName} e.g. Drop-in Visits */
          t`Sitters who offer ${serviceName}`
        )}
        primarySubheaderText={i18n._(serviceDescription)}
      />
      {searchResults.map((searchResult: SearchResult, i: number) => (
        <SearchResultCard
          displayExpanded={displayExpanded}
          index={i}
          isAuthenticated={isAuthenticated}
          key={`alternate-${
            searchResult.isFacility ? searchResult.facilityOpk : searchResult.personOpk
          }`}
          language={language}
          memberProfileLinkParams={memberProfileLinkParams}
          onFavoriteToggled={() => onFavoriteToggled(searchResult.personOpk)}
          searchResult={searchResult}
          isSeoPage={isSeoPage}
          preferCatReviews={preferCatReviews}
          isPriceTransparency={isPriceTransparency}
          fromAlternateSearchResults
        />
      ))}
    </>
  );
};
