import React, { useCallback, useId, useMemo } from 'react';
import { FieldError } from 'react-hook-form';
import { t } from '@lingui/macro';
import z from 'zod';

import { Box } from '@rover/kibble/core';
import { useKibbleForm } from '@rover/kibble/forms';
import RadioField from '@rover/kibble/forms/fields/RadioField';
import {
  RadioFieldOptionConfig,
  RadioFieldVariants,
} from '@rover/kibble/forms/fields/RadioField/RadioField.common';
import { emitAnalyticsEvent } from '@rover/rsdk/src/modules/Analytics';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import { SearchPageBoardingTypeFilterSelect } from '@rover/shared/js/search/utilities/analyticsEvents';

type Props = {
  atDaycareFacility?: boolean;
  inSittersHome?: boolean;
  required?: boolean;
  error?: boolean;
  defaultSetting?: boolean;
  onChange: (arg0: boolean, arg1: boolean) => void;
  layoutVariant?: RadioFieldVariants;
  placement?: string;
};

export type BoardingTypeFilterValues = 'sitter' | 'facility' | 'sitter,facility' | undefined;

const radioOptions: RadioFieldOptionConfig[] = [
  {
    value: 'sitter',
    primaryLabel: t`In a sitter's home`,
  },
  {
    value: 'facility',
    primaryLabel: t`At a local facility`,
  },
  {
    value: 'sitter,facility',
    primaryLabel: t`All types`,
  },
];

export default function BoardingTypeFilter({
  atDaycareFacility,
  inSittersHome,
  required = false,
  defaultSetting = true,
  error = false,
  placement = undefined,
  onChange,
  layoutVariant = 'default',
}: Props): JSX.Element {
  const { i18n } = useI18n();

  // because we have multiple instances of this component on the page, we need to generate a unique schema key for each
  // otherwise react hook form will only update the last rendered instance
  const boardingTypeFilterId = useId();
  const uniqueSchemaKey = `boardingTypeFilter-${boardingTypeFilterId}`;
  const schema = z.object({
    [uniqueSchemaKey]: z.optional(z.enum(['sitter,facility', 'sitter', 'facility'])),
  });

  const fieldState = useMemo<BoardingTypeFilterValues>(() => {
    if (atDaycareFacility && inSittersHome) {
      return 'sitter,facility';
    }
    if (atDaycareFacility) {
      return 'facility';
    }
    if (inSittersHome) {
      return 'sitter';
    }
    return undefined;
  }, [atDaycareFacility, inSittersHome]);

  const values = defaultSetting
    ? {
        [uniqueSchemaKey]: fieldState,
      }
    : undefined;

  const { configureField } = useKibbleForm({
    schema,
    values, // we use the values option to enable controlling the field state via props
  });

  const handleChange = useCallback<(changeEvent: { value: string | number }) => void>(
    ({ value }) => {
      if (typeof value !== 'string') return; // this is just a type regfinement it should never happen since we only pass strings

      // update parent state
      if (value === 'facility') {
        onChange(false, true); // order here is sitter, facility
      } else if (value === 'sitter') {
        onChange(true, false);
      } else if (value === 'sitter,facility') {
        onChange(true, true);
      }

      emitAnalyticsEvent(
        new SearchPageBoardingTypeFilterSelect({
          boardingType: value,
          placement,
        })
      );
    },
    [onChange, placement]
  );

  const errorMessage: FieldError = {
    type: 'required',
    message: 'Please choose a boarding option', // translation for this is in the fieldErrorTranslationMap
  };

  return (
    <Box m="0x" p="0x" border="none" data-testid="boarding-type-filter">
      <RadioField
        {...configureField(uniqueSchemaKey)}
        name="boarding-type"
        required={required}
        primaryLabel={
          required ? i18n._('Show boarding options (required):') : i18n._('Show boarding options:')
        }
        options={radioOptions}
        onChange={handleChange}
        fieldError={error ? errorMessage : undefined}
        variant={layoutVariant}
      />
    </Box>
  );
}
