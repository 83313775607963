import { A11yHiddenBox } from '@rover/kibble/styles';

import MicrodataHTMLAttributes from './MicrodataHTMLAttributes';
import schema from './schema';

type Props = {
  name: string;
  description: string;
} & MicrodataHTMLAttributes;

const EducationalOccupationalCredential: React.FC<Props> = ({
  name,
  description,
  ...props
}: Props) => (
  <A11yHiddenBox {...props} itemScope itemType={schema`EducationalOccupationalCredential`}>
    <meta itemProp="name" content={name} />
    <meta itemProp="description" content={description} />
  </A11yHiddenBox>
);

export default EducationalOccupationalCredential;
