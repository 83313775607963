import moment from 'moment';

import { petNameSyndeticJoin } from '@rover/react-lib/src/utils/pets';
import { OptimizedSearchResult } from '@rover/rsdk/src/apiClient/latest';
import { I18nType } from '@rover/rsdk/src/modules/I18n';
import getDateTimeFormatMapForLang from '@rover/shared/js/constants/i18n/datetime';
import { ServiceType } from '@rover/shared/js/constants/service';

import buildBestMatchesHeader from './buildBestMatchesHeader';
import buildMainSearchResultsHeader from './buildMainSearchResultsHeader';
import buildSelectedProviderHeader from './buildSelectedProviderHeader';
import { HeaderScenariosEnum } from './constants';
import { DateData, PrebuiltHeaderProps } from './types';
import { getFormattedPetData } from './utils';

export type BuilderProps = {
  i18n: I18nType;
  language: string;
  isInitialPage: boolean;
  isPriceTransparency?: boolean;
  isBestMatchExperience?: boolean;
  isBestMatchesSearch?: boolean;
  dates?: Record<'startDate' | 'endDate', Date | undefined>;
  selectedProvider?: OptimizedSearchResult;
  hasBestMatches?: boolean;
  hasResults?: boolean;
  petData: {
    dogCount?: number;
    catCount?: number;
    puppyCount?: number;
    petCount?: number;
  };
  serviceType: ServiceType;
  spacesRequired?: number;
  isSeoPage?: boolean;
};

/**
 * Given a start and end date, returns dates formatted for display in the header
 * as well as whether or not they're the same date.
 */
const getFormattedDates = (
  language: string,
  startDate: Date | undefined,
  endDate: Date | undefined
): DateData => {
  const DF = getDateTimeFormatMapForLang(language);
  // If we are missing any dates, we'll prompt the user to add them.
  if (!startDate || !endDate) {
    return { isSameDate: false };
  }

  const sameDate = startDate?.getTime() === endDate?.getTime();
  const sameYear = startDate?.getFullYear() === endDate?.getFullYear();
  const format = sameYear ? DF.MONTH_DAY_MED : DF.DATE_MED;

  return {
    isSameDate: sameDate,
    startDate: moment(startDate).format(format),
    endDate: moment(endDate).format(format),
  };
};

const searchHeaderBuilder = ({
  i18n,
  dates,
  hasBestMatches,
  hasResults,
  isBestMatchExperience,
  isBestMatchesSearch,
  isInitialPage,
  isPriceTransparency,
  language,
  petData,
  selectedProvider,
  serviceType,
  // spacesRequired is used by unauthenticated mobile web users instead of pet counts
  spacesRequired = 0,
  isSeoPage = false,
}: BuilderProps): Record<string, PrebuiltHeaderProps> => {
  const headers = {};

  const { startDate, endDate } = dates || {};

  const processedDates = getFormattedDates(language, startDate, endDate);
  const processedPetData = getFormattedPetData(petData).map((descriptor) => i18n._(descriptor));
  const hasPets = Object.values(petData).some((count) => count > 0) || spacesRequired > 0;
  const individualPetTypeCountDescription = petNameSyndeticJoin(processedPetData, i18n);

  if (selectedProvider) {
    headers[HeaderScenariosEnum.SELECTED_SITTER] = buildSelectedProviderHeader({
      dateData: processedDates,
      individualPetTypeCountDescription,
      isBestMatchesSearch,
      isPriceTransparency,
      selectedProvider,
      serviceType,
    });
  }

  if (isBestMatchesSearch && hasBestMatches) {
    headers[HeaderScenariosEnum.BEST_MATCHES] = buildBestMatchesHeader({
      dateData: processedDates,
      individualPetTypeCountDescription,
      isPriceTransparency,
      serviceType,
    });
  }

  if (hasResults) {
    headers[HeaderScenariosEnum.SEARCH_RESULTS] = buildMainSearchResultsHeader({
      hasBestMatches,
      isBestMatchExperience,
      isBestMatchesSearch,
      isInitialPage,
      isPriceTransparency,
      individualPetTypeCountDescription,
      selectedProvider,
      serviceType,
      dateData: processedDates,
      hasPets,
      isSeoPage,
    });
  }

  return headers;
};

export default searchHeaderBuilder;
