// Used for date fields that denote the start or end dates
export type DateRangeField = Date | undefined | null;

export interface DateRange {
  startDate: DateRangeField;
  endDate: DateRangeField;
}

export enum DateRangeEnum {
  START_DATE = 'startDate',
  END_DATE = 'endDate',
}

export interface UpdatedDateRange extends DateRange {
  dateChanged: DateRangeEnum;
}

// Used for displaying validation error messages
export type DateRangeValidationError = {
  startDate?: string;
  endDate?: string;
};
