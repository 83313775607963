/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import { t, Trans } from '@lingui/macro';
import styled from 'styled-components';

import { CarouselRightSmall } from '@rover/icons';
import { DSTokenMap } from '@rover/kibble/styles';
import events from '@rover/react-lib/src/pages/search/SearchPage/analytics';
import { getWizardTitle } from '@rover/react-lib/src/pages/search/SearchPage/utilities';
import { I18nType, useI18n } from '@rover/rsdk/src/modules/I18n';
import { SEARCH_SOURCE } from '@rover/shared/js/constants/searchPage.constants';
import { SERVICE_TYPE_CHOICES } from '@rover/shared/js/constants/service';
import type { DataLayerEvent, SearchFilters, ServiceType } from '@rover/types';
import Enum from '@rover/types/src/Enum';

import { PaginatedWizardFiltersCollection } from '../../Filters/Collections';

import StyledDialogModal from './StyledDialogModal';

export class WizardPage extends Enum<string> {
  static ONE = new WizardPage('one');

  static TWO = new WizardPage('two');
}
type Props = {
  i18n: I18nType;
  fireAnalyticsEvent: (
    payload: DataLayerEvent | ((filters: Partial<SearchFilters>) => DataLayerEvent)
  ) => void;
  getServiceTypeBySlug: (arg0: string) => ServiceType | null | undefined;
  hasPets: boolean;
  isCatOnly: boolean;
  isOpen: boolean;
  disablePortal?: boolean;
  onClose: () => void;
  searchFilters: SearchFilters;
  updateFiltersAndFireSearchOnChange: (arg0: Record<string, unknown>) => void;
  setShowStaticMap: (arg0: boolean) => void;
  setShowDynamicMap: (arg0: boolean) => void;
  className?: string;
};
type State = {
  visiblePage: WizardPage;
  didUserCloseModal: boolean;
};

const NextArrow = styled(CarouselRightSmall)`
  fill: white;
  height: 24px;
  left: ${DSTokenMap.SPACE_2X};
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 24px;
`;

const NextButtonContent = (): JSX.Element => (
  <>
    <Trans>Next</Trans>
    <NextArrow />
  </>
);

export class PaginatedWizardModal extends Component<Props, State> {
  modalRef: HTMLDivElement | null | undefined;

  state = {
    visiblePage: WizardPage.ONE,
    didUserCloseModal: false,
  };

  componentDidUpdate(): void {
    const { setShowStaticMap, setShowDynamicMap } = this.props;
    if (
      this.state.didUserCloseModal // check the user closed it
    ) {
      setShowDynamicMap(true);
      setShowStaticMap(false);
    }
  }

  closeModal = (): void => {
    const { fireAnalyticsEvent, onClose } = this.props;
    fireAnalyticsEvent(events.SearchOwnerPreferencesCloseEvent);
    this.setState({
      didUserCloseModal: true,
    });
    onClose();
  };

  showNextPage = (): void => {
    this.setState({
      visiblePage: WizardPage.TWO,
    });
    if (this.modalRef) this.modalRef.scrollTop = 0;
  };

  handleSubmit = (): void => {
    const { updateFiltersAndFireSearchOnChange, searchFilters, onClose, fireAnalyticsEvent } =
      this.props;
    fireAnalyticsEvent(events.SearchOwnerPreferencesCloseEvent);
    onClose();
    updateFiltersAndFireSearchOnChange({
      ...searchFilters,
      changeSource: SEARCH_SOURCE.SEARCH_MODAL,
    });
  };

  get hasNextPage(): boolean {
    const { hasPets, isCatOnly, getServiceTypeBySlug, searchFilters } = this.props;
    const { visiblePage } = this.state;
    const serviceType = getServiceTypeBySlug(searchFilters.serviceType);
    const isOnDemand = serviceType && !serviceType.browsable;
    return !(hasPets || isCatOnly || isOnDemand) && visiblePage === WizardPage.ONE;
  }

  render(): JSX.Element {
    const { i18n, isCatOnly, searchFilters, isOpen, disablePortal, className } = this.props;
    const { visiblePage } = this.state;
    const isGrooming = searchFilters.serviceType === SERVICE_TYPE_CHOICES.assignmentGrooming;
    const searchText = isGrooming ? t`Request Grooming` : t`Search Now`;
    const onClickSubmit = this.hasNextPage ? this.showNextPage : this.handleSubmit;

    return (
      <StyledDialogModal
        disablePortal={disablePortal}
        headerText={i18n._(getWizardTitle(isCatOnly))}
        isOpen={isOpen}
        onClickCancel={null}
        onRequestClose={this.closeModal}
        overlayRef={(node) => (this.modalRef = node)}
        data={{
          'qa-id': SEARCH_SOURCE.SEARCH_MODAL,
          testId: SEARCH_SOURCE.SEARCH_MODAL,
        }}
        useSearchPerfHack
        className={className}
        onClickSubmit={onClickSubmit}
        submitButtonText={this.hasNextPage ? <NextButtonContent /> : i18n._(searchText)}
      >
        <PaginatedWizardFiltersCollection
          locationInputId="location-input-wizard-modal"
          visiblePage={visiblePage}
        />
      </StyledDialogModal>
    );
  }
}

const PaginatedWizardModalWithI18n = (props: Omit<Props, 'i18n'>): JSX.Element => {
  const { i18n } = useI18n();
  return <PaginatedWizardModal {...props} i18n={i18n} />;
};

export default PaginatedWizardModalWithI18n;
