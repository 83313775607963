import React, { FC, useId, useState } from 'react';
import { MessageDescriptor } from '@lingui/core';
import { Trans } from '@lingui/react';
import styled from 'styled-components';

import { CollapseSmall, ExpandSmall } from '@rover/icons';
import { Box, Button } from '@rover/kibble/core';
import { DSTokenMap, Spacing } from '@rover/kibble/styles';
import Fieldset from '@rover/react-lib/src/components/formFields/Fieldset';
import { useI18n } from '@rover/rsdk/src/modules/I18n';

import CheckBoxOption from './CheckBoxOption';

export type Checkbox = {
  attribute: string;
  label: string;
  tooltip: string;
  value?: string;
};

export type Category = {
  category: MessageDescriptor;
  rowsVisibleWhenCollapsed: number;
  checkboxes: Checkbox[];
};

export type Props = {
  category: Category;
  onChange: (updatedFilters: Record<string, any>, checkboxValue: any | null | undefined) => void;
  onExpandAndCollapse?: () => void;
  isChecked: (attribute: string, value: string | null | undefined) => boolean;
};

const StyledExpandSmall = styled(ExpandSmall)`
  fill: ${DSTokenMap.INTERACTIVE_TEXT_COLOR_LINK_PRIMARY.toString()};
  height: ${Spacing.L.toString()};
  vertical-align: middle;
`;

const StyledCollapseSmall = styled(CollapseSmall)`
  fill: ${DSTokenMap.INTERACTIVE_TEXT_COLOR_LINK_PRIMARY.toString()};
  height: ${Spacing.L.toString()};
  vertical-align: middle;
`;

const CheckBoxCategory: FC<Props> = ({
  onExpandAndCollapse = (): void => {},
  isChecked,
  onChange,
  category: { category, checkboxes, rowsVisibleWhenCollapsed },
}) => {
  const checkboxCategoryId = useId();
  const [collapsed, setCollapsed] = useState(true);
  const { i18n } = useI18n();

  const expand = (): void => {
    setCollapsed(false);
    onExpandAndCollapse();
  };

  const collapse = (): void => {
    setCollapsed(true);
    onExpandAndCollapse();
  };

  const maxLength = collapsed ? rowsVisibleWhenCollapsed : checkboxes.length;

  return (
    <Fieldset aria-labelledby={checkboxCategoryId}>
      <Box
        id={checkboxCategoryId}
        marginTop="4x"
        marginBottom="2x"
        paddingBottom="1x"
        borderBottom={`${DSTokenMap.BORDER_WIDTH_SECONDARY.toString()} solid ${DSTokenMap.BORDER_COLOR_PRIMARY.toString()}`}
        sx={{ fontWeight: 'semibold' }}
      >
        <Trans id={category} />
      </Box>
      {checkboxes.slice(0, maxLength).map(({ attribute, label, tooltip, value }) => (
        <CheckBoxOption
          key={`${attribute}${value ? `-${value}` : ''}`}
          checked={isChecked(attribute, value)}
          onChange={(checked, checkboxValue) =>
            onChange(
              {
                [attribute]: checked,
              },
              checkboxValue
            )
          }
          label={label}
          tooltip={tooltip}
          value={value}
        />
      ))}
      {rowsVisibleWhenCollapsed < checkboxes.length && (
        <Button
          variant="flat"
          size="small"
          onClick={collapsed ? expand : collapse}
          aria-expanded={!collapsed}
          aria-controls={checkboxCategoryId}
          padding="0x"
        >
          {collapsed ? i18n._('More') : i18n._('Less')}
          {collapsed ? <StyledExpandSmall /> : <StyledCollapseSmall />}
        </Button>
      )}
    </Fieldset>
  );
};

export default CheckBoxCategory;
