import type { ReactNode } from 'react';
import React from 'react';
import styled from 'styled-components';

import { DSTokenMap, MQ } from '@rover/kibble/styles';
import ErrorBoundary from '@rover/react-lib/src/components/errorHandling/ErrorBoundary';
import { Alignment, HorizontalLayout } from '@rover/react-lib/src/components/layouts';
import { LAYOUT } from '@rover/shared/js/constants/searchPage.constants';

import { StickyMap, StickySidebar } from '..';

const StyledHorizontalLayout = styled(HorizontalLayout)`
  position: relative;

  ${MQ.SM_UP.toString()} {
    min-height: 100vh;
    padding-top: ${LAYOUT.GUTTER}px;

    background-color: ${DSTokenMap.BACKGROUND_COLOR_SECONDARY.toString()};
  }
`;

type Props = {
  children: ReactNode;
  onMarkerClick: (index: number) => void;
  onScrollableFilterChange: () => void;
};

const SearchPageLayout = ({
  children,
  onMarkerClick,
  onScrollableFilterChange,
}: Props): JSX.Element => (
  <StyledHorizontalLayout noWrap hAlign={Alignment.START} vAlign={Alignment.START}>
    <ErrorBoundary location="SearchPageLayoutSidebar">
      <StickySidebar onScrollableFilterChange={onScrollableFilterChange} />
    </ErrorBoundary>

    <ErrorBoundary location="SearchPageLayoutChildren">{children}</ErrorBoundary>

    <ErrorBoundary location="SearchPageLayoutMap">
      <StickyMap
        onMarkerClick={onMarkerClick}
        onScrollableFilterChange={onScrollableFilterChange}
      />
    </ErrorBoundary>
  </StyledHorizontalLayout>
);

export default SearchPageLayout;
