import React, { FC, useCallback, useId } from 'react';

import { Box, Button, Flex, Heading } from '@rover/kibble/core';
import Modal from '@rover/kibble/official-patterns/Modal';
import { useI18n } from '@rover/rsdk/src/modules/I18n';

import PetCounterBody, { PetCounterBodyProps } from './PetCounterBody';

export interface PetCounterDesktopProps extends PetCounterBodyProps {
  isModalOpen: boolean;
  handlePetCounterChange: () => void;
  closeModal: () => void;
  titleText: string;
  subtitleText: string;
  saveButtonText: string;
}

const PetCounterDesktop: FC<PetCounterDesktopProps> = ({
  isModalOpen,
  dogs,
  cats,
  puppies,
  availablePets,
  triggeredFrom,
  setDogs,
  setPuppies,
  setCats,
  handlePetCounterChange,
  closeModal,
  titleText,
  subtitleText,
  saveButtonText,
}) => {
  const headingId = useId();
  const { i18n } = useI18n();

  const onConfirm = useCallback(() => {
    handlePetCounterChange();
    closeModal();
  }, [handlePetCounterChange, closeModal]);

  return (
    <Modal
      headerText={i18n._(titleText)}
      headerSize="400"
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      aria-labelledby={headingId}
      showCloseIcon
    >
      <Flex minWidth={540} flexDirection="column" paddingBottom="6x">
        <Heading size="100" fontWeight="regular" textColor="secondary" id={headingId} mb="4x">
          {i18n._(subtitleText)}
        </Heading>
        <PetCounterBody
          dogs={dogs}
          cats={cats}
          puppies={puppies}
          availablePets={availablePets}
          triggeredFrom={triggeredFrom}
          setDogs={setDogs}
          setPuppies={setPuppies}
          setCats={setCats}
        />
        <Box alignSelf="flex-end" mt="6x">
          <Button
            data-testid="save-pets-button"
            variant="primary"
            onClick={onConfirm}
            disabled={dogs === 0 && cats === 0 && puppies === 0}
          >
            {i18n._(saveButtonText)}
          </Button>
        </Box>
      </Flex>
    </Modal>
  );
};

export default PetCounterDesktop;
