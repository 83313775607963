import React, { ReactNode } from 'react';

import { Box } from '@rover/kibble/core';

export type Props = {
  children: ReactNode;
};

const CollectionWrapper = (props: Props): JSX.Element => <Box p="4x" {...props} />;

export default CollectionWrapper;
