/* eslint-disable max-classes-per-file */
/* eslint-disable no-useless-constructor */
import { CONCEPTS } from '@rover/rsdk/src/concepts';
import type { ConsentPurpose } from '@rover/rsdk/src/modules/Analytics';
import { AnalyticsEvent } from '@rover/types/src/Analytics';

export type TriggeredFrom = 'PreSearch' | 'Homepage' | 'Search';

/**
 * &nbsp;
 *
 * # Description
 *
 * Fires when user clicks on the PetCounter when it is used in SearchPage
 *
 * &nbsp;
 */
export class PetCountInputOpenEvent extends AnalyticsEvent {
  eventName = 'petcounter-input-open';

  consentPurpose: ConsentPurpose = 'functional';

  concept = CONCEPTS.recommendations.browse_search;
}
/**
 * &nbsp;
 *
 * # Description
 *
 * Fires when user increments the petCounter when it is used in SearchPage
 *
 * &nbsp;
 */
export class PetCountAddedEvent extends AnalyticsEvent {
  eventName = 'petcounter-pet-added';

  consentPurpose: ConsentPurpose = 'functional';

  concept = CONCEPTS.recommendations.browse_search;
}
/**
 * &nbsp;
 *
 * # Description
 *
 * Fires when user decrements the petCounter when it is used in SearchPage
 *
 * &nbsp;
 */
export class PetCountRemovedEvent extends AnalyticsEvent {
  eventName = 'petcounter-pet-removed';

  consentPurpose: ConsentPurpose = 'functional';

  concept = CONCEPTS.recommendations.browse_search;
}
/**
 * &nbsp;
 *
 * # Description
 *
 * Fires when user closes the Modal/BottomSheet ot the petCounter when it is used in SearchPage
 *
 * &nbsp;
 */
export class PetCountInputCloseEvent extends AnalyticsEvent {
  eventName = 'petcounter-input-close';

  consentPurpose: ConsentPurpose = 'functional';

  concept = CONCEPTS.recommendations.browse_search;
}
