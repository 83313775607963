import styled from 'styled-components';

import { DSTokenMap } from '@rover/kibble/styles';
import Stepper from '@rover/react-lib/src/components/formFields/Stepper';

export const mobileStyle = { height: '100%' };
export const desktopStyle = {
  border: `${DSTokenMap.BORDER_WIDTH_PRIMARY.toString()} solid ${DSTokenMap.BORDER_COLOR_PRIMARY.toString()}`,
  borderRadius: DSTokenMap.BORDER_RADIUS_SECONDARY.toString(),
  padding: DSTokenMap.SPACE_5X.toString(),
  paddingTop: DSTokenMap.SPACE_6X.toString(),
};

export const StyledStepper = styled(Stepper)`
  input {
    font-weight: 600;
    font-size: ${DSTokenMap.TEXT_200_FONT_SIZE.toString()};
    border: 0px !important;
  }
  button[disabled] {
    background-color: ${DSTokenMap.BORDER_COLOR_PRIMARY.toString()};
    border: ${DSTokenMap.BORDER_WIDTH_PRIMARY.toString()} solid
      ${DSTokenMap.BORDER_COLOR_PRIMARY.toString()};
  }
  button {
    color: ${DSTokenMap.INTERACTIVE_BACKGROUND_COLOR_PRIMARY.toString()};
    background-color: ${DSTokenMap.INTERACTIVE_TEXT_COLOR_PRIMARY.toString()};
    border: ${DSTokenMap.BORDER_WIDTH_PRIMARY.toString()} solid
      ${DSTokenMap.INTERACTIVE_TEXT_COLOR_PRIMARY.toString()};
    box-shadow: none;
  }
`;
