import React from 'react';

export type Props = {
  checked: boolean;
  disabled: boolean;
  onChange: (isChecked: boolean, value?: any | null | undefined) => void;
  id?: string;
  value?: string;
};

const Checkbox = ({ onChange, value, ...other }: Props): JSX.Element => (
  <input
    {...other}
    type="checkbox"
    onChange={(e) => {
      if (typeof value !== 'undefined') {
        onChange(e.target.checked, value);
      } else {
        onChange(e.target.checked);
      }
    }}
  />
);

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
  id: undefined,
  value: undefined,
};
export default Checkbox;
