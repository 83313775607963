import React, { useState } from 'react';
import { Trans } from '@lingui/react';
import styled, { css } from 'styled-components';

import dataLayer from '@rover/globals/dataLayer';
import { ArrowLeft, ArrowRight, Rebook } from '@rover/icons';
import { Button, Flex, Heading } from '@rover/kibble/core';
import { DSTokenMap, TABLET_MAX, TABLET_MIN } from '@rover/kibble/styles';
import Checkbox from '@rover/react-lib/src/components/formFields/Checkbox';
import { Alignment, HorizontalLayout } from '@rover/react-lib/src/components/layouts';
import type { SearchFilters } from '@rover/types';
import { LAYOUT, MAP_SIZES } from '@rover/shared/js/constants/searchPage.constants';
import fireDataLayerEvent from '@rover/utilities/fireDataLayerEvent';

import SearchMap from '../SearchMap';

const DESKTOP = `@media(min-width:${TABLET_MAX + 1}px)`;
const TABLET = `@media (min-width: ${TABLET_MIN}px and max-width: ${TABLET_MAX}px)`;

const StyledStickyMap = styled(Flex)<{
  isExpanded: boolean;
}>`
  height: 100vh;
  display: none;

  ${DESKTOP.toString()} {
    display: flex;
  }

  /* Fixed position calculates total width based on the viewport, not on the inherited width from
  the parent. Any padding on the parent (SearchPageLayout) will result in a slightly different
  width calculation, so margins on its children are used instead. */
  margin-right: ${LAYOUT.GUTTER}px;

  ${({ isExpanded }) => {
    const WIDTH_PERCENT = isExpanded ? LAYOUT.WIDE_WIDTH_PERCENT : LAYOUT.NARROW_WIDTH_PERCENT;

    return css`
      width: calc(${LAYOUT.AVAILABLE_WIDTH} * ${WIDTH_PERCENT});
      ${TABLET} {
        width: calc(${LAYOUT.AVAILABLE_WIDTH} * ${LAYOUT.NARROW_WIDTH_PERCENT});
      }
    `;
  }};

  /* IE11 does not have support for '@supports', so it will use the above. The below will override the above if 'sticky' is supported. */
  @supports (position: sticky) {
    position: sticky;
    top: 0;
    right: 0;
  }

  background-color: ${DSTokenMap.BACKGROUND_COLOR_TERTIARY.toString()};
`;
const MapControls = styled(HorizontalLayout)`
  background-color: ${DSTokenMap.BACKGROUND_COLOR_PRIMARY.toString()};
`;

const CheckboxLabel = styled.label`
  display: flex;
  flex-wrap: nowrap;
  padding: ${DSTokenMap.SPACE_2X};
  cursor: pointer;

  color: ${DSTokenMap.INTERACTIVE_TEXT_COLOR_LINK_SECONDARY_PRESSED.toString()};
  font-size: 14px;
`;
const StyledCheckbox = styled(Checkbox)`
  margin: ${DSTokenMap.SPACE_1X} ${DSTokenMap.SPACE_2X} 0 0;
  cursor: pointer;
`;

type Props = {
  onMarkerClick: (index: number) => void;
  isExpanded: boolean;
  toggleIsExpanded: () => void;
  shouldSearchOnMove: boolean;
  toggleShouldSearchOnMove: () => void;
  fireSearch: (arg0: { searchInitiatedByMap: boolean }) => void;
  updateFilters: (searchFilters: Partial<SearchFilters>) => void;
  onScrollableFilterChange: () => void;
};

const StickyMap = ({
  onMarkerClick,
  isExpanded,
  toggleIsExpanded,
  toggleShouldSearchOnMove,
  shouldSearchOnMove,
  fireSearch,
  updateFilters,
  onScrollableFilterChange,
}: Props): JSX.Element => {
  const [mapHasMoved, setMapHasMoved] = useState(false);

  const onSearchableMapMove = (): void => {
    fireSearch({
      searchInitiatedByMap: true,
    });
    onScrollableFilterChange();
  };

  const handleOnMapChangeSearchToggle = (): void => {
    // Do not pass entire event as payload for action
    fireDataLayerEvent({
      event: 'search-map-update-when-i-move-map-checkbox',
      checked: !shouldSearchOnMove,
    });
    if (toggleShouldSearchOnMove) {
      toggleShouldSearchOnMove();
    }
  };

  const handleMapExpanderClick = (): void => {
    const nextIsExpanded = !isExpanded;
    fireDataLayerEvent({
      event: 'search-map-enlarge-or-shrink-map',
      action: nextIsExpanded ? 'enlarge' : 'shrink',
    });

    if (toggleIsExpanded) {
      toggleIsExpanded();
    }

    const newSize = nextIsExpanded ? MAP_SIZES.LARGE : MAP_SIZES.SMALL;
    dataLayer.push({
      event: 'search-map-size-changed',
      mapSize: newSize,
    });
  };

  const renderOnMoveUpdateControl = (): JSX.Element => {
    if (shouldSearchOnMove || !mapHasMoved) {
      return (
        <CheckboxLabel>
          <StyledCheckbox
            onChange={handleOnMapChangeSearchToggle}
            value="address"
            checked={shouldSearchOnMove}
          />
          <Trans>Update when I move the map</Trans>
        </CheckboxLabel>
      );
    }

    return (
      <Button
        variant="flat"
        size="small"
        icon={Rebook}
        sx={{
          whiteSpace: 'nowrap',
        }}
        onClick={() => {
          setMapHasMoved(false);
          fireSearch({
            searchInitiatedByMap: true,
          });
        }}
      >
        <Trans>Update search results</Trans>
      </Button>
    );
  };

  return (
    <StyledStickyMap
      flexDirection="column"
      flexWrap="nowrap"
      flex="none"
      isExpanded={isExpanded}
      data-qa-id="container-map-search"
    >
      <Heading as="h2" a11yHidden>
        Search Map
      </Heading>
      <MapControls noWrap vAlign={Alignment.CENTER} hAlign={Alignment.SPACE_BETWEEN}>
        <Button
          onClick={handleMapExpanderClick}
          variant="flat"
          size="small"
          aria-expanded={isExpanded}
          icon={isExpanded ? ArrowRight : ArrowLeft}
          sx={{
            whiteSpace: 'nowrap',
          }}
        >
          {isExpanded ? <Trans>Shrink map</Trans> : <Trans>Enlarge map</Trans>}
        </Button>
        <>{renderOnMoveUpdateControl()}</>
      </MapControls>
      <SearchMap
        onMarkerClick={onMarkerClick}
        onMapMoved={(mapMetadata) => {
          updateFilters(mapMetadata);

          if (shouldSearchOnMove) {
            onSearchableMapMove();
          } else {
            setMapHasMoved(true);
          }
        }}
        onZoomChanged={(mapMetadata) => {
          updateFilters(mapMetadata);
          shouldSearchOnMove &&
            fireSearch({
              searchInitiatedByMap: true,
            });
        }}
      />
    </StyledStickyMap>
  );
};

export default StickyMap;
