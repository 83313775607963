import React, { FC, useId, useState } from 'react';
import { DateUtils } from 'react-day-picker';
import { MessageDescriptor } from '@lingui/core';
import styled from 'styled-components';

import { A11yHidden, MQ } from '@rover/kibble/styles';
import { CalendarDirection } from '@rover/react-lib/src/components/datetime/DatePicker';
import DateRangePicker, {
  MobileDirection,
  MobileLabel,
} from '@rover/react-lib/src/components/datetime/DateRangePicker';
import FilterFieldset from '@rover/shared/js/search/components/FilterFieldset';
import FilterLegend from '@rover/shared/js/search/components/FilterLegend';
import {
  type UpdatedDateRange,
  DateRange,
  DateRangeEnum,
  DateRangeField,
} from '@rover/types/src/datetime/DateRange';

const StyledDateRangePicker = styled(DateRangePicker)`
  ${MQ.XS_DOWN.toString()} {
    ${MobileLabel} {
      ${A11yHidden};
    }
  }
`;

export type Props = {
  calendarDirection?: CalendarDirection;
  endPlaceholder?: string | MessageDescriptor;
  label: string;
  language: string;
  maxDate?: DateRangeField;
  onChange: (arg0: Partial<DateRange>) => void;
  showArrow?: boolean;
  searchFilters: Partial<DateRange>;
  startPlaceholder?: string | MessageDescriptor;
};

const OvernightDateRange: FC<Props> = ({
  calendarDirection = CalendarDirection.FLEX,
  maxDate = undefined,
  showArrow = false,
  onChange,
  searchFilters: { startDate: startDateProp, endDate: endDateProp },
  endPlaceholder,
  label,
  language,
  startPlaceholder,
}) => {
  const datePickerId = useId();
  const [startDateState, setStartDateState] = useState<Date>();
  const [endDateState, setEndDateState] = useState<Date>();
  const [blurTimeout, setBlurTimeout] = useState<ReturnType<typeof setTimeout> | undefined>(
    undefined
  );

  // We keep `startDate` and `endDate` in useStates because we need to delay calling `onChange`
  // to avoid triggering unnecessary new searches. However, `startDate` and `endDate` can be
  // changed in several different places on the search page, so we need to synchronize incoming props
  // to state to ensure each date range element on the page is up-to-date with the user's selected dates
  // this patterns is supposedly just like getDerivedStateFromProps
  if (!startDateState && startDateProp) {
    setStartDateState(startDateProp);
  }
  if (!endDateState && endDateProp) {
    setEndDateState(endDateProp);
  }

  const handleFocus = (): void => {
    // If a child receives focus, attempt to clear the blur timeout, cancelling any pending `onChange` calls.
    // this prevents us calling onChange in the case that focus is passing between the two date pickers
    clearTimeout(blurTimeout);
  };

  const handleBlur = (): void => {
    clearTimeout(blurTimeout);
    // Sometimes the user only changes `startDate` and leaves `endDate` unchanged,
    // but because we don't trigger a change until after the user changes `endDate`,
    // it's possible for us to not trigger a new search.
    // To work around this, we briefly wait after `onBlur`, in case the focus has
    // shifted to the `endDate` picker, and then trigger a change.
    setBlurTimeout(
      setTimeout(() => {
        // don't call if we are missing any date state
        if (!startDateState || !endDateState) return;
        // don't call if the date state is the same as props
        if (startDateState === startDateProp && endDateState === endDateProp) return;

        onChange({ startDate: startDateState, endDate: endDateState });
      })
    );
  };

  const handleChange = (dateRange: UpdatedDateRange): void => {
    // DateRangePicker does not send us previously-selected dates,
    // so any call to `onChange` will only have the date that was just selected.
    // We can't just spread `dateRange` and `state` together because the second
    // value will exist, even if it's undefined.
    // Instead, `||` the values and prefer the new values in `dateRange`.
    const mergedOnChangeStartDate = dateRange.startDate || startDateState;
    const mergedOnChangeEndDate = dateRange.endDate || endDateState;
    // If the user selects a valid range but then only changes the start date,
    // they can potentially select a start date after the initial end date.
    // We rely on the calendar not allowing picking a start date after the end date,
    // but the user can click outside the calendar to close it and the end date will remain the same.
    const invalidEndDate =
      mergedOnChangeStartDate &&
      mergedOnChangeEndDate &&
      DateUtils.isDayAfter(mergedOnChangeStartDate, mergedOnChangeEndDate);
    setStartDateState(mergedOnChangeStartDate);
    setEndDateState(invalidEndDate ? mergedOnChangeStartDate : mergedOnChangeEndDate);

    // We only trigger a change if the user just selected the end date, AND both dates are in state.
    // The DateRangePicker automatically opens the end date picker when
    // a user selects a start date, so we don't want to trigger a new
    // search if they're going to change the end date imminently.
    if (
      dateRange.dateChanged === DateRangeEnum.END_DATE &&
      mergedOnChangeStartDate &&
      mergedOnChangeEndDate
    ) {
      // When moving from startDate to endDate a handleBlur event is Qed and fired after onChange in handleChange is called
      // This causes an update with the previous endDate, and triggering a second search api call with an outdated endDate
      // Clearing it prevents this to happen
      clearTimeout(blurTimeout);
      onChange({ startDate: mergedOnChangeStartDate, endDate: mergedOnChangeEndDate });
    }
  };

  return (
    <FilterFieldset aria-labelledby={`${datePickerId}-legend`}>
      {label && <FilterLegend id={`${datePickerId}-legend`}>{label}</FilterLegend>}
      <StyledDateRangePicker
        data-testid="date-range-picker"
        allowKeyboardInput
        calendarDirection={calendarDirection}
        endDate={endDateState}
        endPlaceholder={endPlaceholder}
        id={datePickerId}
        language={language}
        maxDate={maxDate}
        mobileDirection={MobileDirection.ROW}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        showArrow={showArrow}
        startDate={startDateState}
        startPlaceholder={startPlaceholder}
      />
    </FilterFieldset>
  );
};

export default OvernightDateRange;
