import React from 'react';

import { AlertSuccess, Calendar } from '@rover/icons';
import { Flex, Text } from '@rover/kibble/core';
import { DSTokenMap } from '@rover/kibble/styles';

export type Props = {
  calendarRecency?: {
    description: string;
    icon: string;
  };
};

export const icons = {
  greenCheck: (
    <AlertSuccess
      width="16px"
      height="16px"
      fill={DSTokenMap.TEXT_COLOR_SUCCESS.toString()}
      color={DSTokenMap.TEXT_COLOR_SUCCESS.toString()}
    />
  ),
  calendarIcon: (
    <Calendar
      width="16px"
      height="16px"
      fill={DSTokenMap.TEXT_COLOR_CAUTION.toString()}
      color={DSTokenMap.TEXT_COLOR_CAUTION.toString()}
    />
  ),
};

const CalendarRecency: React.FC<Props> = ({ calendarRecency }) => {
  if (!calendarRecency) {
    return null;
  }

  const { icon, description } = calendarRecency;

  return (
    <Flex py="1x" pr="4x" gap="1x">
      {icons[icon]}
      <Text size="100">{description}</Text>
    </Flex>
  );
};

export default CalendarRecency;
