import React, { FC } from 'react';

import { Flex } from '@rover/kibble/core';
import Row from '@rover/kibble/patterns/Row';

type RowListProps = {
  children: React.ReactNode;
};

type RowListType = FC<RowListProps> & { Row: typeof Row };

// eslint-disable-next-line react/prop-types
const RowList: RowListType = ({ children }) => {
  return (
    <Flex flexDirection="column" alignItems="flexStart" padding="0x" data-testid="row-list">
      {children}
    </Flex>
  );
};

RowList.Row = Row;

export default RowList;
