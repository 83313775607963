/* eslint-disable max-classes-per-file */
/* eslint-disable no-useless-constructor */
import { CONCEPTS } from '@rover/rsdk/src/concepts';
import type { ConsentPurpose } from '@rover/rsdk/src/modules/Analytics';
import { AnalyticsEvent } from '@rover/types/src/Analytics';

/**
 * &nbsp;
 *
 * # Description
 *
 * Fires when user clicks anywhere on the selected provider search result card - Search
 *
 * &nbsp;
 */
export class SelectedProviderSearchResultClick extends AnalyticsEvent {
  eventName = 'selected-provider-search-result-click';

  consentPurpose: ConsentPurpose = 'performance';

  concept = CONCEPTS.marketing.growth;

  constructor(eventProperties: { providerOpk: string; isFacility: boolean }) {
    super(eventProperties);
  }
}
