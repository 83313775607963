import DogHouseIcon from '@rover/icons/svg/dog-house.svg';
import { Box, Button, Flex, Heading, Paragraph } from '@rover/kibble/core';
import { MQ } from '@rover/kibble/styles';
import useMatchMedia from '@rover/react-lib/src/hooks/useMatchMedia';
import { ServiceType } from '@rover/shared/js/constants/service';

type Props = {
  dismiss: () => void;
  serviceType: ServiceType;
};

const headingServiceTypeString = {
  [ServiceType.OVERNIGHT_BOARDING]: 'boarding facilities',
  [ServiceType.DOGGY_DAY_CARE]: 'day care centers',
};

const bodyServiceTypeString = {
  [ServiceType.OVERNIGHT_BOARDING]: 'boarding',
  [ServiceType.DOGGY_DAY_CARE]: 'day care',
};

function NoFacilitiesCallout({ dismiss, serviceType }: Props): JSX.Element {
  const isMobile = useMatchMedia(MQ.MOBILE.getBreakpointString());

  return (
    <Flex
      background="primary"
      borderRadius="primary"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      px="6x"
      py="4x"
      variant="elevation_mid"
      data-testid="no-daycare-facilities-callout"
    >
      <Flex flexDirection="row" alignItems="center" justifyContent="center" mb="2x" flexGrow="0">
        <Box mr="4x" flexShrink="0" flexGrow="0">
          <DogHouseIcon width="86px" marginRight="" />
        </Box>
        <Heading
          as="h3"
          mb="0x"
          fontWeight="semibold"
          textColor="primary"
          size={isMobile ? '200' : '300'}
        >
          We couldn't find any {headingServiceTypeString[serviceType]} in your area.
        </Heading>
      </Flex>
      <Paragraph size="100" mb="2x">
        You can still find {bodyServiceTypeString[serviceType]} with trusted dog sitters from the
        list below! 🐾
      </Paragraph>
      <Button minWidth="240px" size="small" onClick={dismiss}>
        Dismiss
      </Button>
    </Flex>
  );
}

export default NoFacilitiesCallout;
