import React from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import moment from 'moment';

import LabelAndErrorFormGroup, {
  Props as LabelAndErrorFormGroupProps,
} from '@rover/react-lib/src/components/utils/LabelAndErrorFormGroup';
import {
  DateRangeEnum,
  DateRangeField,
  UpdatedDateRange,
} from '@rover/types/src/datetime/DateRange';

import {
  createRenderDayForHolidays,
  DatePickerCaption,
  DatePickerNavbar,
  DatePickerStylingWrapper,
} from '../DatePicker';

export type Props = {
  className?: string;
  startDate?: DateRangeField;
  endDate?: DateRangeField;
  onChange: (range: UpdatedDateRange) => void;
  minDate?: Date;
  maxDate?: DateRangeField;
  isDayBlocked?: (Date) => boolean;
  validationError?: string | undefined;
  validationType: 'popover' | 'inline';
  holidays: Date[];
} & Omit<LabelAndErrorFormGroupProps, 'children'>;

type DayPickerClickModifiers = {
  disabled: boolean;
};

const addDayToRange = (day: Date, from: DateRangeField, to: DateRangeField): UpdatedDateRange => {
  if (!from || DateUtils.isDayBefore(day, from) || (from && to)) {
    return { dateChanged: DateRangeEnum.START_DATE, startDate: day, endDate: undefined };
  }

  return { dateChanged: DateRangeEnum.END_DATE, startDate: from, endDate: day };
};

const handleDayClick = (
  startDate: DateRangeField,
  endDate: DateRangeField,
  onChange,
  clickedDate: Date,
  { disabled }: DayPickerClickModifiers = { disabled: false }
): void => {
  if (disabled) return;
  const updatedDateRange = addDayToRange(clickedDate, startDate, endDate);
  onChange(updatedDateRange);
};

const shouldHighlightBetween = (startDate: DateRangeField, endDate: DateRangeField): boolean => {
  const start = startDate ? moment(startDate) : moment();
  const end = endDate ? moment(endDate) : moment();
  return end.diff(start, 'days') > 1;
};

const DatePickerAsCalendar = ({
  startDate,
  endDate,
  onChange,
  className = '',
  maxDate,
  minDate = new Date(),
  isDayBlocked,
  validationError,
  validationType,
  holidays = [],
  ...other
}: Props): JSX.Element => {
  const dayPickerProps = {
    fromMonth: minDate,
    toMonth: maxDate,
    month: startDate || minDate,
    selectedDays: [
      startDate,
      {
        from: startDate,
        to: endDate,
      },
    ],
    disabledDays: [
      {
        before: minDate,
      },
      isDayBlocked,
    ],
    onDayClick: (clickedDate, modifiers) =>
      handleDayClick(startDate, endDate, onChange, clickedDate, modifiers),
    navbarElement: DatePickerNavbar,
    captionElement: DatePickerCaption,
    renderDay: createRenderDayForHolidays(holidays),
  };

  const between = {
    from: startDate ? moment(startDate).add(1, 'days').toDate() : startDate,
    to: endDate ? moment(endDate).add(-1, 'days').toDate() : endDate,
  };

  const modifiers = {
    between: shouldHighlightBetween(startDate, endDate) ? between : {},
    start: startDate,
    end: endDate,
    holiday: holidays,
  };

  return (
    <LabelAndErrorFormGroup
      validationError={validationError}
      validationType={validationType}
      {...other}
    >
      {(formGroupProps) => (
        <DatePickerStylingWrapper className={className} styleAsError={!!validationError}>
          <DayPicker {...formGroupProps} {...dayPickerProps} modifiers={modifiers} />
        </DatePickerStylingWrapper>
      )}
    </LabelAndErrorFormGroup>
  );
};

export default DatePickerAsCalendar;
