import { useId } from 'react';
import { I18n as I18nCore } from '@lingui/core';
import { t } from '@lingui/macro';
import styled from 'styled-components';

import { DogSizeGiant, DogSizeLarge, DogSizeMedium, DogSizeSmall } from '@rover/icons';
import { DSTokenMap, MQ } from '@rover/kibble/styles';
import {
  IconWrapper,
  SELECT_BUTTON_IS_SELECTED_CLASS,
  SelectButtonLabel,
  Wrapper,
} from '@rover/react-lib/src/components/buttons/SelectButton';
import ButtonGroup from '@rover/react-lib/src/components/formFields/ButtonGroup';
import Fieldset from '@rover/react-lib/src/components/formFields/Fieldset';
import type { Option } from '@rover/react-lib/src/components/formFields/MultiSelectButtonGroup';
import MultiSelectButtonGroup from '@rover/react-lib/src/components/formFields/MultiSelectButtonGroup';
import { StyledCheckmark } from '@rover/react-lib/src/components/formFields/MultiSelectButtonGroup/MultiSelectButtonGroup';
import { ApiFrontendConfigurationRetrieve200SettingsDogSizeBuckets } from '@rover/rsdk/src/apiClient/latest';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import FilterLabel from '@rover/shared/js/search/components/FilterLabel';

const PET_SIZE_CHOICES = {
  giant: { icon: DogSizeGiant, title: t`Giant` },
  large: { icon: DogSizeLarge, title: t`Large` },
  medium: { icon: DogSizeMedium, title: t`Medium` },
  small: { icon: DogSizeSmall, title: t`Small` },
};

const StyledMultiSelectButtonGroup = styled(MultiSelectButtonGroup)`
  & ${Wrapper} {
    ${MQ.MD_UP.toString()} {
      flex-direction: column;
      justify-content: center;
    }
  }

  & ${SelectButtonLabel} {
    padding: ${DSTokenMap.SPACE_4X};

    ${MQ.MD_UP.toString()} {
      justify-content: center;
    }
  }

  & ${IconWrapper} {
    ${MQ.MD_UP.toString()} {
      margin-bottom: 6px;
      margin-right: 0px;
    }
  }
`;

const HomePageButtonGroup = styled(StyledMultiSelectButtonGroup)`
  & ${StyledCheckmark} {
    display: none;
  }
  & ${SelectButtonLabel} {
    height: 64px;
    padding: 0;

    &.${SELECT_BUTTON_IS_SELECTED_CLASS} {
      color: ${DSTokenMap.TEXT_COLOR_PRIMARY.toString()};
    }
  }
`;

interface Props {
  displayAsIconButtons?: boolean;
  displayAsHomePageSearchBoxButtons?: boolean;
  label: string;
  onChange: (sizeKey: string | number) => void;
  petSizeBuckets: ApiFrontendConfigurationRetrieve200SettingsDogSizeBuckets;
  searchFilters: {
    giantDogs?: boolean;
    largeDogs?: boolean;
    mediumDogs?: boolean;
    smallDogs?: boolean;
  };
  weightUnit: string;
}

interface BasicOption {
  key: string;
  label: string;
  value: string;
}

type IconOption = Option & {
  icon: string;
  key: string;
  title: string;
  subtitle: string;
  displayAs: 'expanded';
};

const createOptions = (
  buckets: ApiFrontendConfigurationRetrieve200SettingsDogSizeBuckets,
  searchFilters: Props['searchFilters']
): BasicOption[] =>
  Object.keys(buckets).map((key) => ({
    key: `${key}Dogs`,
    label: buckets[key],
    value: searchFilters[`${key}Dogs`],
  }));

const createIconOptions = (
  buckets: ApiFrontendConfigurationRetrieve200SettingsDogSizeBuckets,
  weightUnit: Props['weightUnit'],
  i18n: I18nCore
): IconOption[] => {
  return Object.keys(buckets).map((key) => {
    const { title } = PET_SIZE_CHOICES[key];
    const sizeBucket = buckets[key];
    const [lowerWeight, upperWeight] = sizeBucket.split('-');

    return {
      icon: PET_SIZE_CHOICES[key].icon,
      key: `${key}Dogs`,
      title,
      subtitle: `${sizeBucket.replace('-', ' - ')} ${weightUnit}`,
      displayAs: 'expanded',
      ariaLabel: i18n._(
        /* i18n: this is an aria-label for a weight range selection button, ex: 'Small (0 to 15 lbs)'.  */
        t`${i18n._(title)} (${lowerWeight} to ${upperWeight} ${weightUnit})`
      ),
    };
  });
};

const createIconOptionsWithoutIcons = (
  buckets: ApiFrontendConfigurationRetrieve200SettingsDogSizeBuckets,
  weightUnit: Props['weightUnit'],
  i18n: I18nCore
): Array<Omit<IconOption, 'icon'>> =>
  // NOTE(Ryan) This feels like a perfectly acceptable usecase for an "unused" var
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  createIconOptions(buckets, weightUnit, i18n).map(({ icon, ...other }) => other);

const PetSize = ({
  displayAsHomePageSearchBoxButtons,
  displayAsIconButtons,
  label,
  onChange,
  petSizeBuckets,
  searchFilters,
  weightUnit,
}: Props): JSX.Element | null => {
  const petSizeSelectorId = useId();
  const { i18n } = useI18n();

  if (!petSizeBuckets) return null;

  let buttons: JSX.Element;
  if (displayAsIconButtons) {
    buttons = (
      <StyledMultiSelectButtonGroup
        onChange={onChange}
        options={createIconOptions(petSizeBuckets, weightUnit, i18n)}
        selected={searchFilters}
        showCheckmark
      />
    );
  } else if (displayAsHomePageSearchBoxButtons) {
    buttons = (
      <HomePageButtonGroup
        onChange={onChange}
        options={createIconOptionsWithoutIcons(petSizeBuckets, weightUnit, i18n)}
        selected={searchFilters}
        showCheckmark
      />
    );
  } else {
    buttons = (
      <ButtonGroup
        onToggle={(option) => onChange(option.key)}
        options={createOptions(petSizeBuckets, searchFilters)}
      />
    );
  }

  return (
    petSizeBuckets && (
      <Fieldset aria-labelledby={petSizeSelectorId}>
        <FilterLabel id={petSizeSelectorId} as="div">
          {label}
        </FilterLabel>
        {buttons}
      </Fieldset>
    )
  );
};

export default PetSize;
