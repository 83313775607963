import React, { forwardRef, memo } from 'react';
import { t } from '@lingui/macro';
import styled, { css } from 'styled-components';

import { Flex } from '@rover/kibble/core';
import { DSTokenMap, Spacing } from '@rover/kibble/styles';
import { HorizontalLayout, VerticalLayout } from '@rover/react-lib/src/components';
import { MicrodataHTMLAttributes, schema } from '@rover/react-lib/src/components/microdata';
import useDeviceContext from '@rover/react-lib/src/context/useDeviceContext';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import appendUrlParams from '@rover/rsdk/src/modules/Network/appendUrlParams';
import { snakeify } from '@rover/rsdk/src/modules/Network/object-utilities';
import type { SearchResult } from '@rover/types';

import CalendarRecency from './CalendarRecency';
import FeaturedLabel from './FeaturedLabel';
import FeaturedReview from './FeaturedReview';
import HolidayConfirmation from './HolidayConfirmation';
import ImageColumn from './ImageColumn';
import InfoColumn from './InfoColumn';
import PillsAndFavoriteRow from './PillsAndFavoriteRow';
import PriceColumn from './PriceColumn';
import { SEARCH_RESULT_CARD_Z_INDEX } from './SearchResultCard.constants';

const LAZY_LOAD_AFTER_INDEX = 4;

export type Props = {
  displayExpanded: boolean;
  index?: number;
  hideFavorite?: boolean;
  isActive?: boolean;
  isAuthenticated: boolean | null | undefined;
  language: string;
  memberProfileLinkParams: Record<string, string | undefined>;
  onBlur?: () => void;
  onFavoriteToggled: (personOpk: string) => void;
  onFocus?: (index: number) => void;
  onMouseEnter?: (index: number) => void;
  onMouseLeave?: () => void;
  searchResult: SearchResult;
  isSeoPage?: boolean;
  preferCatReviews: boolean;
  onClick?: (
    entityOpk: string,
    isFacility: boolean,
    isBestMatch?: boolean,
    maybeBestMatch?: boolean,
    isPriceTransparency?: boolean
  ) => void;
  isPriceTransparency?: boolean;
  fromAlternateSearchResults?: boolean;
  isSelectedProviderResult?: boolean;
} & MicrodataHTMLAttributes;

// Any elements that explicity need to be interactive in the search card
// should have z-index: 2. Otherwise, we want to be able to click through
// to the member profile, which is why this link sits on top of the entire card.
export const MemberProfileAnchor = styled.a`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: ${SEARCH_RESULT_CARD_Z_INDEX.PROFILE_ANCHOR.toNumber()};
  cursor: pointer;
`;

export const leftBoxShadow = (): ReturnType<typeof css> =>
  css`
    box-shadow: inset ${DSTokenMap.SPACE_1X} 0 0 0
      ${DSTokenMap.INTERACTIVE_TEXT_COLOR_PRIMARY_ACTIVE.toString()};
  `;

export const SearchResultCardWrapper = styled(VerticalLayout)<{
  $isActive?: boolean;
  $isSelectedProviderResult?: boolean;
}>`
  &:hover {
    ${leftBoxShadow()};
  }

  ${({ $isActive }) => ($isActive ? leftBoxShadow() : '')}
  background-color: ${DSTokenMap.BACKGROUND_COLOR_PRIMARY.toString()};
  padding: ${Spacing.M.toString()};
  position: relative;
  width: 100%;
  margin-bottom: ${({ $isSelectedProviderResult }) =>
    $isSelectedProviderResult ? DSTokenMap.SPACE_10X : '0'};

  &:not(:last-child) {
    border-bottom: 1px solid ${DSTokenMap.BORDER_COLOR_PRIMARY.toString()};
  }

  &:first-child {
    border-radius: ${DSTokenMap.BORDER_RADIUS_SECONDARY} 0 0 0;
  }

  &:last-child {
    border-radius: 0 0 0 ${DSTokenMap.BORDER_RADIUS_SECONDARY};
  }
`;

const SearchResultCard = forwardRef(function SearchResultCard(
  {
    displayExpanded,
    hideFavorite,
    index,
    isActive,
    isAuthenticated,
    language,
    memberProfileLinkParams,
    onBlur = () => {},
    onFavoriteToggled,
    onFocus = () => {},
    onMouseEnter = () => {},
    onMouseLeave = () => {},
    searchResult,
    isSeoPage,
    preferCatReviews,
    onClick = () => {},
    isPriceTransparency,
    fromAlternateSearchResults,
    isSelectedProviderResult,
    ...props
  }: Props,
  ref
) {
  const {
    defaultImage: { small: smallImage, medium: mediumImage },
    description,
    featuredLabel,
    isFavorite,
    personOpk,
    currencyCode,
    price,
    shortName,
    unitAsSentence,
    webUrl,
    primaryBadgeData,
    catReviewText,
    reviewText,
    catReviewPhotoUrl,
    reviewDogPhotoUrl,
    calendarRecency,
    holidayConfirmation,
    isFacility,
    facilityOpk,
    rank,
    isBestMatch,
    maybeBestMatch,
  } = searchResult;
  const { i18n } = useI18n();

  const { isDesktop } = useDeviceContext();
  const indexExists = typeof index === 'number';

  const memberProfileLink =
    Object.keys(memberProfileLinkParams).length === 0
      ? webUrl
      : appendUrlParams(webUrl, snakeify(memberProfileLinkParams));

  const showIndex = !fromAlternateSearchResults;

  return (
    <SearchResultCardWrapper
      data-qa-id="search-results"
      data-testid="search-result-card"
      onMouseEnter={indexExists ? () => onMouseEnter(index) : undefined}
      onMouseLeave={() => onMouseLeave()}
      onFocus={indexExists ? () => onFocus(index) : undefined}
      onBlur={() => onBlur()}
      $isActive={isActive}
      // @ts-expect-error the default typing for a ref doesn't allow a second argument
      ref={(searchCard) => ref && ref(searchCard, index)}
      {...props}
      itemScope
      itemType={isFacility ? schema`Organization` : schema`Person`}
      $isSelectedProviderResult={isSelectedProviderResult}
    >
      <meta itemProp="url" content={memberProfileLink} />
      <MemberProfileAnchor
        onClick={() => {
          // if isFacility is true and facilityOpk not null, we want to use the facilityOpk instead of the personOpk
          const opk = isFacility && facilityOpk ? facilityOpk : personOpk;
          onClick(opk, isFacility, !!isBestMatch, !!maybeBestMatch);
        }}
        aria-label={
          indexExists && showIndex
            ? i18n._(t`Search result number ${rank}: ${shortName}`)
            : shortName
        }
        href={memberProfileLink}
        rel={!isSeoPage ? undefined : 'nofollow'}
        target={isDesktop && !isSeoPage ? '_blank' : '_self'}
      />
      {featuredLabel && <FeaturedLabel featuredLabel={featuredLabel} />}
      <HorizontalLayout noWrap>
        <ImageColumn
          displayExpanded={displayExpanded}
          desktopProfileImage={mediumImage}
          mobileProfileImage={smallImage}
          shortName={shortName}
          lazy={!!index && index > LAZY_LOAD_AFTER_INDEX}
          badge={primaryBadgeData}
          isFacility={isFacility}
        />
        <InfoColumn
          displayExpanded={displayExpanded}
          index={index}
          searchResult={searchResult}
          isSeoPage={!!isSeoPage}
        />
        <PriceColumn
          currencyCode={currencyCode}
          language={language}
          price={price}
          unitAsSentence={unitAsSentence}
          isPriceTransparency={!!isPriceTransparency}
        />
      </HorizontalLayout>
      <PillsAndFavoriteRow
        isAuthenticated={isAuthenticated}
        isFavorite={isFavorite}
        onFavoriteToggled={() => onFavoriteToggled(personOpk)}
        ratingsAverage={searchResult.ratingsAverage}
        repeatClientCount={searchResult.providerProfile.repeatClientCount}
        reviewsCount={searchResult.reviewsCount}
        yearsOfExperience={searchResult.yearsOfExperience}
        hideFavorite={hideFavorite || isSeoPage || isFacility}
        isFacility={isFacility}
      />
      <FeaturedReview
        description={description}
        reviewText={preferCatReviews && catReviewText ? catReviewText : reviewText}
        reviewPetPhotoUrl={
          preferCatReviews && catReviewPhotoUrl ? catReviewPhotoUrl : reviewDogPhotoUrl
        }
      />
      {(calendarRecency && (
        <Flex justifyContent="space-between" width="100%" flexWrap="wrap" gap="3x">
          <CalendarRecency calendarRecency={calendarRecency} />
          {holidayConfirmation && <HolidayConfirmation holidayConfirmation={holidayConfirmation} />}
        </Flex>
      )) ||
        (holidayConfirmation && (
          <Flex justifyContent="space-between" width="100%" flexWrap="wrap" gap="3x">
            <HolidayConfirmation holidayConfirmation={holidayConfirmation} />
          </Flex>
        ))}
    </SearchResultCardWrapper>
  );
});

export default memo(SearchResultCard);
