import React from 'react';

import { Connect } from '@rover/react-lib/src/components/utils/Redux';
import { selectors as searchBoxSelectors } from '@rover/react-lib/src/pages/index/HomePage/components/SearchBox/SearchBox.duck';
import { selectors as searchPageSelectors } from '@rover/react-lib/src/pages/search/SearchPage/SearchPage.duck';
import { WizardPageType } from '@rover/react-lib/src/pages/search/SearchPage/WizardPageType';
import { selectors as frontendConfigurationSelectors } from '@rover/react-lib/src/redux/ducks/frontendConfiguration.duck';
import { selectors as searchSelectors } from '@rover/react-lib/src/redux/ducks/search.duck';
import { selectors as serviceTypeSelectors } from '@rover/react-lib/src/redux/ducks/serviceTypes.duck';
import type { SearchFilters, SearchFiltersOptions, ServiceTypeOption } from '@rover/types';

import PageOneWizardCollection from './PageOneWizardCollection';

export type Props = {
  isCatOnly: boolean;
  onChange: (arg0: Partial<SearchFilters>, arg1?: boolean) => void;
  pageType: WizardPageType;
  searchFilters: SearchFilters;
  serviceTypeFilterOptions: SearchFiltersOptions;
  serviceTypeOptions: ServiceTypeOption[];
  locationInputId?: string;
};

const selector = (state) => ({
  getServiceTypeBySlug: serviceTypeSelectors.getServiceTypeBySlug(state),
  hasPets: searchSelectors.getHasPets(state),
  language: frontendConfigurationSelectors.getLanguage(state),
  rolloutPriceTransparency: searchPageSelectors.getRolloutPriceTransparency(state),
  isInPetCountersExperiment: searchPageSelectors.getIsInPetCountersExperiment(state),
  isDaycareFilterEarlierExperiment: searchBoxSelectors.getIsDaycareFilterEarlierInExperiment(state),
  isDaycareFilterOutsideMarketsInExperiment:
    searchBoxSelectors.getIsDaycareFilterOutsideMarketsInExperiment(state),
  isBoardingFilterOutsideMarketsInExperiment:
    searchBoxSelectors.getIsBoardingFilterOutsideMarketsInExperiment(state),
});

const PageOneWizardCollectionContainer = (props: Props): JSX.Element => (
  <Connect selector={selector}>
    {(connectedProps) => <PageOneWizardCollection {...connectedProps} {...props} />}
  </Connect>
);

export default PageOneWizardCollectionContainer;
