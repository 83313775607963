import { t } from '@lingui/macro';

import { Box } from '@rover/kibble/core';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import type { SitterBadge } from '@rover/types';

import DesktopAvatar from './DesktopAvatar';
import MobileAvatar from './MobileAvatar';

export type Props = {
  displayExpanded: boolean;
  desktopProfileImage: string;
  mobileProfileImage: string;
  shortName: string;
  lazy?: boolean;
  badge?: SitterBadge;
  isFacility?: boolean;
};

const ImageColumn = ({
  displayExpanded,
  desktopProfileImage,
  mobileProfileImage,
  shortName,
  lazy = true,
  badge,
  isFacility = false,
}: Props): React.ReactElement => {
  const { i18n } = useI18n();
  const description = i18n._(t`Sitter Profile Image: ${shortName}`);

  return (
    <>
      <meta itemProp="image" content={desktopProfileImage} />
      <Box display={['block', 'none']}>
        <MobileAvatar
          imageUrl={mobileProfileImage}
          badge={badge}
          description={description}
          isFacility={isFacility}
        />
      </Box>
      <Box display={['none', 'block']} flexShrink="0">
        <DesktopAvatar
          imageUrl={desktopProfileImage}
          lazy={!!lazy}
          badge={badge}
          displayExpanded={displayExpanded}
          description={description}
          isFacility={isFacility}
        />
      </Box>
    </>
  );
};

export default ImageColumn;
