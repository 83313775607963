import React, { useCallback, useId } from 'react';
import styled from 'styled-components';

import { Spacing } from '@rover/kibble/styles';
import FancyCheckbox from '@rover/react-lib/src/components/formFields/FancyCheckbox';
import { HorizontalLayout } from '@rover/react-lib/src/components/layouts';
import type { Pet, PetType } from '@rover/types/src/Pet';
import type { SearchFilters } from '@rover/types/src/SearchFilters';
import FilterLabel from '@rover/shared/js/search/components/FilterLabel';

const StyledFancyCheckbox = styled(FancyCheckbox)`
  display: inline-flex;

  &:not(:last-child) {
    margin-right: ${Spacing.M.toString()};
  }
`;

export interface Props {
  petOptions: Pet[];
  onChange: (searchFilters: Partial<SearchFilters>) => void;
  searchFilters: {
    pet?: string[];
    petType?: PetType[];
  };
  label: string;
}

const Pets = ({ onChange, petOptions, searchFilters, label }: Props): JSX.Element => {
  const petsId = useId();
  const selectedPets = searchFilters.pet || [];
  const selectedPetTypes = searchFilters.petType || [];

  const handleChange = useCallback(
    ({ pet, petType }: { pet: string; petType: PetType }): void => {
      if (!pet) return;

      if (selectedPets.includes(pet)) {
        const newPets = selectedPets.filter((p) => p !== pet);
        const newPetOptions = petOptions.filter((p) => newPets.includes(p.pk.toString()));
        const newPetTypes = [...new Set(newPetOptions.map((p) => p.petType))];

        onChange({ pet: newPets, petType: newPetTypes });
      } else {
        onChange({
          pet: [...selectedPets, pet],
          petType: [...new Set([...selectedPetTypes, petType])],
        });
      }
    },
    [selectedPets, petOptions, selectedPetTypes, onChange]
  );

  return (
    <HorizontalLayout>
      <FilterLabel htmlFor={petsId}>{label}</FilterLabel>
      <div id={petsId}>
        {petOptions.map(({ name: displayName, pk: value, petType }) => (
          <StyledFancyCheckbox
            checked={selectedPets.includes(value.toString())}
            id={petsId}
            key={value}
            label={displayName}
            onChange={() => {
              handleChange({ pet: value.toString(), petType });
            }}
            fontSize="100"
          />
        ))}
      </div>
    </HorizontalLayout>
  );
};

export default Pets;
