import React from 'react';
import styled from 'styled-components';

import { AlertSuccess, Calendar } from '@rover/icons';
import { Box, Flex, Text } from '@rover/kibble/core';
import { Color, DSTokenMap, MQ } from '@rover/kibble/styles';
import { useI18n } from '@rover/rsdk/src/modules/I18n';

export type Props = {
  featuredLabel: {
    extra?: string;
    text: string;
  };
};

const FeaturedLabel = styled(Flex)<{
  hasExtra: boolean;
}>`
  background-color: ${({ hasExtra }) => (hasExtra ? Color.GREEN_100.toString() : '')};
  border-radius: ${DSTokenMap.BORDER_RADIUS_SECONDARY};
  border: ${({ hasExtra }) =>
    hasExtra
      ? ''
      : `${DSTokenMap.BORDER_WIDTH_SECONDARY} solid ${DSTokenMap.BORDER_COLOR_SECONDARY}`};

  ${MQ.XS_DOWN.toString()} {
    min-height: 30px;
  }
`;

export const StyledAlertSuccess = styled(AlertSuccess)`
  fill: ${Color.GREEN_600.toString()};
  height: ${DSTokenMap.TEXT_100_FONT_SIZE};
  margin-right: ${DSTokenMap.SPACE_1X};
  stroke: ${Color.GREEN_600.toString()};
  width: ${DSTokenMap.TEXT_100_FONT_SIZE};
`;

export const StyledCalendar = styled(Calendar)`
  fill: ${DSTokenMap.TEXT_COLOR_PRIMARY.toString()};
  height: ${DSTokenMap.TEXT_100_FONT_SIZE};
  margin-right: ${DSTokenMap.SPACE_1X};
  stroke: ${DSTokenMap.TEXT_COLOR_PRIMARY.toString()};
  width: ${DSTokenMap.TEXT_100_FONT_SIZE};
`;

export default ({ featuredLabel: { text, extra } }: Props): React.ReactElement => {
  const { locale } = useI18n();
  const hasExtra = extra !== undefined;
  return (
    <FeaturedLabel
      alignItems="center"
      flexDirection="row"
      hasExtra={hasExtra}
      justifyContent="center"
      mb="4x"
      px="4x"
      py="2x"
      width="100%"
    >
      {hasExtra ? <StyledAlertSuccess /> : <StyledCalendar />}
      <Box flex={['1 1']} ml="1x">
        <Text textColor="secondary" size="100">
          {text}
          {hasExtra && extra !== '' && (
            <>
              {locale === 'fr-fr' ? ' ' : ''}: {extra}
            </>
          )}
        </Text>
      </Box>
    </FeaturedLabel>
  );
};
