import { ZIndex } from '@rover/kibble/styles';

export const SEARCH_RESULT_CARD_Z_INDEX = {
  BADGE_CLOSED: 3,
  BADGE_OPEN: 4,
  INTERACTIVE: 2,
  PROFILE_ANCHOR: ZIndex.ONE,
  SCORE_DATA: ZIndex.NAVBAR.toNumber() + 1,
};
export const MAX_REVIEW_LINE_COUNT = 2;

export const AVATAR_SIZES = {
  EXPANDED: 80,
  REDUCED: 64,
};

export const AVATAR_BADGE_SIZES = {
  EXPANDED: 24,
  REDUCED: 16,
};
