import React, { useState } from 'react';
import styled from 'styled-components';

import { Box, Button, Flex, Text } from '@rover/kibble/core';
import DialogModal from '@rover/react-lib/src/components/modals/DialogModal';
import type { SearchScoreDebugData } from '@rover/types';

import { SEARCH_RESULT_CARD_Z_INDEX } from './SearchResultCard.constants';

export type Props = {
  data: SearchScoreDebugData[];
};

const StyledDialogModal = styled(DialogModal)`
  &.RoverModalContent {
    max-width: fit-content !important;
  }
`;

const SearchScoreData = ({ data = [] }: Props): React.ReactElement => {
  const [isDebugModalOpen, setIsDebugModalOpen] = useState(false);

  return (
    <>
      <StyledDialogModal
        cancelButtonText={null}
        headerText="Search Score Data"
        onClickCancel={null}
        onClickSubmit={() => setIsDebugModalOpen(false)}
        onRequestClose={() => setIsDebugModalOpen(false)}
        submitButtonText="Close"
        isOpen={isDebugModalOpen}
      >
        <Flex flexDirection="column">
          {data.map(([key, value]) => (
            <Flex key={key} justifyContent="space-between" alignItems="center">
              <Text fontWeight="semibold" marginRight="1x">
                {key}:
              </Text>
              <Text>
                <code>{`${value}`}</code>
              </Text>
            </Flex>
          ))}
        </Flex>
      </StyledDialogModal>
      <Box zIndex={SEARCH_RESULT_CARD_Z_INDEX.INTERACTIVE.toString()} position="relative">
        <Button size="small" my="2x" onClick={() => setIsDebugModalOpen(true)}>
          Search Score Data
        </Button>
      </Box>
    </>
  );
};

export default SearchScoreData;
