import React from 'react';
import styled from 'styled-components';

import { DSTokenMap, MQ } from '@rover/kibble/styles';
import { LAYOUT, SEARCH_PAGE_Z_INDEX } from '@rover/shared/js/constants/searchPage.constants';

import SidebarFiltersCollection from '../Filters/Collections/SidebarFiltersCollectionContainer';

export type Props = {
  onScrollableFilterChange: () => void;
};

type State = {
  innerHeight: number;
  windowHeight: number;
};

const SIDEBAR_TOP_MARGIN = 6;
const DEFAULT_SIDEBAR_HEIGHT = 1358;

const Wrapper = styled.div<{ minHeight: number }>`
  align-self: stretch;
  flex: 0 0 ${LAYOUT.LEFT_WIDTH}px;
  margin-left: ${LAYOUT.GUTTER}px;
  margin-right: ${LAYOUT.GUTTER}px;
  position: relative;
  z-index: ${SEARCH_PAGE_Z_INDEX.STICKY_SIDEBAR};
  min-height: ${({ minHeight }) => minHeight}px;
  display: none;

  ${MQ.SM_UP.toString()} {
    display: block;
  }
`;

const Sticky = styled.div<{ innerHeight: number; windowHeight: number }>`
  position: sticky;
  background-color: ${DSTokenMap.BACKGROUND_COLOR_PRIMARY.toString()};
  width: ${LAYOUT.LEFT_WIDTH}px;
  border-radius: ${DSTokenMap.BORDER_RADIUS_SECONDARY} ${DSTokenMap.BORDER_RADIUS_SECONDARY} 0px 0px;

  top: ${({ innerHeight, windowHeight }) => {
    const sidebarHeight = innerHeight || DEFAULT_SIDEBAR_HEIGHT;
    const sidebarAndMarginHeight = sidebarHeight + SIDEBAR_TOP_MARGIN;
    const topOffset =
      windowHeight > sidebarAndMarginHeight
        ? `${SIDEBAR_TOP_MARGIN}px`
        : `calc(100% - ${sidebarHeight}px)`;
    return topOffset;
  }};
`;

class StickySidebar extends React.Component<Props, State> {
  state = {
    innerHeight: 0,
    windowHeight: 9999,
  };

  componentDidMount() {
    this.updateInnerHeight();
    this.updateWindowHeight();

    if (process.env.JS_ENV_CLIENT) {
      window.addEventListener('resize', this.updateWindowHeight);
    }
  }

  wrapperRef = React.createRef<HTMLDivElement>();

  stickyRef = React.createRef<HTMLDivElement>();

  updateInnerHeight = () => {
    setTimeout(() => {
      if (
        this.stickyRef?.current?.offsetHeight &&
        this.stickyRef.current.offsetHeight !== this.state.innerHeight
      ) {
        this.setState(() => ({ innerHeight: this.stickyRef.current?.offsetHeight || 0 }));
      }
    }, 200);
  };

  updateWindowHeight = () => {
    this.setState(() => ({ windowHeight: window.innerHeight })); // eslint-disable-line rover/no-platform-specific-globals-or-imports
  };

  render() {
    const { onScrollableFilterChange } = this.props;
    const { innerHeight, windowHeight } = this.state;

    return (
      <Wrapper ref={this.wrapperRef} minHeight={innerHeight}>
        <Sticky ref={this.stickyRef} innerHeight={innerHeight} windowHeight={windowHeight}>
          <SidebarFiltersCollection
            onScrollableFilterChange={onScrollableFilterChange}
            onUpdateInnerHeight={this.updateInnerHeight}
          />
        </Sticky>
      </Wrapper>
    );
  }
}

export default React.memo(StickySidebar);
