import React, { useMemo } from 'react';
import { DateUtils } from 'react-day-picker';
import { MessageDescriptor } from '@lingui/core';
import { t } from '@lingui/macro';

import { CalendarDirection } from '@rover/react-lib/src/components/datetime/DatePicker';
import { SchedulerFormProvider } from '@rover/react-lib/src/pages/contact-sitter/ContactSitterPage/components/ContactSitterForm/components/ServiceScheduler/contexts/SchedulerFormContext';
import { OvernightDateRange } from '@rover/react-lib/src/pages/search/SearchPage/components/Filters/Dates';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import OneTimeRepeatToggleButtons from '@rover/shared/js/search/components/OneTimeRepeatToggleButtons';
import RepeatServiceScheduler from '@rover/shared/js/search/components/RepeatServiceScheduler';
import type { Day, SearchFilters, ServiceType } from '@rover/types';
import { ServiceFrequencyEnum } from '@rover/types/src/ServiceFrequency';

export type Props = {
  calendarDirection: CalendarDirection;
  displayAs?: 'compact' | null | 'expanded';
  endPlaceholder: string | MessageDescriptor;
  dayOfWeekLabel?: string | MessageDescriptor;
  language: string;
  maxDate?: Date;
  onChange: (arg0?: ServiceFrequencyEnum) => void;
  onDateRangeChange: (arg0: { startDate?: Date | null; endDate?: Date | null }) => void;
  oneTimeLabel: string;
  onSelectedDaysChange: (arg0: Day[]) => void;
  onStartDateChange: (arg0: {
    startDate?: Date | null;
    endDate?: Date | null;
    fireSearch: boolean;
  }) => void;
  repeatStartDateLabel: string | MessageDescriptor;
  searchFilters: SearchFilters;
  serviceType?: ServiceType | null;
  showLabel: boolean;
  showArrow: boolean;
  startPlaceholder: string | MessageDescriptor;
};

const DaytimeServiceScheduler = ({
  calendarDirection,
  dayOfWeekLabel,
  displayAs,
  endPlaceholder,
  language,
  maxDate,
  onChange,
  onDateRangeChange,
  oneTimeLabel,
  onSelectedDaysChange,
  onStartDateChange,
  repeatStartDateLabel,
  searchFilters,
  serviceType,
  showArrow,
  showLabel,
  startPlaceholder,
}: Props): JSX.Element => {
  const { i18n } = useI18n();
  const handleRepeatServiceSchedulerStartDateChange = (selectedStartDate) => {
    const { endDate } = searchFilters;

    if (endDate) {
      const updatedEndDate = DateUtils.isDayAfter(selectedStartDate, endDate) ? undefined : endDate;
      return onStartDateChange({
        startDate: selectedStartDate,
        endDate: updatedEndDate,
        fireSearch: true,
      });
    }

    return onStartDateChange({
      startDate: selectedStartDate,
      endDate: undefined,
      fireSearch: true,
    });
  };

  const contextValue = useMemo(() => ({ isDirty: false, onFormDirtinessChanged: () => {} }), []);
  const { selectedDays, startDate, endDate, frequency } = searchFilters;

  return (
    <SchedulerFormProvider context={contextValue}>
      {serviceType && serviceType.name && (
        <OneTimeRepeatToggleButtons
          displayAs={displayAs}
          frequency={frequency}
          label={
            showLabel
              ? i18n._(
                  /* i18n: How often do you need {serviceType}? e.g. Doggy Day Care, Dog Walking... */
                  t`How often do you need ${i18n._(serviceType.name)}?`
                )
              : ''
          }
          onChange={onChange}
        />
      )}
      {frequency === 'onetime' ? (
        <OvernightDateRange
          calendarDirection={calendarDirection}
          endPlaceholder={endPlaceholder}
          label={oneTimeLabel}
          language={language}
          maxDate={maxDate}
          onChange={onDateRangeChange}
          startPlaceholder={startPlaceholder}
          searchFilters={{
            startDate,
            endDate,
          }}
          showArrow={showArrow}
        />
      ) : (
        <RepeatServiceScheduler
          dayOfWeekLabel={dayOfWeekLabel}
          language={language}
          maxDate={maxDate}
          onSelectedDaysChange={onSelectedDaysChange}
          onStartDateChange={handleRepeatServiceSchedulerStartDateChange}
          repeatStartDateLabel={repeatStartDateLabel}
          selectedDays={selectedDays || []}
          serviceTypeSlug={serviceType && serviceType.slug}
          showLabel={showLabel}
          startDate={startDate}
          startPlaceholder={startPlaceholder}
        />
      )}
    </SchedulerFormProvider>
  );
};

DaytimeServiceScheduler.defaultProps = {
  calendarDirection: CalendarDirection.FLEX,
  maxDate: undefined,
};
export default DaytimeServiceScheduler;
