import React, { FC } from 'react';
import { Trans } from '@lingui/react';

import { Box, Button, Heading, Paragraph } from '@rover/kibble/core';
import BottomSheet from '@rover/kibble/official-patterns/BottomSheet';
import {
  EXPLANATION_MODAL_BUTTON_TEXT,
  EXPLANATION_MODAL_HEADER,
  EXPLANATION_MODAL_INITIAL_PARAGRAPH,
} from '@rover/react-lib/src/pages/search/SearchPage/components/Modals/BestMatchExplanationModal/constants';
import { useI18n } from '@rover/rsdk/src/modules/I18n';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};
const BestMatchExplanationBottomSheet: FC<Props> = ({ isOpen, onClose }) => {
  const { i18n } = useI18n();
  return (
    <BottomSheet
      height={520}
      isOpen={isOpen}
      onRequestClose={onClose}
      hideCloseIcon
      isDismissible
      closeOnDragDown
    >
      <Box>
        <Heading size="300" fontWeight="semibold">
          {i18n._(EXPLANATION_MODAL_HEADER)}
        </Heading>
        <Paragraph>{i18n._(EXPLANATION_MODAL_INITIAL_PARAGRAPH)}</Paragraph>
        <Paragraph mb="6x">
          <Trans>
            All pet care providers who sign up on Rover pass{' '}
            <a href="/background-checks" target="_blank">
              a background check
            </a>
            .
          </Trans>
        </Paragraph>
        <Button circular variant="primary" onClick={onClose} fullWidth>
          {i18n._(EXPLANATION_MODAL_BUTTON_TEXT)}
        </Button>
      </Box>
    </BottomSheet>
  );
};

export default BestMatchExplanationBottomSheet;
