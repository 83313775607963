/* eslint-disable rover/no-platform-specific-globals-or-imports */
import React, { Component, Fragment } from 'react';
import { Trans } from '@lingui/react';
import isEqual from 'lodash-es/isEqual';
import styled from 'styled-components';

// eslint-disable-next-line import/no-unresolved, import/extensions
import GAPI from '@rover/react-lib/src/components/utils/Google/GAPI';
import type { GoogleMapsConfigurationType } from '@rover/types/src/GoogleMapsConfiguration';
import emitMetric from '@rover/utilities/emitMetric';

type ControlPosition = number;

type LatLngLiteral = {
  lat: number;
  lng: number;
};
type LatLngLiteralString = {
  lat: string;
  lng: string;
};
type MapTypeId = 'HYBRID' | 'ROADMAP' | 'SATELLITE' | 'TERRAIN';
type MapTypeControlStyle = google.maps.MapTypeControlStyle;
type MapTypeControlOptions = {
  mapTypeIds?: MapTypeId[];
  position?: ControlPosition;
  style?: MapTypeControlStyle;
};
type PanControlOptions = {
  position?: ControlPosition;
};
type FullScreenControlOptions = {
  position?: ControlPosition;
};
type RotateControlOptions = {
  position?: ControlPosition;
};
type ScaleControlStyle = google.maps.ScaleControlStyle;
type ScaleControlOptions = {
  style?: ScaleControlStyle;
};

type StreetViewControlOptions = {
  position?: ControlPosition;
};
type ZoomControlStyle = google.maps.ZoomControlStyle;
type ZoomControlOptions = {
  position?: ControlPosition;
  style?: ZoomControlStyle;
};
type GestureHandling = 'cooperative' | 'greedy' | 'none' | 'auto';
export type MapMetadata = {
  zoomlevel: number;
  centerlat: number;
  centerlng: number;
  minlat: number;
  minlng: number;
  maxlat: number;
  maxlng: number;
};
type LatLngProp = LatLngLiteral | LatLngLiteralString;
type MapOptions = {
  backgroundColor?: string;
  clickableIcons?: boolean;
  disableDefaultUI?: boolean;
  disableDoubleClickZoom?: boolean;
  draggable?: boolean;
  draggableCursor?: string;
  draggingCursor?: string;
  fullscreenControl?: boolean;
  fullscreenControlOption?: FullScreenControlOptions;
  gestureHandling?: GestureHandling;
  heading?: number;
  keyboardShortcuts?: boolean;
  mapTypeControl?: boolean;
  mapTypeControlOptions?: MapTypeControlOptions;
  mapTypeId?: string;
  maxZoom?: number;
  minZoom?: number;
  noClear?: boolean;
  panControl?: boolean;
  panControlOptions?: PanControlOptions;
  rotateControl?: boolean;
  rotateControlOptions?: RotateControlOptions;
  scaleControl?: boolean;
  scaleControlOptions?: ScaleControlOptions;
  scrollwheel?: boolean;
  streetView?: google.maps.StreetViewPanorama;
  streetViewControl?: boolean;
  streetViewControlOptions?: StreetViewControlOptions;
  // Styles is used style the GoogleMap.
  styles?: GoogleMapsConfigurationType[];
  tilt?: number;
  zoom?: number | null;
  zoomControl?: boolean;
  zoomControlOptions?: ZoomControlOptions;
};

type WrappedGoogleMapOptions = {
  google: typeof google;
};

type RoverMapOptions = MapOptions & {
  visible?: boolean; // eslint-disable-line react/no-unused-prop-types
  onLoad?: () => void;
  onError?: () => void; // eslint-disable-line react/no-unused-prop-types
  onTilesLoaded?: () => void;
  onIdle?: (arg0: MapMetadata) => void;
  onCenterChanged?: (center: { lat: number; lng: number }) => void;
  onZoomChanged?: (arg0: MapMetadata) => void;
  children?: JSX.Element | JSX.Element[];
  center?:
    | {
        lat: string;
        lng: string;
      }
    | LatLngLiteral
    | null;
  className?: string;
  innerRef?: (arg0: HTMLDivElement | null) => void;
};

type GoogleMapOptions = RoverMapOptions & WrappedGoogleMapOptions;

const Map = styled.div`
  height: 100%;
  width: 100%;
`;

const formatCoordinate = (coordinate: string | number) =>
  parseFloat(coordinate.toString()).toFixed(5);

export const determineCentersEquivalent = (center1: LatLngProp, center2: LatLngProp): boolean => {
  return (
    formatCoordinate(center1.lat) === formatCoordinate(center2.lat) &&
    formatCoordinate(center1.lng) === formatCoordinate(center2.lng)
  );
};
class GoogleMap extends Component<GoogleMapOptions> {
  map: any;

  div: HTMLDivElement | null | undefined;

  static defaultProps = {
    children: undefined,
    clickableIcons: true,
    disableDefaultUI: false,
    disableDoubleClickZoom: false,
    draggable: true,
    draggableCursor: 'auto',
    draggingCursor: 'auto',
    fullscreenControl: false,
    gestureHandling: 'cooperative',
    mapTypeControl: false,
    maxZoom: 16,
    minZoom: 8,
    rotateControl: false,
    scrollwheel: false,
    streetViewControl: false,
  };

  componentDidMount(): void {
    this.loadMap();
    this.forceUpdate();
    this.handleLoad();
    this.handleIdle();
    this.handleCenterChanged();
    this.handleZoomChanged();
  }

  componentDidUpdate(prevProps: GoogleMapOptions): void {
    const updatedShallow = (value: keyof GoogleMapOptions) =>
      this.props[value] !== prevProps[value];

    const updatedDeep = (value: keyof GoogleMapOptions) =>
      !isEqual(this.props[value], prevProps[value]);

    if (updatedShallow('google')) {
      this.loadMap();
    }

    if (updatedShallow('visible')) {
      this.restyleMap();
    }

    if (updatedShallow('zoom')) {
      const currentZoom = this.map.getZoom();

      if (currentZoom !== this.props.zoom) {
        this.map.setZoom(this.props.zoom);
      }
    }

    if (updatedDeep('styles')) {
      this.updateMapOptions({
        styles: this.props.styles,
      });
    }

    const center: google.maps.LatLng = this.map.getCenter();

    if (center && this.props.center) {
      const centersAreEqual = determineCentersEquivalent(this.props.center, {
        lat: center.lat().toString() as string,
        lng: center.lng().toString() as string,
      });

      if (!centersAreEqual) {
        this.recenterMap();
      }
    }
  }

  componentWillUnmount(): void {
    if (this.map) {
      google.maps.event.clearInstanceListeners(this.map);
    }
  }

  updateMapOptions(options) {
    const { map } = this;

    if (map) {
      map.setOptions(options);
    }
  }

  recenterMap(): void {
    const { map } = this;
    const { google } = this.props;

    if (map && this.props.center) {
      const { lat, lng } = this.props.center;
      const center = {
        lat: parseFloat(lat.toString()),
        lng: parseFloat(lng.toString()),
      };
      map.setCenter(center);
      google.maps.event.trigger(map, 'recenter');
    }
  }

  restyleMap(): void {
    if (this.map) {
      const { google } = this.props;
      google.maps.event.trigger(this.map, 'resize');
    }
  }

  get mapMetadata() {
    const { map } = this;
    const bounds = map.getBounds();
    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();
    const center = map.getCenter();
    const currentMapMetadata = {
      zoomlevel: map.getZoom(),
      centerlat: center.lat(),
      centerlng: center.lng(),
      minlat: sw.lat(),
      minlng: sw.lng(),
      maxlat: ne.lat(),
      maxlng: ne.lng(),
    };
    return currentMapMetadata;
  }

  handleLoad = () => {
    const { map } = this;
    const { google, onLoad, onTilesLoaded } = this.props;
    google.maps.event.addListenerOnce(map, 'idle', () => {
      onLoad && onLoad();
    });

    // If the tiles don't load within 5 seconds, emit a metric to track this
    const tilesLoadedLogger = setTimeout(() => {
      emitMetric('search_map_tiles_loaded', { success: 'false' });
    }, 10000);

    google.maps.event.addListener(map, 'tilesloaded', () => {
      clearTimeout(tilesLoadedLogger);
      emitMetric('search_map_tiles_loaded', { success: 'true' });

      onTilesLoaded && onTilesLoaded();
    });
  };

  handleIdle = () => {
    const { map } = this;
    const { google, onIdle } = this.props;
    google.maps.event.addListener(map, 'idle', () => {
      if (onIdle) {
        onIdle(this.mapMetadata);
      }
    });
  };

  handleZoomChanged = () => {
    const { map } = this;
    const { google, onZoomChanged } = this.props;
    google.maps.event.addListener(map, 'zoom_changed', () => {
      if (onZoomChanged) {
        // getting this.mapMetadata can fail during some loading scenarios,
        // but refires without issue once the map finishes loading
        try {
          onZoomChanged(this.mapMetadata);
        } catch {
          // eslint-disable-next-line no-console
          console.warn('Failed to get mapMetadata, this is probably fine.');
        }
      }
    });
  };

  handleCenterChanged = () => {
    const { map } = this;
    const { google, onCenterChanged } = this.props;
    google.maps.event.addListener(map, 'center_changed', () => {
      if (onCenterChanged) {
        const center = map.getCenter();
        onCenterChanged({
          lat: center.lat(),
          lng: center.lng(),
        });
      }
    });
  };

  loadMap(): void {
    const {
      backgroundColor,
      clickableIcons,
      disableDefaultUI,
      disableDoubleClickZoom,
      draggable,
      draggableCursor,
      draggingCursor,
      fullscreenControl,
      fullscreenControlOption,
      gestureHandling,
      heading,
      keyboardShortcuts,
      mapTypeControl,
      mapTypeControlOptions,
      mapTypeId,
      maxZoom,
      minZoom,
      noClear,
      panControl,
      panControlOptions,
      rotateControl,
      rotateControlOptions,
      scaleControl,
      scaleControlOptions,
      scrollwheel,
      streetView,
      streetViewControl,
      streetViewControlOptions,
      styles,
      tilt,
      zoomControl,
      zoomControlOptions,
      google,
    } = this.props;
    let { zoom } = this.props;
    let center;

    if (this.props.center !== undefined && this.props.center !== null) {
      const { lat, lng } = this.props.center;
      center = new google.maps.LatLng(parseFloat(lat.toString()), parseFloat(lng.toString()));
    } else {
      center = new google.maps.LatLng(47.6140865, -122.3445927);
    }

    if (this.props.zoom === undefined) {
      zoom = 11;
    }

    const mapConfig = {
      backgroundColor,
      center,
      clickableIcons,
      disableDefaultUI,
      disableDoubleClickZoom,
      draggable,
      draggableCursor,
      draggingCursor,
      fullscreenControl,
      fullscreenControlOption,
      gestureHandling,
      heading,
      keyboardShortcuts,
      mapTypeControl,
      mapTypeControlOptions,
      mapTypeId,
      maxZoom,
      minZoom,
      noClear,
      panControl,
      panControlOptions,
      rotateControl,
      rotateControlOptions,
      scaleControl,
      scaleControlOptions,
      scrollwheel,
      streetView,
      streetViewControl,
      streetViewControlOptions,
      styles,
      tilt,
      zoom,
      zoomControl,
      zoomControlOptions: zoomControlOptions || {
        position: google.maps.ControlPosition.RIGHT_TOP,
      },
    };

    if (this.div) {
      this.map = new google.maps.Map(this.div, mapConfig as google.maps.MapOptions);
    }
    google.maps.event.addDomListener(window, 'resize', () =>
      google.maps.event.addListenerOnce(this.map, 'idle', () => {
        const point = this.map.getCenter();
        this.map.setCenter(point);
        google.maps.event.trigger(this.map, 'resize');
      })
    );
    // this.createEvents();
    google.maps.event.trigger(this.map, 'resize');
    google.maps.event.trigger(this.map, 'ready');
  }

  renderChildren(): React.ReactNode[] | null | undefined {
    const { children } = this.props;
    if (children) {
      return React.Children.map(children, (child: JSX.Element) =>
        React.cloneElement(child, {
          map: this.map,
          google: this.props.google,
        })
      );
    }
    return null;
  }

  render(): JSX.Element {
    const { className, innerRef } = this.props;
    return (
      <Fragment>
        <Map
          className={className}
          ref={(div) => {
            this.div = div;

            if (innerRef) {
              innerRef(div);
            }
          }}
        >
          <Trans>Loading map...</Trans>
        </Map>
        {this.renderChildren()}
      </Fragment>
    );
  }
}

const GAPIWrappedGoogleMap = GAPI(GoogleMap);
export default React.forwardRef<HTMLDivElement, RoverMapOptions>((props, ref) => (
  <GAPIWrappedGoogleMap innerRef={ref} {...props} />
));
