import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { plural, t } from '@lingui/macro';

import { CarouselRightSmall } from '@rover/icons';
import { Text } from '@rover/kibble/core';
import { ButtonIconSizeMap } from '@rover/kibble/core/Button/Button.common';
import { SpacingSystemType } from '@rover/kibble/core/styledSystemPropTypes';
import ClickableInput from '@rover/kibble/patterns/ClickableInput';
import { ThemeTextColor, ThemeTextSizes } from '@rover/kibble/styles';
import { useA11yContext } from '@rover/react-lib/src/context/A11yContext';
import { emitAnalyticsEvent } from '@rover/rsdk/src/modules/Analytics';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import { isMobileBrowser } from '@rover/rsdk/src/modules/Network/userAgent';
import { PetType } from '@rover/types/src/Pet';

import type { TriggeredFrom } from './analyticsEvents';
import { PetCountInputCloseEvent, PetCountInputOpenEvent } from './analyticsEvents';
import PetCounterDesktop from './PetCounterDesktop';
import PetCounterMobile from './PetCounterMobile';

const petCounterTitleText = t`How many pets need care?`;
const petCounterSubtitleText = t`Add at least one pet. This helps you find a sitter and see the most accurate pricing.`;
const petCounterSaveButtonText = t`Save pets`;
const petCounterCloseButtonText = t`Close`;

export type PetCounterProps = {
  inputLabel?: string;
  inputLabelSize?: ThemeTextSizes;
  inputLabelColor?: ThemeTextColor;
  inputLabelMarginBottom?: SpacingSystemType;
  hideHelperText?: boolean;
  isHomePage?: boolean;
  dogCount?: number;
  catCount?: number;
  puppyCount?: number;
  availablePets?: string[];
  triggeredFrom?: TriggeredFrom;
  onChange: (arg0: {
    petType: PetType[];
    counter: number;
    dogCount: number;
    catCount: number;
    puppyCount: number;
  }) => void;
};

const DEFAULT_AVAILABLE_PETS = [PetType.DOG, PetType.CAT];

const PetCounter: FC<PetCounterProps> = ({
  dogCount = 0,
  catCount = 0,
  puppyCount = 0,
  hideHelperText = false,
  onChange,
  availablePets = DEFAULT_AVAILABLE_PETS,
  inputLabel,
  inputLabelSize,
  inputLabelColor,
  inputLabelMarginBottom,
  isHomePage = false,
  triggeredFrom = 'Search',
}) => {
  const [petsCounterOpen, setPetsCounterOpen] = useState(false);
  const { setScreenReaderAnnouncement } = useA11yContext();
  const isMobile = isMobileBrowser();
  const { i18n } = useI18n();

  const [dogs, setDogs] = useState(dogCount);
  const [cats, setCats] = useState(catCount);
  const [puppies, setPuppies] = useState(puppyCount);

  useEffect(() => {
    if (petsCounterOpen) {
      setDogs(dogCount);
      setCats(catCount);
      setPuppies(puppyCount);
    }
  }, [catCount, dogCount, petsCounterOpen, puppyCount]);

  const petString = useMemo(() => {
    const dogPlural = i18n._(
      plural({
        value: dogCount,
        one: '# dog',
        other: '# dogs',
      })
    );
    const catPlural = i18n._(
      plural({
        value: catCount,
        one: '# cat',
        other: '# cats',
      })
    );
    const puppyPlural = i18n._(
      plural({
        value: puppyCount,
        one: '# puppy',
        other: '# puppies',
      })
    );

    if (availablePets.includes(PetType.PUPPY)) {
      return dogPlural.concat(', ', puppyPlural, ', ', catPlural);
    }

    return dogPlural.concat(', ', catPlural);
  }, [i18n, dogCount, catCount, puppyCount, availablePets]);

  const handlePetCounterChange = useCallback((): void => {
    const petCounts = {
      [PetType.DOG]: dogs,
      [PetType.CAT]: cats,
      [PetType.PUPPY]: puppies,
    };

    const newPetTypes: PetType[] = (
      Object.keys(petCounts) as Array<(typeof PetType)[keyof typeof PetType]>
    ).reduce<PetType[]>((acc, type) => {
      if (type === PetType.PUPPY && petCounts[type] > 0) {
        return [...acc, PetType.DOG];
      }

      if (petCounts[type] > 0) {
        return [...acc, type];
      }

      return acc;
    }, []);

    // Make values unique
    const uniquePetTypes = Array.from(new Set(newPetTypes));

    setScreenReaderAnnouncement(
      i18n._(
        /* i18n: The string will evaluate to something like: How many pets? 1 dog, 1 cat, 0 puppies. */
        t`How many pets? ${petString}`
      )
    );

    const petsSelected = dogs > 0 || cats > 0 || puppies > 0 || isHomePage;
    // avoid triggering on change when all pets are 0
    if (petsSelected) {
      onChange({
        petType: uniquePetTypes,
        counter: Math.min(dogs + cats + puppies, 3),
        dogCount: dogs,
        catCount: cats,
        puppyCount: puppies,
      });
    }
  }, [dogs, cats, puppies, setScreenReaderAnnouncement, i18n, petString, isHomePage, onChange]);

  const closePetCounter = (): void => {
    emitAnalyticsEvent(new PetCountInputCloseEvent({ triggeredFrom }));
    setPetsCounterOpen(false);
  };

  const openPetCounter = (): void => {
    emitAnalyticsEvent(new PetCountInputOpenEvent({ triggeredFrom }));
    setPetsCounterOpen(!petsCounterOpen);
  };

  return (
    <div data-testid="pet-counter">
      <ClickableInput
        inputLabel={inputLabel ?? i18n._(t`How many pets?`)}
        onClick={openPetCounter}
        hideIcon={isMobile}
        icon={
          <CarouselRightSmall
            width={ButtonIconSizeMap.default}
            height={ButtonIconSizeMap.default}
            fill="currentColor"
          />
        }
        {...(!hideHelperText && {
          helperText: i18n._(t`For a more accurate search, select at least 1 pet`),
        })}
        {...(inputLabelSize ? { inputLabelSize } : {})}
        {...(inputLabelMarginBottom ? { inputLabelMarginBottom } : {})}
        {...(inputLabelColor ? { inputLabelColor } : {})}
      >
        <Text size="200">{petString}</Text>
      </ClickableInput>

      {isMobile ? (
        <PetCounterMobile
          isBottomSheetOpen={petsCounterOpen}
          dogs={dogs}
          cats={cats}
          puppies={puppies}
          availablePets={availablePets}
          triggeredFrom={triggeredFrom}
          setDogs={setDogs}
          setPuppies={setPuppies}
          setCats={setCats}
          handlePetCounterChange={handlePetCounterChange}
          closeBottomSheet={closePetCounter}
          titleText={i18n._(petCounterTitleText)}
          subtitleText={i18n._(petCounterSubtitleText)}
          saveButtonText={i18n._(petCounterSaveButtonText)}
          closeButtonText={i18n._(petCounterCloseButtonText)}
        />
      ) : (
        <PetCounterDesktop
          isModalOpen={petsCounterOpen}
          dogs={dogs}
          cats={cats}
          puppies={puppies}
          availablePets={availablePets}
          triggeredFrom={triggeredFrom}
          setDogs={setDogs}
          setPuppies={setPuppies}
          setCats={setCats}
          handlePetCounterChange={handlePetCounterChange}
          closeModal={closePetCounter}
          titleText={i18n._(petCounterTitleText)}
          subtitleText={i18n._(petCounterSubtitleText)}
          saveButtonText={i18n._(petCounterSaveButtonText)}
        />
      )}
    </div>
  );
};

export default PetCounter;
