import { MessageDescriptor } from '@lingui/core';
import { plural } from '@lingui/macro';

/**
 * Given a dog, puppy, and cat count, returns a string formatted for display in the header.
 */
export const getFormattedPetData = ({
  dogCount: originalDogCount = 0,
  puppyCount = 0,
  catCount = 0,
}): MessageDescriptor[] => {
  let dogCount = originalDogCount;
  const petDescriptors: MessageDescriptor[] = [];

  if (dogCount || (!puppyCount && !catCount && !dogCount)) {
    if (!puppyCount && !catCount && !dogCount) {
      dogCount = 1;
    }

    petDescriptors.push(
      plural({
        value: dogCount,
        one: '# dog',
        other: '# dogs',
      })
    );
  }

  if (puppyCount) {
    petDescriptors.push(
      plural({
        value: puppyCount,
        one: '# puppy',
        other: '# puppies',
      })
    );
  }

  if (catCount) {
    petDescriptors.push(
      plural({
        value: catCount,
        one: '# cat',
        other: '# cats',
      })
    );
  }

  return petDescriptors;
};
