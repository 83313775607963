import { PureComponent } from 'react';
import isEqual from 'lodash-es/isEqual';
import pick from 'lodash-es/pick';

import markersImage from './markersSprite.png';

type LatLng = { lat: string | number; lng: string | number };

const MARKER_WIDTH = 44;
const MARKER_HEIGHT = 56;
const NUM_MARKERS_WIDE = 2;
const NUM_MARKERS_TALL = 100;
const SCALE_DOWN_FACTOR = 2;

export type Props = {
  position: LatLng;
  map: Record<string, any>;
  google: Record<string, any>;
  index: number;
  title: string;
  rank?: number | null;
  onMouseOver?: (index: number) => void;
  onMouseOut?: () => void;
  onClick?: (index: number) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  hovering?: boolean | null | undefined;
  zIndex?: number | null | undefined;
};

const MARKER_OPTION_PROPS = ['index', 'hovering'];

class Marker extends PureComponent<Props> {
  marker:
    | {
        setMap: (...args: any[]) => any;
        setIcon: (...args: any[]) => any;
        setPosition: (...args: any[]) => any;
        setZIndex: (...args: any[]) => any;
      }
    | null
    | undefined;

  prevPosition = this.position;

  componentDidMount(): void {
    this.renderMarker();
  }

  componentDidUpdate(prevProps: Props): void {
    const { map, google } = this.props;

    if (map !== prevProps.map || google !== prevProps.google) {
      if (this.marker) {
        this.marker.setMap(null);
      }

      this.renderMarker();
      return;
    }

    if (this.marker) {
      const { position } = this;

      if (
        !isEqual(
          pick(prevProps, [...MARKER_OPTION_PROPS]),
          pick(this.props, [...MARKER_OPTION_PROPS])
        )
      ) {
        this.marker.setMap(null);
        this.renderMarker();
      }

      if (!isEqual(position, this.prevPosition)) {
        this.marker.setPosition(position);
        this.prevPosition = position;
      }

      if (prevProps.zIndex !== this.props.zIndex) {
        this.marker.setZIndex(this.props.zIndex);
      }

      // When the dynamic map is loaded, attach focus/blur listeners to markers.
      // (we can't do this as part of attachEventListeners because Gmaps' Marker doesn't have focus/blur events)
      if (this.markerDOMElement === null) {
        // Since the map marker isn't a React component, we have to select it via a DOM query
        const quotedTitle = this.props.title.replace(/"/g, '\\"');
        // eslint-disable-next-line rover/no-platform-specific-globals-or-imports
        this.markerDOMElement = document.querySelector(`[title="${quotedTitle}"]`) as HTMLElement;
        if (this.markerDOMElement) this.attachFocusListeners(this.markerDOMElement);
      }
    }
  }

  componentWillUnmount(): void {
    if (this.marker) {
      this.marker.setMap(null);
    }
  }

  markerDOMElement = null as unknown as HTMLElement;

  get iconOptions(): Record<string, unknown> {
    const { google, rank } = this.props;
    const { hovering } = pick(this.props, MARKER_OPTION_PROPS);
    // Make pins slightly larger on hover/selection so that color is not the only differentiation
    const scaleDownFactor = hovering ? SCALE_DOWN_FACTOR * 0.75 : SCALE_DOWN_FACTOR;

    // If the rank is null or greater than 100, we want to use the blank marker
    const markerOrigin = rank == null || rank > 100 ? 99 : rank - 1;

    return {
      size: new google.maps.Size(MARKER_WIDTH / scaleDownFactor, MARKER_HEIGHT / scaleDownFactor),
      scaledSize: new google.maps.Size(
        (MARKER_WIDTH * NUM_MARKERS_WIDE) / scaleDownFactor,
        (MARKER_HEIGHT * NUM_MARKERS_TALL) / scaleDownFactor
      ),
      origin: new google.maps.Point(
        hovering ? 0 : MARKER_WIDTH / scaleDownFactor,
        markerOrigin * (MARKER_HEIGHT / scaleDownFactor)
      ),
      url: markersImage,
    };
  }

  get position(): LatLng {
    const { lat, lng } = this.props.position;

    if (typeof lat === 'string' && typeof lng === 'string') {
      return { lat: parseFloat(lat), lng: parseFloat(lng) };
    }
    return { lat, lng };
  }

  onMarkerSelected = (): void => {
    const { onMouseOver, index } = this.props;
    onMouseOver && onMouseOver(index);
  };

  onMarkerUnselected = (): void => {
    const { onMouseOut } = this.props;
    onMouseOut && onMouseOut();
  };

  attachEventListeners = (): void => {
    const { google } = this.props;

    if (this.marker && google.maps) {
      // we destructure the values within the event handlers to avoid
      // using stale props, which would occur if we destructured outside
      // of each event handler
      google.maps.event.addListener(this.marker, 'mouseover', this.onMarkerSelected);
      google.maps.event.addListener(this.marker, 'mouseout', this.onMarkerUnselected);

      google.maps.event.addListener(this.marker, 'click', () => {
        const { onClick, index } = this.props;
        onClick && onClick(index);
      });
    }
  };

  attachFocusListeners = (element: HTMLElement): void => {
    // Behavior on focus/blur is identical to mouseOver/mouseOut
    element.addEventListener('focus', this.onMarkerSelected);
    element.addEventListener('blur', this.onMarkerUnselected);
  };

  renderMarker(): void {
    const { map, google, title, zIndex } = this.props;

    if (map && google) {
      const pref = {
        map,
        title, // The title property is used as the aria-label for screen readers
        position: this.position,
        icon: this.iconOptions,
        zIndex,
        optimized: false,
      };

      if (this.marker) {
        google.maps.event.clearInstanceListeners(this.marker);
      }

      this.marker = new google.maps.Marker(pref);
      this.attachEventListeners();
    }
  }

  render(): null {
    return null;
  }
}

export default Marker;
