import React, { FC, useId } from 'react';
import styled, { css } from 'styled-components';

import { A11yHidden, DSTokenMap, FocusOutline, MQ } from '@rover/kibble/styles';
import useMatchMedia from '@rover/react-lib/src/hooks/useMatchMedia';

const Checkbox = styled.input`
  ${A11yHidden}
  height: 100%;
  width: 100%;
`;

type LabelProps = {
  active: boolean;
  bigLabel?: boolean;
};

const bwPrimary = DSTokenMap.BORDER_WIDTH_PRIMARY.toString();
const ibcPrimary = DSTokenMap.INTERACTIVE_BORDER_COLOR_PRIMARY.toString();
const Label = styled.label<LabelProps>`
  align-items: center;
  position: relative;
  background: ${DSTokenMap.INTERACTIVE_BACKGROUND_COLOR_PRIMARY.toString()};
  border-radius: ${DSTokenMap.BORDER_RADIUS_SECONDARY};
  box-shadow: inset 0 0 0 ${bwPrimary} ${ibcPrimary};
  color: ${DSTokenMap.INTERACTIVE_TEXT_COLOR_PRIMARY.toString()};
  cursor: pointer;
  display: flex;
  font-weight: normal;
  justify-content: center;
  margin: 0 0 0 -1px;
  min-height: 40px;
  width: 100%;
  font-size: ${({ bigLabel }) =>
    bigLabel ? DSTokenMap.TEXT_200_FONT_SIZE.toString() : DSTokenMap.TEXT_100_FONT_SIZE.toString()};

  ${FocusOutline({
    outlineOffset: 3,
  })}

  &:first-child {
    margin-left: 0;

    &:not(:last-child) {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &:not(:first-child) {
    &:last-child {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
    &:not(:last-child) {
      border-radius: 0;
    }
  }

  &:hover {
    background: ${DSTokenMap.INTERACTIVE_BACKGROUND_COLOR_PRIMARY_PRESSED.toString()};
    text-decoration: none;
  }

  ${(props) =>
    !props.active
      ? ''
      : css`
          background-color: ${DSTokenMap.BACKGROUND_COLOR_PRIMARY.toString()} !important;
          box-shadow: inset 0 0 0 2px
            ${DSTokenMap.INTERACTIVE_BORDER_COLOR_PRIMARY_ACTIVE.toString()};
          color: ${DSTokenMap.TEXT_COLOR_PRIMARY.toString()};
          z-index: 3;

          &:hover {
            box-shadow: inset 0 0 0 2px
              ${DSTokenMap.INTERACTIVE_BORDER_COLOR_PRIMARY_ACTIVE_PRESSED.toString()};
          }
        `};

  ${MQ.XS.toString()} {
    font-size: ${DSTokenMap.TEXT_100_FONT_SIZE.toString()};
  }
`;

export type Props = {
  buttonType: 'radio' | 'checkbox';
  checked?: boolean;
  label: string;
  name?: string;
  onToggle: (...args: any[]) => any;
  value: string | boolean;
  bigLabel?: boolean;
  borderRadius?: string;
};

const ButtonGroupButton: FC<Props> = ({
  buttonType,
  checked,
  label,
  name,
  onToggle,
  value,
  bigLabel,
  borderRadius,
  ...other
}) => {
  const id = useId();
  const isMobile = useMatchMedia(MQ.MOBILE.getBreakpointString());

  return (
    <Label
      {...other}
      style={{ borderRadius }}
      active={buttonType === 'checkbox' ? Boolean(value) : !!checked}
      htmlFor={id}
      bigLabel={bigLabel}
    >
      <Checkbox
        aria-label={other['aria-label'] ?? ((isMobile && label) || undefined)}
        type={buttonType}
        role={buttonType === 'radio' ? 'radio' : 'checkbox'}
        checked={checked}
        id={id}
        name={name}
        onChange={onToggle}
        value={String(value)}
        data-testid="styled-input"
      />
      <span aria-hidden={isMobile ? 'true' : undefined}>{label}</span>
    </Label>
  );
};

export default ButtonGroupButton;
