import { Children, FC, PropsWithChildren, ReactNode } from 'react';

// React elements can't be stringified, so implement our own take on `Array.prototype.join` for JSX
// Given:
//     join([<div>1</div>, <div>2</div>, <div>3</div>], ", ")
// Returns:
//     [<div>1</div>, ", ", <div>2</div>, ", ", <div>3</div>]
export function join(jsx: ReactNode[], separator: ReactNode): ReactNode[] {
  if (jsx.length === 0) return [];
  const [first, ...rest] = jsx;
  return [first, ...rest.flatMap((element) => [separator, element])];
}

type Props = PropsWithChildren<{ separator: ReactNode }>;

const Join: FC<Props> = ({ children, separator }) => join(Children.toArray(children), separator);

export default Join;
