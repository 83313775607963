import React from 'react';
import styled from 'styled-components';

import Fieldset from '@rover/react-lib/src/components/formFields/Fieldset';

import ButtonGroupButton from './ButtonGroupButton';

const Wrapper = styled(Fieldset)`
  display: flex;
  flex-direction: row;
`;

export type Option = {
  key: string | number;
  label: string;
  value: string | boolean;
  'data-qa-id'?: string;
  [key: string]: any;
};

export type Props = {
  buttonType?: 'radio' | 'checkbox';
  legendId?: string;
  onToggle: (option: Option, index: number, event: Event) => void;
  options: Option[];
  value?: any;
  name?: string;
  bigLabel?: boolean;
  gap?: string;
};

const ButtonGroup = ({
  buttonType = 'checkbox',
  legendId,
  name = '',
  onToggle,
  options,
  value,
  bigLabel,
  gap,
  ...other
}: Props): JSX.Element => (
  <Wrapper
    style={{ gap }}
    legendId={legendId}
    role={buttonType === 'radio' ? 'radiogroup' : 'group'}
    {...other}
  >
    {options.map((o, i) => {
      if (buttonType === 'checkbox') {
        return (
          <ButtonGroupButton
            checked={!!o.value}
            buttonType={buttonType}
            data-qa-id={o['data-qa-id']}
            key={o.key || o.label}
            label={o.label}
            aria-label={o['aria-label']}
            name={name}
            onToggle={(event) => onToggle(o, i, event)}
            value={o.value}
            bigLabel={bigLabel}
            borderRadius={gap ? '4px' : undefined}
          />
        );
      }

      return (
        <ButtonGroupButton
          buttonType={buttonType}
          checked={o.value === value}
          key={o.key || o.label}
          label={o.label}
          aria-label={o['aria-label']}
          name={name}
          onToggle={(event) => onToggle(o, i, event)}
          value={o.value}
          borderRadius={gap ? '4px' : undefined}
        />
      );
    })}
  </Wrapper>
);

export default ButtonGroup;
