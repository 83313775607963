import React, { FC, useCallback, useId } from 'react';
import styled from 'styled-components';

import { Spacing } from '@rover/kibble/styles';
import FancyCheckbox from '@rover/react-lib/src/components/formFields/FancyCheckbox';
import Fieldset from '@rover/react-lib/src/components/formFields/Fieldset';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import FilterLabel from '@rover/shared/js/search/components/FilterLabel';
import { PetType } from '@rover/types/src/Pet';

export interface Props {
  label: string;
  onChange: (petTypes: PetType[]) => void;
  searchFilters: {
    petType: PetType[];
    [other: string]: any;
  };
  layout?: 'column' | 'row';
}

const PetTypeFilterWrapper = styled.div<{ layout?: string }>`
  display: flex;
  flex-direction: ${({ layout }) => layout || 'column'};
`;

const StyledFancyCheckbox = styled(FancyCheckbox)<{ layout?: string }>`
  display: inline-flex !important;
  margin-bottom: 0 !important;
  &:not(:last-child) {
    margin-right: ${Spacing.M.toString()};
  }
`;

const StyledFilterLabel = styled(FilterLabel)<{ layout?: string }>`
  width: auto;
  margin-right: ${({ layout }) => (layout === 'row' ? Spacing.M.toString() : 0)};
`;

const PetTypeFilter: FC<Props> = ({ label, onChange, searchFilters, layout }) => {
  const petTypeId = useId();
  const catTypeId = useId();
  const dogTypeId = useId();
  const { i18n } = useI18n();
  const selectedTypes = searchFilters.petType;

  const handleChange = useCallback(
    (petType: PetType): void => {
      if (selectedTypes.includes(petType)) {
        onChange(selectedTypes.filter((p) => p !== petType));
      } else {
        onChange([...selectedTypes, petType]);
      }
    },
    [onChange, selectedTypes]
  );

  return (
    <PetTypeFilterWrapper layout={layout}>
      <StyledFilterLabel
        id={petTypeId}
        layout={layout}
        as="div"
        data-testid="pet-type-filter-label"
      >
        {label}
      </StyledFilterLabel>
      <Fieldset aria-labelledby={petTypeId}>
        <StyledFancyCheckbox
          checked={selectedTypes.includes(PetType.DOG)}
          id={dogTypeId}
          label={i18n._('Dog')}
          layout={layout}
          onChange={() => handleChange(PetType.DOG)}
          data-qa-id="dog-pet-type-selector"
          fontSize="100"
          labelMarginLeft={layout === 'row' ? '2x' : '4x'}
        />
        <StyledFancyCheckbox
          checked={selectedTypes.includes(PetType.CAT)}
          id={catTypeId}
          layout={layout}
          label={i18n._('Cat')}
          onChange={() => handleChange(PetType.CAT)}
          data-qa-id="cat-pet-type-selector"
          fontSize="100"
          labelMarginLeft={layout === 'row' ? '2x' : '4x'}
        />
      </Fieldset>
    </PetTypeFilterWrapper>
  );
};

export default PetTypeFilter;
