import React from 'react';

import OnOffSwitch from '@rover/react-lib/src/components/buttons/OnOffSwitch';
import { HorizontalLayout } from '@rover/react-lib/src/components/layouts';

type Props = {
  label: string;
  onChange: (arg0: boolean) => void;
  value: boolean;
};

const TogglePreference = ({ label, onChange, value }: Props): JSX.Element => (
  <HorizontalLayout>
    <OnOffSwitch label={label} onToggle={onChange} on={value} inlineLabel />
  </HorizontalLayout>
);

export default TogglePreference;
