import React, { useEffect, useRef, useState } from 'react';
import { Trans } from '@lingui/react';
import styled from 'styled-components';

import dataLayer from '@rover/globals/dataLayer';
import { Flex, Text } from '@rover/kibble/core';
import Avatar from '@rover/kibble/patterns/Avatar';
import { Color } from '@rover/kibble/styles';

import { SEARCH_RESULT_CARD_Z_INDEX } from './SearchResultCard.constants';

const EXPANDED_LINE_CLAMP = 6;
const DEFAULT_LINE_CLAMP = 2;

export type Props = {
  reviewText?: string | null;
  description?: string | null;
  reviewPetPhotoUrl?: string | null;
  expandedLineClampLevel?: number;
  defaultLineClampLevel?: number;
};

// eslint-disable-next-line rover/prefer-kibble-components
const MoreButton = styled.button`
  position: absolute;
  cursor: pointer;
  background: none;
  border: 0 none;
  padding: 12px 0;
  margin: 0;
  right: 0;
  bottom: -12px;
  z-index: ${SEARCH_RESULT_CARD_Z_INDEX.INTERACTIVE.toString()};
`;
// eslint-disable-next-line rover/prefer-kibble-components
const MoreButtonText = styled.span`
  display: inline;
  padding-left: 9ch;
  text-align: right;
  background: linear-gradient(to right, rgba(18, 18, 18, 0), rgba(255, 255, 255, 1) 9ch);
  color: ${Color.BLUE_600.toString()};
  &:hover {
    color: ${Color.BLUE_700.toString()};
  }
`;

// eslint-disable-next-line rover/prefer-kibble-components
const ExpandableReview = styled.p<{
  isExpanded: boolean;
  expandedLineClampLevel: number;
  defaultLineClampLevel: number;
}>`
  width: 100%;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: ${(props) =>
    props.isExpanded ? props.expandedLineClampLevel : props.defaultLineClampLevel};
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
  margin-top: 0px;
`;

const FeaturedReview = ({
  description,
  reviewText,
  reviewPetPhotoUrl,
  expandedLineClampLevel = EXPANDED_LINE_CLAMP,
  defaultLineClampLevel = DEFAULT_LINE_CLAMP,
}: Props): React.ReactElement => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const expandableReviewText = useRef<HTMLParagraphElement>(null);
  const stopObserving = useRef<() => void>((): void => {});

  const handleResize = (): void => {
    if (!expandableReviewText.current) return;
    const currentLineClampValue = isExpanded ? expandedLineClampLevel : defaultLineClampLevel;
    const lineHeight = parseFloat(getComputedStyle(expandableReviewText.current).lineHeight);
    // This is the line count including text that is hidden by the line clamp
    const actualLineCount = Math.round(expandableReviewText.current.scrollHeight / lineHeight);
    const isAllTextVisible = actualLineCount <= currentLineClampValue;
    setIsExpanded(isAllTextVisible);
  };

  useEffect(() => {
    // If the observer is undefined, exit early and don't init them
    if (typeof ResizeObserver === 'undefined' || typeof IntersectionObserver === 'undefined') {
      return () => {};
    }

    // This observer is managed by the declared intersection observer below
    const resizeObserver = new ResizeObserver(() => {
      handleResize();
    });
    const startObservingResize = (): void => {
      if (process.env.JS_ENV_CLIENT && expandableReviewText.current) {
        resizeObserver.observe(expandableReviewText.current);
      }
    };
    const stopObservingResize = (): void => {
      if (process.env.JS_ENV_CLIENT) {
        resizeObserver.disconnect();
      }
    };
    // This observer ensures only the reviews that are in view do resize checks
    const intersectionObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          startObservingResize();
        } else {
          stopObservingResize();
        }
      });
    });
    const startObservingIntersect = (): void => {
      if (process.env.JS_ENV_CLIENT && expandableReviewText.current) {
        intersectionObserver.observe(expandableReviewText.current);
      }
    };
    const stopObservingIntersect = (): void => {
      if (process.env.JS_ENV_CLIENT) intersectionObserver.disconnect();
    };

    stopObserving.current = (): void => {
      // When the user expands we want to stop observing immediately
      // to prevent unwanted collapsing of the text block
      stopObservingIntersect();
      stopObservingResize();
    };

    startObservingIntersect();
    return () => {
      if (process.env.JS_ENV_CLIENT && expandableReviewText.current) {
        stopObservingIntersect();
        stopObservingResize();
      }
    };
  }, []);

  const hasReviewText = !!reviewText;

  const shouldShowReviewerPetPhoto = hasReviewText && reviewPetPhotoUrl;

  return (
    <Flex mt="0x" marginBottom="3x" position="relative">
      {shouldShowReviewerPetPhoto && (
        <Avatar
          imageUrl={reviewPetPhotoUrl}
          type="person"
          size="small"
          marginRight="2x"
          sx={{ flexShrink: 0 }}
        />
      )}
      <ExpandableReview
        ref={expandableReviewText}
        isExpanded={isExpanded}
        expandedLineClampLevel={expandedLineClampLevel}
        defaultLineClampLevel={defaultLineClampLevel}
      >
        {hasReviewText ? (
          <Text size="100" data-testid="reviewText">
            &ldquo;{reviewText}&rdquo;
          </Text>
        ) : (
          <>
            <Text size="100">
              <Trans>About:</Trans>{' '}
            </Text>
            <span itemProp="description">{description}</span>
          </>
        )}
      </ExpandableReview>
      {!isExpanded && (
        <MoreButton
          onClick={() => {
            setIsExpanded(true);
            dataLayer.push({
              event: 'sitter-card-review-read-more-button-click',
            });
            stopObserving.current();
          }}
        >
          <MoreButtonText>
            <Trans>Read more</Trans>
          </MoreButtonText>
        </MoreButton>
      )}
    </Flex>
  );
};

export default FeaturedReview;
