import React, { FC, useEffect } from 'react';
import SimpleMarkdown from '@khanacademy/simple-markdown';

import { Edit, Map, NowBolt, Person, Search } from '@rover/icons';
import { Icon } from '@rover/icons/utils';
import { AlertSeverity } from '@rover/react-lib/src/components/notifications/Alert/Alert';
import { emitAnalyticsEvent } from '@rover/rsdk/src/modules/Analytics';
import { getPath } from '@rover/shared/js/utils/getFullUrl';

import SearchSectionHeader, { HeaderIconEnum } from '../../SearchSectionHeader';

import { SearchSittersBannerShown, SearchSittersBannerShownWithAlert } from './analyticsEvents';

const reactOutput = SimpleMarkdown.defaultReactOutput;
// This method will render markdown in block format (e.g. a div). Do not use
// this method when rendering inside a paragraph.
const mdBlock = SimpleMarkdown.defaultBlockParse;
// This method will render markdown in inline format, which is better suited
// for paragraphs.
const mdInline = SimpleMarkdown.defaultInlineParse;

type SearchSittersBannerProps = {
  title: string;
  text?: string | JSX.Element;
  secondText?: string | JSX.Element;
  banner?: string | null;
  alertType?: AlertSeverity | string;
  icon?: string | Icon;
  infoIconOnClick?: () => void;
};

export const SearchSittersBanner: FC<SearchSittersBannerProps> = ({
  title,
  text,
  secondText,
  banner,
  alertType = AlertSeverity.WARNING,
  icon,
  infoIconOnClick,
}) => {
  useEffect(() => {
    const path = getPath();
    if (path === '/search/') {
      if (alertType === AlertSeverity.WARNING) {
        emitAnalyticsEvent(new SearchSittersBannerShownWithAlert());
      } else {
        emitAnalyticsEvent(new SearchSittersBannerShown());
      }
    }
  }, [alertType]);

  const getIcon = (iconName?: string | Icon): Icon => {
    if (!iconName) {
      return Search;
    }

    if (typeof iconName === 'string') {
      switch (iconName) {
        case HeaderIconEnum.EDIT:
          return Edit;
        case HeaderIconEnum.MAP:
          return Map;
        case HeaderIconEnum.BOLT:
          return NowBolt;
        case HeaderIconEnum.PERSON:
          return Person;
        case HeaderIconEnum.SEARCH:
        default:
          return Search;
      }
    }

    return iconName;
  };

  const getSubHeaderText = (textElement): React.ReactNode | JSX.Element => {
    if (typeof textElement === 'string') {
      return reactOutput(mdInline(textElement));
    }
    return textElement;
  };

  return (
    <SearchSectionHeader
      headerIcon={getIcon(icon)}
      headerText={title}
      primarySubheaderText={getSubHeaderText(text)}
      secondarySubheaderText={getSubHeaderText(secondText)}
      alertText={banner && reactOutput(mdBlock(banner))}
      alertSeverity={alertType as AlertSeverity}
      infoIconOnClick={infoIconOnClick}
    />
  );
};
