import React, { FC, useCallback } from 'react';

import { Box, Button, Flex, Heading } from '@rover/kibble/core';
import BottomSheet from '@rover/kibble/official-patterns/BottomSheet';
import { useI18n } from '@rover/rsdk/src/modules/I18n';

import PetCounterBody, { PetCounterBodyProps } from './PetCounterBody';

export interface PetCounterMobileProps extends PetCounterBodyProps {
  isBottomSheetOpen: boolean;
  handlePetCounterChange: () => void;
  closeBottomSheet: () => void;
  titleText: string;
  subtitleText: string;
  saveButtonText: string;
  closeButtonText: string;
}

const PetCounterMobile: FC<PetCounterMobileProps> = ({
  isBottomSheetOpen,
  dogs,
  cats,
  puppies,
  availablePets,
  triggeredFrom,
  setDogs,
  setPuppies,
  setCats,
  handlePetCounterChange,
  closeBottomSheet,
  titleText,
  subtitleText,
  saveButtonText,
  closeButtonText,
}) => {
  const { i18n } = useI18n();

  const onConfirm = useCallback(() => {
    handlePetCounterChange();
    closeBottomSheet();
  }, [handlePetCounterChange, closeBottomSheet]);

  return (
    <BottomSheet
      onRequestClose={closeBottomSheet}
      isFullHeight={false}
      isOpen={isBottomSheetOpen}
      isDismissible
      hideCloseIcon
    >
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        height="auto"
      >
        <Flex flexDirection="column" width="100%">
          <Heading size="200" textAlign="left">
            {i18n._(titleText)}
          </Heading>
          <Heading size="100" fontWeight="regular" textColor="secondary" mb="1x">
            {i18n._(subtitleText)}
          </Heading>
          <Box paddingY="6x" paddingX="0x">
            <PetCounterBody
              dogs={dogs}
              cats={cats}
              puppies={puppies}
              availablePets={availablePets}
              triggeredFrom={triggeredFrom}
              setDogs={setDogs}
              setPuppies={setPuppies}
              setCats={setCats}
            />
          </Box>
        </Flex>
        <Button
          data-testid="save-pets-button"
          variant="primary"
          size="small"
          fullWidth
          onClick={onConfirm}
          disabled={dogs === 0 && cats === 0 && puppies === 0}
        >
          {i18n._(saveButtonText)}
        </Button>
        <Button
          data-testid="close-pets-button"
          variant="default"
          size="small"
          fullWidth
          onClick={closeBottomSheet}
          marginTop="3x"
          marginBottom="1x"
        >
          {i18n._(closeButtonText)}
        </Button>
      </Flex>
    </BottomSheet>
  );
};

export default PetCounterMobile;
