import React, { FC, useCallback, useEffect, useId, useState } from 'react';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import { Flex, Text } from '@rover/kibble/core';
import { DSTokenMap } from '@rover/kibble/styles';
import Slider from '@rover/react-lib/src/components/formFields/Slider';
import { PRICE_FILTER } from '@rover/shared/js/constants/searchPage.constants';
import FilterFieldset from '@rover/shared/js/search/components/FilterFieldset';
import { formatCurrency } from '@rover/utilities/currency';

export type Props = {
  currencyCode: string;
  label: string;
  language: string;
  onChange: (values: { min: number; max: number }) => void;
  selectedRangeMax: number;
  selectedRangeMin: number;
  sliderMax?: number | null;
  sliderMin?: number | null;
  isIncludingFees?: boolean;
};

export type State = {
  isDragging: boolean;
  maxHandleValue: number;
  minHandleValue: number;
};

const HANDLE_HEIGHT = 20;
const HANDLE_HEIGHT_MOBILE = 28;

const StyledSlider = styled(Slider)`
  flex-grow: 1;
  margin: 0 ${DSTokenMap.SPACE_4X};
`;

type CurrencyLabelProps = {
  value: number;
  currencyCode: string;
  language: string;
  isIncludingFees?: boolean;
  isMaxValue?: boolean;
};

const CurrencyLabel = ({
  value,
  currencyCode,
  language,
  isIncludingFees,
  isMaxValue,
}: CurrencyLabelProps): JSX.Element => {
  const formattedCurrencyLabel = formatCurrency(value, currencyCode, language, {
    includeDecimal: false,
  });

  return (
    <Text size="100">
      {formattedCurrencyLabel}
      {isIncludingFees && isMaxValue && '+'}
    </Text>
  );
};

const PriceFilter: FC<Props> = ({
  currencyCode,
  label,
  language,
  sliderMax = PRICE_FILTER.DEFAULT_MAX_PRICE,
  sliderMin = PRICE_FILTER.DEFAULT_MIN_PRICE,
  selectedRangeMax,
  selectedRangeMin,
  onChange,
  isIncludingFees,
}) => {
  const priceSliderId = useId();
  const [isDragging, setIsDragging] = useState(false);
  const [minHandleValue, setMinHandleValue] = useState(selectedRangeMin);
  const [maxHandleValue, setMaxHandleValue] = useState(selectedRangeMax);
  const [prevCurrencyCode, setPrevCurrencyCode] = useState(currencyCode);

  const sliderValuesWithinBounds = useCallback(
    (currentSliderValues: number[]): number[] => {
      const [selectedMin, selectedMax] = currentSliderValues;
      if (sliderMin == null || sliderMax == null) return currentSliderValues;

      // Bounds are present and the slider is outside of those, reset the slider to bounds
      if (
        selectedMin < sliderMin ||
        selectedMin > sliderMax ||
        selectedMax < sliderMin ||
        selectedMax > sliderMax
      ) {
        return [sliderMin, sliderMax];
      }

      return currentSliderValues;
    },
    [sliderMin, sliderMax]
  );

  const handleChange = useCallback(
    ({ values }: { values: number[] }): void => {
      const [$minHandleValue, $maxHandleValue] = sliderValuesWithinBounds(values);
      onChange({ max: $maxHandleValue, min: $minHandleValue });
      setMinHandleValue($minHandleValue);
      setMaxHandleValue($maxHandleValue);
      setIsDragging(false);
    },
    [onChange, sliderValuesWithinBounds]
  );

  const handleValuesUpdated = useCallback(({ values }: { values: number[] }): void => {
    const [$minHandleValue, $maxHandleValue] = values;
    setMinHandleValue($minHandleValue);
    setMaxHandleValue($maxHandleValue);
  }, []);

  const handleDragMove = useCallback((): void => {
    setIsDragging(true);
  }, []);

  useEffect(() => {
    if (currencyCode !== prevCurrencyCode) {
      onChange({ max: sliderMax as number, min: sliderMin as number });
      setPrevCurrencyCode(currencyCode);
    }
  }, [currencyCode, onChange, prevCurrencyCode, sliderMax, sliderMin]);

  const [sliderRangeMin, sliderRangeMax] = sliderValuesWithinBounds([
    selectedRangeMin,
    selectedRangeMax,
  ]);

  const minHandlePrice = isDragging ? minHandleValue : sliderRangeMin;
  const maxHandlePrice = isDragging ? maxHandleValue : sliderRangeMax;

  return (
    <FilterFieldset aria-labelledby={priceSliderId}>
      <Flex flexDirection="column" id={priceSliderId}>
        <Text
          size="100"
          textColor="secondary"
          fontWeight="semibold"
          mb={isIncludingFees ? '1x' : '2x'}
        >
          {label}
        </Text>
        {isIncludingFees && (
          <Text size="100" textColor="tertiary" mb="2x">
            <Trans>including fees</Trans>
          </Text>
        )}
      </Flex>
      <Flex alignItems="center">
        <CurrencyLabel
          value={minHandlePrice}
          currencyCode={currencyCode}
          language={language}
          data-testid="min-value"
          isIncludingFees={isIncludingFees}
        />
        <StyledSlider
          data-testid="price-slider"
          handleHeight={HANDLE_HEIGHT}
          handleHeightMobile={HANDLE_HEIGHT_MOBILE}
          id={priceSliderId}
          max={sliderMax}
          min={sliderMin}
          onChange={handleChange}
          onSliderDragMove={handleDragMove}
          onValuesUpdated={handleValuesUpdated}
          values={[sliderRangeMin, sliderRangeMax]}
          prefix={currencyCode}
        />
        <CurrencyLabel
          value={maxHandlePrice}
          currencyCode={currencyCode}
          language={language}
          data-testid="max-value"
          isIncludingFees={isIncludingFees}
          isMaxValue={maxHandleValue === sliderMax}
        />
      </Flex>
    </FilterFieldset>
  );
};

export default PriceFilter;
