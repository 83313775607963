import { FC } from 'react';

import { Connect, ContainerProps } from '@rover/react-lib/src/components/utils/Redux';
import events from '@rover/react-lib/src/pages/search/SearchPage/analytics';
import {
  actions as searchMapActions,
  effects as searchMapEffects,
} from '@rover/react-lib/src/pages/search/SearchPage/SearchMap.duck';
import {
  effects as searchModalEffects,
  selectors as searchModalSelectors,
} from '@rover/react-lib/src/pages/search/SearchPage/SearchModal.duck';
import { effects as searchPageEffects } from '@rover/react-lib/src/pages/search/SearchPage/SearchPage.duck';
import { selectors as frontendConfigurationSelectors } from '@rover/react-lib/src/redux/ducks/frontendConfiguration.duck';
import { selectors as searchSelectors } from '@rover/react-lib/src/redux/ducks/search.duck';
import { selectors as serviceTypeSelectors } from '@rover/react-lib/src/redux/ducks/serviceTypes.duck';

import { SearchWizardProvider } from './context';
import { SearchWizardModal } from './SearchWizardModal';

const actions = {
  setShowStaticMap: searchMapActions.setShowStaticMap,
  setShowDynamicMap: searchMapActions.setShowDynamicMap,
};

const effects = {
  fireAnalyticsEvent: searchPageEffects.fireAnalyticsEvent,
  updateFiltersAndFireSearchOnChange: searchMapEffects.updateFiltersFireSearchAndLoadDynamicMap,
  updateFilters: searchModalEffects.updateFilters,
};

const selector = (state) => ({
  getServiceTypeBySlug: serviceTypeSelectors.getServiceTypeBySlug(state),
  hasPets: searchSelectors.getHasPets(state),
  isCatOnly: searchModalSelectors.getIsCatOnly(state),
  searchFilters: searchModalSelectors.getFilters(state),
  serviceTypeFilterOptions: searchModalSelectors.getServiceTypeFilterOptions(state),
  serviceTypeOptions: searchModalSelectors.getPetTypeBrowsableServiceTypeOptions(state),
  petSizeBuckets: frontendConfigurationSelectors.getSettings(state).dogSizeBuckets,
  weightUnit: frontendConfigurationSelectors.getSettings(state).weightUnit,
});

export type Props = ContainerProps<
  FC<{ isOpen: boolean; onClose: () => void }>,
  typeof actions,
  typeof effects,
  typeof selector
>;

export const SearchWizardModalContainer: FC<Props> = (props) => {
  return (
    <Connect
      actions={actions}
      effects={effects}
      selector={selector}
      onMount={[
        () => searchPageEffects.fireAnalyticsEvent(events.SearchOwnerPreferencesDisplayEvent),
      ]}
    >
      {(connectedProps) => (
        <SearchWizardProvider {...connectedProps} {...props}>
          <SearchWizardModal />
        </SearchWizardProvider>
      )}
    </Connect>
  );
};
