import { AlertSeverity } from '@rover/react-lib/src/components/notifications/Alert/Alert';
import {
  AvailableForSelectedDatesEnum,
  OptimizedSearchResult,
} from '@rover/rsdk/src/apiClient/latest';
import { ServiceType } from '@rover/shared/js/constants/service';

import {
  AlertContentEnum,
  getAlertContent,
  getSubHeader,
  HEADER_TITLES,
  HeaderIconEnum,
  HeaderTitlesEnum,
  SubHeadersEnum,
} from './constants';
import { DateData, PrebuiltHeaderProps } from './types';

type Props = {
  dateData: DateData;
  individualPetTypeCountDescription: string;
  isBestMatchesSearch?: boolean;
  isPriceTransparency?: boolean;
  selectedProvider: OptimizedSearchResult;
  serviceType: ServiceType;
};

const buildSelectedProviderHeader = ({
  dateData,
  individualPetTypeCountDescription,
  isBestMatchesSearch,
  isPriceTransparency,
  selectedProvider,
  serviceType,
}: Props): PrebuiltHeaderProps | object => {
  const { startDate, endDate } = dateData;
  const availability =
    selectedProvider.availableForSelectedDates || AvailableForSelectedDatesEnum.unknown;
  const isUnavailable = availability === AvailableForSelectedDatesEnum.unavailable;
  const hasDates = startDate && endDate;

  const title =
    HEADER_TITLES[
      isUnavailable
        ? HeaderTitlesEnum.SELECTED_SITTER_UNAVAILABLE_TITLE
        : HeaderTitlesEnum.SELECTED_SITTER_TITLE
    ];

  let primarySubheader;
  let secondarySubheader;
  let alertText;

  if (!isPriceTransparency) {
    if (hasDates) {
      if (availability === AvailableForSelectedDatesEnum.available) {
        primarySubheader = getSubHeader(SubHeadersEnum.SELECTED_SITTER_AVAILABLE, {
          serviceType,
          sitterName: selectedProvider.shortName,
          dateData,
        });
      } else if (availability === AvailableForSelectedDatesEnum.unavailable) {
        primarySubheader = getSubHeader(SubHeadersEnum.SELECTED_SITTER_UNAVAILABLE, {
          serviceType,
          sitterName: selectedProvider.shortName,
          dateData,
        });
      } else {
        primarySubheader = getSubHeader(SubHeadersEnum.SELECTED_SITTER_NO_DATES);
      }
    } else {
      primarySubheader = getSubHeader(SubHeadersEnum.SELECTED_SITTER_NO_DATES);
    }
  } else if (!hasDates) {
    // Price transparency, no dates
    alertText = getAlertContent(AlertContentEnum.PRICE_TRANSPARENCY_ADD_DATES, {
      serviceType,
      individualPetTypeCountDescription,
    });
  } else {
    // Price transparency, has dates
    alertText = getAlertContent(
      isUnavailable
        ? AlertContentEnum.PRICE_TRANSPARENCY_SELECTED_SITTER_UNAVAILABLE
        : AlertContentEnum.PRICE_TRANSPARENCY_SELECTED_SITTER_AVAILABLE,
      {
        serviceType,
        individualPetTypeCountDescription,
        dateData,
        sitterName: selectedProvider.shortName,
      }
    );
  }

  if (isBestMatchesSearch && selectedProvider.isBestMatch) {
    if (isPriceTransparency) {
      primarySubheader = getSubHeader(SubHeadersEnum.BEST_MATCH_SELECTED_PROVIDER_SHORT_LEAD_TIME);
    } else {
      secondarySubheader = getSubHeader(
        SubHeadersEnum.BEST_MATCH_SELECTED_PROVIDER_SHORT_LEAD_TIME
      );
    }
  }

  return {
    icon: HeaderIconEnum.PERSON,
    title,
    primarySubheader,
    secondarySubheader,
    alertText,
    alertSeverity: startDate && endDate ? AlertSeverity.INFO : AlertSeverity.WARNING,
    isBestMatchHeader: false,
  };
};

export default buildSelectedProviderHeader;
