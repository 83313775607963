import React from 'react';
import { plural, t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import get from 'lodash-es/get';
import intersection from 'lodash-es/intersection';
import styled from 'styled-components';

import { Box, Heading, Text } from '@rover/kibble/core';
import { DSTokenMap } from '@rover/kibble/styles';
import { CalendarDirection } from '@rover/react-lib/src/components/datetime/DatePicker';
import ServiceTypeFilter, {
  ServiceFilterType,
} from '@rover/react-lib/src/components/formFields/ServiceTypeFilter';
import Advanced from '@rover/react-lib/src/pages/search/SearchPage/components/Filters/Advanced';
import {
  DayTimeAvailability,
  DaytimeServiceScheduler,
  FulltimeAvailability,
  OvernightDateRange,
} from '@rover/react-lib/src/pages/search/SearchPage/components/Filters/Dates';
import Location from '@rover/react-lib/src/pages/search/SearchPage/components/Filters/Location';
import PriceFilter from '@rover/react-lib/src/pages/search/SearchPage/components/Filters/PriceFilter';
import SpacesRequired from '@rover/react-lib/src/pages/search/SearchPage/components/Filters/SpacesRequired';
import StarSitterFilter from '@rover/react-lib/src/pages/search/SearchPage/components/Filters/StarSitterFilter';
import PetCounter from '@rover/react-lib/src/pages/search/SearchPage/components/PetCounter';
import {
  advancedFiltersCounter,
  getServiceType,
  shouldShowControls,
} from '@rover/react-lib/src/pages/search/SearchPage/utilities';
import { useLocationInputTexts } from '@rover/react-lib/src/pages/search/SearchPage/utilities/useLocationInputTexts';
import getMaxRequestDate from '@rover/react-lib/src/utils/getMaxRequestDate';
import { ApiFrontendConfigurationRetrieve200SettingsDogSizeBuckets } from '@rover/rsdk/src/apiClient/latest';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import {
  DROP_OFF_PICK_UP_SUPPORTED_SERVICES,
  SHOW_SPACES_REQUIRED_WITH_PETS_SERVICES,
} from '@rover/shared/js/constants/searchPage.constants';
import Pets from '@rover/shared/js/search/components/Pets';
import PetSize from '@rover/shared/js/search/components/PetSize';
import PetTypeFilter from '@rover/shared/js/search/components/PetTypeFilter';
import getIsCatOnlyPetType from '@rover/shared/js/search/utilities/getIsCatOnlyPetType';
import type {
  Pet,
  SearchFilters,
  SearchFiltersOptions,
  ServiceType,
  ServiceTypeOption,
} from '@rover/types';
import type { SearchDerivedData } from '@rover/types/src/apiResponses/FetchSearchResponse';
import { PetType } from '@rover/types/src/Pet';
import { ServiceTypeSlug } from '@rover/types/src/ServiceType';

import { CollectionWrapper, FilterWrapper } from '../common';
import DaycareTypeFilter from '../DaycareTypeFilter';

export type Props = {
  currencyCode: string;
  getPriceUnit: (arg0: string) => string;
  getServiceTypeBySlug: (arg0: string) => ServiceType | null | undefined;
  hasPets: boolean;
  isCatOnly: boolean;
  language: string;
  onScrollableFilterChange: () => void;
  onUpdateInnerHeight: () => void;
  petOptions: Pet[];
  petSizeBuckets: ApiFrontendConfigurationRetrieve200SettingsDogSizeBuckets;
  priceFilterMaxValue: number;
  priceFilterMinValue: number;
  searchFilters: SearchFilters;
  serviceTypeFilterOptions: SearchFiltersOptions;
  serviceTypeOptions: ServiceTypeOption[];
  updateFiltersAndFireSearchOnChange: (searchFilters: Partial<SearchFilters>) => void;
  weightUnit: string;
  isInGingrFacilitiesInSearchExperiment: boolean;
  queryDerivedData: SearchDerivedData;
  rolloutPriceTransparency?: boolean;
  isInPetCountersExperiment?: boolean;
};

const FILTERS_TO_RESET_SCROLL: Array<keyof Partial<SearchFilters>> = [
  'serviceType',
  'location',
  'startDate',
  'endDate',
  'petType',
  'giantDogs',
  'largeDogs',
  'mediumDogs',
  'smallDogs',
  'starSitter',
  'inSittersHome',
  'atDaycareFacility',
];

const StyledCollectionWrapper = styled(CollectionWrapper)`
  padding: 10px 10px 0 10px;
`;

const AdvancedWrapper = styled(FilterWrapper)`
  background-color: ${DSTokenMap.BACKGROUND_COLOR_SECONDARY.toString()};
  margin-bottom: 0;
  margin-left: -${DSTokenMap.SPACE_4X};
  margin-right: -15px;
  padding: ${DSTokenMap.SPACE_4X};
`;

const SidebarFiltersCollection = ({
  currencyCode,
  getPriceUnit,
  getServiceTypeBySlug,
  hasPets,
  isCatOnly,
  isInGingrFacilitiesInSearchExperiment,
  language,
  onScrollableFilterChange,
  onUpdateInnerHeight,
  petOptions,
  petSizeBuckets,
  priceFilterMaxValue,
  priceFilterMinValue,
  queryDerivedData,
  searchFilters,
  serviceTypeFilterOptions,
  serviceTypeOptions,
  updateFiltersAndFireSearchOnChange,
  weightUnit,
  rolloutPriceTransparency,
  isInPetCountersExperiment,
}: Props): JSX.Element => {
  const { i18n } = useI18n();
  const serviceType = getServiceTypeBySlug(searchFilters.serviceType);
  const isIncludingFees = serviceTypeFilterOptions.minprice?.fullprice === true;
  const { morningAvailability, middayAvailability, eveningAvailability } = serviceTypeFilterOptions;

  const { showStarSitterFilter } = queryDerivedData;
  const { starSitterLink } = queryDerivedData;

  // Abstract this into a selector in https://roverdotcom.atlassian.net/browse/DEV-42261
  const startPlaceholder = DROP_OFF_PICK_UP_SUPPORTED_SERVICES.has(searchFilters.serviceType)
    ? t`Drop off`
    : t`Start`;
  const endPlaceholder = DROP_OFF_PICK_UP_SUPPORTED_SERVICES.has(searchFilters.serviceType)
    ? t`Pick up`
    : t`End`;

  const handleChange = (changedFilters: Partial<SearchFilters>): void => {
    const changedFiltersKeys = Object.keys(changedFilters);
    const scrollableFilters = intersection(FILTERS_TO_RESET_SCROLL, changedFiltersKeys);
    const scrollableFiltersWithUpdatedValues = scrollableFilters.filter(
      (filter) => searchFilters[filter] !== changedFilters[filter]
    );
    const shouldResetScroll = scrollableFiltersWithUpdatedValues.length !== 0;

    if (shouldResetScroll) {
      onScrollableFilterChange();
    }

    updateFiltersAndFireSearchOnChange(changedFilters);
  };

  const handleChangeDateRange = (dateRange): void => {
    if (dateRange) {
      handleChange({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      });
    }
  };

  const {
    showDateRange,
    showDaycareType,
    showDaytimeAvailability,
    showDaytimeServiceScheduler,
    showFulltimeAvailability,
    showPets,
    showPetSize,
    showPetType,
    showPrice,
    showSpacesRequired,
    showStarSitterOnlyFilter,
  } = shouldShowControls({
    hasPets,
    isCatOnly,
    serviceOptions: serviceTypeFilterOptions,
    serviceType: serviceType || undefined,
    serviceTypeSlug: searchFilters.serviceType,
    isInGingrFacilitiesInSearchExperiment,
    isDaycareFilterOutsideMarketsInExperiment: undefined, // Always `undefined` on desktop to hide the daycare filter in outside markets
    showStarSitterFilter,
    rolloutPriceTransparency,
    isIncludingFees,
  });

  const maxDate = getMaxRequestDate();

  const shouldShowPetCounter = rolloutPriceTransparency || isInPetCountersExperiment;

  const { getPlaceholderText, getTitleText } = useLocationInputTexts({
    titleTextControl: i18n._(t`${serviceType?.displayName} near`),
  });

  return (
    <Box>
      <StyledCollectionWrapper>
        <Heading as="h2" a11yHidden>
          <Trans>Search Filters</Trans>
        </Heading>

        {showPets && shouldShowPetCounter && (
          <FilterWrapper>
            <Pets
              label={i18n._(
                plural({
                  value: serviceTypeFilterOptions.pets
                    ? serviceTypeFilterOptions.pets.choices.length
                    : 0,
                  one: 'Your pet:',
                  other: 'Your pets:',
                })
              )}
              onChange={({ pet, petType = [] }) => {
                const localIsCatOnly = getIsCatOnlyPetType(petType);
                const newServiceType = getServiceType(
                  localIsCatOnly,
                  searchFilters.serviceType as ServiceTypeSlug,
                  get(serviceType, 'suggestedPetType')
                );
                return handleChange({
                  pet,
                  petType,
                  catCare: localIsCatOnly,
                  serviceType: newServiceType,
                });
              }}
              petOptions={petOptions}
              searchFilters={searchFilters}
            />
            {!searchFilters.pet.length && (
              <Text size="100" textColor="tertiary">
                {i18n._(t`Select at least one pet to ensure a more accurate search`)}
              </Text>
            )}
          </FilterWrapper>
        )}

        {showPetType && shouldShowPetCounter && (
          <FilterWrapper>
            <PetCounter
              dogCount={searchFilters.dogCount}
              catCount={searchFilters.catCount}
              puppyCount={searchFilters.puppyCount}
              inputLabelSize="100"
              inputLabelColor="secondary"
              inputLabelMarginBottom="2x"
              availablePets={[PetType.DOG, PetType.PUPPY, PetType.CAT]}
              triggeredFrom="Search"
              onChange={({ petType, dogCount, catCount, puppyCount }) => {
                const localIsCatOnly = getIsCatOnlyPetType(petType);
                const updatedServiceType = getServiceType(
                  localIsCatOnly,
                  searchFilters.serviceType as ServiceTypeSlug,
                  get(serviceType, 'suggestedPetType')
                );
                const args = {
                  petType,
                  catCare: localIsCatOnly,
                  serviceType: updatedServiceType,
                  dogCount,
                  catCount,
                  puppyCount,
                };
                return handleChange(args);
              }}
              hideHelperText
            />
          </FilterWrapper>
        )}

        <FilterWrapper>
          <ServiceTypeFilter
            filterType={ServiceFilterType.ICON_DROPDOWN}
            label={i18n._('Service type')}
            onChange={(newValues) => {
              handleChange(newValues);
              if (onUpdateInnerHeight) {
                onUpdateInnerHeight();
              }
            }}
            priceFilterMaxValue={priceFilterMaxValue}
            priceFilterMinValue={priceFilterMinValue}
            searchFilters={searchFilters}
            serviceTypeOptions={serviceTypeOptions}
          />
        </FilterWrapper>

        {serviceType && serviceType.displayName && (
          <FilterWrapper>
            <Location
              locationInputId="location-input-sidebar"
              label={getTitleText()}
              placeholder={getPlaceholderText()}
              onChange={handleChange}
              location={searchFilters.location}
            />
          </FilterWrapper>
        )}

        {showDateRange && (
          <FilterWrapper>
            <OvernightDateRange
              calendarDirection={CalendarDirection.DOWN}
              endPlaceholder={endPlaceholder}
              label={i18n._('Dates')}
              language={language}
              maxDate={maxDate}
              onChange={handleChangeDateRange}
              searchFilters={searchFilters}
              showArrow
              startPlaceholder={startPlaceholder}
            />
          </FilterWrapper>
        )}

        {showDaytimeServiceScheduler && (
          <FilterWrapper>
            <DaytimeServiceScheduler
              dayOfWeekLabel={i18n._('For which days?')}
              displayAs="compact"
              endPlaceholder={endPlaceholder}
              language={language}
              maxDate={maxDate}
              onChange={(frequency) => {
                handleChange({ frequency });
                onUpdateInnerHeight();
              }}
              onDateRangeChange={handleChangeDateRange}
              oneTimeLabel={i18n._('Dates')}
              onSelectedDaysChange={(selectedDays) => handleChange({ selectedDays })}
              onStartDateChange={handleChangeDateRange}
              repeatStartDateLabel={i18n._('Start Date')}
              searchFilters={searchFilters}
              serviceType={serviceType}
              showArrow
              showLabel
              startPlaceholder={startPlaceholder}
            />
          </FilterWrapper>
        )}

        {showSpacesRequired &&
          (!shouldShowPetCounter ||
            SHOW_SPACES_REQUIRED_WITH_PETS_SERVICES.has(searchFilters.serviceType)) && (
            <FilterWrapper>
              <SpacesRequired
                onChange={(spacesRequired) => handleChange({ spacesRequired })}
                searchFilters={searchFilters}
              />
            </FilterWrapper>
          )}

        {showDaytimeAvailability && (
          <FilterWrapper>
            <DayTimeAvailability
              availabilityBuckets={{
                morning: morningAvailability ? morningAvailability.label : null,
                midday: middayAvailability ? middayAvailability.label : null,
                evening: eveningAvailability ? eveningAvailability.label : null,
              }}
              label={i18n._('Which time(s) do you need?')}
              labelIsVisible
              onChange={(availability) => handleChange(availability)}
              searchFilters={searchFilters}
            />
          </FilterWrapper>
        )}

        {showFulltimeAvailability && (
          <FilterWrapper>
            <FulltimeAvailability
              label={i18n._('Sitter is home full-time')}
              onChange={(fulltimeAvailability) => handleChange({ fulltimeAvailability })}
              searchFilters={searchFilters}
            />
          </FilterWrapper>
        )}

        {showPetType && !shouldShowPetCounter && (
          <FilterWrapper>
            <PetTypeFilter
              label={i18n._('Pet type(s)')}
              onChange={(petType) => {
                const localIsCatOnly = getIsCatOnlyPetType(petType);
                const updatedServiceType = getServiceType(
                  localIsCatOnly,
                  searchFilters.serviceType as ServiceTypeSlug,
                  get(serviceType, 'suggestedPetType')
                );
                const args = {
                  petType,
                  catCare: localIsCatOnly,
                  serviceType: updatedServiceType,
                };
                return handleChange(args);
              }}
              searchFilters={searchFilters}
            />
          </FilterWrapper>
        )}

        {showPetSize && (
          <FilterWrapper>
            <PetSize
              displayAsIconButtons={false}
              label={i18n._(t`Dog size (${weightUnit})`)}
              onChange={(sizeKey) => handleChange({ [sizeKey]: !searchFilters[sizeKey] })}
              petSizeBuckets={petSizeBuckets}
              searchFilters={searchFilters}
              weightUnit={weightUnit}
            />
          </FilterWrapper>
        )}

        {showPets && !shouldShowPetCounter && (
          <FilterWrapper>
            <Pets
              label={i18n._(
                plural({
                  value: serviceTypeFilterOptions.pets
                    ? serviceTypeFilterOptions.pets.choices.length
                    : 0,
                  one: 'Your pet:',
                  other: 'Your pets:',
                })
              )}
              onChange={({ pet, petType = [] }) => {
                const localIsCatOnly = getIsCatOnlyPetType(petType);
                const newServiceType = getServiceType(
                  localIsCatOnly,
                  searchFilters.serviceType as ServiceTypeSlug,
                  get(serviceType, 'suggestedPetType')
                );
                return handleChange({
                  pet,
                  petType,
                  catCare: localIsCatOnly,
                  serviceType: newServiceType,
                });
              }}
              petOptions={petOptions}
              searchFilters={searchFilters}
            />
          </FilterWrapper>
        )}

        {showPrice && (
          <FilterWrapper>
            <PriceFilter
              currencyCode={currencyCode}
              label={i18n._(
                /* i18n: Rate per {priceUnit} e.g. day, night, walk */
                t`Rate per ${getPriceUnit(searchFilters.serviceType)}`
              )}
              language={language}
              onChange={({ min, max }) => {
                handleChange({ minprice: min, maxprice: max });
              }}
              selectedRangeMin={searchFilters.minprice}
              selectedRangeMax={searchFilters.maxprice || priceFilterMaxValue}
              sliderMax={priceFilterMaxValue}
              sliderMin={priceFilterMinValue}
              isIncludingFees={isIncludingFees}
            />
          </FilterWrapper>
        )}

        {showDaycareType && (
          <FilterWrapper>
            <DaycareTypeFilter
              onChange={(inSittersHome, atDaycareFacility) => {
                handleChange({ inSittersHome, atDaycareFacility });
              }}
              inSittersHome={searchFilters.inSittersHome}
              atDaycareFacility={searchFilters.atDaycareFacility}
              placement="search-sidebar"
            />
          </FilterWrapper>
        )}
      </StyledCollectionWrapper>
      {showStarSitterOnlyFilter && (
        <Box mb="4x" mt="6x" pl="2x" pr="2x">
          <StarSitterFilter
            label={i18n._('Only show star sitters')}
            onChange={(starSitter) => handleChange({ starSitter })}
            value={searchFilters.starSitter}
            starSitterLink={starSitterLink}
          />
        </Box>
      )}
      <StyledCollectionWrapper>
        <AdvancedWrapper>
          <Advanced
            advancedFiltersCount={advancedFiltersCounter(searchFilters, serviceTypeFilterOptions)}
            isPriceTransparency={!!shouldShowPetCounter}
            onChange={(filter) => handleChange(filter)}
            searchFiltersOptions={serviceTypeFilterOptions}
            searchFilters={searchFilters}
            onCategoryExpandAndCollapse={onUpdateInnerHeight}
          />
        </AdvancedWrapper>
      </StyledCollectionWrapper>
    </Box>
  );
};

export default SidebarFiltersCollection;
