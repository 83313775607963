import React, { FC, useEffect, useRef } from 'react';
import { t } from '@lingui/macro';

import Modal from '@rover/kibble/official-patterns/Modal';
import { useI18n } from '@rover/rsdk/src/modules/I18n';

import { PetCount } from './components/PetCount';
import { PetInformation } from './components/PetInformation';
import { ServiceDetails } from './components/ServiceDetails';
import { useSearchWizard } from './context';

const STEP_COMPONENTS = {
  0: ServiceDetails,
  1: PetCount,
  2: PetInformation,
};

export const SearchWizardModal: FC = () => {
  const { isOpen, handleClose, currentStep } = useSearchWizard();
  const { i18n } = useI18n();
  const CurrentComponent = STEP_COMPONENTS[currentStep];
  const modalRef = useRef<HTMLElement | null>(null);

  const STEP_HEADERS = {
    0: i18n._(t`What are you searching for?`),
    1: i18n._(t`How many pets need care?`),
    2: i18n._(t`Tell us more about your pets`),
  };
  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.focus();
    }
  }, [currentStep]);

  return (
    <Modal
      modalRef={(node) => {
        modalRef.current = node;
      }}
      isOpen={isOpen}
      onRequestClose={handleClose as () => void}
      showCloseIcon
      headerText={STEP_HEADERS[currentStep]}
      headerSize="400"
      overlaySx={{ overflowY: 'auto', flexWrap: 'wrap' }}
      contentProps={{ pb: '6x', width: '600px', maxHeight: 'unset' }}
    >
      <CurrentComponent />
    </Modal>
  );
};
