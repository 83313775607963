import React from 'react';
import styled from 'styled-components';

import { DSTokenMap, FontWeight, MQ } from '@rover/kibble/styles';
import IconTooltip from '@rover/react-lib/src/components/callouts/IconTooltip';
import { PopoverPlacement } from '@rover/react-lib/src/components/callouts/Popover';
import CheckBox from '@rover/react-lib/src/components/formFields/Checkbox';
import { useI18n } from '@rover/rsdk/src/modules/I18n';

export type Props = {
  label: string;
  tooltip: string;
  onChange: (isChecked: boolean, value: any | null | undefined) => void;
  checked: boolean;
  value?: any;
};

const StyledDiv = styled.div`
  margin-bottom: 10px;
`;

const StyledLabel = styled.label`
  display: inline-block;
`;

const StyledSpan = styled.span`
  display: inline;
  font-weight: ${FontWeight.NORMAL.toString()};
  margin-left: 10px;
  margin-right: 5px;

  ${MQ.SM_UP.toString()} {
    padding-bottom: ${DSTokenMap.SPACE_2X.toString()};
  }
`;

const CheckBoxOption = ({ label, tooltip, onChange, checked, value }: Props): JSX.Element => {
  const { i18n } = useI18n();

  return (
    <StyledDiv>
      <StyledLabel>
        <CheckBox checked={checked} onChange={onChange} value={value} />
        <StyledSpan>{label}</StyledSpan>
        {tooltip && ( // preventDefault here avoids the issue where tapping the tooltip selects the checkbox
          // the following lints are ignorable since we are just catching an event here
          /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
          <span onClick={(e) => e.preventDefault()}>
            <IconTooltip text={i18n._(tooltip)} placement={PopoverPlacement.BOTTOM} />
          </span>
        )}
      </StyledLabel>
    </StyledDiv>
  );
};

export default CheckBoxOption;
