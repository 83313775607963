import React from 'react';
import { t } from '@lingui/macro';

import ServiceTypeFilter from '@rover/react-lib/src/components/formFields/ServiceTypeFilter';
import {
  DaycareTypeFilter,
  FilterWrapper,
  Location,
} from '@rover/react-lib/src/pages/search/SearchPage/components/Filters';
import {
  DayTimeAvailability,
  DaytimeServiceScheduler,
  OvernightDateRange,
} from '@rover/react-lib/src/pages/search/SearchPage/components/Filters/Dates';
import PetCounter from '@rover/react-lib/src/pages/search/SearchPage/components/PetCounter';
import { shouldShowControls } from '@rover/react-lib/src/pages/search/SearchPage/utilities';
import { useLocationInputTexts } from '@rover/react-lib/src/pages/search/SearchPage/utilities/useLocationInputTexts';
import { WizardPageType } from '@rover/react-lib/src/pages/search/SearchPage/WizardPageType';
import getMaxRequestDate from '@rover/react-lib/src/utils/getMaxRequestDate';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import { DROP_OFF_PICK_UP_SUPPORTED_SERVICES } from '@rover/shared/js/constants/searchPage.constants';
import { SERVICE_TYPE_CHOICES } from '@rover/shared/js/constants/service';
import PetTypeFilter from '@rover/shared/js/search/components/PetTypeFilter';
import getIsCatOnlyPetType from '@rover/shared/js/search/utilities/getIsCatOnlyPetType';
import type {
  SearchFilters,
  SearchFiltersOptions,
  ServiceType,
  ServiceTypeOption,
} from '@rover/types';
import { PetType } from '@rover/types/src/Pet';

import BoardingTypeFilter from '../../BoardingTypeFilter';

export type Props = {
  getServiceTypeBySlug: (arg0: string) => ServiceType | null | undefined;
  hasPets: boolean;
  isCatOnly: boolean;
  language: string;
  onChange: (arg0: Partial<SearchFilters>, arg1?: boolean) => void;
  pageType: WizardPageType;
  searchFilters: SearchFilters;
  serviceTypeFilterOptions: SearchFiltersOptions;
  serviceTypeOptions: ServiceTypeOption[];
  isDaycareFilterEarlierExperiment: boolean;
  isDaycareFilterOutsideMarketsInExperiment: boolean;
  isBoardingFilterOutsideMarketsInExperiment: boolean;
  locationInputId?: string;
  rolloutPriceTransparency?: boolean;
  isInPetCountersExperiment?: boolean;
};

const PageOneWizardCollection = ({
  getServiceTypeBySlug,
  hasPets,
  isCatOnly,
  language,
  onChange,
  pageType,
  searchFilters,
  serviceTypeFilterOptions,
  serviceTypeOptions,
  locationInputId = 'location-input-wizard',
  rolloutPriceTransparency,
  isInPetCountersExperiment,
  isDaycareFilterEarlierExperiment,
  isDaycareFilterOutsideMarketsInExperiment,
  isBoardingFilterOutsideMarketsInExperiment,
}: Props): JSX.Element => {
  const { i18n } = useI18n();

  const { getPlaceholderText, getTitleText } = useLocationInputTexts({
    titleTextVariant: i18n._(`What's your address?`),
  });

  const serviceType = getServiceTypeBySlug(searchFilters.serviceType);
  const handleChangeDateRange = (dateRange): void => {
    if (dateRange) {
      onChange(
        {
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
        },
        dateRange.fireSearch
      );
    }
  };

  const handleSelectedDaysChange = (selectedDays): void => {
    onChange({ selectedDays });
  };

  // Abstract this into a selector in https://roverdotcom.atlassian.net/browse/DEV-42261
  const startPlaceholder = DROP_OFF_PICK_UP_SUPPORTED_SERVICES.has(searchFilters.serviceType)
    ? t`Drop off`
    : t`Start`;
  const endPlaceholder = DROP_OFF_PICK_UP_SUPPORTED_SERVICES.has(searchFilters.serviceType)
    ? t`Pick up`
    : t`End`;

  const { morningAvailability, middayAvailability, eveningAvailability } = serviceTypeFilterOptions;

  const { showPetType, showDateRange, showDaytimeServiceScheduler, showDaytimeAvailability } =
    shouldShowControls({
      hasPets,
      isCatOnly,
      pageType,
      serviceOptions: serviceTypeFilterOptions,
      serviceType: serviceType || undefined,
      serviceTypeSlug: searchFilters.serviceType,
    });

  const maxDate = getMaxRequestDate();
  const shouldShowPetCounter = rolloutPriceTransparency || isInPetCountersExperiment;

  const shouldShowBoardingTypeFilter =
    isBoardingFilterOutsideMarketsInExperiment &&
    searchFilters.serviceType === SERVICE_TYPE_CHOICES.boarding &&
    !isCatOnly;

  const shouldShowDaycareTypeFilter =
    (isDaycareFilterEarlierExperiment || isDaycareFilterOutsideMarketsInExperiment) &&
    searchFilters.serviceType === SERVICE_TYPE_CHOICES.dayCare;

  return (
    <>
      {showPetType && (!shouldShowPetCounter || pageType === WizardPageType.MODAL) && (
        <FilterWrapper>
          <PetTypeFilter
            label={i18n._(t`I'm looking for service for my:`)}
            searchFilters={searchFilters}
            onChange={(petType) => {
              const localIsCatOnly = getIsCatOnlyPetType(petType);
              const args = {
                petType,
                catCare: localIsCatOnly,
                ...(localIsCatOnly && { serviceType: SERVICE_TYPE_CHOICES.dropIn }),
              };
              return onChange(args);
            }}
          />
        </FilterWrapper>
      )}
      {showPetType && shouldShowPetCounter && pageType !== WizardPageType.MODAL && (
        <FilterWrapper>
          <PetCounter
            inputLabel={i18n._(t`I'm looking for service for my:`)}
            inputLabelMarginBottom="2x"
            inputLabelColor="secondary"
            dogCount={searchFilters.dogCount}
            catCount={searchFilters.catCount}
            puppyCount={searchFilters.puppyCount}
            availablePets={[PetType.DOG, PetType.PUPPY, PetType.CAT]}
            triggeredFrom="Search"
            onChange={({ petType, dogCount, catCount, puppyCount }) => {
              const localIsCatOnly = getIsCatOnlyPetType(petType);
              const args = {
                petType,
                catCare: localIsCatOnly,
                dogCount,
                catCount,
                puppyCount,
                ...(localIsCatOnly && { serviceType: SERVICE_TYPE_CHOICES.dropIn }),
              };
              return onChange(args);
            }}
            hideHelperText
          />
        </FilterWrapper>
      )}
      <FilterWrapper>
        <ServiceTypeFilter
          displayMode={pageType === WizardPageType.MODAL ? 'horizontal' : null}
          serviceTypeOptions={serviceTypeOptions}
          label={i18n._(t`Which service do you need?`)}
          searchFilters={searchFilters}
          onChange={onChange}
        />
      </FilterWrapper>
      {shouldShowBoardingTypeFilter && (
        <FilterWrapper>
          <BoardingTypeFilter
            onChange={(newInSittersHome, newAtDaycareFacility) => {
              onChange({
                inSittersHome: newInSittersHome,
                atDaycareFacility: newAtDaycareFacility,
              });
            }}
            inSittersHome={searchFilters.inSittersHome}
            atDaycareFacility={searchFilters.atDaycareFacility}
            layoutVariant={pageType === WizardPageType.MODAL ? 'inline-options' : 'default'}
            placement="search-wizard"
          />
        </FilterWrapper>
      )}
      {shouldShowDaycareTypeFilter && (
        <FilterWrapper>
          <DaycareTypeFilter
            title={i18n._(t`Show day care options:`)}
            onChange={(newInSittersHome, newAtDaycareFacility) => {
              onChange({
                inSittersHome: newInSittersHome,
                atDaycareFacility: newAtDaycareFacility,
              });
            }}
            inSittersHome={searchFilters.inSittersHome}
            atDaycareFacility={searchFilters.atDaycareFacility}
            layoutVariant={pageType === WizardPageType.MODAL ? 'inline-options' : 'default'}
            placement="search-wizard"
          />
        </FilterWrapper>
      )}
      <FilterWrapper>
        <Location
          locationInputId={locationInputId}
          label={getTitleText()}
          placeholder={getPlaceholderText()}
          location={searchFilters.location}
          onChange={onChange}
        />
      </FilterWrapper>
      {showDateRange && (
        <FilterWrapper>
          <OvernightDateRange
            endPlaceholder={endPlaceholder}
            maxDate={maxDate}
            label={i18n._(t`Which dates do you need?`)}
            language={language}
            onChange={handleChangeDateRange}
            searchFilters={searchFilters}
            startPlaceholder={startPlaceholder}
          />
        </FilterWrapper>
      )}
      {showDaytimeServiceScheduler && (
        <FilterWrapper>
          <DaytimeServiceScheduler
            displayAs={pageType === WizardPageType.MODAL ? 'compact' : null}
            endPlaceholder={endPlaceholder}
            language={language}
            maxDate={maxDate}
            onChange={(frequency) => onChange({ frequency })}
            onDateRangeChange={handleChangeDateRange}
            oneTimeLabel={i18n._(t`Which dates do you need?`)}
            onSelectedDaysChange={handleSelectedDaysChange}
            onStartDateChange={handleChangeDateRange}
            repeatStartDateLabel={startPlaceholder}
            searchFilters={searchFilters}
            serviceType={serviceType}
            showArrow={false}
            showLabel
            startPlaceholder={startPlaceholder}
          />
        </FilterWrapper>
      )}
      {pageType === WizardPageType.MODAL && showDaytimeAvailability && (
        <FilterWrapper>
          <DayTimeAvailability
            availabilityBuckets={{
              morning: morningAvailability ? morningAvailability.label : null,
              midday: middayAvailability ? middayAvailability.label : null,
              evening: eveningAvailability ? eveningAvailability.label : null,
            }}
            label={i18n._(t`Which time(s) do you need?`)}
            labelIsVisible
            onChange={(availability) => onChange(availability)}
            searchFilters={searchFilters}
          />
        </FilterWrapper>
      )}
    </>
  );
};

export default PageOneWizardCollection;
