import React, { FC, useEffect, useId, useState } from 'react';

import { DropdownSmall } from '@rover/icons';
import { Box, Text } from '@rover/kibble/core';
import { ButtonIconSizeMap } from '@rover/kibble/core/Button/Button.common';
import { DSTokenMap } from '@rover/kibble/styles';
import { isAndroid, isiOS } from '@rover/rsdk/src/modules/Network/userAgent';

import { getAriaLabel, Props } from './ClickableInput.common';

const ClickableInput: FC<Props> = ({
  children,
  helperText,
  inputLabel,
  inputLabelSize,
  inputLabelColor,
  inputLabelMarginBottom,
  isError,
  onClick,
  icon = (
    <DropdownSmall
      width={ButtonIconSizeMap.default}
      height={ButtonIconSizeMap.default}
      fill="currentColor"
    />
  ),
  hideIcon,
  pointerCursor = false,
}) => {
  const buttonId = useId();
  const helperTextId = useId();
  const [showIcon, setShowIcon] = useState(false);

  useEffect(() => {
    setShowIcon(!hideIcon);
  }, [hideIcon]);

  return (
    <>
      <Text
        fontWeight="semibold"
        mb={inputLabelMarginBottom ?? '1x'}
        sx={{
          display: 'block',
        }}
        as="label"
        size={inputLabelSize ?? ['100', '200']}
        {...(inputLabelColor ? { textColor: inputLabelColor } : {})}
        htmlFor={buttonId}
      >
        {inputLabel}
      </Text>
      <Box
        id={buttonId}
        as="button"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        // Android and iOS screen readers will only read the `label` element and not the button value (`children`)
        aria-label={isAndroid() || isiOS() ? getAriaLabel(inputLabel, children) : undefined}
        aria-describedby={helperText ? helperTextId : undefined}
        data-testid="clickable-input"
        onClick={onClick}
        px="2x"
        width="100%"
        height="12x"
        borderRadius="secondary"
        border="none"
        background="primary"
        sx={{
          boxShadow: isError
            ? `0px 0px 0px 2px ${DSTokenMap.BORDER_COLOR_ERROR}`
            : `0px 0px 0px 2px ${DSTokenMap.BORDER_COLOR_PRIMARY}`,
          color: DSTokenMap.TEXT_COLOR_SECONDARY.toHexString(),
          cursor: pointerCursor ? 'pointer' : undefined,
        }}
      >
        {children}
        <Box
          width={ButtonIconSizeMap.default}
          height={ButtonIconSizeMap.default}
          flexShrink="0"
          display={showIcon ? 'block' : 'none'}
          data-testid="clickable-input-icon-wrapper"
        >
          {icon}
        </Box>
      </Box>
      {helperText && (
        <Text
          data-testid="helper-text"
          id={helperTextId}
          size="100"
          textColor={isError ? 'error' : 'tertiary'}
          mt="2x"
          sx={{ display: 'block' }}
        >
          {helperText}
        </Text>
      )}
    </>
  );
};

export default ClickableInput;
