import React from 'react';

import { Edit } from '@rover/icons';
import { Callout } from '@rover/types';

import SearchSectionHeader from '../../SearchSectionHeader';

type EmptyResultsProps = {
  searchCallouts: Callout[] | undefined;
};

const EmptyResults = ({ searchCallouts }: EmptyResultsProps): JSX.Element => {
  const emptyResultCallout = searchCallouts?.find(
    ({ slug }) => slug === 'empty-results-callout'
  ) || {
    data: {
      title: '',
      subtitle: '',
    },
  };

  return (
    <SearchSectionHeader
      headerIcon={Edit}
      headerText={emptyResultCallout.data.title}
      primarySubheaderText={emptyResultCallout.data.subtitle}
    />
  );
};

export default EmptyResults;
