import React, { memo } from 'react';

import { Connect } from '@rover/react-lib/src/components/utils/Redux';
import { selectors as searchFiltersSelectors } from '@rover/react-lib/src/pages/search/SearchPage/SearchFilters.duck';
import {
  effects as searchPageEffects,
  selectors as searchPageSelectors,
} from '@rover/react-lib/src/pages/search/SearchPage/SearchPage.duck';
import { selectors as frontendConfigurationSelectors } from '@rover/react-lib/src/redux/ducks/frontendConfiguration.duck';
import { selectors as petsSelectors } from '@rover/react-lib/src/redux/ducks/pets.duck';
import { selectors as searchSelectors } from '@rover/react-lib/src/redux/ducks/search.duck';
import { selectors as serviceTypeSelectors } from '@rover/react-lib/src/redux/ducks/serviceTypes.duck';

import SidebarFiltersCollection from './SidebarFiltersCollection';

export type Props = {
  onScrollableFilterChange: () => void;
  onUpdateInnerHeight: () => void;
};

const effects = {
  updateFiltersAndFireSearchOnChange: searchPageEffects.updateFiltersAndFireSearchOnChange,
};

const selector = (state) => ({
  currencyCode: searchSelectors.getSearchCurrencyCode(state),
  getPriceUnit: serviceTypeSelectors.getPriceUnit(state),
  getServiceTypeBySlug: serviceTypeSelectors.getServiceTypeBySlug(state),
  hasPets: searchSelectors.getHasPets(state),
  isCatOnly: searchFiltersSelectors.getIsCatOnly(state),
  language: frontendConfigurationSelectors.getLanguage(state),
  petOptions: petsSelectors.getPets(state),
  petSizeBuckets: frontendConfigurationSelectors.getSettings(state).dogSizeBuckets,
  priceFilterMaxValue: searchPageSelectors.getPriceFilterMaxValue(state),
  priceFilterMinValue: searchPageSelectors.getPriceFilterMinValue(state),
  searchFilters: searchFiltersSelectors.getFilters(state),
  serviceTypeFilterOptions: searchFiltersSelectors.getServiceTypeFilterOptions(state),
  serviceTypeOptions: searchFiltersSelectors.getPetTypeBrowsableServiceTypeOptions(state),
  weightUnit: frontendConfigurationSelectors.getSettings(state).weightUnit,
  queryDerivedData: searchSelectors.getQueryDerivedData(state),
  isInGingrFacilitiesInSearchExperiment:
    searchPageSelectors.getIsGingrFacilitiesInSearchExperiment(state),
  rolloutPriceTransparency: searchPageSelectors.getRolloutPriceTransparency(state),
  isInPetCountersExperiment: searchPageSelectors.getIsInPetCountersExperiment(state),
});

const SidebarFiltersCollectionContainer = (props: Props): JSX.Element => (
  <Connect selector={selector} effects={effects}>
    {(connectedProps) => <SidebarFiltersCollection {...connectedProps} {...props} />}
  </Connect>
);

export default memo(SidebarFiltersCollectionContainer);
