import type { LatLng } from '@rover/types/src/LatLng';

import type { StaticMapMarker } from './StaticMapMarker';

const DEFAULT_COLOR = '0x404347';
const DEFAULT_SIZE = 'small';
const DEFAULT_ICON =
  'https://www.rover.com/static/new_design/images/markers/blank-off-new@1x.b031a29f54f6.png';
type MarkerStyle = {
  name: string;
  value: string | null | undefined;
};
const PIPE = '%7C';

function styleToString({ name, value }: MarkerStyle): string {
  return value ? `${name}:${encodeURIComponent(value)}${PIPE}` : '';
}

function locationToString(locations: LatLng[]): string {
  return locations.map(({ lat, lng }) => `${lat}, ${lng}`).join(PIPE);
}

function markerToString({ color, icon, label, size, locations }: StaticMapMarker): string {
  const params: string[] = [];
  params.push(`icon:${encodeURIComponent(icon || DEFAULT_ICON)}`);
  params.push(
    styleToString({
      name: 'color',
      value: color || DEFAULT_COLOR,
    })
  );
  if (label)
    params.push(
      styleToString({
        name: 'label',
        value: label,
      })
    );
  params.push(
    styleToString({
      name: 'size',
      value: size || DEFAULT_SIZE,
    })
  );
  params.push(locationToString(locations));
  return `markers=${params.join(PIPE)}`;
}

function markersToParams(markers: StaticMapMarker[]): string {
  return markers.map(markerToString).join('&');
}

export default markersToParams;
