import React, { ButtonHTMLAttributes, InputHTMLAttributes, useId } from 'react';
import { t } from '@lingui/macro';
import styled from 'styled-components';

import { Minus, Plus } from '@rover/icons';
import { Button, ButtonProps, Flex } from '@rover/kibble/core';
import { DSTokenMap } from '@rover/kibble/styles';
import { useI18n } from '@rover/rsdk/src/modules/I18n';

export const StyledInput = styled.input`
  max-width: 44px;
  border: none;
  background-color: transparent;
  appearance: none;
  font-size: 16px;
  text-align: center;
  color: ${DSTokenMap.TEXT_COLOR_SECONDARY.toString()};
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export type Props = {
  value: number;
  onIncrement: (...args: any[]) => any;
  onDecrement: (...args: any[]) => any;
  disabled?: boolean;
  minValue?: number;
  maxValue?: number;
  incrementProps?: ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>;
  decrementProps?: ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  focusableRef?: React.Ref<HTMLButtonElement>;
};

const Stepper = ({
  value = 0,
  onIncrement,
  onDecrement,
  disabled = false,
  minValue,
  maxValue,
  incrementProps,
  decrementProps,
  inputProps,
  focusableRef,
  ...other
}: Props): JSX.Element => {
  const { i18n } = useI18n();
  const stepperInputId = useId();

  return (
    <Flex flexDirection="row" {...other}>
      <Button
        type="button"
        aria-label={i18n._(
          /* i18n: current value (number) and the operation performed by the button */
          t`Current value ${value}, decrement`
        )}
        onClick={onDecrement}
        data-testid="stepper-decrement"
        disabled={disabled || minValue === value}
        aria-controls={stepperInputId}
        aria-disabled={disabled || minValue === value}
        icon={Minus}
        size="small"
        mr="1x"
        p="1x"
        {...decrementProps}
      />
      <StyledInput
        data-testid="stepper-input"
        id={stepperInputId}
        tabIndex={-1}
        type="text"
        inputMode="numeric"
        aria-roledescription="Number field"
        aria-live="polite"
        value={value}
        disabled={disabled}
        readOnly
        {...inputProps}
      />
      <Button
        type="button"
        aria-label={i18n._(
          /* i18n: current value (number) and the operation performed by the button */
          t`Current value ${value}, increment`
        )}
        onClick={onIncrement}
        data-testid="stepper-increment"
        disabled={disabled || maxValue === value}
        aria-controls={stepperInputId}
        aria-disabled={disabled || maxValue === value}
        icon={Plus}
        size="small"
        ml="1x"
        p="1x"
        buttonRef={focusableRef}
        {...incrementProps}
      />
    </Flex>
  );
};

export default Stepper;
