import React, { FC, ReactNode, useEffect, useId } from 'react';
import styled, { css } from 'styled-components';

import { Check, Close } from '@rover/icons';
import { A11yHidden, DSTokenMap } from '@rover/kibble/styles';
import FormGroup from '@rover/react-lib/src/components/formFields/FormGroup';
import { Label } from '@rover/react-lib/src/components/utils/LabelAndErrorFormGroup';

const CSS_TRANSITION_TIME = '0.2s';

export type Props = {
  disabled?: boolean;
  hideLabel?: boolean;
  inlineLabel?: boolean;
  label?: ReactNode;
  ariaLabelledby?: string;
  on?: boolean;
  onToggle: (value: boolean) => void;
};

type StyledFormGroupProps = {
  inlineLabel?: boolean;
};

const StyledFormGroup = styled(FormGroup)<StyledFormGroupProps>`
  ${(props) =>
    !props.inlineLabel
      ? ''
      : css`
          align-items: center;
          display: flex;
          width: 100%;
        `};
`;

type StyledLabelProps = {
  hideLabel?: boolean;
  inlineLabel?: boolean;
};

export const StyledLabel = styled(Label)<StyledLabelProps>`
  display: block;
  margin-bottom: 0;
  margin-right: ${DSTokenMap.SPACE_4X};
  ${({ hideLabel }) => (hideLabel ? A11yHidden : '')};
  ${({ inlineLabel }) =>
    !inlineLabel
      ? ''
      : css`
          display: inline;
          flex: 1;
        `};
`;

type IconWrapperProps = {
  $on: boolean;
};

export const IconWrapper = styled.div<IconWrapperProps>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  border-radius: ${DSTokenMap.BORDER_RADIUS_ROUND};
  top: 2px;
  right: ${({ $on }) => ($on ? '2px' : '26px')};
  background: ${({ $on }) =>
    $on
      ? DSTokenMap.INTERACTIVE_BACKGROUND_COLOR_PRIMARY.toString()
      : DSTokenMap.TEXT_COLOR_TERTIARY.toString()};

  transition: background ${CSS_TRANSITION_TIME} ease-in-out,
    right ${CSS_TRANSITION_TIME} ease-in-out;
`;

export const StyledCheckIcon = styled(Check)`
  height: 12px;
  width: 12px;
  stroke-width: 2px;

  transition: stroke ${CSS_TRANSITION_TIME} ease-in-out;
`;

export const StyledCloseIcon = styled(Close)`
  height: 10px;
  width: 10px;
  stroke-width: 2px;
`;

// eslint-disable-next-line rover/prefer-kibble-components
export const SwitchButton = styled.button<{ $on: boolean }>`
  position: relative;
  height: 32px;
  width: 56px;
  border-radius: 16px;
  background: ${({ $on }) =>
    $on ? DSTokenMap.INTERACTIVE_BACKGROUND_COLOR_PRIMARY_ACTIVE.toString() : 'transparent'};
  border: ${DSTokenMap.BORDER_WIDTH_PRIMARY} solid
    ${({ $on }) =>
      $on
        ? DSTokenMap.INTERACTIVE_BORDER_COLOR_PRIMARY_ACTIVE.toString()
        : DSTokenMap.BORDER_COLOR_PRIMARY.toString()};
  cursor: pointer;
  outline-offset: 4px;

  transition: background ${CSS_TRANSITION_TIME} ease-in-out,
    border-color ${CSS_TRANSITION_TIME} ease-in-out;

  svg {
    vertical-align: middle;
    stroke: ${({ $on }) =>
      $on
        ? DSTokenMap.TEXT_COLOR_SECONDARY.toString()
        : DSTokenMap.TEXT_COLOR_PRIMARY_INVERSE.toString()};
    fill: ${({ $on }) =>
      $on
        ? DSTokenMap.TEXT_COLOR_SECONDARY.toString()
        : DSTokenMap.TEXT_COLOR_PRIMARY_INVERSE.toString()};
  }

  &:disabled {
    opacity: 1;
    background: ${({ $on }) =>
      $on
        ? DSTokenMap.INTERACTIVE_TEXT_COLOR_DISABLED.toString()
        : DSTokenMap.INTERACTIVE_BACKGROUND_COLOR_DISABLED.toString()};
    cursor: not-allowed;
    border: ${DSTokenMap.BORDER_WIDTH_PRIMARY} solid
      ${({ $on }) =>
        $on
          ? DSTokenMap.INTERACTIVE_TEXT_COLOR_DISABLED.toString()
          : DSTokenMap.INTERACTIVE_BORDER_COLOR_DISABLED.toString()};

    ${IconWrapper} {
      background: ${({ $on }) =>
        $on
          ? DSTokenMap.INTERACTIVE_BACKGROUND_COLOR_PRIMARY.toString()
          : DSTokenMap.TEXT_COLOR_DISABLED.toString()};
    }

    ${StyledCheckIcon} {
      stroke: ${DSTokenMap.INTERACTIVE_TEXT_COLOR_DISABLED.toString()};
    }
  }
`;

const OnOffSwitch: FC<Props> = ({
  ariaLabelledby,
  on = false,
  onToggle,
  disabled = false,
  label,
  hideLabel,
  inlineLabel = false,
  ...other
}) => {
  const id = useId();
  const labelId = useId();

  useEffect(() => {
    if (!label && !ariaLabelledby) {
      // eslint-disable-next-line no-console
      console.warn(
        `OnOffSwitch with id "${id}" was not supplied a label or ariaLabelledby prop. This adversely affects accessibility, please refactor your usage of the OnOffSwitch component.`
      );
    }
  }, [label, ariaLabelledby, id]);

  return (
    <StyledFormGroup inlineLabel={inlineLabel} {...other}>
      {label && (
        <StyledLabel id={labelId} inlineLabel={inlineLabel} hideLabel={hideLabel}>
          {label}
        </StyledLabel>
      )}
      <SwitchButton
        role="switch"
        aria-checked={on}
        // We'd prefer to use `htmlFor={id}` on the label element.
        // However, a `label` referring to a `button` isn't always understood by assistive devices;
        // see https://github.com/dequelabs/axe-core/issues/3696
        aria-labelledby={ariaLabelledby || (label ? labelId : undefined)}
        id={id}
        $on={on}
        disabled={disabled}
        onClick={() => onToggle(!on)}
        type="button"
      >
        <IconWrapper $on={on}>{on ? <StyledCheckIcon /> : <StyledCloseIcon />}</IconWrapper>
      </SwitchButton>
    </StyledFormGroup>
  );
};

export default OnOffSwitch;
