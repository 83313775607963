/* eslint-disable @typescript-eslint/explicit-function-return-type */
import EXPERIMENTS from '@rover/react-lib/src/constants/experiments.constants';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import useExperimentsShim from '@rover/shared/js/hooks/useExperimentsShim';

type LocationInputTextsOptions = {
  placeholderTextVariant?: string;
  placeholderTextControl?: string;
  titleTextVariant?: string;
  titleTextControl?: string;
};

export const useLocationInputTexts = (options: LocationInputTextsOptions = {}) => {
  const { i18n } = useI18n();
  const { isVariant } = useExperimentsShim();

  const placeHolderTextVariant = options.placeholderTextVariant ?? i18n._('Add your address');
  const placeHolderTextControl = options.placeholderTextControl ?? i18n._('Zip code or Address');
  const titleTextVariant = options.titleTextVariant ?? i18n._('Address');
  const titleTextControl =
    options.titleTextControl ?? i18n._('What’s your address or cross-streets?');

  const usePromptForAddressInLocationFieldsExperiment = isVariant(
    EXPERIMENTS.PROMPT_FOR_ADDRESS_IN_LOCATION_FIELDS_EXPERIMENT
  );

  return {
    getPlaceholderText: () =>
      usePromptForAddressInLocationFieldsExperiment
        ? placeHolderTextVariant
        : placeHolderTextControl,
    getTitleText: () =>
      usePromptForAddressInLocationFieldsExperiment ? titleTextVariant : titleTextControl,
  };
};
