import { FC, useEffect, useState } from 'react';
import { t } from '@lingui/macro';

import { CarouselRightSmall } from '@rover/icons';
import { Box, Button, Flex, Text } from '@rover/kibble/core';
import { useSearchWizard } from '@rover/react-lib/src/pages/search/SearchPage/components/Modals/SearchWizardModal/context/SearchWizardContext';
import PetCounterBody from '@rover/react-lib/src/pages/search/SearchPage/components/PetCounter/PetCounterBody';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import { ServiceType } from '@rover/shared/js/constants/service';
import { SearchFilters } from '@rover/types';
import { PetType } from '@rover/types/src/Pet';

const DEFAULT_AVAILABLE_PETS = [PetType.DOG, PetType.PUPPY, PetType.CAT];

const initializeDogCount = (searchFilters: SearchFilters): number => {
  const { dogCount, catCount, puppyCount } = searchFilters;
  const isNoPetSelected = dogCount === 1 && catCount === 0 && puppyCount === 0;
  const isDropIn = searchFilters.serviceType === ServiceType.DROP_IN;

  if (isNoPetSelected && !isDropIn) {
    return 1;
  }

  if (isNoPetSelected && isDropIn) {
    return 0;
  }

  return dogCount ?? 1;
};

const initializeCatCount = (searchFilters: SearchFilters): number => {
  const { dogCount, catCount, puppyCount } = searchFilters;
  const isNoPetSelected = dogCount === 1 && catCount === 0 && puppyCount === 0;
  const isDropIn = searchFilters.serviceType === ServiceType.DROP_IN;

  if (isDropIn && isNoPetSelected) {
    return 1;
  }

  return catCount ?? 0;
};

export const PetCount: FC = () => {
  const { searchFilters, isLastStep, updateFilters, handleSubmit } = useSearchWizard();
  const { i18n } = useI18n();
  const [dogs, setDogs] = useState(initializeDogCount(searchFilters));
  const [cats, setCats] = useState(initializeCatCount(searchFilters));
  const [puppies, setPuppies] = useState(searchFilters.puppyCount ?? 0);

  const buttonIcon = !isLastStep ? CarouselRightSmall : undefined;
  const isNoPetSelected = dogs === 0 && cats === 0 && puppies === 0;

  useEffect(() => {
    const petCounts = {
      [PetType.DOG]: dogs,
      [PetType.CAT]: cats,
      [PetType.PUPPY]: puppies,
    };

    const uniquePetTypes: PetType[] = (
      Object.keys(petCounts) as Array<(typeof PetType)[keyof typeof PetType]>
    ).reduce<PetType[]>((acc, type) => {
      if (petCounts[type] > 0 && !acc.includes(type)) {
        acc.push(type === PetType.PUPPY ? PetType.DOG : type);
      }
      return acc;
    }, []);

    updateFilters({
      petType: uniquePetTypes,
      dogCount: dogs,
      catCount: cats,
      puppyCount: puppies,
    });
  }, [dogs, cats, puppies, updateFilters]);

  return (
    <>
      <Text size="100" textColor="secondary" pb="4x" sx={{ display: 'block' }}>
        {i18n._(
          t`Add at least one pet. This helps you find a sitter and see the most accurate pricing.`
        )}
      </Text>
      <Box mb="6x">
        <PetCounterBody
          dogs={dogs}
          cats={cats}
          puppies={puppies}
          availablePets={DEFAULT_AVAILABLE_PETS}
          triggeredFrom="PreSearch"
          setDogs={setDogs}
          setPuppies={setPuppies}
          setCats={setCats}
        />
      </Box>
      <Flex justifyContent="flex-end">
        <Button
          variant="primary"
          onClick={handleSubmit}
          icon={buttonIcon}
          iconAlignment="right"
          disabled={isNoPetSelected}
        >
          {isLastStep ? i18n._(t`Search Now`) : i18n._(t`Next`)}
        </Button>
      </Flex>
    </>
  );
};
