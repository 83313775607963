import React from 'react';
import { Trans } from '@lingui/react';

import { TrustLogo } from '@rover/icons';
import { Box, Flex, Paragraph } from '@rover/kibble/core';
import { DSTokenMap } from '@rover/kibble/styles';

export const bannerTextEU = (
  <Trans>
    All sitters pass through an <b>ID verification process</b> and are <b>approved by our team</b>{' '}
    of sitter specialists.
  </Trans>
);
export const bannerTextNA = (
  <Trans>
    All sitters pass a <b>basic background check</b> by a third-party provider and are{' '}
    <b>approved by our team</b> of sitter specialists.
  </Trans>
);

export type Props = {
  userInEurope: boolean;
};

const SearchTrustSafetyBanner = ({ userInEurope }: Props): JSX.Element => {
  return (
    <Box display={['flex', 'none']} flexDirection="column" alignItems="flex-start" paddingTop="12x">
      <Box
        sx={{ backgroundColor: DSTokenMap.BACKGROUND_COLOR_SECONDARY.toString() }}
        borderRadius={DSTokenMap.BORDER_RADIUS_SECONDARY}
        border={`${DSTokenMap.BORDER_WIDTH_SECONDARY.toString()} solid ${DSTokenMap.BORDER_COLOR_INFO.toString()}`}
        padding="4x"
        margin="4x"
        flexDirection="column"
        // do not display on desktop
        display={['flex', 'none']}
      >
        <Flex alignItems="flex-start" alignSelf="stretch">
          <Box>
            <TrustLogo />
          </Box>
          <Paragraph size="100" marginLeft="6x" marginBottom="0x">
            {userInEurope ? bannerTextEU : bannerTextNA}
          </Paragraph>
        </Flex>
      </Box>
    </Box>
  );
};

export default SearchTrustSafetyBanner;
