import { MessageDescriptor } from '@lingui/core';
import { t } from '@lingui/macro';

import { ServiceType } from '@rover/shared/js/constants/service';

import { DateData } from './types';

type HeaderContentType = MessageDescriptor | string;

export enum HeaderScenariosEnum {
  SELECTED_SITTER = 'selected-sitter',
  BEST_MATCHES = 'best-matches',
  SEARCH_RESULTS = 'search-results',
  LOW_SUPPLY = 'low-supply',
  NO_RESULTS = 'no-results',
}

export enum HeaderIconEnum {
  BOLT = 'NowBolt',
  EDIT = 'Edit',
  MAP = 'Map',
  PERSON = 'Person',
  SEARCH = 'Search',
}

// TITLES
export enum HeaderTitlesEnum {
  AVAILABLE_SITTERS_NEAR_YOU = 'available-sitters-near-you',
  FIND_MATCH = 'find-a-match',
  OTHER_SITTERS_IN_AREA = 'other-sitters-in-your-area',
  SITTERS_CLOSE_TO_YOU = 'sitters-close-to-you',
  SITTERS_IN_AREA = 'sitters-in-your-area',
  SELECTED_SITTER_TITLE = 'your-selected-sitter',
  SELECTED_SITTER_UNAVAILABLE_TITLE = 'your-selected-sitter-is-not-available',
  BEST_MATCHES = 'best-matches-for-your-request',
}

export const HEADER_TITLES: Record<HeaderTitlesEnum, MessageDescriptor> = {
  [HeaderTitlesEnum.AVAILABLE_SITTERS_NEAR_YOU]: t`Available sitters near you`,
  [HeaderTitlesEnum.FIND_MATCH]: t`Find a match`,
  [HeaderTitlesEnum.OTHER_SITTERS_IN_AREA]: t`Other sitters in your area`,
  [HeaderTitlesEnum.SITTERS_CLOSE_TO_YOU]: t`Sitters close to you`,
  [HeaderTitlesEnum.SITTERS_IN_AREA]: t`Sitters in your area`,
  [HeaderTitlesEnum.SELECTED_SITTER_TITLE]: t`Your selected sitter`,
  [HeaderTitlesEnum.SELECTED_SITTER_UNAVAILABLE_TITLE]: t`Your selected sitter is not available`,
  [HeaderTitlesEnum.BEST_MATCHES]: t`Best matches for your request`,
};

// SUBHEADERS
export enum SubHeadersEnum {
  ADD_DATES,
  SHOWING_OTHER_PROVIDERS,
  SEEING_PROVIDERS_DATES,
  BEST_MATCH_SELECTED_PROVIDER_SHORT_LEAD_TIME,
  BEST_MATCH_LIST_SHORT_LEAD_TIME,
  SELECTED_SITTER_NO_DATES,
  SELECTED_SITTER_AVAILABLE,
  SELECTED_SITTER_UNAVAILABLE,
  POST_SELECTED_SITTER_RESULTS,
}

const getAddDatesSubHeader = (serviceType?: ServiceType): HeaderContentType => {
  if (!serviceType) {
    return '';
  }

  switch (serviceType) {
    case ServiceType.OVERNIGHT_BOARDING:
      return t`Add dates to see boarding sitters who'll be available for your need. These are sitters in your area, but they might not be available.`;
    case ServiceType.DOGGY_DAY_CARE:
      return t`Add dates to see daycare sitters who'll be available for your need. These are sitters in your area, but they might not be available.`;
    case ServiceType.DROP_IN:
      return t`Add dates to see drop-in sitters who'll be available for your need. These are sitters in your area, but they might not be available.`;
    case ServiceType.DOG_WALKING:
    case ServiceType.OVERNIGHT_TRAVELING:
    default:
      return t`Add dates to see sitters who'll be available for your need. These are sitters in your area, but they might not be available.`;
  }
};

const getShowingOtherProvidersSubHeader = (serviceType?: ServiceType): HeaderContentType => {
  if (!serviceType) {
    return '';
  }

  switch (serviceType) {
    case ServiceType.OVERNIGHT_BOARDING:
      return t`Showing other available boarding sitters.`;
    case ServiceType.DOGGY_DAY_CARE:
      return t`Showing other available daycare sitters.`;
    case ServiceType.DROP_IN:
      return t`Showing other available drop-in sitters.`;
    case ServiceType.DOG_WALKING:
    case ServiceType.OVERNIGHT_TRAVELING:
    default:
      return t`Showing other available sitters.`;
  }
};

const getYoureSeeingProviders = (
  serviceType?: ServiceType,
  dateData?: DateData
): HeaderContentType => {
  if (!serviceType || !dateData) {
    return '';
  }

  const { isSameDate, startDate, endDate } = dateData || {};

  if (isSameDate) {
    switch (serviceType) {
      case ServiceType.OVERNIGHT_BOARDING:
        return t`You're seeing boarding sitters available on **${startDate}**.`;
      case ServiceType.DOGGY_DAY_CARE:
        return t`You're seeing daycare sitters available on **${startDate}**.`;
      case ServiceType.DROP_IN:
        return t`You're seeing drop-in sitters available on **${startDate}**.`;
      case ServiceType.DOG_WALKING:
      case ServiceType.OVERNIGHT_TRAVELING:
      default:
        return t`You're seeing sitters available on **${startDate}**.`;
    }
  }

  switch (serviceType) {
    case ServiceType.OVERNIGHT_BOARDING:
      return t`You're seeing boarding sitters available from **${startDate} to ${endDate}**.`;
    case ServiceType.DOGGY_DAY_CARE:
      return t`You're seeing daycare sitters available from **${startDate} to ${endDate}**.`;
    case ServiceType.DROP_IN:
      return t`You're seeing drop-in sitters available from **${startDate} to ${endDate}**.`;
    case ServiceType.DOG_WALKING:
    case ServiceType.OVERNIGHT_TRAVELING:
    default:
      return t`You're seeing sitters available from **${startDate} to ${endDate}**.`;
  }
};

const getSelectedSitterAvailable = (
  sitterName?: string,
  dateData?: DateData
): HeaderContentType => {
  if (!sitterName || !dateData) {
    return '';
  }

  const { isSameDate, startDate, endDate } = dateData || {};

  if (isSameDate) {
    return t`${sitterName} is available on **${startDate}**.`;
  }

  return t`${sitterName} is available from **${startDate} to ${endDate}**.`;
};

const getSelectedSitterUnavailable = (
  sitterName?: string,
  dateData?: DateData
): HeaderContentType => {
  if (!sitterName || !dateData) {
    return '';
  }

  const { isSameDate, startDate, endDate } = dateData || {};

  if (isSameDate) {
    return t`${sitterName} is not available on **${startDate}**.`;
  }

  return t`${sitterName} is not available from **${startDate} to ${endDate}**.`;
};

const getPostSelectedSitterResults = (
  serviceType?: ServiceType,
  dateData?: DateData
): HeaderContentType => {
  if (!serviceType || !dateData) {
    return '';
  }

  const { isSameDate, startDate, endDate } = dateData || {};

  if (isSameDate) {
    switch (serviceType) {
      case ServiceType.OVERNIGHT_BOARDING:
        return t`Showing boarding sitters available on **${startDate}**.`;
      case ServiceType.DOGGY_DAY_CARE:
        return t`Showing daycare sitters available on **${startDate}**.`;
      case ServiceType.DROP_IN:
        return t`Showing drop-in sitters available on **${startDate}**.`;
      case ServiceType.DOG_WALKING:
      case ServiceType.OVERNIGHT_TRAVELING:
      default:
        return t`Showing sitters available on **${startDate}**.`;
    }
  }

  switch (serviceType) {
    case ServiceType.OVERNIGHT_BOARDING:
      return t`Showing boarding sitters available from **${startDate} to ${endDate}**.`;
    case ServiceType.DOGGY_DAY_CARE:
      return t`Showing daycare sitters available from **${startDate} to ${endDate}**.`;
    case ServiceType.DROP_IN:
      return t`Showing drop-in sitters available from **${startDate} to ${endDate}**.`;
    case ServiceType.DOG_WALKING:
    case ServiceType.OVERNIGHT_TRAVELING:
    default:
      return t`Showing sitters available from **${startDate} to ${endDate}**.`;
  }
};

export const getSubHeader = (
  subHeader: SubHeadersEnum,
  params: {
    serviceType?: ServiceType;
    sitterName?: string;
    dateData?: DateData;
  } = {}
): HeaderContentType => {
  const { serviceType, sitterName, dateData } = params;

  switch (subHeader) {
    case SubHeadersEnum.ADD_DATES:
      return getAddDatesSubHeader(serviceType);
    case SubHeadersEnum.SHOWING_OTHER_PROVIDERS:
      return getShowingOtherProvidersSubHeader(serviceType);
    case SubHeadersEnum.SEEING_PROVIDERS_DATES:
      return getYoureSeeingProviders(serviceType, dateData);
    case SubHeadersEnum.SELECTED_SITTER_NO_DATES:
      return t`Add dates to see if this sitter is available.`;
    case SubHeadersEnum.SELECTED_SITTER_AVAILABLE:
      return getSelectedSitterAvailable(sitterName, dateData);
    case SubHeadersEnum.SELECTED_SITTER_UNAVAILABLE:
      return getSelectedSitterUnavailable(sitterName, dateData);
    case SubHeadersEnum.POST_SELECTED_SITTER_RESULTS:
      return getPostSelectedSitterResults(serviceType, dateData);
    case SubHeadersEnum.BEST_MATCH_SELECTED_PROVIDER_SHORT_LEAD_TIME:
      return t`This sitter accepts last-minute requests.`;
    case SubHeadersEnum.BEST_MATCH_LIST_SHORT_LEAD_TIME:
      return t`These sitters accept last-minute requests.`;
    default:
      return '';
  }
};

// ALERTS - Price Transparency
export enum AlertContentEnum {
  PRICE_TRANSPARENCY_ADD_DATES,
  PRICE_TRANSPARENCY_PRICES_FOR_UNIT,
  PRICE_TRANSPARENCY_SELECTED_SITTER_AVAILABLE,
  PRICE_TRANSPARENCY_SELECTED_SITTER_UNAVAILABLE,
}

const getPriceTransparencyAddDatesAlertContent = (
  serviceType: ServiceType,
  individualPetTypeCount: string
): MessageDescriptor => {
  switch (serviceType) {
    case ServiceType.OVERNIGHT_BOARDING:
    case ServiceType.OVERNIGHT_TRAVELING:
      /* i18n: {individualPetTypeCount} may refer to any combination of pet counts for a service. Examples include: "3 dogs" or "1 dog and 2 puppies" or "2 dogs, 1 puppy, and 2 cats" */
      return t`Add your dates to get accurate pricing. Prices are estimates per night for **${individualPetTypeCount}**, including fees. Pricing may change based on your search.`;
    case ServiceType.DOGGY_DAY_CARE:
      /* i18n: {individualPetTypeCount} may refer to any combination of pet counts for a service. Examples include: "3 dogs" or "1 dog and 2 puppies" or "2 dogs, 1 puppy, and 2 cats" */
      return t`Add your dates to get accurate pricing. Prices are estimates per day for **${individualPetTypeCount}**, including fees. Pricing may change based on your search.`;
    case ServiceType.DROP_IN:
      /* i18n: {individualPetTypeCount} may refer to any combination of pet counts for a service. Examples include: "3 dogs" or "1 dog and 2 puppies" or "2 dogs, 1 puppy, and 2 cats" */
      return t`Add your dates to get accurate pricing. Prices are estimates per visit for **${individualPetTypeCount}**, including fees. Pricing may change based on your search.`;
    case ServiceType.DOG_WALKING:
    default:
      /* i18n: {individualPetTypeCount} may refer to any combination of pet counts for a service. Examples include: "3 dogs" or "1 dog and 2 puppies" or "2 dogs, 1 puppy, and 2 cats" */
      return t`Add your dates to get accurate pricing. Prices are estimates per walk for **${individualPetTypeCount}**, including fees. Pricing may change based on your search.`;
  }
};

const getPriceTransparencyResultsListAlertContent = (
  serviceType: ServiceType,
  individualPetTypeCount?: string,
  dateData?: DateData
): MessageDescriptor => {
  const { isSameDate, startDate, endDate } = dateData || {};

  if (isSameDate) {
    switch (serviceType) {
      case ServiceType.OVERNIGHT_BOARDING:
      case ServiceType.OVERNIGHT_TRAVELING:
        /* i18n: {individualPetTypeCount} may refer to any combination of pet counts for a service. Examples include: "3 dogs" or "1 dog and 2 puppies" or "2 dogs, 1 puppy, and 2 cats" */
        return t`You're seeing prices per night for **${individualPetTypeCount}** on **${startDate}**, including fees. Pricing may change based on your search.`;
      case ServiceType.DOGGY_DAY_CARE:
        /* i18n: {individualPetTypeCount} may refer to any combination of pet counts for a service. Examples include: "3 dogs" or "1 dog and 2 puppies" or "2 dogs, 1 puppy, and 2 cats" */
        return t`You're seeing prices per day for **${individualPetTypeCount}** on **${startDate}**, including fees. Pricing may change based on your search.`;
      case ServiceType.DROP_IN:
        /* i18n: {individualPetTypeCount} may refer to any combination of pet counts for a service. Examples include: "3 dogs" or "1 dog and 2 puppies" or "2 dogs, 1 puppy, and 2 cats" */
        return t`You're seeing prices per visit for **${individualPetTypeCount}** on **${startDate}**, including fees. Pricing may change based on your search.`;
      case ServiceType.DOG_WALKING:
      default:
        /* i18n: {individualPetTypeCount} may refer to any combination of pet counts for a service. Examples include: "3 dogs" or "1 dog and 2 puppies" or "2 dogs, 1 puppy, and 2 cats" */
        return t`You're seeing prices per walk for **${individualPetTypeCount}** on **${startDate}**, including fees. Pricing may change based on your search.`;
    }
  }

  switch (serviceType) {
    case ServiceType.OVERNIGHT_BOARDING:
    case ServiceType.OVERNIGHT_TRAVELING:
      /* i18n: {individualPetTypeCount} may refer to any combination of pet counts for a service. Examples include: "3 dogs" or "1 dog and 2 puppies" or "2 dogs, 1 puppy, and 2 cats" */
      return t`You're seeing prices per night for **${individualPetTypeCount}** from **${startDate} to ${endDate}**, including fees. Pricing may change based on your search.`;
    case ServiceType.DOGGY_DAY_CARE:
      /* i18n: {individualPetTypeCount} may refer to any combination of pet counts for a service. Examples include: "3 dogs" or "1 dog and 2 puppies" or "2 dogs, 1 puppy, and 2 cats" */
      return t`You're seeing prices per day for **${individualPetTypeCount}** from **${startDate} to ${endDate}**, including fees. Pricing may change based on your search.`;
    case ServiceType.DROP_IN:
      /* i18n: {individualPetTypeCount} may refer to any combination of pet counts for a service. Examples include: "3 dogs" or "1 dog and 2 puppies" or "2 dogs, 1 puppy, and 2 cats" */
      return t`You're seeing prices per visit for **${individualPetTypeCount}** from **${startDate} to ${endDate}**, including fees. Pricing may change based on your search.`;
    case ServiceType.DOG_WALKING:
    default:
      /* i18n: {individualPetTypeCount} may refer to any combination of pet counts for a service. Examples include: "3 dogs" or "1 dog and 2 puppies" or "2 dogs, 1 puppy, and 2 cats" */
      return t`You're seeing prices per walk for **${individualPetTypeCount}** from **${startDate} to ${endDate}**, including fees. Pricing may change based on your search.`;
  }
};

const getPriceTransparencySelectedSitterAvailableAlertContent = (
  serviceType: ServiceType,
  sitterName?: string,
  individualPetTypeCount?: string,
  dateData?: DateData
): MessageDescriptor => {
  const { isSameDate, startDate, endDate } = dateData || {};

  if (isSameDate) {
    switch (serviceType) {
      case ServiceType.OVERNIGHT_BOARDING:
      case ServiceType.OVERNIGHT_TRAVELING:
        /* i18n: {individualPetTypeCount} may refer to any combination of pet counts for a service. Examples include: "3 dogs" or "1 dog and 2 puppies" or "2 dogs, 1 puppy, and 2 cats" */
        return t`${sitterName} is available. You're seeing prices per night for **${individualPetTypeCount}** on **${startDate}**, including fees. Pricing may change based on your search.`;
      case ServiceType.DOGGY_DAY_CARE:
        /* i18n: {individualPetTypeCount} may refer to any combination of pet counts for a service. Examples include: "3 dogs" or "1 dog and 2 puppies" or "2 dogs, 1 puppy, and 2 cats" */
        return t`${sitterName} is available. You're seeing prices per day for **${individualPetTypeCount}** on **${startDate}**, including fees. Pricing may change based on your search.`;
      case ServiceType.DROP_IN:
        /* i18n: {individualPetTypeCount} may refer to any combination of pet counts for a service. Examples include: "3 dogs" or "1 dog and 2 puppies" or "2 dogs, 1 puppy, and 2 cats" */
        return t`${sitterName} is available. You're seeing prices per visit for **${individualPetTypeCount}** on **${startDate}**, including fees. Pricing may change based on your search.`;
      case ServiceType.DOG_WALKING:
      default:
        /* i18n: {individualPetTypeCount} may refer to any combination of pet counts for a service. Examples include: "3 dogs" or "1 dog and 2 puppies" or "2 dogs, 1 puppy, and 2 cats" */
        return t`${sitterName} is available. You're seeing prices per walk for **${individualPetTypeCount}** on **${startDate}**, including fees. Pricing may change based on your search.`;
    }
  }

  switch (serviceType) {
    case ServiceType.OVERNIGHT_BOARDING:
    case ServiceType.OVERNIGHT_TRAVELING:
      /* i18n: {individualPetTypeCount} may refer to any combination of pet counts for a service. Examples include: "3 dogs" or "1 dog and 2 puppies" or "2 dogs, 1 puppy, and 2 cats" */
      return t`${sitterName} is available. You're seeing prices per night for **${individualPetTypeCount}** from **${startDate} to ${endDate}**, including fees. Pricing may change based on your search.`;
    case ServiceType.DOGGY_DAY_CARE:
      /* i18n: {individualPetTypeCount} may refer to any combination of pet counts for a service. Examples include: "3 dogs" or "1 dog and 2 puppies" or "2 dogs, 1 puppy, and 2 cats" */
      return t`${sitterName} is available. You're seeing prices per day for **${individualPetTypeCount}** from **${startDate} to ${endDate}**, including fees. Pricing may change based on your search.`;
    case ServiceType.DROP_IN:
      /* i18n: {individualPetTypeCount} may refer to any combination of pet counts for a service. Examples include: "3 dogs" or "1 dog and 2 puppies" or "2 dogs, 1 puppy, and 2 cats" */
      return t`${sitterName} is available. You're seeing prices per visit for **${individualPetTypeCount}** from **${startDate} to ${endDate}**, including fees. Pricing may change based on your search.`;
    case ServiceType.DOG_WALKING:
    default:
      /* i18n: {individualPetTypeCount} may refer to any combination of pet counts for a service. Examples include: "3 dogs" or "1 dog and 2 puppies" or "2 dogs, 1 puppy, and 2 cats" */
      return t`${sitterName} is available. You're seeing prices per walk for **${individualPetTypeCount}** from **${startDate} to ${endDate}**, including fees. Pricing may change based on your search.`;
  }
};

const getPriceTransparencySelectedSitterUnavailableAlertContent = (
  serviceType: ServiceType,
  sitterName?: string,
  individualPetTypeCount?: string,
  dateData?: DateData
): MessageDescriptor => {
  const { isSameDate, startDate, endDate } = dateData || {};

  if (isSameDate) {
    switch (serviceType) {
      case ServiceType.OVERNIGHT_BOARDING:
      case ServiceType.OVERNIGHT_TRAVELING:
        /* i18n: {individualPetTypeCount} may refer to any combination of pet counts for a service. Examples include: "3 dogs" or "1 dog and 2 puppies" or "2 dogs, 1 puppy, and 2 cats" */
        return t`${sitterName} is not available. You're seeing prices per night for **${individualPetTypeCount}** on **${startDate}**, including fees. Pricing may change based on your search.`;
      case ServiceType.DOGGY_DAY_CARE:
        /* i18n: {individualPetTypeCount} may refer to any combination of pet counts for a service. Examples include: "3 dogs" or "1 dog and 2 puppies" or "2 dogs, 1 puppy, and 2 cats" */
        return t`${sitterName} is not available. You're seeing prices per day for **${individualPetTypeCount}** on **${startDate}**, including fees. Pricing may change based on your search.`;
      case ServiceType.DROP_IN:
        /* i18n: {individualPetTypeCount} may refer to any combination of pet counts for a service. Examples include: "3 dogs" or "1 dog and 2 puppies" or "2 dogs, 1 puppy, and 2 cats" */
        return t`${sitterName} is not available. You're seeing prices per visit for **${individualPetTypeCount}** on **${startDate}**, including fees. Pricing may change based on your search.`;
      case ServiceType.DOG_WALKING:
      default:
        /* i18n: {individualPetTypeCount} may refer to any combination of pet counts for a service. Examples include: "3 dogs" or "1 dog and 2 puppies" or "2 dogs, 1 puppy, and 2 cats" */
        return t`${sitterName} is not available. You're seeing prices per walk for **${individualPetTypeCount}** on **${startDate}**, including fees. Pricing may change based on your search.`;
    }
  }

  switch (serviceType) {
    case ServiceType.OVERNIGHT_BOARDING:
    case ServiceType.OVERNIGHT_TRAVELING:
      /* i18n: {individualPetTypeCount} may refer to any combination of pet counts for a service. Examples include: "3 dogs" or "1 dog and 2 puppies" or "2 dogs, 1 puppy, and 2 cats" */
      return t`${sitterName} is not available. You're seeing prices per night for **${individualPetTypeCount}** from **${startDate} to ${endDate}**, including fees. Pricing may change based on your search.`;
    case ServiceType.DOGGY_DAY_CARE:
      /* i18n: {individualPetTypeCount} may refer to any combination of pet counts for a service. Examples include: "3 dogs" or "1 dog and 2 puppies" or "2 dogs, 1 puppy, and 2 cats" */
      return t`${sitterName} is not available. You're seeing prices per day for **${individualPetTypeCount}** from **${startDate} to ${endDate}**, including fees. Pricing may change based on your search.`;
    case ServiceType.DROP_IN:
      /* i18n: {individualPetTypeCount} may refer to any combination of pet counts for a service. Examples include: "3 dogs" or "1 dog and 2 puppies" or "2 dogs, 1 puppy, and 2 cats" */
      return t`${sitterName} is not available. You're seeing prices per visit for **${individualPetTypeCount}** from **${startDate} to ${endDate}**, including fees. Pricing may change based on your search.`;
    case ServiceType.DOG_WALKING:
    default:
      /* i18n: {individualPetTypeCount} may refer to any combination of pet counts for a service. Examples include: "3 dogs" or "1 dog and 2 puppies" or "2 dogs, 1 puppy, and 2 cats" */
      return t`${sitterName} is not available. You're seeing prices per walk for **${individualPetTypeCount}** from **${startDate} to ${endDate}**, including fees. Pricing may change based on your search.`;
  }
};

export const getAlertContent = (
  alertContent: AlertContentEnum,
  params: {
    dateData?: DateData;
    individualPetTypeCountDescription: string;
    serviceType: ServiceType;
    sitterName?: string;
  }
): HeaderContentType => {
  const { serviceType, sitterName, dateData, individualPetTypeCountDescription } = params;

  switch (alertContent) {
    case AlertContentEnum.PRICE_TRANSPARENCY_ADD_DATES:
      return getPriceTransparencyAddDatesAlertContent(
        serviceType,
        individualPetTypeCountDescription
      );
    case AlertContentEnum.PRICE_TRANSPARENCY_PRICES_FOR_UNIT:
      return getPriceTransparencyResultsListAlertContent(
        serviceType,
        individualPetTypeCountDescription,
        dateData
      );
    case AlertContentEnum.PRICE_TRANSPARENCY_SELECTED_SITTER_AVAILABLE:
      return getPriceTransparencySelectedSitterAvailableAlertContent(
        serviceType,
        sitterName,
        individualPetTypeCountDescription,
        dateData
      );
    case AlertContentEnum.PRICE_TRANSPARENCY_SELECTED_SITTER_UNAVAILABLE:
      return getPriceTransparencySelectedSitterUnavailableAlertContent(
        serviceType,
        sitterName,
        individualPetTypeCountDescription,
        dateData
      );
    default:
      return '';
  }
};
