import React from 'react';

import { Connect } from '@rover/react-lib/src/components/utils/Redux';
import { selectors as searchPageSelectors } from '@rover/react-lib/src/pages/search/SearchPage/SearchPage.duck';
import { WizardPageType } from '@rover/react-lib/src/pages/search/SearchPage/WizardPageType';
import { selectors as frontendConfigurationSelectors } from '@rover/react-lib/src/redux/ducks/frontendConfiguration.duck';
import { selectors as searchSelectors } from '@rover/react-lib/src/redux/ducks/search.duck';
import { selectors as serviceTypeSelectors } from '@rover/react-lib/src/redux/ducks/serviceTypes.duck';
import type { SearchFilters, SearchFiltersOptions } from '@rover/types';

import PageTwoWizardCollection from './PageTwoWizardCollection';

export type Props = {
  isCatOnly: boolean;
  onChange: (arg0: Record<string, unknown>, arg1?: boolean) => void;
  pageType: WizardPageType;
  searchFilters: SearchFilters;
  serviceTypeFilterOptions: SearchFiltersOptions;
};

const selector = (state) => ({
  getServiceTypeBySlug: serviceTypeSelectors.getServiceTypeBySlug(state),
  hasPets: searchSelectors.getHasPets(state),
  language: frontendConfigurationSelectors.getLanguage(state),
  petSizeBuckets: frontendConfigurationSelectors.getSettings(state).dogSizeBuckets,
  weightUnit: frontendConfigurationSelectors.getSettings(state).weightUnit,
  rolloutPriceTransparency: searchPageSelectors.getRolloutPriceTransparency(state),
  isInPetCountersExperiment: searchPageSelectors.getIsInPetCountersExperiment(state),
});

const PageTwoWizardCollectionContainer = (props: Props): JSX.Element => (
  <Connect selector={selector}>
    {(connectedProps) => <PageTwoWizardCollection {...connectedProps} {...props} />}
  </Connect>
);

export default PageTwoWizardCollectionContainer;
