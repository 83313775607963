import React, { Fragment, useId } from 'react';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import { Close } from '@rover/icons';
import { Box, Button } from '@rover/kibble/core';
import { DSTokenMap, FontSize, FontWeight, Spacing } from '@rover/kibble/styles';
import { Modal } from '@rover/react-lib/src/components';
import { Alignment, HorizontalLayout } from '@rover/react-lib/src/components/layouts';
import Form from '@rover/react-lib/src/components/utils/Form';
import { AdvancedFiltersCollection } from '@rover/react-lib/src/pages/search/SearchPage/components';
import AdvancedFiltersCollectionContainerV2 from '@rover/react-lib/src/pages/search/SearchPage/components/Filters/Collections/AdvancedFiltersCollectionContainerV2';
import { SEARCH_PAGE_Z_INDEX } from '@rover/shared/js/constants/searchPage.constants';
import type { SearchFilters } from '@rover/types';

export type Props = {
  getServiceTypeDisplayName: (arg0: string) => string;
  isOpen: boolean;
  onClose: (arg0: { scrollToResults: boolean }) => void;
  searchFilters: SearchFilters;
  updateFiltersAndFireSearchOnChange: (arg0: Partial<SearchFilters>) => void;
  className?: string;
  inVariantSimplifiedMobileOwnerSearchFormExperiment?: boolean;
  isRollout1?: boolean;
};
const HeaderOverlay = styled(HorizontalLayout)<{ isOpen: boolean; className?: string }>`
  visibility: ${({ isOpen }) => (isOpen ? 'inherit' : 'hidden')};
  background-color: white;
  border-bottom: 1px solid ${DSTokenMap.BORDER_COLOR_PRIMARY.toString()};
  z-index: ${SEARCH_PAGE_Z_INDEX.ADVANCED_FILTER_MODAL.toString()};
  position: fixed;
  top: 0;
  padding: ${Spacing.S.toString()};
`;
const HeaderTitle = styled.span`
  font-size: ${FontSize.L.toString()};
  font-weight: ${FontWeight.BOLD.toString()};
`;
const ModalContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 56px ${Spacing.M.toString()} 0 ${Spacing.M.toString()};
  overflow: auto;
`;
const ApplyButton = styled(Button)<{
  isOpen: boolean;
}>`
  visibility: ${({ isOpen }) => (isOpen ? 'inherit' : 'hidden')};
  position: fixed;
  bottom: ${DSTokenMap.SPACE_6X};
  left: 50%;
  transform: translateX(-50%);
  z-index: ${SEARCH_PAGE_Z_INDEX.ADVANCED_FILTER_MODAL.toString()};
  width: 70%;
`;

const AdvancedFiltersModal = ({
  getServiceTypeDisplayName,
  isOpen,
  onClose,
  searchFilters,
  updateFiltersAndFireSearchOnChange,
  className,
  inVariantSimplifiedMobileOwnerSearchFormExperiment = false,
  isRollout1 = false,
}: Props): JSX.Element => {
  const handleSubmit = (filters): void => {
    onClose({
      scrollToResults: true,
    });

    updateFiltersAndFireSearchOnChange(filters);
  };

  const handleClose = (form): void => {
    form.clearData();
    onClose({
      scrollToResults: false,
    });
  };

  const initialData: SearchFilters = { ...searchFilters };
  const headerTitleId = useId();
  return (
    // TODO: Refactor this component to use Redux instead of form, like in PaginatedWizardModal - https://roverdotcom.atlassian.net/browse/DEV-43006
    <Form initialData={initialData} onSubmit={handleSubmit}>
      {(form) => (
        <Fragment>
          <HeaderOverlay
            className={className}
            isOpen={isOpen}
            hAlign={Alignment.CENTER}
            vAlign={Alignment.CENTER}
          >
            <HeaderTitle id={headerTitleId}>
              <Trans id={getServiceTypeDisplayName(searchFilters.serviceType)} />
            </HeaderTitle>
            <Box position="absolute" top="1x" right="0x">
              <Button
                icon={Close}
                aria-label="Close Modal"
                variant="flat"
                size="small"
                onClick={() => handleClose(form)}
              />
            </Box>
          </HeaderOverlay>
          <Modal
            isOpen={isOpen}
            hasCloseButton={false}
            closeTimeout={0}
            aria-labelledby={headerTitleId}
            className={className}
          >
            <ModalContentWrapper>
              {inVariantSimplifiedMobileOwnerSearchFormExperiment && isRollout1 ? (
                <AdvancedFiltersCollectionContainerV2
                  formData={form.data}
                  onChange={form.onChange}
                />
              ) : (
                <AdvancedFiltersCollection formData={form.data} onChange={form.onChange} />
              )}
            </ModalContentWrapper>
          </Modal>
          <ApplyButton className={className} type="submit" variant="primary" isOpen={isOpen}>
            <Trans>Apply</Trans>
          </ApplyButton>
        </Fragment>
      )}
    </Form>
  );
};

export default AdvancedFiltersModal;
