import React from 'react';
import styled, { css } from 'styled-components';

import { MQ } from '@rover/kibble/styles';
import Popover from '@rover/react-lib/src/components/callouts/Popover';
import SitterBadge from '@rover/react-lib/src/pages/search/shared/components/SitterBadge';
import type { SearchResult } from '@rover/types';

import { MicrodataHTMLAttributes } from '../../microdata';

type SitterBadgesProps = {
  badgeData: SearchResult['secondaryBadgeData'];
  displayExpanded: boolean;
  handleBadgeChange: (isBadgeOpen: boolean) => void;
  index?: number;
  badgeProps?: MicrodataHTMLAttributes;
};

const BADGE_SIZE = '24px';
const BADGE_SIZE_XS = '16px';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`;

const StyledSitterBadge = styled(SitterBadge)<{
  displayExpanded: boolean;
}>`
  & img {
    width: ${BADGE_SIZE};
    height: ${BADGE_SIZE};

    ${MQ.XS_DOWN.toString()} {
      width: ${BADGE_SIZE_XS};
      height: ${BADGE_SIZE_XS};
    }
  }

  display: inline-block;

  ${MQ.XS_DOWN.toString()} {
    vertical-align: middle;
  }

  ${MQ.LG.toString()} {
    ${({ displayExpanded }) =>
      displayExpanded
        ? ''
        : css`
            vertical-align: middle;
          `}
  }
`;

const SitterBadges: React.FC<SitterBadgesProps> = ({
  badgeData,
  displayExpanded,
  handleBadgeChange,
  index,
  badgeProps = {},
}) => {
  if (badgeData.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      {badgeData
        .filter((badge) => badge.showOnSearch)
        .map((badge) => (
          <StyledSitterBadge
            displayExpanded={displayExpanded}
            title={badge.title}
            content={badge.content}
            imageUrl={badge.imageUrl}
            key={badge.imageUrl}
            slug={badge.slug}
            onChange={handleBadgeChange}
            popoverPlacement={
              index === 0 || typeof index !== 'number' // if index is 0 or undefined, the card will be the first in the list
                ? Popover.Placement.BOTTOM
                : Popover.Placement.TOP
            }
            {...badgeProps}
          />
        ))}
    </Wrapper>
  );
};

export default SitterBadges;
