import React from 'react';

import { Connect } from '@rover/react-lib/src/components/utils/Redux';
import { selectors as searchFiltersSelectors } from '@rover/react-lib/src/pages/search/SearchPage/SearchFilters.duck';
import {
  effects as searchPageEffects,
  selectors as searchPageSelectors,
} from '@rover/react-lib/src/pages/search/SearchPage/SearchPage.duck';
import { selectors as serviceTypeSelectors } from '@rover/react-lib/src/redux/ducks/serviceTypes.duck';

import AdvancedFiltersModal from './AdvancedFiltersModal';

export type Props = {
  isOpen: boolean;
  onClose: (arg0: { scrollToResults: boolean }) => void;
  className?: string;
};
const effects = {
  updateFiltersAndFireSearchOnChange: searchPageEffects.updateFiltersAndFireSearchOnChange,
};

const selector = (state) => ({
  getServiceTypeName: serviceTypeSelectors.getName(state),
  getServiceTypeDisplayName: serviceTypeSelectors.getDisplayName(state),
  searchFilters: searchFiltersSelectors.getFilters(state),
  isRollout1: searchPageSelectors.getRollout1SimplifiedMobileOwnerSearchForm(state),
  inVariantSimplifiedMobileOwnerSearchFormExperiment:
    searchPageSelectors.getIsInSimplifiedMobileOwnerSearchFormExperiment(state),
});

const AdvancedFiltersModalContainer = (props: Props): JSX.Element => (
  <Connect effects={effects} selector={selector}>
    {(connectedProps) => <AdvancedFiltersModal {...connectedProps} {...props} />}
  </Connect>
);

export default AdvancedFiltersModalContainer;
