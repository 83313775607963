import type { RefObject } from 'react';
import React, { memo } from 'react';

import { Connect } from '@rover/react-lib/src/components/utils/Redux';
import { selectors as searchPageSelectors } from '@rover/react-lib/src/pages/search/SearchPage/SearchPage.duck';
import type { SearchResultsObservation } from '@rover/react-lib/src/pages/search/SearchPage/SearchPage.types';
import { selectors as countryConfigurationSelectors } from '@rover/react-lib/src/redux/ducks/countryConfiguration.duck';
import { selectors as searchSelectors } from '@rover/react-lib/src/redux/ducks/search.duck';
import { selectors as serviceTypeSelectors } from '@rover/react-lib/src/redux/ducks/serviceTypes.duck';

import { selectors as searchFiltersSelectors } from '../../SearchFilters.duck';
import { selectors as searchMapSelectors } from '../../SearchMap.duck';

import SearchResultsWrapper from './SearchResultsWrapper';

export type Props = {
  onObserve: (observation: SearchResultsObservation) => void;
  scrollToResults: boolean;
  searchCardRefs?: (instance: HTMLDivElement, index: number) => void;
  resultsRef: RefObject<any>;
  isSeoPage: boolean;
  showWizardFilters: boolean;
};

const selector = (state) => ({
  isMapExpanded: searchMapSelectors.getIsExpanded(state),
  isSearchLoading: searchSelectors.getSearchIsLoading(state),
  userInEurope: countryConfigurationSelectors.getCurrentContinentIsEurope(state),
  isRollout1: searchPageSelectors.getRollout1SimplifiedMobileOwnerSearchForm(state),
  inVariantSimplifiedMobileOwnerSearchFormExperiment:
    searchPageSelectors.getIsInSimplifiedMobileOwnerSearchFormExperiment(state),
  searchFilters: searchFiltersSelectors.getFilters(state),
  serviceTypeOptions: serviceTypeSelectors.getServiceTypes(state),
  hasSelectedProvider: searchSelectors.getHasSelectedProvider(state),
});

const SearchResultsWrapperContainer = (props: Props): JSX.Element => (
  <Connect selector={selector}>
    {(connectedProps) => <SearchResultsWrapper {...connectedProps} {...props} />}
  </Connect>
);

export default memo(SearchResultsWrapperContainer);
