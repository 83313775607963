import React, { ForwardedRef, forwardRef, ForwardRefRenderFunction } from 'react';

import { Box, Flex, Text } from '@rover/kibble/core';

import type { Props, RowElement } from './RowPropsType';

const Row: ForwardRefRenderFunction<RowElement, Props> = (
  {
    label,
    sublabel,
    // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
    // @ts-ignore - this prop is correctly included via RowPropsType, and this passes typecheck in codespaces, but GHA calls it an error
    labelHtmlFor,
    helperItem,
    leftItem,
    rightItem,
    ariaLabel,
    onClick,
    role,
    width,
  }: Props,
  ref: ForwardedRef<RowElement>
) => {
  const labelFC =
    typeof label === 'string' ? (
      <Text data-testid="row-label" fontWeight="semibold">
        {label}
      </Text>
    ) : (
      label
    );

  const sublabelFC =
    sublabel && typeof sublabel === 'string' ? (
      <Text data-testid="row-sublabel" textColor="tertiary" size="100" fontWeight="regular">
        {sublabel}
      </Text>
    ) : (
      sublabel
    );

  return (
    <Box
      ref={ref}
      data-testid="row"
      onClick={onClick}
      aria-label={role === 'button' ? ariaLabel : undefined}
      role={role}
      sx={{ ...(onClick ? { cursor: 'pointer' } : {}) }}
    >
      <Flex
        flexDirection="row"
        flexWrap="nowrap"
        alignItems="center"
        justifyContent="space-between"
        alignContent="center"
        paddingY="2x"
        minHeight="56px"
      >
        <Flex flexDirection="row" alignItems="center" flexWrap="nowrap" flex="1">
          {leftItem}
          <Flex
            flexDirection="column"
            justifyContent="center"
            pl={leftItem ? '2x' : '0x'}
            pr={rightItem ? '2x' : '0x'}
            gap="1x"
            as={labelHtmlFor ? 'label' : undefined}
            htmlFor={labelHtmlFor}
          >
            <Flex flexDirection="row" alignItems="center" maxWidth={width}>
              {labelFC}
              {helperItem && <Flex pl="2x">{helperItem}</Flex>}
            </Flex>
            {sublabelFC}
          </Flex>
        </Flex>
        {rightItem && (
          <Box pl="2x" display="flex" alignItems="center" justifyContent="center">
            {rightItem}
          </Box>
        )}
      </Flex>
    </Box>
  );
};

export default forwardRef(Row);
