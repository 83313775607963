import React, { FC, useMemo } from 'react';
import { t } from '@lingui/macro';
import moment from 'moment';

import { Flex, Heading, Paragraph, Text } from '@rover/kibble/core';
import { DSTokenMap } from '@rover/kibble/styles';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import { SearchFilters, ServiceType } from '@rover/types';
import { DateRangeField } from '@rover/types/src/datetime/DateRange';
import { ServiceFrequencyEnum } from '@rover/types/src/ServiceFrequency';

function formatDateRange(
  startDate?: DateRangeField,
  endDate?: DateRangeField,
  frequency?: ServiceFrequencyEnum,
  supportsRepeatBookings?: boolean
): string {
  if (!startDate) {
    return '';
  }
  const startDateIsCurrentYear = moment(startDate).year() === moment().year();

  // case where we start date is present and end date is not ore recurring case
  if (
    startDate &&
    (!endDate || (frequency === ServiceFrequencyEnum.REPEAT && supportsRepeatBookings))
  ) {
    if (startDateIsCurrentYear) {
      return moment(startDate).format('MMM DD');
    }
    return moment(startDate).format('MMM DD, YYYY');
  }

  const isStartDateSameYear = moment(startDate).isSame(endDate, 'year');
  const isEndDateSameYear = moment(endDate).isSame(startDate, 'year');
  if (isStartDateSameYear && isEndDateSameYear && startDateIsCurrentYear) {
    return `${moment(startDate).format('MMM DD')} - ${moment(endDate).format('MMM DD')}`;
  }

  return `${moment(startDate).format('MMM DD, YYYY')} - ${moment(endDate).format('MMM DD, YYYY')}`;
}

export const SearchResultsTitle: FC<{
  searchFilters: SearchFilters;
  serviceTypeOptions: ServiceType[];
  shouldAddMargin?: boolean;
}> = ({ searchFilters, serviceTypeOptions, shouldAddMargin }) => {
  const { i18n } = useI18n();
  const startDate = searchFilters?.startDate;
  const endDate = searchFilters?.endDate;
  const frequency = searchFilters?.frequency;

  const selectedService = useMemo<ServiceType | undefined>(
    () => serviceTypeOptions?.find((option) => option.slug === searchFilters.serviceType),
    [serviceTypeOptions, searchFilters]
  );

  const formattedDateRange = useMemo<string>(
    () => formatDateRange(startDate, endDate, frequency, selectedService?.supportsRepeatBookings),
    [startDate, endDate, frequency, selectedService?.supportsRepeatBookings]
  );

  return (
    <Flex
      pt="8x"
      pb="5x"
      px="4x"
      mt={shouldAddMargin ? '16x' : '0x'}
      flexDirection="column"
      borderBottomWidth="1px"
      borderBottomStyle="solid"
      display={['flex', 'none']}
      data-testid="search-results-title"
      borderBottomColor={DSTokenMap.BORDER_COLOR_PRIMARY.toString()}
    >
      {startDate ? (
        <>
          <Heading size="300" marginBottom="2x">
            {i18n._(t`Best matches for you`)}
          </Heading>
          <Paragraph size="100">
            {`${i18n._(t`Showing ${selectedService?.displayName} sitters available:`)}`}
            <Text size="100" ml="1x">
              {formattedDateRange}
            </Text>
          </Paragraph>
        </>
      ) : (
        <>
          <Heading size="300" marginBottom="2x">
            {i18n._(t`Sitters close to you`)}
          </Heading>
          <Paragraph size="100">
            {i18n._(
              t`Add dates to see sitters who’ll be available for your need. These are sitters in your area, but might not be available.`
            )}
          </Paragraph>
        </>
      )}
    </Flex>
  );
};
