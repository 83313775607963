import React, { Fragment } from 'react';
import { Trans } from '@lingui/macro';

import { Box, Button, Flex } from '@rover/kibble/core';
import { useI18n } from '@rover/rsdk/src/modules/I18n';

import CheckBoxCategory, { Category } from './CheckBoxCategory';

export type Props = {
  filtersCount?: number;
  categories: Category[];
  resetAllLinkText: string;
  resetAll: () => void;
  isChecked: (attribute: string, value: string | null | undefined) => boolean;
  onChange: (updatedFilters: Record<string, any>, checkboxValue: any | null | undefined) => void;
  onCategoryExpandAndCollapse?: () => void;
};

const CollapsingCheckBoxCollection = ({
  filtersCount,
  isChecked,
  onChange,
  categories,
  resetAll,
  resetAllLinkText,
  onCategoryExpandAndCollapse,
}: Props): JSX.Element => {
  const { i18n } = useI18n();

  return (
    <Fragment>
      <Flex justifyContent="space-between">
        {typeof filtersCount !== 'undefined' && (
          <Box paddingY="2x" paddingX="0x">
            <Trans>Filters ({filtersCount})</Trans>
          </Box>
        )}
        <Button variant="flat" size="small" onClick={resetAll}>
          {resetAllLinkText}
        </Button>
      </Flex>
      {categories.map((category) => (
        <CheckBoxCategory
          key={i18n._(category.category)}
          isChecked={isChecked}
          onChange={onChange}
          onExpandAndCollapse={onCategoryExpandAndCollapse}
          category={category}
        />
      ))}
    </Fragment>
  );
};

export default CollapsingCheckBoxCollection;
