/* eslint-disable max-classes-per-file */
import React, { FC, useId, useRef } from 'react';
import { DateUtils } from 'react-day-picker';
import { MessageDescriptor } from '@lingui/core/i18n';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import styled from 'styled-components';

import { ArrowRight } from '@rover/icons';
import { A11yHidden, DSTokenMap, MQ, Spacing } from '@rover/kibble/styles';
import type { DatePickerHandle } from '@rover/react-lib/src/components/datetime/DatePicker';
import DatePicker, { CalendarDirection } from '@rover/react-lib/src/components/datetime/DatePicker';
import {
  DateRangeEnum,
  DateRangeField,
  DateRangeValidationError,
  UpdatedDateRange,
} from '@rover/types/src/datetime/DateRange';
import Enum from '@rover/types/src/Enum';

import FormGroup from '../../formFields/FormGroup';

import DateRangePickerAsCalendar from './DateRangePickerAsCalendar';

export class MobileDirection extends Enum<string> {
  static ROW = new MobileDirection('row');

  static COLUMN = new MobileDirection('column');

  static isLocked = true;
}

export type Props = {
  id?: string;
  allowKeyboardInput?: boolean;
  asCalendar?: boolean;
  endDate?: DateRangeField;
  endPlaceholder?: string | MessageDescriptor;
  language: string;
  maxDate?: DateRangeField;
  mobileDirection?: MobileDirection;
  onBlur?: () => void;
  onInputBlurDatePicker?: (
    dateRange: DateRangeEnum,
    e?: React.FocusEvent<HTMLDivElement, Element>,
    isDateValid?: boolean
  ) => void;
  onChange: (range: UpdatedDateRange) => void;
  onFocus?: () => void;
  showArrow?: boolean;
  startDate?: DateRangeField;
  startPlaceholder?: string | MessageDescriptor;
  calendarDirection?: CalendarDirection;
  isMobile?: boolean;
  holidays?: Date[];
  shouldAutoSwitch?: boolean;
  validationErrors?: DateRangeValidationError;
};

type DateRangePickerWrapperProps = {
  mobileDirection: MobileDirection;
};

const DateRangePickerWrapper = styled.div<DateRangePickerWrapperProps>`
  display: flex;
  flex-direction: row;
  width: 100%;

  ${MQ.XS.toString()} {
    flex-direction: ${(props) => props.mobileDirection.toString()};
  }
`;

const StyledFormGroup = styled(FormGroup)`
  flex: 1;
`;

export const MobileLabel = styled.label`
  font-weight: normal;

  margin-bottom: ${Spacing.XS.toString()};

  ${MQ.SM_UP.toString()} {
    ${A11yHidden};
  }
`;

type IconWrapperProps = {
  mobileDirection: Props['mobileDirection'];
};

export const IconWrapper = styled.div<IconWrapperProps>`
  display: flex;
  align-items: center;
  margin: 0 10px;
  height: 40px;

  ${MQ.XS.toString()} {
    display: ${(props) => (props.mobileDirection === MobileDirection.COLUMN ? 'none' : 'flex')};
    height: auto;
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledArrowRight = styled(({ showArrow, ...other }) => <ArrowRight {...other} />)`
  display: ${(props) => (props.showArrow ? 'inline' : 'none')};
  fill: ${DSTokenMap.BORDER_COLOR_PRIMARY.toString()};
  height: 14px;
  width: 14px;
`;

const getDateAtMidnight = (date: DateRangeField): DateRangeField =>
  date && new Date(new Date(date).setHours(0, 0, 0, 0));

const DateRangePicker: FC<Props> = ({
  asCalendar = false,
  endPlaceholder = t`End Date`,
  maxDate = undefined,
  mobileDirection = MobileDirection.COLUMN,
  onChange = (): undefined => undefined,
  showArrow = true,
  startPlaceholder = t`Start Date`,
  calendarDirection = CalendarDirection.FLEX,
  isMobile = false,
  holidays = [],
  startDate,
  endDate,
  allowKeyboardInput,
  language,
  onInputBlurDatePicker,
  shouldAutoSwitch = true,
  validationErrors,
  ...other
}) => {
  const startPickerId = useId();
  const endPickerId = useId();
  const endDatePickerRef = useRef<DatePickerHandle>(null);

  const handleChange = ({
    dateChanged,
    startDate: $startDate,
    endDate: $endDate,
  }: UpdatedDateRange): void => {
    // ignore the time because when we store these values in the url, we only store
    // the day/month/year, so this makes things consistent
    const endDateAtMidnight = getDateAtMidnight($endDate);
    const startDateAtMidnight = getDateAtMidnight($startDate);

    const sameStartDate =
      $startDate && startDate ? DateUtils.isSameDay($startDate, startDate) : false;

    const sameEndDate = $endDate && endDate ? DateUtils.isSameDay($endDate, endDate) : false;

    // Do not fire onChange if both dates did not change
    if (!sameStartDate || !sameEndDate) {
      onChange({
        dateChanged,
        startDate: startDateAtMidnight || undefined,
        endDate: endDateAtMidnight || undefined,
      });
    }
  };

  const handleStartDateChange = (selectedStartDate: DateRangeField): void => {
    const updatedEndDate = (() => {
      if (!endDate || !selectedStartDate) return endDate;
      return DateUtils.isDayAfter(selectedStartDate, endDate) ? undefined : endDate;
    })();

    handleChange({
      dateChanged: DateRangeEnum.START_DATE,
      startDate: selectedStartDate,
      endDate: updatedEndDate,
    });

    if (shouldAutoSwitch) {
      endDatePickerRef.current?.showDatePicker();
    }
  };

  if (asCalendar) {
    return (
      <DateRangePickerAsCalendar
        startDate={startDate}
        endDate={endDate}
        onChange={onChange}
        holidays={holidays}
        maxDate={maxDate}
        validationType="inline"
        {...other}
      />
    );
  }

  const isAllowKeyboardInputDefined = typeof allowKeyboardInput !== 'undefined';
  const allowKeyboard = isAllowKeyboardInputDefined ? allowKeyboardInput : !isMobile;

  return (
    <DateRangePickerWrapper mobileDirection={mobileDirection} {...other}>
      <StyledFormGroup>
        <MobileLabel htmlFor={startPickerId}>
          <Trans id={startPlaceholder} />
        </MobileLabel>
        <DatePicker
          allowKeyboardInput={allowKeyboard}
          calendarDirection={calendarDirection}
          date={startDate}
          id={startPickerId}
          language={language}
          maxDate={maxDate}
          onChange={handleStartDateChange}
          placeholder={startPlaceholder}
          holidays={holidays}
          isMobileBrowser={isMobile}
          validationType="inline"
          onInputBlur={(e, isValidDate) =>
            onInputBlurDatePicker && onInputBlurDatePicker(DateRangeEnum.START_DATE, e, isValidDate)
          }
          validationError={validationErrors?.startDate}
        />
      </StyledFormGroup>
      <IconWrapper mobileDirection={mobileDirection}>
        <StyledArrowRight showArrow={showArrow} />
      </IconWrapper>
      <StyledFormGroup>
        <MobileLabel htmlFor={endPickerId}>
          <Trans id={endPlaceholder} />
        </MobileLabel>

        <DatePicker
          allowKeyboardInput={allowKeyboard}
          calendarDirection={calendarDirection}
          date={endDate}
          holidays={holidays}
          id={endPickerId}
          language={language}
          maxDate={maxDate}
          minDate={startDate}
          modifiers={{ start: startDate }}
          onChange={(selectedDate) =>
            handleChange({
              dateChanged: DateRangeEnum.END_DATE,
              startDate,
              endDate: selectedDate,
            })
          }
          placeholder={endPlaceholder}
          ref={endDatePickerRef}
          isMobileBrowser={isMobile}
          validationType="inline"
          onInputBlur={(e, isValidDate) =>
            onInputBlurDatePicker && onInputBlurDatePicker(DateRangeEnum.END_DATE, e, isValidDate)
          }
          validationError={validationErrors?.endDate}
        />
      </StyledFormGroup>
    </DateRangePickerWrapper>
  );
};

export default DateRangePicker;
