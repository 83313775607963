import React from 'react';

import { Connect, ContainerProps } from '@rover/react-lib/src/components/utils/Redux';
import events from '@rover/react-lib/src/pages/search/SearchPage/analytics';
import {
  actions as searchMapActions,
  effects as searchMapEffects,
} from '@rover/react-lib/src/pages/search/SearchPage/SearchMap.duck';
import { selectors as searchModalSelectors } from '@rover/react-lib/src/pages/search/SearchPage/SearchModal.duck';
import { effects as searchPageEffects } from '@rover/react-lib/src/pages/search/SearchPage/SearchPage.duck';
import { selectors as searchSelectors } from '@rover/react-lib/src/redux/ducks/search.duck';
import { selectors as serviceTypeSelectors } from '@rover/react-lib/src/redux/ducks/serviceTypes.duck';

import PaginatedWizardModal from './PaginatedWizardModal';

const actions = {
  setShowStaticMap: searchMapActions.setShowStaticMap,
  setShowDynamicMap: searchMapActions.setShowDynamicMap,
};
const effects = {
  fireAnalyticsEvent: searchPageEffects.fireAnalyticsEvent,
  updateFiltersAndFireSearchOnChange: searchMapEffects.updateFiltersFireSearchAndLoadDynamicMap,
};

const selector = (state) => ({
  getServiceTypeBySlug: serviceTypeSelectors.getServiceTypeBySlug(state),
  hasPets: searchSelectors.getHasPets(state),
  isCatOnly: searchModalSelectors.getIsCatOnly(state),
  searchFilters: searchModalSelectors.getFilters(state),
});

type Props = ContainerProps<
  typeof PaginatedWizardModal,
  typeof actions,
  typeof effects,
  typeof selector
>;

const PaginatedWizardModalContainer = (props: Props): JSX.Element => (
  <Connect
    actions={actions}
    effects={effects}
    selector={selector}
    onMount={[
      () => searchPageEffects.fireAnalyticsEvent(events.SearchOwnerPreferencesDisplayEvent),
    ]}
  >
    {(connectedProps) => <PaginatedWizardModal {...connectedProps} {...props} />}
  </Connect>
);

export default PaginatedWizardModalContainer;
