import React from 'react';

import { Connect } from '@rover/react-lib/src/components/utils/Redux';
import { selectors as searchBoxSelectors } from '@rover/react-lib/src/pages/index/HomePage/components/SearchBox/SearchBox.duck';
import { selectors as searchPageSelectors } from '@rover/react-lib/src/pages/search/SearchPage/SearchPage.duck';
import { selectors as frontendConfigurationSelectors } from '@rover/react-lib/src/redux/ducks/frontendConfiguration.duck';
import { selectors as petsSelectors } from '@rover/react-lib/src/redux/ducks/pets.duck';
import { selectors as searchSelectors } from '@rover/react-lib/src/redux/ducks/search.duck';
import { selectors as serviceTypeSelectors } from '@rover/react-lib/src/redux/ducks/serviceTypes.duck';
import type { SearchFilters } from '@rover/types';

import { selectors as searchFiltersSelectors } from '../../../SearchFilters.duck';

import AdvancedFiltersCollection from './AdvancedFiltersCollection';

export type Props = {
  formData: Partial<SearchFilters>;
  onChange: (arg0: Partial<SearchFilters>) => void;
};

const selector = (state) => ({
  getServiceTypeBySlug: serviceTypeSelectors.getServiceTypeBySlug(state),
  currencyCode: searchSelectors.getSearchCurrencyCode(state),
  getPriceUnit: serviceTypeSelectors.getPriceUnit(state),
  hasPets: searchSelectors.getHasPets(state),
  language: frontendConfigurationSelectors.getLanguage(state),
  petOptions: petsSelectors.getPets(state),
  petSizeBuckets: frontendConfigurationSelectors.getSettings(state).dogSizeBuckets,
  priceFilterMaxValue: searchPageSelectors.getPriceFilterMaxValue(state),
  priceFilterMinValue: searchPageSelectors.getPriceFilterMinValue(state),
  searchFilters: searchFiltersSelectors.getFilters(state),
  serviceTypeFilterOptions: searchFiltersSelectors.getServiceTypeFilterOptions(state),
  weightUnit: frontendConfigurationSelectors.getSettings(state).weightUnit,
  serviceTypeSelectors: serviceTypeSelectors.getServiceTypeBySlug(state),
  queryDerivedData: searchSelectors.getQueryDerivedData(state),
  isInGingrFacilitiesInSearchExperiment:
    searchPageSelectors.getIsGingrFacilitiesInSearchExperiment(state),
  isDaycareFilterOutsideMarketsInExperiment:
    searchBoxSelectors.getIsDaycareFilterOutsideMarketsInExperiment(state),
  rolloutPriceTransparency: searchPageSelectors.getRolloutPriceTransparency(state),
  isInPetCountersExperiment: searchPageSelectors.getIsInPetCountersExperiment(state),
});

const AdvancedFiltersCollectionContainer = (props: Props): JSX.Element => (
  <Connect selector={selector}>
    {(connectedProps) => <AdvancedFiltersCollection {...connectedProps} {...props} />}
  </Connect>
);

export default AdvancedFiltersCollectionContainer;
