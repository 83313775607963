import React, { useId } from 'react';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';

import Fieldset from '@rover/react-lib/src/components/formFields/Fieldset';
import SingleSelectButtonGroup from '@rover/react-lib/src/components/formFields/SingleSelectButtonGroup';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import FilterLabel from '@rover/shared/js/search/components/FilterLabel';

import { TogglePreference } from '..';

type Props = {
  displayAsToggle?: boolean;
  onChange: (arg0: boolean) => void;
  value?: boolean;
};

const PUPPY_PREFERENCES_OPTIONS = [
  {
    value: true,
    title: t`Puppy (less than 1 year)`,
  },
  {
    value: false,
    title: t`Adult`,
  },
];

const Pets = ({ displayAsToggle, onChange, value = false }: Props): JSX.Element => {
  const { i18n } = useI18n();
  const id = useId();

  return displayAsToggle ? (
    <TogglePreference
      label={i18n._('I have a puppy younger than one year old')}
      onChange={onChange}
      value={value}
    />
  ) : (
    <Fieldset aria-labelledby={id}>
      <FilterLabel id={id}>
        <Trans>How old are your dogs?</Trans>
      </FilterLabel>
      <SingleSelectButtonGroup
        buttons={PUPPY_PREFERENCES_OPTIONS}
        id={id}
        onChange={onChange}
        value={value}
      />
    </Fieldset>
  );
};
export default Pets;
