import React, { FC, useId, useMemo } from 'react';
import { t, Trans } from '@lingui/macro';

import type { Option } from '@rover/react-lib/src/components/formFields/ButtonGroup';
import ButtonGroup from '@rover/react-lib/src/components/formFields/ButtonGroup';
import { SERVICE_TYPE_CHOICES } from '@rover/shared/js/constants/service';
import FilterLabel from '@rover/shared/js/search/components/FilterLabel';
import type { SearchFilters } from '@rover/types';

export const SERVICE_TYPE_LABELS = {
  [SERVICE_TYPE_CHOICES.boarding]: t`How many pets?`,
  [SERVICE_TYPE_CHOICES.dropIn]: t`How many visits per day?`,
  [SERVICE_TYPE_CHOICES.dayCare]: t`How many pets?`,
  [SERVICE_TYPE_CHOICES.walking]: t`How many walks per day?`,
};

type Props = {
  onChange: (spacesRequired: number) => void;
  searchFilters: Partial<SearchFilters>;
  mb?: string;
};

const SpacesRequired: FC<Props> = ({ searchFilters, onChange, mb }) => {
  const legendId = useId();
  const { spacesRequired, serviceType: serviceTypeSlug } = searchFilters;
  const label = SERVICE_TYPE_LABELS[`${serviceTypeSlug}`] || '';

  const options = useMemo(
    (): Option[] =>
      [1, 2, 3].map((key) => ({
        key: `${key}-spaces-required`,
        label: key === 3 ? `${key}+` : `${key}`,
        value: key.toString(),
      })),
    []
  );

  return (
    <>
      {label && (
        <FilterLabel id={legendId} mb={mb}>
          <Trans id={label.id} />
        </FilterLabel>
      )}
      <ButtonGroup
        data-testid="spaces-required"
        buttonType="radio"
        legendId={legendId}
        name="spaces-required"
        onToggle={(option) => onChange(parseInt(`${option.value}`, 10))}
        options={options}
        value={spacesRequired?.toString()}
      />
    </>
  );
};

export default SpacesRequired;
