import React, { useState } from 'react';

import { Box, Flex, Text } from '@rover/kibble/core';
import type { SearchResult, ServiceTypeSlug } from '@rover/types';

import SearchScoreData from '../SearchScoreData';

import NameRow from './NameRow';
import OfferedServices from './OfferedServices';

export type Props = {
  displayExpanded: boolean;
  index?: number;
  searchResult: SearchResult;
  isSeoPage: boolean;
};

const InfoColumn = ({
  displayExpanded,
  index,
  searchResult: {
    neighborhood,
    city,
    state,
    zip,
    secondaryBadgeData,
    shortName,
    title,
    popoverDebugData,
    browsableServiceSlugs,
    distanceFromSearchCenterObj: { distanceDisplay },
    profileStatusBadgeData,
    isFacility,
    businessAddress,
    rank,
  },
  isSeoPage,
}: Props): JSX.Element => {
  const [isBadgeOpen, setIsBadgeOpen] = useState(false);

  const serviceLocation = [neighborhood, city, state, zip].filter(Boolean).join(', ');
  const facilityLocation = businessAddress;
  const location = isFacility && facilityLocation ? facilityLocation : serviceLocation;

  const handleBadgeChange = (): void => {
    setIsBadgeOpen(true);
  };

  return (
    <Flex flex="1 1 0" flexDirection="column" px="4x">
      <NameRow
        badgeData={secondaryBadgeData}
        displayExpanded={displayExpanded}
        name={shortName}
        handleBadgeChange={handleBadgeChange}
        index={index}
        isBadgeOpen={isBadgeOpen}
        profileStatusBadgeData={profileStatusBadgeData}
        rank={rank}
      />
      {popoverDebugData && <SearchScoreData data={popoverDebugData} />}
      <Box
        display={['none', displayExpanded ? 'block' : 'none']}
        mb="1x"
        sx={{
          hyphens: 'auto',
          overflowWrap: 'break-word',
          wordBreak: 'break-word',
          wordWrap: 'break-word',
        }}
      >
        <Text size="100">{title}</Text>
      </Box>
      <Text size="100">{location}</Text>
      <Box mb="2x" display={['block', 'none']}>
        <Text size="100">{distanceDisplay}</Text>
      </Box>
      {isSeoPage && <OfferedServices slugs={browsableServiceSlugs as ServiceTypeSlug[]} />}
    </Flex>
  );
};

export default InfoColumn;
