import styled from 'styled-components';

import { DSTokenMap } from '@rover/kibble/styles';
import Label from '@rover/react-lib/src/components/typography/Label';

const FilterLabel = styled(Label)`
  display: block;
  margin-bottom: ${(props) => props.mb || DSTokenMap.SPACE_2X.toString()};
  width: 100%;
  font-weight: 700;
  color: ${DSTokenMap.TEXT_COLOR_SECONDARY.toString()};
`;

export default FilterLabel;
