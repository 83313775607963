import React from 'react';

import { Connect } from '@rover/react-lib/src/components/utils/Redux';
import { effects as searchFiltersEffects } from '@rover/react-lib/src/pages/search/SearchPage/SearchFilters.duck';
import {
  actions as searchMapActions,
  selectors as searchMapSelectors,
} from '@rover/react-lib/src/pages/search/SearchPage/SearchMap.duck';
import { effects as searchPageEffects } from '@rover/react-lib/src/pages/search/SearchPage/SearchPage.duck';

import StickyMap from './StickyMap';

type Props = {
  onMarkerClick: (index: number) => void;
  onScrollableFilterChange: () => void;
};

const selector = (state) => ({
  isExpanded: searchMapSelectors.getIsExpanded(state),
  shouldSearchOnMove: searchMapSelectors.getShouldSearchOnMove(state),
});

const effects = {
  fireSearch: searchPageEffects.fireSearch,
  updateFilters: searchFiltersEffects.updateFilters,
};

const StickyMapContainer = (props: Props): JSX.Element => (
  <Connect
    actions={{
      toggleIsExpanded: searchMapActions.toggleIsExpanded,
      toggleShouldSearchOnMove: searchMapActions.toggleShouldSearchOnMove,
    }}
    selector={selector}
    effects={effects}
  >
    {(connectedProps) => <StickyMap {...connectedProps} {...props} />}
  </Connect>
);

export default StickyMapContainer;
