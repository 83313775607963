import React, { PureComponent } from 'react';
import { Trans } from '@lingui/macro';

import { Box, Link, Text } from '@rover/kibble/core';

import TogglePreference from './TogglePreference';

type Props = {
  label: string;
  onChange: (arg0: boolean) => void;
  value: boolean;
  starSitterLink: string;
};

export default class StarSitterFilter extends PureComponent<Props> {
  render(): JSX.Element {
    const { label, onChange, value, starSitterLink } = this.props;
    return (
      <Box>
        <TogglePreference label={label} onChange={onChange} value={value} />
        <Text size="100">
          <Trans>
            <Link href={starSitterLink}>Learn more about the program</Link>
          </Trans>
        </Text>
      </Box>
    );
  }
}
