import React, { useEffect, useRef, useState } from 'react';
import { MessageDescriptor } from '@lingui/core';
import capitalize from 'lodash-es/capitalize';
import findIndex from 'lodash-es/findIndex';
import sortBy from 'lodash-es/sortBy';
import moment from 'moment';
import uuid from 'uuid';

import { Box } from '@rover/kibble/core';
import ButtonGroup from '@rover/react-lib/src/components/formFields/ButtonGroup';
import FormBasicValidationError from '@rover/react-lib/src/components/formFields/FormValidationError/FormBasicValidationError';
import dayFactory from '@rover/react-lib/src/factories/dayFactory';
import timeFactory from '@rover/react-lib/src/factories/timeFactory';
import type { Day } from '@rover/types';
import type { Time } from '@rover/types/src/datetime/Time';

import '@rover/utilities/allMomentLocales';

export type Props = {
  id?: string;
  selectedDays: Day[];
  language?: string;
  onChange: (arg0: Day[]) => void;
  validationError?: MessageDescriptor | null | string;
  'aria-labelledby'?: string;
  bigLabel?: boolean;
};

type DayOfWeek = {
  longName: string;
  shortName: string;
  shortestName: string;
  englishLongName: string;
  dayId: number;
};

const USA_ORDERED_ENGLISH_DAYS_OF_WEEK: string[] = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

const DAYS_IN_WEEK = 7;
const MIN_FULL_TEXT_WIDTH = 360;

export const getDaysFromLanguage = (language): DayOfWeek[] => {
  if (language) {
    moment.locale(language);
  }
  const i18nStartOfWeekOffset = moment.localeData().firstDayOfWeek();
  const weekdaysShort = moment.weekdaysShort(true);
  const weekdaysShortest = moment.weekdaysMin(true);
  return moment.weekdays(true).reduce((accum: DayOfWeek[], weekday, i) => {
    // While the ISO standard starts the week on 0:monday, our system expects 0:sunday
    // Because of this, the day's dayId in the array (which determines it's localized display order)
    // may differ from it's dayId property
    const USADayOfWeekIndex = (i + i18nStartOfWeekOffset) % DAYS_IN_WEEK;
    accum.push({
      longName: weekday,
      shortName: weekdaysShort[i].replace('.', ''),
      shortestName: weekdaysShortest[i],
      englishLongName: USA_ORDERED_ENGLISH_DAYS_OF_WEEK[USADayOfWeekIndex],
      dayId: USADayOfWeekIndex,
    });
    return accum;
  }, []);
};

const DayOfWeekPicker = (props: Props): JSX.Element => {
  const pickerRef = useRef<HTMLElement | null>(null);
  const [showFullText, setShowFullText] = useState(true);

  const handleResize = (): void => {
    if (pickerRef.current) {
      if (pickerRef.current.offsetWidth < MIN_FULL_TEXT_WIDTH) {
        setShowFullText(false);
      } else {
        setShowFullText(true);
      }
    }
  };

  const handleToggle = (dayId: number, days: DayOfWeek[]): void => {
    const { selectedDays, onChange } = props;
    let newSelectedDays: Day[] = [...selectedDays];

    // if we have the day, toggle it off
    if (newSelectedDays.find((newSelectedDay: Day) => newSelectedDay.id === dayId)) {
      newSelectedDays = newSelectedDays.filter((selectedDay: Day) => selectedDay.id !== dayId); // else we're toggling on
    } else {
      let times: Time[];
      const earliestSelectedDayWithLocalizedIndex = selectedDays.reduce(
        (currentEarliestSelectedDay: any, selectedDay: Day) => {
          const selectedDayLocalizedIndex = findIndex(
            days,
            (day: DayOfWeek) => day.dayId === selectedDay.id
          );
          if (
            currentEarliestSelectedDay.localizedIndex &&
            selectedDayLocalizedIndex < currentEarliestSelectedDay.localizedIndex
          ) {
            return { ...selectedDay, localizedIndex: selectedDayLocalizedIndex };
          }
          return currentEarliestSelectedDay;
        },
        {}
      );
      // if we have an earlier day, copy it
      if (
        earliestSelectedDayWithLocalizedIndex &&
        earliestSelectedDayWithLocalizedIndex.localizedIndex < dayId
      ) {
        times = earliestSelectedDayWithLocalizedIndex.times.map((time: Time) => ({
          ...time,
          id: uuid.v4(),
        })); // selectedDays[0] doesn't exist, or toggled day is earlier than selectedDays[0]
      } else {
        times = [timeFactory()];
      }

      const newSelectedDayName = capitalize(
        days.find((day) => day.dayId === dayId)?.englishLongName
      );
      newSelectedDays.push(
        dayFactory({
          name: newSelectedDayName,
          id: dayId,
          times,
        })
      );
    }
    onChange(
      sortBy(
        newSelectedDays,
        ({ id: usaDayId }) =>
          (usaDayId + DAYS_IN_WEEK - moment.localeData().firstDayOfWeek()) % DAYS_IN_WEEK
      )
    );
  };

  useEffect(() => {
    if (process.env.JS_ENV_CLIENT) {
      window.addEventListener('resize', handleResize);
    }
    handleResize();

    return () => {
      if (process.env.JS_ENV_CLIENT) {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  const {
    selectedDays,
    validationError,
    language,
    'aria-labelledby': ariaLabelledby,
    bigLabel = false,
    id,
  } = props;
  const days = getDaysFromLanguage(language);

  return (
    <Box
      ref={(picker) => {
        pickerRef.current = picker;
      }}
      id={id}
    >
      <ButtonGroup
        legendId={ariaLabelledby}
        options={days.map((day) => ({
          label: showFullText ? day.shortName : day.shortestName,
          'aria-label': day.longName,
          value: !!selectedDays.find((selectedDay) => selectedDay.id === day.dayId),
          key: day.dayId,
          dayId: day.dayId,
          'data-qa-id': `button-${day.englishLongName}`,
        }))}
        onToggle={(option): void => handleToggle(option.dayId, days)}
        bigLabel={bigLabel}
      />
      <FormBasicValidationError errorMessage={validationError as MessageDescriptor} />
    </Box>
  );
};

export default DayOfWeekPicker;
