import React from 'react';

import { Flex } from '@rover/kibble/core';
import { SEARCH_PAGE_Z_INDEX } from '@rover/shared/js/constants/searchPage.constants';

import AdvancedFiltersButton from './AdvancedFiltersButton';

export type Props = {
  selectedFilterCount: number;
  onClick: () => void;
};

export default ({ ...other }: Props): JSX.Element => (
  <Flex
    display={['flex', 'none']}
    position="fixed"
    bottom="6x"
    zIndex={SEARCH_PAGE_Z_INDEX.ADVANCED_FILTER_BUTTON_FIXED.toString()}
    justifyContent="center"
    width="100%"
  >
    <AdvancedFiltersButton variant="primary" {...other} />
  </Flex>
);
