import React from 'react';

import LocationInput from '@rover/react-lib/src/containers/LocationInputContainer';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import FilterLabel from '@rover/shared/js/search/components/FilterLabel';
import { SearchFilters } from '@rover/types';

/** A more accurate (though not guaranteed to be) typing of the argument passed to Props['onChange'] below */
export type LocationOnChangeData = {
  centerlat: string;
  centerlng: string;
  location: string;
  locationAccuracy?: string;
  locationType?: string;
  maxlat: undefined;
  maxlng: undefined;
  minlat: undefined;
  minlng: undefined;
  rawLocationTypes: string[];
  zoomlevel: undefined;
};

type Props = {
  label: string;
  placeholder?: string;
  /** This is improperly typed. The type `LocationOnChangeData` is more accurate */
  onChange: (arg0: Partial<SearchFilters>) => void;
  onClear?: () => void;
  location?: SearchFilters['location'];
  suggestionsMenuAlignment?: 'left' | 'right';
  locationInputId: string;
  inputHeight?: string;
};

const Location = ({
  label,
  placeholder,
  onChange,
  onClear,
  suggestionsMenuAlignment = 'left',
  locationInputId,
  inputHeight,
  location,
}: Props): JSX.Element => {
  const { i18n } = useI18n();

  return (
    <>
      <FilterLabel htmlFor={locationInputId}>{label}</FilterLabel>
      <LocationInput
        channel="ssr-search"
        enableLatLng
        inputId={locationInputId}
        inputValue={location}
        onClear={onClear}
        onChange={({ value, lat, lng, locationAccuracy, locationType, rawLocationTypes }) =>
          onChange({
            centerlat: lat,
            centerlng: lng,
            location: value,
            locationAccuracy,
            locationType,
            maxlat: undefined,
            maxlng: undefined,
            minlat: undefined,
            minlng: undefined,
            rawLocationTypes,
            zoomlevel: undefined,
          })
        }
        placeholder={placeholder || i18n._('Zip code or Address')}
        inputHeight={inputHeight}
        suggestionsMenuAlignment={suggestionsMenuAlignment}
        validationType="inline"
      />
    </>
  );
};

export default Location;
