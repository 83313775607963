import React from 'react';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import { Map } from '@rover/icons';
import { Box, Flex, Heading } from '@rover/kibble/core';
import { DSTokenMap } from '@rover/kibble/styles';

const Icon = styled(Map)`
  fill: ${DSTokenMap.TEXT_COLOR_SECONDARY.toString()};
`;

function GoogleMapError(): JSX.Element {
  return (
    <Flex width="100%" alignItems="center" flexDirection="column" justifyContent="center">
      <Box>
        <Icon />
      </Box>
      <Box p="4x">
        <Heading as="div" textColor="secondary">
          <Trans>There was a problem loading the map</Trans>
        </Heading>
      </Box>
    </Flex>
  );
}

export default GoogleMapError;
