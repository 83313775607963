import { A11yHiddenBox } from '@rover/kibble/styles';
import { getAbsoluteUrl } from '@rover/shared/js/utils/getFullUrl';

import MicrodataHTMLAttributes from './MicrodataHTMLAttributes';
import schema from './schema';

type Props = {
  indexUrl?: string;
} & MicrodataHTMLAttributes;

const Brand: React.FC<Props> = ({ indexUrl, ...props }: Props) => (
  <A11yHiddenBox {...props} itemScope itemType={schema`Brand`}>
    <meta itemProp="name" content="Rover" />
    {indexUrl ? <meta itemProp="url" content={getAbsoluteUrl(indexUrl).toString()} /> : null}
  </A11yHiddenBox>
);

export default Brand;
