import React from 'react';
import styled from 'styled-components';

import FancyCheckbox from '@rover/react-lib/src/components/formFields/FancyCheckbox';

const FullTimeAvailabilityCheckbox = styled(FancyCheckbox)`
  display: inline-flex;
  margin-bottom: 0;
`;
type Props = {
  label: string;
  onChange: (arg0: boolean) => void;
  searchFilters: {
    fulltimeAvailability: boolean;
  };
};

const FulltimeAvailability = ({
  label,
  onChange,
  searchFilters: { fulltimeAvailability },
}: Props): JSX.Element => (
  <FullTimeAvailabilityCheckbox
    checked={fulltimeAvailability}
    label={label}
    onChange={onChange}
    fontSize="100"
    fontWeight="semibold"
  />
);

export default FulltimeAvailability;
