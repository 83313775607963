import styled from 'styled-components';

import { DSTokenMap } from '@rover/kibble/styles';

// Almost always use `FilterLabel` in preference to this component.
// This should only be used if you need the appearance of a label but not
// the `label` element; most often when you're creating a legend for a
// `fieldset` but can't use a `legend` element due to styling concerns.
const FilterLegend = styled.div`
  margin-bottom: ${DSTokenMap.SPACE_2X.toString()};
  width: 100%;
  font-weight: 700;
  color: ${DSTokenMap.TEXT_COLOR_SECONDARY.toString()};
`;

export default FilterLegend;
