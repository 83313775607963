import { FC, useCallback } from 'react';
import { t } from '@lingui/macro';

import { Button, Flex } from '@rover/kibble/core';
import {
  FilterWrapper,
  SpacesRequired,
} from '@rover/react-lib/src/pages/search/SearchPage/components/Filters';
import {
  CatPreferences,
  DogPreferences,
} from '@rover/react-lib/src/pages/search/SearchPage/components/Filters/Preferences';
import { useSearchWizard } from '@rover/react-lib/src/pages/search/SearchPage/components/Modals/SearchWizardModal/context/SearchWizardContext';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import PetSize from '@rover/shared/js/search/components/PetSize';
import { SearchFilters } from '@rover/types';

export const PetInformation: FC = () => {
  const {
    searchFilters,
    weightUnit,
    petSizeBuckets,
    showControls: { showPetSize, showSpacesRequired, showCatPreferences, showDogPreferences },
    updateFilters,
    handleSubmit,
  } = useSearchWizard();
  const { i18n } = useI18n();

  const handlePropChange = useCallback(
    (key: keyof SearchFilters) => (value: SearchFilters[keyof SearchFilters]) => {
      updateFilters({ [key]: value });
    },
    [updateFilters]
  );

  const handleSizeChange = useCallback(
    (sizeKey: string | number): void => updateFilters({ [sizeKey]: !searchFilters[sizeKey] }),
    [searchFilters, updateFilters]
  );

  return (
    <>
      {showSpacesRequired && (
        <FilterWrapper>
          <SpacesRequired
            searchFilters={searchFilters}
            onChange={handlePropChange('spacesRequired')}
          />
        </FilterWrapper>
      )}
      {showPetSize && (
        <FilterWrapper>
          <PetSize
            displayAsIconButtons
            label={i18n._(t`What size are your dogs (${weightUnit})?`)}
            searchFilters={searchFilters}
            weightUnit={weightUnit}
            onChange={handleSizeChange}
            petSizeBuckets={petSizeBuckets}
          />
        </FilterWrapper>
      )}

      {showCatPreferences && (
        <FilterWrapper>
          <CatPreferences onChange={handlePropChange('noCats')} value={searchFilters.noCats} />
        </FilterWrapper>
      )}

      {showDogPreferences && (
        <FilterWrapper>
          <DogPreferences
            onChange={handlePropChange('personDoesNotHaveDogs')}
            value={searchFilters.personDoesNotHaveDogs}
          />
        </FilterWrapper>
      )}

      <Flex justifyContent="flex-end">
        <Button variant="primary" onClick={handleSubmit}>
          {i18n._(t`Search Now`)}
        </Button>
      </Flex>
    </>
  );
};
