import React from 'react';

import { Connect } from '@rover/react-lib/src/components/utils/Redux';
import { selectors as searchPageSelectors } from '@rover/react-lib/src/pages/search/SearchPage/SearchPage.duck';
import { selectors as currentUserSelectors } from '@rover/react-lib/src/redux/ducks/currentUser.duck';
import { selectors as frontendConfigurationSelectors } from '@rover/react-lib/src/redux/ducks/frontendConfiguration.duck';
import {
  effects as searchEffects,
  selectors as searchSelectors,
} from '@rover/react-lib/src/redux/ducks/search.duck';

import { selectors as searchFiltersSelectors } from '../../SearchFilters.duck';
import { actions as searchMapActions, selectors as searchMapSelectors } from '../../SearchMap.duck';

import SearchResults from './SearchResults';

type Props = {
  searchCardRefs?: (instance: HTMLDivElement, index: number) => void;
  isSeoPage: boolean;
};
const effects = {
  onFavoriteToggled: searchEffects.toggleFavorite,
};
const actions = {
  onResultCardMouseEnter: searchMapActions.resultCardHovered,
  onResultCardMouseLeave: searchMapActions.resultCardHoveredOut,
};

const selector = (state) => ({
  alternateSearchResults: searchSelectors.getAlternateSearchResults(state),
  alternateServiceName: searchSelectors.getAlternateServiceName(state),
  alternateServiceDescription: searchSelectors.getAlternateServiceDescription(state),
  bestMatchConfigs: searchSelectors.getBestMatchConfigs(state),
  bestMatchSearchResults: searchSelectors.getBestMatchSearchResults(state),
  isBestMatchExperience: searchSelectors.getIsBestMatchExperience(state),
  isBestMatchesSearch: searchSelectors.getBestMatchConfigs(state).length > 0,
  // Currently based on excluded person OPKs. If this object is used by other parts of search, we'll have to rework this
  hasBestMatches: (searchSelectors.getExcludedPersonOpks(state) ?? []).length > 0,
  currentFilters: searchFiltersSelectors.getFilters(state),
  didFiltersChangeSincePageLoad: searchFiltersSelectors.getDidFiltersChangeSincePageLoad(state),
  hoveringResultIndex: searchMapSelectors.getHoveringResultIndex(state),
  isAuthenticated: currentUserSelectors.isAuthenticated(state),
  isMapExpanded: searchMapSelectors.getIsExpanded(state),
  language: frontendConfigurationSelectors.getLanguage(state),
  memberProfileLinkParams: searchFiltersSelectors.getMemberProfileLinkParams(state),
  searchErrorMessage: searchSelectors.getSearchErrorMessage(state),
  searchResults: searchSelectors.getSearchResults(state),
  searchCallouts: searchSelectors.getSearchCallouts(state),
  searchHeader: searchSelectors.getSearchHeader(state),
  searchResponseNumberOfResults: searchSelectors.getSearchResponseTotalNumberOfResults(state),
  preferCatReviews: searchSelectors.getShouldPreferCatReviews(state),
  selectedProviderResult: searchSelectors.getSelectedProvider(state),
  searchQueryDates: searchSelectors.getQueryDateRange(state),
  searchQueryDistinctDates: searchSelectors.getQueryDistinctDates(state),
  rolloutPriceTransparency: searchPageSelectors.getRolloutPriceTransparency(state),
});

const SearchResultsContainer = (props: Props): JSX.Element => (
  <Connect selector={selector} effects={effects} actions={actions}>
    {(connectedProps) => <SearchResults {...connectedProps} {...props} />}
  </Connect>
);

export default SearchResultsContainer;
