import React from 'react';

import { Connect } from '@rover/react-lib/src/components/utils/Redux';
import {
  actions as searchMapActions,
  selectors as searchMapSelectors,
} from '@rover/react-lib/src/pages/search/SearchPage/SearchMap.duck';
import { selectors as currentCountryConfigurationSelectors } from '@rover/react-lib/src/redux/ducks/countryConfiguration.duck';
import { selectors as frontendConfigurationSelectors } from '@rover/react-lib/src/redux/ducks/frontendConfiguration.duck';
import { selectors as searchSelectors } from '@rover/react-lib/src/redux/ducks/search.duck';

import type { MapMetadata } from '../GoogleMap';

import SearchMap from './SearchMap';

type Props = {
  onMapMoved: (arg0: MapMetadata) => void;
  onMarkerClick: (index: number) => void;
  onZoomChanged: (arg0: MapMetadata) => void;
};

const actions = {
  onMouseOver: searchMapActions.resultMarkerHovered,
  onMouseOut: searchMapActions.resultMarkerHoveredOut,
};

const selector = (state) => ({
  center: searchSelectors.getCenterLatLng(state),
  showStaticMap: searchMapSelectors.getShowStaticMap(state),
  showDynamicMap: searchMapSelectors.getShowDynamicMap(state),
  mapData: searchSelectors.getMapData(state),
  zoomLevel: searchSelectors.getZoomLevel(state),
  searchResults: searchSelectors.getSearchResults(state),
  bestMatchResults: searchSelectors.getBestMatchSearchResults(state),
  searchInitiatedByMap: searchSelectors.getSearchInitiatedByMap(state),
  settings: frontendConfigurationSelectors.getSettings(state),
  mapStyle: currentCountryConfigurationSelectors.getGoogleMapsConfiguration(state),
  rolloutSignStaticMap: searchMapSelectors.getRolloutSignStaticMap(state),
  hoveringResultIndex: searchMapSelectors.getHoveringResultIndex(state),
  zIndex: searchMapSelectors.getMarkerZIndex(state),
});

const SearchMapContainer = (props: Props): JSX.Element => (
  <Connect actions={actions} selector={selector}>
    {(connectedProps) => <SearchMap {...connectedProps} {...props} />}
  </Connect>
);

export default SearchMapContainer;
