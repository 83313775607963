/* eslint-disable rover/no-platform-specific-globals-or-imports */
import type { ComponentType } from 'react';
import React, { Component } from 'react';

import { captureError } from '@rover/rsdk/src/modules/ErrorReporting';

import GAPIError from './GAPIError';

const getSrc = (channel) => {
  const channelParam = channel ? `&channel=${channel}` : '';
  return `https://maps.googleapis.com/maps/api/js?client=gme-aplaceforroverinc&v=3${channelParam}`;
};

export type Props = {
  channel?: string;
  timeoutInSeconds: number;
  onError: () => void;
};

type State = {
  loaded: boolean;
  didError: boolean;
};

/**
 *
 * @deprecated
 * This component is deprecated in favor of using the `@vis.gl/react-google-maps`
 * package
 * */
export default (WrappedComponent: ComponentType<any>): any =>
  class GAPI extends Component<Props, State> {
    timeoutId: ReturnType<typeof setTimeout> | null | undefined;

    static defaultProps = {
      timeoutInSeconds: 30,
      onError: () => {},
    };

    constructor(props: Props, context: any) {
      super(props, context);
      this.state = {
        loaded: !!window.google,
        didError: false,
      };
    }

    componentDidMount() {
      if (!this.state.loaded && !window.google) {
        const script = document.createElement('script');
        script.src = getSrc(this.props.channel);
        script.async = true;
        script.onload = this.handleLoad;
        script.onerror = this.handleError;

        if (document.body) {
          document.body.appendChild(script);
          this.timeoutId = setTimeout(this.handleError, this.props.timeoutInSeconds * 1000);
        } else {
          captureError(new Error('GAPI tried to mount without a document.body'));
        }
      }
    }

    handleLoad = () => {
      // don't handle load if we already errored
      if (this.state.didError) {
        return;
      }

      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }

      this.setState(() => ({
        loaded: true,
      }));
    };

    handleError = () => {
      this.setState(() => ({
        didError: true,
      }));
      this.props.onError();
    };

    render() {
      const { loaded, didError } = this.state;

      if (loaded || window.google) {
        return <WrappedComponent google={window.google} {...this.props} />;
      }

      if (didError) {
        return <GAPIError />;
      }

      return null;
    }
  };
