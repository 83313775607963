import React from 'react';

import { Connect } from '@rover/react-lib/src/components/utils/Redux';
import {
  effects as searchFiltersEffects,
  selectors as searchFiltersSelectors,
} from '@rover/react-lib/src/pages/search/SearchPage/SearchFilters.duck';
import {
  effects as searchPageEffects,
  selectors as searchPageSelectors,
} from '@rover/react-lib/src/pages/search/SearchPage/SearchPage.duck';
import { selectors as frontendConfigurationSelectors } from '@rover/react-lib/src/redux/ducks/frontendConfiguration.duck';
import { selectors as serviceTypeSelectors } from '@rover/react-lib/src/redux/ducks/serviceTypes.duck';

import CondensedFiltersCollection from './CondensedFiltersCollection';

export type Props = {
  showAdvancedFiltersModal: () => void;
};

const effects = {
  updateFilters: searchFiltersEffects.updateFilters,
  updateFiltersAndFireSearchOnChange: searchPageEffects.updateFiltersAndFireSearchOnChange,
};

const selector = (state) => ({
  advancedFiltersCount: searchFiltersSelectors.getAdvancedFiltersCount(state),
  getServiceTypeBySlug: serviceTypeSelectors.getServiceTypeBySlug(state),
  isCatOnly: searchFiltersSelectors.getIsCatOnly(state),
  language: frontendConfigurationSelectors.getLanguage(state),
  priceFilterMaxValue: searchPageSelectors.getPriceFilterMaxValue(state),
  priceFilterMinValue: searchPageSelectors.getPriceFilterMinValue(state),
  searchFilters: searchFiltersSelectors.getFilters(state),
  serviceTypeFilterOptions: searchFiltersSelectors.getServiceTypeFilterOptions(state),
  serviceTypeOptions: searchFiltersSelectors.getPetTypeBrowsableServiceTypeOptions(state),
});

const CondensedFiltersCollectionContainer = (props: Props): JSX.Element => (
  <Connect selector={selector} effects={effects}>
    {(connectedProps) => <CondensedFiltersCollection {...connectedProps} {...props} />}
  </Connect>
);

export default CondensedFiltersCollectionContainer;
