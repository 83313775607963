import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { TABLET_MAX } from '@rover/kibble/styles';
import useMatchMedia from '@rover/react-lib/src/hooks/useMatchMedia';
import events from '@rover/react-lib/src/pages/search/SearchPage/analytics';
import { Props as SearchWizardModalContainerProps } from '@rover/react-lib/src/pages/search/SearchPage/components/Modals/SearchWizardModal/SearchWizardModalContainer';
import {
  PetCountInputCloseEvent,
  PetCountInputOpenEvent,
} from '@rover/react-lib/src/pages/search/SearchPage/components/PetCounter/analyticsEvents';
import {
  shouldShowControls,
  ShowControls,
} from '@rover/react-lib/src/pages/search/SearchPage/utilities';
import { WizardPageType } from '@rover/react-lib/src/pages/search/SearchPage/WizardPageType';
import { ApiFrontendConfigurationRetrieve200 } from '@rover/rsdk/src/apiClient/latest';
import { emitAnalyticsEvent } from '@rover/rsdk/src/modules/Analytics';
import { SEARCH_SOURCE } from '@rover/shared/js/constants/searchPage.constants';
import {
  DataLayerEvent,
  SearchFilters,
  SearchFiltersOptions,
  ServiceType,
  ServiceTypeOption,
} from '@rover/types';

// NOTE: These props are passed through to the Context as props from redux
type ConnectedProps = {
  getServiceTypeBySlug: (arg0: string) => ServiceType | null | undefined;
  fireAnalyticsEvent: (
    payload: DataLayerEvent | ((filters: Partial<SearchFilters>) => DataLayerEvent)
  ) => void;
  searchFilters: SearchFilters;
  updateFiltersAndFireSearchOnChange: (arg0: Record<string, unknown>) => void;
  updateFilters: (arg0: Partial<SearchFilters>) => void;
  setShowStaticMap: (arg0: boolean) => void;
  setShowDynamicMap: (arg0: boolean) => void;
  serviceTypeFilterOptions: SearchFiltersOptions;
  serviceTypeOptions: ServiceTypeOption[];
  hasPets: boolean;
  isCatOnly: boolean;
  petSizeBuckets: ApiFrontendConfigurationRetrieve200['settings']['dogSizeBuckets'];
  weightUnit: ApiFrontendConfigurationRetrieve200['settings']['weightUnit'];
};

type ContextValue = {
  currentStep: number;
  isOpen: boolean;
  handleClose: (props?: { isSubmit?: boolean }) => void;
  serviceType?: ServiceType | null;
  showControls: ShowControls;
  handleSubmit: () => void;
  isLastStep: boolean;
} & Omit<ConnectedProps, 'getServiceTypeBySlug' | 'setShowStaticMap' | 'setShowDynamicMap'>;

export const SearchWizardContext = createContext<ContextValue>({} as ContextValue);

const MAX_STEP = 2;

export type ProviderProps = SearchWizardModalContainerProps & ConnectedProps;

export const SearchWizardProvider: FC<PropsWithChildren<ProviderProps>> = ({
  children,
  isCatOnly,
  hasPets,
  searchFilters,
  serviceTypeFilterOptions,
  getServiceTypeBySlug,
  setShowDynamicMap,
  setShowStaticMap,
  onClose,
  updateFiltersAndFireSearchOnChange,
  fireAnalyticsEvent,
  ...rest
}) => {
  const isDesktopSize = useMatchMedia(`(min-width:${TABLET_MAX + 1}px)`);
  const [currentStep, setCurrentStep] = useState(
    searchFilters.startDate && searchFilters.endDate ? 1 : 0
  );
  const handleClose = useCallback(
    ({ isSubmit }: { isSubmit?: boolean } = {}) => {
      if (isDesktopSize) {
        setShowDynamicMap(true);
        setShowStaticMap(false);
      }

      // The modal was dismissed on the PetCounter
      if (!isSubmit && currentStep === 1) {
        emitAnalyticsEvent(new PetCountInputCloseEvent({ triggeredFrom: 'PreSearch' }));
      }
      onClose();
    },
    [currentStep, isDesktopSize, onClose, setShowDynamicMap, setShowStaticMap]
  );

  const isLastStep = useMemo(
    () => currentStep === MAX_STEP || (currentStep === 1 && isCatOnly) || hasPets,
    [currentStep, hasPets, isCatOnly]
  );

  const handleSubmit = useCallback(() => {
    if (currentStep === 0 && !isLastStep) {
      emitAnalyticsEvent(new PetCountInputOpenEvent({ triggeredFrom: 'PreSearch' }));
    }

    if (currentStep === 1) {
      emitAnalyticsEvent(new PetCountInputCloseEvent({ triggeredFrom: 'PreSearch' }));
    }

    if (isLastStep) {
      fireAnalyticsEvent(events.SearchOwnerPreferencesCloseEvent);
      updateFiltersAndFireSearchOnChange({
        ...searchFilters,
        changeSource: SEARCH_SOURCE.SEARCH_MODAL,
      });
      handleClose({ isSubmit: true });
    } else {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  }, [
    currentStep,
    isLastStep,
    fireAnalyticsEvent,
    updateFiltersAndFireSearchOnChange,
    searchFilters,
    handleClose,
  ]);

  const context: ContextValue = useMemo(() => {
    const serviceType = getServiceTypeBySlug(searchFilters.serviceType);
    const showControls = shouldShowControls({
      hasPets,
      isCatOnly,
      pageType: WizardPageType.MODAL,
      serviceOptions: serviceTypeFilterOptions,
      serviceType: serviceType || undefined, // default null to undefined to avoid type error
      serviceTypeSlug: searchFilters.serviceType,
    });

    return {
      currentStep,
      showControls,
      serviceType,
      searchFilters,
      serviceTypeFilterOptions,
      isCatOnly,
      hasPets,
      isLastStep,
      handleSubmit,
      handleClose,
      fireAnalyticsEvent,
      updateFiltersAndFireSearchOnChange,
      ...rest,
    };
  }, [
    searchFilters,
    hasPets,
    isCatOnly,
    serviceTypeFilterOptions,
    currentStep,
    isLastStep,
    handleSubmit,
    handleClose,
    fireAnalyticsEvent,
    updateFiltersAndFireSearchOnChange,
    getServiceTypeBySlug,
    rest,
  ]);

  return <SearchWizardContext.Provider value={context}>{children}</SearchWizardContext.Provider>;
};

export const useSearchWizard = (): ContextValue => {
  const context = useContext(SearchWizardContext);

  if (!context) {
    throw new Error('useSearchWizard must be used within a SearchWizardProvider');
  }

  return context;
};
